import React, { useEffect, useState } from "react";
import {
  Alert,
  Bets,
  CreatingSubUser,
  EntryandLimitInformation,
  ErrorAlert,
  ErrorUnAlert,
  SettingsToUse,
  UnLimitAlert,
  WorkWithTheSystem,
} from "./index";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ResetInputs,setTextColor,setBackgroundColor,setGameConfig } from "../redux/actions/actions";
import Cookies from "js-cookie";
import Language from '../language.json'

const NewUser = ({
  newUsersUserType,
  newUsersUsername,
  newUsersPassword,
  newUsersEmail,
  newUsersRememberName,
  newUsersPoint,
  newUsersPlayerLimit,
  playerLimitCheckbox,
  newUsersAgencyLimit,
  agencyLimitCheckbox,
  newUsersSuperLimit,
  superLimitCheckbox,
  newUsersDealerLimit,
  dealerLimitCheckbox,
  newUsersAllSystem,
  newUsersAllSystemRule,
  newUsersPreMatch,
  newUsersPreMatchRule,
  newUsersLive,
  newUsersLiveRule,
  newUsersVideoGame,
  newUsersVideoGameRule,
  backgroundColor,
  textColor,
  gameConfig,
  setGameConfig
}) => {
  const [limits,setlimits] = useState([])
  const [sportGames,setSportGames] = useState([])
  const [videoGames,setVideoGames] = useState([])
  const [virtualGames,setVirtualGames] = useState([])
  const [alert,setAlert] = useState(null)
  const [errorAlert,setErrorAlert] = useState(null)
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  const [errorUnLimitAlert,setErrorUnLimitAlert] = useState(null)
  const dispatch = useDispatch();
  const selectedGames = useSelector((state) => state.selectedGames);


  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + '/api/on_off_games'; 
  const apilimitUrl = process.env.REACT_APP_API_URL + '/api/getuserlimits'; 
  useEffect(()=>{
    axios.get(apiUrl,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      setSportGames(response.data.sport_games);
      setVideoGames(response.data.video_games);
      setVirtualGames(response.data.virtual_games);
      
    })
    .catch((error) => {
      console.error("API Error:", error);
    })

    axios.get(apilimitUrl,{
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      setlimits(response.data);
      
    })
    .catch((error) => {
      console.error("API Error:", error);
    })
  },[setSportGames,setVideoGames,setVirtualGames])

  const AllBets = [...sportGames,...videoGames,...virtualGames]

  const createUser = () => {
    const token = Cookies.get("token")
    const apiUrl = process.env.REACT_APP_API_URL + "/api/createuser"
    const comission = [
     {
       comission_main_type: 1,
       comission_type: newUsersAllSystem,
       comission_rule: parseInt(newUsersAllSystemRule) ? parseInt(newUsersAllSystemRule) : 25,
      },
      {
        comission_main_type: 2,
       comission_type: newUsersPreMatch,
       comission_rule: parseInt(newUsersPreMatchRule) ? parseInt(newUsersPreMatchRule) : 25,
      },
      {
        comission_main_type: 3,
       comission_type: newUsersLive,
       comission_rule: parseInt(newUsersLiveRule) ? parseInt(newUsersLiveRule) : 25,
      },
      {
      comission_main_type: 4,
      comission_type: newUsersVideoGame,
      comission_rule: parseInt(newUsersVideoGameRule) ? parseInt(newUsersVideoGameRule) : 25,
     }
    ]

  const selectedSportGamesToSend = sportGames.filter((data) =>
  selectedGames.includes(data)
).map((data) => {
  return {
    id: data.id,
    name: data.name,
    active: 0,
  };
});

const unselectedSportGamesToSend = sportGames.filter(
  (data) => !selectedGames.includes(data)
).map((data) => {
  return {
    id: data.id,
    name: data.name,
    active: 1,
  };
});
const selectedVideoGamesToSend = videoGames.filter((data) =>
selectedGames.includes(data)
).map((data) => {
return {
  id: data.id,
  name: data.name,
  active: 0,
};
});

const unselectedVideoGamesToSend = videoGames.filter(
(data) => !selectedGames.includes(data)
).map((data) => {
return {
  id: data.id,
  name: data.name,
  active: 1,
};
});

const selectedVirtualGamesToSend = virtualGames.filter((data) =>
selectedGames.includes(data)
).map((data) => {
return {
  id: data.id,
  name: data.name,
  active: 0,
};
});

const unselectedVirtualGamesToSend = virtualGames.filter(
(data) => !selectedGames.includes(data)
).map((data) => {
return {
  id: data.id,
  name: data.name,
  active: 1,
};
});

const sportGamesToSend = [...selectedSportGamesToSend, ...unselectedSportGamesToSend];
const videoGamesToSend = [...selectedVideoGamesToSend, ...unselectedVideoGamesToSend];
const virtualGamesToSend = [...selectedVirtualGamesToSend, ...unselectedVirtualGamesToSend];

console.log(newUsersSuperLimit)
    const postData = {
      user_type: newUsersUserType,
      username: newUsersUsername,
      pass: newUsersPassword,
      email: newUsersEmail,
      note: newUsersRememberName,
      start_limit: parseInt(newUsersPoint),
      user_created_limit: parseInt(newUsersPlayerLimit),
      agency_created_limit:parseInt(newUsersAgencyLimit),
      super_created_limit:parseInt(newUsersSuperLimit),
      dealer_created_limit:parseInt(newUsersDealerLimit),
      delete_rule: playerLimitCheckbox ? 1 : 0,
      comission:comission,
      sport_games:sportGamesToSend,
      video_games:videoGamesToSend,
      virtual_games:virtualGamesToSend
    }
    axios.post(apiUrl,postData,{
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then((response)=>{
      if(response.data.message){
        setAlert(response.data.message);
      }
      if(response.data.error){
        setErrorAlert(response.data.error)
      }
      if(response.data.errorUn){
        setErrorUnAlert(response.data.errorUn)
      }
      if(response.data.unlimit){
        setErrorUnLimitAlert(response.data.unlimit)
      }
    })
    .catch((error) => {
      console.error("API ERROR",error);
    })
  }

  return (
    <div>
      {
        errorAlert && 
        <ErrorAlert
        errorAlert={errorAlert}
        setErrorAlert={setErrorAlert}
        />
      }
      { alert && 
      <Alert
      alert={alert}
      setAlert={setAlert}
      />
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      {
        errorUnLimitAlert && (
          <UnLimitAlert
          errorUnLimitAlert={errorUnLimitAlert}
          setErrorUnLimitAlert={setErrorUnLimitAlert}
          />
        )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].newUser.titleNewUser}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="bg-[#fff]">
          <div className="flex flex-wrap w-[100%]">
            <div className="flex-none">
              <EntryandLimitInformation 
              textColor={textColor}
              backgroundColor={backgroundColor}
              limits={limits}
              />
            </div>
            {
                Number(Cookies.get("user_type")) !== 5 && 
              <div className="flex-1">
              <CreatingSubUser 
              textColor={textColor}
              backgroundColor={backgroundColor}
              limits={limits}
              />
               {Cookies.get("user_type") == 7 || Cookies.get("user_type")== 5 ?
              <WorkWithTheSystem 
              textColor={textColor}
              backgroundColor={backgroundColor}
              />:""}
            </div>
            }
            <div className="flex-1">
              <Bets 
              selectedGames={selectedGames} 
              dispatch={dispatch} 
              AllBets={AllBets}
              textColor={textColor}
              backgroundColor={backgroundColor}
              />
              <SettingsToUse 
              textColor={textColor}
              backgroundColor={backgroundColor}
              />
            </div>
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <Link>
          <button 
          className={`forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[${backgroundColor}] text-[#fff] cursor-pointer transition-all duration-300`}
          onClick={createUser}
          >
          {Language["en"].newUser.titleCreateUser}
          </button>
            </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newUsersUserType: state.newUsersUserType,
  newUsersUsername: state.newUsersUsername,
  newUsersPassword: state.newUsersPassword,
  newUsersEmail: state.newUsersEmail,
  newUsersRememberName: state.newUsersRememberName,
  newUsersPoint: state.newUsersPoint,
  newUsersPlayerLimit: state.newUsersPlayerLimit,
  playerLimitCheckbox: state.playerLimitCheckbox,
  newUsersAgencyLimit: state.newUsersAgencyLimit,
  agencyLimitCheckbox: state.agencyLimitCheckbox,
  newUsersSuperLimit: state.newUsersSuperLimit,
  superLimitCheckbox: state.superLimitCheckbox,
  newUsersDealerLimit: state.newUsersDealerLimit,
  dealerLimitCheckbox: state.dealerLimitCheckbox,
  newUsersAllSystem: state.newUsersAllSystem,
  newUsersAllSystemRule: state.newUsersAllSystemRule,
  newUsersPreMatch: state.newUsersPreMatch,
  newUsersPreMatchRule: state.newUsersPreMatchRule,
  newUsersLive: state.newUsersLive,
  newUsersLiveRule: state.newUsersLiveRule,
  newUsersAllBets: state.newUsersAllBets,
  newUsersVideoGame: state.newUsersVideoGame,
  newUsersVideoGameRule: state.newUsersVideoGameRule,
  textColor: state.textColor,
  backgroundColor: state.backgroundColor,
  gameConfig: state.gameConfig
})

export default connect(mapStateToProps,{setTextColor,setBackgroundColor})(NewUser);
