import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Coupon from "./Coupon";
import "./desktop.css";
import Language from "../language.json";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { setBackgroundColor, setTextColor } from "../redux/actions/actions";
import { connect } from "react-redux";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { GiWorld } from "react-icons/gi";
import Cookies from "js-cookie";
import Tracker from "./Tracker";

function LiveDetail({
  backgroundColor,
  setBackgroundColor,
  textColor,
  setTextColor,
  socket,
  click,
}) {
  const { eventsId } = useParams();
  const [matchData, setMatchData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [clickedLink, setClickedLink] = useState(null);
  const [selectedOdds, setSelectedOdds] = useState([]);
  const [disableOddClick, setDisableOddClick] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [goals, setGoals] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [handleLinks, setHandleLinks] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [resultdata, setResultData] = useState(false);
  const [lmtId,setLmtId] = useState("")
  const [lmtLoading,setLmtLoading] = useState(true)
  const [couponchange, setcouponchange] = useState(1)
  const handleLinkClick = (color) => {
    setClickedLink(color);
  };

  const flags = require("country-iso-3-to-2");
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const borderColor = {
    borderBottom: `2px solid ${backgroundColor}`,
   };

  const handleGroupButtonClick = (groupId) => {
    setSelectedGroupId(groupId);
  };
  useEffect(() => {
    setTimeout(() => {
      setLmtLoading(false)
    },2000)
  },[])

    const handleOddClick = (
      clickedOdd,
      marketName,
      matchData,
      eventsId,
      market,
      match
    ) => {
      const clickedOddApiId = clickedOdd?.ApiId;
      const isOddSelected = selectedOdds.some(
        (odd) => odd.event_id === Number(eventsId)
      );
    
      const clickedOddName = clickedOdd?.name;
      const clickedOddPrice = clickedOdd?.Price.toFixed(1);
      const clickedOddTime = matchData?.LiveCurrentTime;
      const clickedOddScore = matchData?.LiveScore;
      const teams = [
        matchData?.Competitors[0]?.competitor1?.name,
        matchData?.Competitors[1]?.competitor2?.name,
      ];
    
      const existingSelectedOdd = selectedOdds.find(
        (odd) => odd.event_id === Number(eventsId)
      );
    
      if (existingSelectedOdd) {
        removeLastSelectedOdd();
      }
    
      click(clickedOdd?.ApiId);
    
      const oddInfo = {
        odd_name: clickedOddName,
        teams: teams ? teams.join(" - ") : "",
        market_name: marketName,
        odd_price: clickedOddPrice,
        LiveCurrentTime: clickedOddTime,
        org_market_type:clickedOdd?.OrgMarketTypeId,
        LiveScore: clickedOddScore,
        event_id: Number(eventsId),
        is_live: matchData?.Status,
        market_api_id: market?.ApiId,
        market_id: market?._id,
        marketSportId: market?.SportMarketId,
        odd_api_id: clickedOdd?.ApiId,
        oddSelectionTypeId: clickedOdd?.SelectionTypeId,
        odd_id: clickedOdd?._id,
        api_group_id: market?.api_group_id
      };
    
      setDisableOddClick(true);
      setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, oddInfo]);
    };
    
  

  const removeLastSelectedOdd = () => {
    if (selectedOdds.length > 0) {
      const lastSelectedOddApiId =
        selectedOdds[selectedOdds.length - 1].odd_api_id;
      removeOddFromSelection(lastSelectedOddApiId);
    }
  };

  const removeOddFromSelection = (clickedOddApiId) => {
    setSelectedOdds((prevSelectedOdds) => {
      return prevSelectedOdds.filter(
        (odd) => odd.odd_api_id !== clickedOddApiId
      );
    });
    const element = document.getElementById(clickedOddApiId);
    if (element) {
      element.classList.remove("selected");
    }
  };

  useEffect(() => {
    // socket.on("disconnect", function () {
    //   window.location.reload();
    // });
    // socket.on("reconnect", function () {
    //   socket.emit("live-detail", Number(eventsId));
    //   socket.emit("event-result", Number(eventsId), sportId);
    // });
    setTextColor(textColor);
    const sportId = 66;
    socket.emit("live-detail", Number(eventsId));
    socket.emit("event-result", Number(eventsId), sportId);
    socket.on("live-detail", (data) => {
      let eventsdata = data;
      if (eventsdata) {
        setMatchData(eventsdata);
      } else {
        setMatchData([])
      }
    });

    function settlement(){
      var a=4568;
      console.log(a);
      var b=a+(45*5);
      console.log(b);
    }
    socket.on("event-result", (data) => {
      let eventsdata = data;
      if (data.error) {
        setResultData(false);
        setIsLoading(false);
      } else {
        setResultData(true);
        setEventData(eventsdata);
        setLmtId(eventsdata.lmtId)
        setGoals(eventsdata?.details?.goals);
        setIsLoading(false);
      }
    });
    socket.emit("live-general", 66);
    socket.on("live-general", (data) => {
      const events = data?.Result?.Items[0].Items;
      setLiveData(events);
    });
  }, [handleLinks, isLoading]);
  const handleLink = () => {
    if (handleLinks === 1) {
      setHandleLinks(0);
    }
    if (handleLinks === 0) {
      setHandleLinks(1);
    }
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = liveData?.filter((live) => {
    return live?.Events?.some((matches) => {
      return (
        matches.Competitors[0].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        ) ||
        matches.Competitors[1].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        )
      );
    });
  });

  const backgroundImages = {
    content: "",
    backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
  };

  const backgroundImages2 = {
    content: "",
    backgroundImage: `linear-gradient(-90deg, ${backgroundColor} 20%, transparent)`,
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
        <TailSpin
          height={80}
          width={80}
          color={`${backgroundColor}`}
          ariaLabel="tail-spin-loading"
          radius={1}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="pt-[15px]">
      <div
        className="container"
        style={isLoading ? { display: "none" } : { display: "flex" }}
      >
        <div className="content">
          <div className="livelistleft mt-[-50px]">
            <div className="leftlistsearch">
              <input
                type="text"
                className="input"
                id="livesearch"
                placeholder={Language["en"].live.teamsearch}
                onChange={handleSearch}
              />
            </div>
            {filteredData?.map(
              (live, index) =>
                live.Events?.length > 0 && (
                  <div className="leftulkebox p-[5px]">
                    <div className="leftulketitle">
                      <div>
                        {live.ISO ? (
                          <img
                            src={require(`../assest/4x3/${live?.ISO?.toLowerCase()}.svg`)}
                            width={"25px"}
                            alt="svg"
                            className="mr-2"
                          />
                        ) : (
                          <span className="flex items-center mr-3">
                            <GiWorld color="white" size={20} />
                          </span>
                        )}
                        {live?.CatName}
                      </div>
                      <span>{live?.Name}</span>
                    </div>
                    {live.Events.filter(
                      (o) =>
                        o.Competitors[0].Name.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        ) ||
                        o.Competitors[1].Name.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        )
                    ).map((matches, index) => (
                      <div key={index} className="leftmatchlist">
                        <div className="leftmatchbox">
                          <div
                            className="teams"
                            style={{ backgroundColor: backgroundColor }}
                          >
                            <span>
                              <Link
                                to={`/Detail/${matches?.Id}`}
                                onClick={() => setIsLoading(true)}
                              >
                                {matches.Competitors[0].Name} -{" "}
                                {matches.Competitors[1].Name}
                              </Link>
                            </span>
                          </div>
                          <div className="infos flex justify-between">
                            <div>{matches?.LiveScore}</div>
                            <div>
                              {parseInt(matches?.LiveCurrentTime?.split("'").join("")) > 45
                                ? Language["en"].live.secondhalf
                                : Language["en"].live.firsthalf}
                            </div>
                            <div className="left-[25px] relative">
                              {matches?.LiveCurrentTime}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
            )}
          </div>
          <div className="centered nomargin right-margin">
            <div className="sport-selected-tab-liveD  ml-5">
              <div
                style={backgroundImages}
                className="h-[3px] right-0 left-2 bottom-0 w-[100%] absolute rounded-[5px]"
              ></div>

              <Link
                to="/Live"
                className="bg-[#333]"
                onClick={() => handleLinkClick(Language["en"].live.soccer)}
              >
                {Language["en"].live.soccer}
              </Link>
              <Link
                to="/Live-Basketbol"
                className="bg-[#333]"
                onClick={() => handleLinkClick(Language["en"].live.basketball)}
              >
                {Language["en"].live.basketball}
              </Link>
              <Link style={{ backgroundColor: backgroundColor }}>
                <div>
                  {matchData?.Competitors?.[0]?.competitor1?.name} -
                  {matchData?.Competitors?.[1]?.competitor2?.name}
                </div>
              </Link>
            </div>
            <div className="liveres">
              <div className="dlive-center">
                <div className="dlive-header">
                  <div className="dlive-info">
                    <div className="dlive-ligname">
                      {matchData && matchData.length > 0 && (
                        <span>
                          <span
                            className={`fi fi-${String(
                              flags(matchData?.ISO)
                            ).toLowerCase()} mr-2`}
                          ></span>
                        </span>
                      )}

                      <div>
                        {matchData && matchData.ChampName
                          ? matchData.ChampName
                          : "no name"}
                      </div>
                    </div>

                    <div className="dnames" style={{ fontFamily: "Poppins" }}>
                      <div>
                        {matchData?.Competitors?.[0]?.competitor1?.name}
                        <span className="text-[#ccc]">
                          {matchData?.LiveScore?.split(":")[0]}
                          {
                            <span>
                              {resultdata && eventData.periods
                                ? eventData.periods.p1.home
                                : 0}
                            </span>
                          }
                        </span>
                      </div>
                      <div>
                        <span className="text-[#ccc]">
                          {matchData?.LiveScore?.split(":")[1]}
                          {
                            <span>
                              {resultdata && eventData.periods
                                ? eventData.periods.p1.away
                                : 0}
                            </span>
                          }
                        </span>
                        {matchData?.Competitors?.[1]?.competitor2?.name}
                      </div>
                    </div>

                    <div>
                      <div className="dlive-devre">
                        {Number(matchData?.LiveCurrentTime?.split("'").join("")) < 46
                          ? Language["en"].live.firsthalf
                          : Language["en"].live.secondhalf}{" "}
                        {matchData?.LiveCurrentTime}
                      </div>
                      {resultdata && (
                        <div className="flex gap-2">
                          <div className="flex-1 flex flex-wrap  w-[50%] justify-end">
                            {resultdata &&
                              goals.homeGoals.length > 0 &&
                              goals.homeGoals.map((goal, index) => (
                                <div
                                  key={index}
                                  className="flex justify-center gap-5"
                                >
                                  <div className="flex flex-row flex-wrap flex-1 gap-1 justify-start rounded-tl-[5px] rounded-bl-[5px]">
                                    <div className="text-[#fff] text-[11px] flex mr-[5px] mb-[5px] h-[30px] items-center">
                                      <div className="py-[5px] px-[10px] bg-[#111]">
                                        {goal.count}.{Language["en"].live.Goal}
                                      </div>
                                      <div className="py-[5px] px-[10px] bg-[#444] rounded-tr-[5px] rounded-br-[5px]">
                                        {Number(goal.time)}'
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="flex flex-1 flex-wrap w-[50%]">
                            {resultdata &&
                              goals.homeGoals.length > 0 &&
                              goals.awayGoals.map((goal, index) => (
                                <div
                                  key={index}
                                  className="flex justify-center flex-wrap gap-5"
                                >
                                  <div className="flex flex-row flex-wrap flex-1 gap-1 justify-start rounded-tl-[5px] rounded-bl-[5px]">
                                    <div className="text-[#fff] text-[11px] flex mr-[5px] mb-[5px] h-[30px] items-center">
                                      <div className="py-[5px] px-[10px] bg-[#444] rounded-tl-[5px] rounded-bl-[5px]">
                                        {Number(goal.time)}'
                                      </div>
                                      <div className="py-[5px] px-[10px] bg-[#111]">
                                        {goal.count}.{Language["en"].live.Goal}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {resultdata && (
                    <div className="dlive-visual">
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.offsides}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.shotontargets.home === 0 &&
                                eventData.details.shotontargets.away === 0
                                  ? "1"
                                  : eventData.details.shotontargets.home
                              }`,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.shotontargets.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.shotontargets.away === 0 &&
                                eventData.details.shotontargets.home === 0
                                  ? "1"
                                  : eventData.details.shotontargets.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.shotontargets.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.throwins}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.shotofftargets.home === 0 &&
                                eventData.details.shotofftargets.away === 0
                                  ? "1"
                                  : eventData.details.shotofftargets.home
                              }`,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.shotofftargets.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.shotofftargets.home === 0 &&
                                eventData.details.shotofftargets.away === 0
                                  ? "1"
                                  : eventData.details.shotofftargets.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.shotofftargets.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.corners}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.corners.home === 0 &&
                                eventData.details.corners.away === 0
                                  ? "1"
                                  : eventData.details.corners.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.corners.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.corners.home === 0 &&
                                eventData.details.corners.away === 0
                                  ? "1"
                                  : eventData.details.corners.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.corners.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.freekicks}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.freekicks.home === 0 &&
                                eventData.details.freekicks.away === 0
                                  ? "1"
                                  : eventData.details.freekicks.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.freekicks.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.freekicks.home === 0 &&
                                eventData.details.freekicks.away === 0
                                  ? "1"
                                  : eventData.details.freekicks.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.freekicks.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.yellowcards}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.yellowcard.home === 0 &&
                                eventData.details.yellowcard.away === 0
                                  ? "1"
                                  : eventData.details.yellowcard.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.yellowcard.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.yellowcard.home === 0 &&
                                eventData.details.yellowcard.away === 0
                                  ? "1"
                                  : eventData.details.yellowcard.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.yellowcard.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.redcards}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.redcard.home === 0 &&
                                eventData.details.redcard.away === 0
                                  ? "1"
                                  : eventData.details.redcard.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.redcard.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.redcard.home === 0 &&
                                eventData.details.redcard.away === 0
                                  ? "1"
                                  : eventData.details.redcard.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.redcard.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.penalties}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.penalty_rewardeds.home ===
                                  0 &&
                                eventData.details.penalty_rewardeds.away === 0
                                  ? "1"
                                  : eventData.details.penalty_rewardeds.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.penalty_rewardeds.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.penalty_rewardeds.home ===
                                  0 &&
                                eventData.details.penalty_rewardeds.away === 0
                                  ? "1"
                                  : eventData.details.penalty_rewardeds.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.penalty_rewardeds.away}
                          </div>
                        </div>
                      </div>
                      <div className="visual-line">
                        <div className="visual-line-title">
                          {Language["en"].live.substitution}
                        </div>
                        <div className="visual-line-chart">
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.goalkicks.home === 0 &&
                                eventData.details.goalkicks.away === 0
                                  ? "1"
                                  : eventData.details.goalkicks.home
                              } `,
                              backgroundColor: backgroundColor,
                            }}
                            className="fazla"
                          >
                            {eventData.details.goalkicks.home}
                          </div>
                          <div
                            style={{
                              flexGrow: `${
                                eventData.details.goalkicks.home === 0 &&
                                eventData.details.goalkicks.away === 0
                                  ? "1"
                                  : eventData.details.goalkicks.away
                              } `,
                            }}
                            className="fazla"
                          >
                            {eventData.details.goalkicks.away}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-between edit-buttons">
                  <>
                    {Number(Cookies.get("user_type")) === 7 && (
                      <div>
                        <Link>{Language["en"].live.removematch}</Link>
                        <Link>{Language["en"].live.oddedit}</Link>
                      </div>
                    )}
                  </>
                  <div>
                    <div className="flex space-x-4 p-2">
                      <button
                        key="all"
                        style={{ ...(!selectedGroupId ? borderColor : {})}}
                        className={`text-[#fff] text-[12px] w-full bg-[#333333] whitespace-nowrap py-2 px-[15px] rounded hover:bg-[#444] ${
                          !selectedGroupId && `bg-[#1b1b1b] hover:bg-[#1b1b1b]`
                         }`}
                        onClick={() => handleGroupButtonClick(null)}
                      >
                        All
                      </button>
                
                      {matchData && matchData.MarketGroups && matchData?.MarketGroups?.map((group) => (
                        <button
                          key={group.Id}
                          style={{...(selectedGroupId === group.Id ? borderColor : {})}}
                          className={`text-[#fff] text-[12px] w-full bg-[#333333] whitespace-nowrap py-2 px-[15px] rounded hover:bg-[#444] ${
                            selectedGroupId === group.Id && "bg-[#1b1b1b] hover:bg-[#1b1b1b]"
                           }`}
                          onClick={() => handleGroupButtonClick(group.Id)}
                        >
                          {group.Name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="dlive-oranlar">
                  <div
                    style={backgroundImages2}
                    className="h-[3px] right-0 left-0 top-0 w-[100%] absolute rounded-[5px]"
                  ></div>

                  <div className="dlive-blok">
                  {console.log(selectedGroupId)}
                    {matchData?.MarketGroups?.filter((group) => !selectedGroupId || group.Id == selectedGroupId ).map((group, groupIndex) => (
                      <div key={groupIndex}>
                        {group.Id === 23
                          ? ""
                          : group.Markets?.slice(
                              0,
                              group.Markets?.length / 2
                            ).map((market, marketIndex) => (
                              <div className="dlive-oranbox" key={marketIndex}>
                                <div
                                  className="dlive-oranbox-title"
                                  style={{ color: `${textColor}` }}
                                >
                                  {market.Name}
                                </div>
                                <div className="dlive-oranlist">
                                  {market.Odds[0]?.SPOV
                                    ? market.Odds ==
                                      market.Odds?.sort(function (a, b) {
                                        return a?.SPOV - b?.SPOV;
                                      })
                                    : ""}
                                  {market.Odds.map((odd, oddIndex) => (
                                    <div
                                      key={oddIndex}
                                      className={` ${
                                        market.ColumnCount === 2
                                          ? "w-[50%]"
                                          : market.ColumnCount === 3
                                          ? "w-[33.33%]"
                                          : "w-full"
                                      }`}
                                    >
                                      <div
                                        id={odd.ApiId}
                                        onClick={() =>
                                          (handleOddClick(
                                            odd,
                                            market?.Name,
                                            matchData,
                                            eventsId,
                                            market
                                          ),setcouponchange(1))
                                        }
                                        className={`dlive-odd h-[36.5px] ots2-detail  ${odd?.RecentStatus}`}
                                      >
                                        <span className="titles">{`${
                                          odd.additionalName === "bos" ||
                                          odd.additionalName === null
                                            ? odd.name
                                            : odd.additionalName
                                        }`}</span>
                                        <span className="odd">
                                          {odd && odd.Price
                                            ? odd.Price.toFixed(2)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                      </div>
                    ))}
                  </div>
                  <div className="dlive-blok">
                    {matchData?.MarketGroups?.filter(
         (group) => !selectedGroupId || group.Id == selectedGroupId
        ).map((group, groupIndex) => (
                      <div key={groupIndex}>
                        {group.Id === 23
                          ? ""
                          : group.Markets?.slice(
                            group.Markets?.length / 2,
                              group.Markets?.length
                            ).map((market, marketIndex) => (
                              <div className="dlive-oranbox" key={marketIndex}>
                                <div
                                  className="dlive-oranbox-title"
                                  style={{ color: `${textColor}` }}
                                >
                                  {market.Name}
                                </div>
                                <div className="dlive-oranlist">
                                  {market.Odds.map((odd, oddIndex) => (
                                    <div
                                      key={oddIndex}
                                      className={` ${
                                        market.ColumnCount === 2
                                          ? "w-[50%]"
                                          : market.ColumnCount === 3
                                          ? "w-[33.33%]"
                                          : "w-full"
                                      }`}
                                    >
                                      <div
                                        className={`dlive-odd h-[36.5px] ots2-detail ${odd?.RecentStatus}`}
                                        id={odd.ApiId}
                                        onClick={() =>
                                          (handleOddClick(
                                            odd,
                                            market?.Name,
                                            matchData,
                                            eventsId,
                                            market
                                          ),setcouponchange(1))
                                        }
                                      >
                                        <span className="titles">
                                          {odd.name}
                                        </span>
                                        <span className="odd" value={odd.Price}>
                                          {odd && odd.Price
                                            ? odd.Price.toFixed(1)
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right"></div>
        </div>
        {
          eventData && lmtId &&
          <>
          <div className="title" style={{
            position: "absolute",
            top: "76px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            width: "283px",
            color: "white",
            right: "13px",
            zIndex: "9999",
            textTransform: "capitalize"
          }}>visual representation </div>
          <div
          style={{
            position: "absolute",
            top: "110px",
            right: "8px",
            zIndex: "9999",
          }}
        >
          <Tracker lmtId={lmtId} lmtLoading={lmtLoading} setLmtLoading={setLmtLoading}
          />
        </div>
        </>
        }
        <div
          style={{
            position: "absolute",
            top: `${ eventData && lmtId ? "500px" : "80px"}`,
            right: "8px",
            zIndex: "9999",
            overflow: "hidden"
          }}
        >
          <Coupon
            selectedOdds={selectedOdds}
            setSelectedOdds={setSelectedOdds}
            couponchange={couponchange}
            setcouponchange={setcouponchange}
          />
        </div>
        
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
    textColor: state.textColor,
  };
};
export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(
  LiveDetail
);
