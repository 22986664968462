import React, { useEffect } from 'react'
import Language from '../../language.json'
import 
{
  couponWinLimitMaxPayValueUpdate,
  couponWinLimitSingleMaxPayValueUpdate,
  couponWinLimitLiveMaxPayValueUpdate,
  couponWinLimitLiveSingleMaxPayValueUpdate,
  couponWinLimitSysMaxPayValueUpdate
} from '../../redux/actions/actions'
import axios from 'axios';
import { connect } from 'react-redux';
import {IsLoading} from '../index';
import Cookies from "js-cookie";


const LimitsWhenWinningCoupons = ({
  couponWinLimitMaxPay,
  couponWinLimitMaxPayValueUpdate,
  couponWinLimitSingleMaxPay,
  couponWinLimitSingleMaxPayValueUpdate,
  couponWinLimitLiveMaxPay,
  couponWinLimitLiveMaxPayValueUpdate,
  couponWinLimitLiveSingleMaxPay,
  couponWinLimitLiveSingleMaxPayValueUpdate,
  couponWinLimitSysMaxX,
  couponWinLimitSysMaxPayValueUpdate,
  isLoading,
  setIsLoading,
  textColor,
  backgroundColor
}) => {
  
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config";
  useEffect(() => {
        axios.get(apiUrl,{
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then((response) => {
      setIsLoading(false)
      const couponWinLimitMaxPayValue = response.data.coupon_win_limit.max_pay
      const couponWinLimitSingleMaxPayValue = response.data.coupon_win_limit.single_max_pay
      const couponWinLimitLiveMaxPayValue = response.data.coupon_win_limit.live_max_pay
      const couponWinLimitLiveSingleMaxPayValue = response.data.coupon_win_limit.live_single_max_pay
      const couponWinLimitSysMaxPayValue = response.data.coupon_win_limit.sys_max_pay
      if(!couponWinLimitMaxPay){
        couponWinLimitMaxPayValueUpdate(couponWinLimitMaxPayValue)
      }
      if(!couponWinLimitSingleMaxPay){
        couponWinLimitSingleMaxPayValueUpdate(couponWinLimitSingleMaxPayValue)
      }
      if(!couponWinLimitLiveMaxPay){
        couponWinLimitLiveMaxPayValueUpdate(couponWinLimitLiveMaxPayValue)
      }
      if(!couponWinLimitLiveSingleMaxPay){
        couponWinLimitLiveSingleMaxPayValueUpdate(couponWinLimitLiveSingleMaxPayValue)
      }
      if(!couponWinLimitSysMaxX){
        couponWinLimitSysMaxPayValueUpdate(couponWinLimitSysMaxPayValue)
      }
    })
    .catch((error) => {
      console.error("API error:", error);
    })
  },[])
  

  return (
    <>
    {
    isLoading ?  
    <div className='flex w-full min-h-[100px] mt-3 justify-center items-center'> <IsLoading/> </div>
  :
    
    <div className='p-[5px]'>
     <div className={`pl-[35px] p-[10px] border-b-[1px] text-base relative`}
          style={{borderBottomColor:backgroundColor,color:textColor}}
          >
        {Language["en"].limitsWhenWinningCoupons.title}
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenWinningCoupons.generalMaxPayable}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={couponWinLimitMaxPay}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              couponWinLimitMaxPayValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenWinningCoupons.singleMaxPayable}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={couponWinLimitSingleMaxPay}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              couponWinLimitSingleMaxPayValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenWinningCoupons.liveMaxPayable}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={couponWinLimitLiveMaxPay}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              couponWinLimitLiveMaxPayValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenWinningCoupons.liveSingleMaxPayable}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={couponWinLimitLiveSingleMaxPay}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              couponWinLimitLiveSingleMaxPayValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenWinningCoupons.systematicMaxPayable} <br />
        <p className="text-[#777777] text-[11px] font-normal tracking-tight leading-3 text-xs mt-[-8px]">
        {Language["en"].limitsWhenWinningCoupons.text}
        </p>
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={couponWinLimitSysMaxX}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              couponWinLimitSysMaxPayValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
    </div>
  }</>
  )
}

const mapStateToProps = (state) => ({
  couponWinLimitMaxPay: state.couponWinLimitMaxPay,
  couponWinLimitSingleMaxPay: state.couponWinLimitSingleMaxPay,
  couponWinLimitLiveMaxPay: state.couponWinLimitLiveMaxPay,
  couponWinLimitLiveSingleMaxPay: state.couponWinLimitLiveSingleMaxPay,
  couponWinLimitSysMaxX: state.couponWinLimitSysMaxX
})

const mapDispatchToProps = {
  couponWinLimitMaxPayValueUpdate,
  couponWinLimitSingleMaxPayValueUpdate,
  couponWinLimitLiveMaxPayValueUpdate,
  couponWinLimitLiveSingleMaxPayValueUpdate,
  couponWinLimitSysMaxPayValueUpdate
}

export default connect(mapStateToProps,mapDispatchToProps)(LimitsWhenWinningCoupons)