import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
 DashboardHeader,
 Navbar,
 Footer,
 ManagementPanel,
 SystemSettings,
 GameToggle,
 ChangesCenter,
 AllCoupons,
 BettingDensity,
 LiveBettingDensity,
 MyUsers,
 NewUser,
 PersonOpeningLimit,
 OpenAndCloseQuickGame,
 OnlineUsers,
 DailyReport,
 ReportDetails,
 AccountActivite,
 VideoGameActivite,
 MyUsersUser,
 MyUsersUpdate,
 MyUsersDelete,
 MyUsersUserUpdate,
 GeneralBasis,
 BasedRateType,
 BasedSportType,
 LeagueBased,
 SingleMatchBased,
 RatioType,
 LeagueType,
 MatchDetails,
 ChangePassword,
 VideoGames,
} from "../components";
import MobileResults from "../components/mobile/MobileResults";
import MobileLiveResult from "../components/mobile/MobileLiveResult";
import MobileKupon from "../components/mobile/MobileKupon";
import MobileHesap from "../components/mobile/MobileHesap";
import MobilePassword from "../components/mobile/MobilePassword";
import MobileBasket from "../components/mobile/MobileBasket";
import Live from "../components/Live";
import Main from "../components/Main";
import ResultBasketball from "../components/ResultBasketball";
import LiveDetail from "../components/LiveDetail";
import LiveBasketbol from "../components/LiveBasketbol";
import MobileCasino from "../components/mobile/MobileCasino";
import Login from "../components/Login";
import { io } from "socket.io-client";
import VideoGamesPage from "../components/VideoGamesPage";
import Cookies from "js-cookie";
import { useState,useEffect } from "react";
import {setTextColor} from '../redux/actions/actions'
import { connect } from "react-redux";

const MainLayout = ({ children }) => (
 <div>
  <Navbar />
  {children}
 </div>
);

const Dashboard = ({ click,textColor }) => {
 const token = Cookies.get("token");
 const socket = io("https://feed.thoth.lt", {
  auth: {
   token: token,
  },
 });

 const globalStyle = `
   ::-webkit-scrollbar-thumb {
     background-color: ${textColor};
   }
 `;

 return (
  <>
  <style>{globalStyle}</style>
  <div className="Dashboard container mx-auto p-[15px]">
   <BrowserRouter>
    <Routes>
     <Route
      path="/"
      element={
       <MainLayout>
        {" "}
        <Main socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
     <Route path="/video-games" element={
      <MainLayout>
        <VideoGamesPage />
      </MainLayout>}>
     </Route>
     <Route
      path="/managementPanel"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <ManagementPanel token={token} />
        </>
       </MainLayout>
      }
     />
          <Route
      path="/login"
      element={
<Login></Login>
      }
     />
     <Route
      path="/changePassword"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <ChangePassword token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/system-settings"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <SystemSettings token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/game-toggle"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <GameToggle token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/changes-center"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <ChangesCenter token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/all-coupons"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <AllCoupons token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/betting-dentisy"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <BettingDensity token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/live-betting-dentisy"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <LiveBettingDensity token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/my-users"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MyUsers token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/new-user"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <NewUser token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/person-opening-limit"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <PersonOpeningLimit token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/open-and-close-quick-game"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <OpenAndCloseQuickGame token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/online-users"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <OnlineUsers token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/daily-report"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <DailyReport token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/report-details"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <ReportDetails token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/account-activite"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <AccountActivite token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/video-game-activite"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <VideoGameActivite token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/my-users-user/:user_id`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MyUsersUser token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/updateuser/:user_id`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MyUsersUpdate token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/updateusersuser/:user_id`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MyUsersUserUpdate token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/deleteuser/:user_id`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MyUsersDelete token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/general-basis`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <GeneralBasis token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/based-rate-type`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <BasedRateType token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={`/based-sport-type`}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <BasedSportType token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path={"/league-based"}
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <LeagueBased token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/single-match-based"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <SingleMatchBased token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/ratio-type"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <RatioType token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/league-type"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <LeagueType token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/match-details"
      element={
       <MainLayout>
        <>
         <DashboardHeader />
         <MatchDetails token={token} />
        </>
       </MainLayout>
      }
     />
     <Route
      path="/Casino"
      element={
       <MainLayout>
        <MobileCasino token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Live-Result"
      element={
       <MainLayout>
        <MobileResults token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Canlı"
      element={
       <MainLayout>
        <MobileLiveResult token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Kupon"
      element={
       <MainLayout>
        <MobileKupon token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Hesap"
      element={
       <MainLayout>
        <MobileHesap token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Sifre"
      element={
       <MainLayout>
        <MobilePassword token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Basket"
      element={
       <MainLayout>
        <MobileBasket token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Live"
      element={
       <MainLayout>
        <Live socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Results"
      element={
       <MainLayout>
        <Main socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Basketball"
      element={
       <MainLayout>
        <ResultBasketball socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Detail/:eventsId"
      element={
       <MainLayout>
        <LiveDetail socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
     <Route
      path="/Live-Basketbol"
      element={
       <MainLayout>
        <LiveBasketbol socket={socket} click={click} token={token} />
       </MainLayout>
      }
     />
    </Routes>
    {/* <Footer/> */}
   </BrowserRouter>
  </div>
  </>
 );
};
const mapStateToProps = (state) => ({
  textColor: state.textColor
})

export default connect(mapStateToProps,{setTextColor})(Dashboard);
