import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { setBackgroundColor, setTextColor } from '../redux/actions/actions';
import LeftMenu from "./LeftMenu";
import "./desktop.css";
import { Link } from "react-router-dom";
import Football from "./Football";
import { TailSpin } from "react-loader-spinner";
import SportSelectedTab from "./SportSelectedTab";
import Basketball from "./Basketball";
import Language from '../language.json'
import Cookies from "js-cookie";

function ResultBasketball({ backgroundColor, textColor,setTextColor, setBackgroundColor, socket, click }) {
  const lang = "en"
 const [clickedLink, setClickedLink] = useState(null);
 const [liveData, setLiveData] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const [eventIsLoading, setEventIsLoading] = useState(true)
 const [date2, setDate2] = useState();
 const [date1, setDate1] = useState(new Date());
 const [filterId,setFilterId] = useState("219")
 const [name, setName] = useState(null)
 const [color, setColor] = useState(Language[lang].ResultBasketballPage.textToday)
 const [catId, setCatId] = useState(null)
 const [footballGames,setFootballGames] = useState()


 const sportId = 67;
 const count = null;
 const champId = null;
 let date = new Date()
 date.setDate(date.getDate());
 date.setHours(23);
 date.setMinutes(59);
let nextDay = date.toISOString();
function encodeBase64UTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
  }));
}
function decodeBase64UTF8(encodedStr) {
return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
}).join(''));
}
useEffect(() => {
  const base64EncodedString = Cookies.get("gameConfig");
  const decodedString = decodeBase64UTF8(base64EncodedString);
  const gameConfigObject = JSON.parse(decodedString);
  gameConfigObject?.sport_games?.pre_sports?.map((video) => (
    video.id === "66" ? setFootballGames(video.active) : ""
  ))
},[])

 useEffect(() => { 
   socket.on('disconnect', function () {
  window.location.reload();
});
socket.on('reconnect', function () {
  socket.emit("upcoming", sportId, count, catId, champId, date1, color == Language[lang].ResultBasketballPage.textToday ? nextDay : date2 , name);
}); 
socket.emit("upcoming", sportId, count, catId, champId, date1, color == Language[lang].ResultBasketballPage.textToday ? nextDay : date2 , name);
   socket.on("upcoming", (data) => {
    const events = data.Result;
    setLiveData(events);
    setIsLoading(false);
    setEventIsLoading(false)
   });
 }, [date1,date2, color, name, catId]);

 if (isLoading) {
  return (
   <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
    <TailSpin
      height={80}
      width={80}
      color={`${backgroundColor}`}
      ariaLabel="tail-spin-loading"
      radius={1}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
   </div>
  );
 }

 const backgroundImages = {
  content: '',
  backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`
 }

 const handleInputChange = (e) => {
  setName(e.target.value);
  setDate2(null)
  setDate1(new Date())
  setColor("All")
};

 const handleCatIdClick = (catId) => {
  setCatId(catId)
  setColor("All")
  setDate1(new Date())
  setDate2(null)
}


 const handleLinkClick = (e,a) => {
setEventIsLoading(true)

  switch (e) {
    case Language[lang].ResultBasketballPage.textAll:
      setDate2(null)
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textAll)
    break;
    case getFormattedDate(a) :
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate()+a);
      date.setDate(date.getDate()+a);
      date.setHours(23);
      date.setMinutes(59);
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      
      nextDay = date.toISOString();
      setDate2(nextDay)
      setDate1(currentDate)
      setColor(getFormattedDate(a))
    break;
   case Language[lang].ResultBasketballPage.textToday:
      date.setDate(date.getDate());
      date.setHours(23);
      date.setMinutes(59);
      nextDay = date.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textToday)
    break;
    case Language[lang].ResultBasketballPage.textMunite1:
      const date30 = new Date()
      date30.setTime(date30.getTime() + 30 * 60 * 1000)
      nextDay = date30.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textMunite1)
    break;
    case Language[lang].ResultBasketballPage.textMunite2:
      const dateOne = new Date()
      dateOne.setTime(dateOne.getTime() + 60 * 60 * 1000)
      nextDay = dateOne.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textMunite2)
    break;
    case Language[lang].ResultBasketballPage.textMunite3:
      const dateThree = new Date()
      dateThree.setTime(dateThree.getTime() + 180 * 60 * 1000)
      nextDay = dateThree.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textMunite3)
    break;
    case Language[lang].ResultBasketballPage.textMunite4:
      const dateSix = new Date()
      dateSix.setTime(dateSix.getTime() + 360 * 60 * 1000)
      nextDay = dateSix.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textMunite4)
    break;
    case Language[lang].ResultBasketballPage.textMunite5:
      const dateTwelve = new Date()

      dateTwelve.setTime(dateTwelve.getTime() + 720 * 60 * 1000)
      nextDay = dateTwelve.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language[lang].ResultBasketballPage.textMunite5)
    break;
    case Language[lang].ResultBasketballPage.textMunite6:
    const dateTwentyFour = new Date()
    dateTwentyFour.setTime(dateTwentyFour.getTime() + 1440 * 60 * 1000)
    nextDay = dateTwentyFour.toISOString();
    setDate2(nextDay);
      setDate1(new Date())
    setColor(Language[lang].ResultBasketballPage.textMunite6)
    break;
    

   default:
    break;
  }
 };

 const handleMarketClick = (id) => {
  setFilterId(id)  
 } 

 const navbarStyle = {
  backgroundColor: backgroundColor,
  textColor: textColor
};

const getFormattedDate = (offset) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + offset);

  const options = {
    weekday: "short", 
  };

  return currentDate.toLocaleDateString("tr-TR", options);
};

 return (
  <div>
   <LeftMenu liveData={liveData} socket={socket} setLiveData={setLiveData} handleCatIdClick={handleCatIdClick} setDate1={setDate1} setDate2={setDate2} setColor={setColor}/>
   <div className="sport-selected-tab">
    <div style={backgroundImages} className="h-[3px] right-0 left-[22px] bottom-0 w-[100%] absolute rounded-[5px]"></div>
    {
      footballGames === 1 &&
      <Link to="/Results" className="bg-[#333]">
     {Language[lang].ResultBasketballPage.titleFootball}
    </Link>
    }
    <Link
     to="/Basketball"
     className="bg-[#333]"
     style={{backgroundColor: backgroundColor }}>
     {Language[lang].ResultBasketballPage.titleBasketball}
    </Link>
   </div>
   <div className="ml-[240px] w-[950px] mt-[-150px] rounded-2xl absolute bg-tblack text-text-white">
    <div className="p-2 bg-[#333] flex flex-grow rounded-2xl rounded-bl-none rounded-br-none">
     <input className="p-[10px] border-[#999] font-bold outline-none w-[33%] cursor-text
      bg-[#f9f9f9] shadow-lg h-[40px] text-black rounded-md" type="search" placeholder="Search Team" value={name} onChange={handleInputChange} />
     <div className="flex flex-1 shadow-custom items-center pl-[5px] justify-between rounded-2xl">
      <Link
       className={`search-links hover:brightness-110 cursor-pointer`}
       onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textToday)}
       style={color === Language[lang].ResultBasketballPage.textToday ? navbarStyle : undefined}
      >
       {Language[lang].ResultBasketballPage.textToday}
      </Link>
      {[1, 2, 3].map((offset) => (
        <Link
          key={offset}
          className={`search-links hover:brightness-110 cursor-pointer`}
          onClick={() => handleLinkClick(getFormattedDate(offset),offset)}
          style={color === getFormattedDate(offset) ? navbarStyle : undefined}
        >
          {getFormattedDate(offset)}
        </Link>
      ))}
      <Link
       className={`search-links hover:brightness-110 cursor-pointer`}
       onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textAll)}
       style={color === Language[lang].ResultBasketballPage.textAll ? navbarStyle : undefined}
      >
        {Language[lang].ResultBasketballPage.textAll}

      </Link>
     </div>
    </div>
    <div className="flex">
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite1)}
      style={color === Language[lang].ResultBasketballPage.textMunite1 ? navbarStyle : undefined}
     >
      {Language[lang].ResultBasketballPage.textMunite1}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language[lang].ResultBasketballPage.textMunite2 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite2)}
     >
     {Language[lang].ResultBasketballPage.textMunite2}

     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language[lang].ResultBasketballPage.textMunite3 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite3)}
     >
      {Language[lang].ResultBasketballPage.textMunite3}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color ===Language[lang].ResultBasketballPage.textMunite4 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite4)}
     >
      {Language[lang].ResultBasketballPage.textMunite4}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language[lang].ResultBasketballPage.textMunite5 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite5)}
     >
      {Language[lang].ResultBasketballPage.textMunite5}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language[lang].ResultBasketballPage.textMunite6 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language[lang].ResultBasketballPage.textMunite6)}
     >
      {Language[lang].ResultBasketballPage.textMunite6}
     </div>
    </div>
    <div className="flex w-[100%] justify-between border-b-2 border-gray-800">
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "219" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("219")}
     >
      {Language[lang].ResultBasketballPage.textLine1}
     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "223" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("223")}
     >
      {Language[lang].ResultBasketballPage.textLine2}

     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "225" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("225")}
     >
      {Language[lang].ResultBasketballPage.textLine3}
     </div>
    </div>
    {eventIsLoading ?      <div className="flex items-center justify-center py-5">
    <TailSpin
     height={80}
     width={80}
     color="#4fa94d"
     ariaLabel="tail-spin-loading"
     radius={1}
     wrapperStyle={{}}
     wrapperClass=""
     visible={true}
    />
   </div>:<Basketball click={click} liveData={liveData.Items} socket={socket} filterId={filterId}/>}
    
   </div>
  </div>
 );
}

const mapStateToProps = (state) => {
 return {
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
 };
};

export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(ResultBasketball);
