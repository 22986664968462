import Cookies from 'js-cookie';
import React from 'react'
import { Link } from 'react-router-dom'

const ErrorUnAlert = ({errorUnAlert,setErrorUnAlert}) => {

    function handleErrorAlert(){
        setErrorUnAlert(null)
        Cookies.remove("token");
        Cookies.remove("username");
        window.location.reload();
    }
    return (
      <div className="w-[100vw] h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center z-[999999999]">
              <div className="flex relative rounded flex-col justify-center items-center min-w-[200px] max-w-[500px] min-h-[100px] w-[auto] h-[auto] bg-white">
              <div className="mb-5 text-center">
              <p className='text-red-600 text-xl'>ERROR</p>
              <pre className="p-5" dangerouslySetInnerHTML={{ __html: errorUnAlert }}></pre>
              </div>
              <button className="absolute bottom-0 rounded-b w-full bg-red-600 text-white font-bold p-1 bg-red" onClick={handleErrorAlert}>OKAY</button>
              </div>
            </div>
    )
  }
export default ErrorUnAlert