import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ActiviteList, ActiviteInput, IsLoading } from "./index";
import {
  accountActiviteProcessValueUpdate,
  accountActiviteProcessTypeValueUpdate,
  accountActiviteInputValueUpdate,
  setTextColor,
  setBackgroundColor
} from "../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from '../language.json'

const AccountActivite = ({
  accountActiviteProcess,
  accountActiviteProcessType,
  accountActiviteProcessValueUpdate,
  accountActiviteProcessTypeValueUpdate,
  accountActiviteInput,
  accountActiviteInputValueUpdate,
  textColor,
  backgroundColor
}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const [resetPost,setResetPost] = useState(false)
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/gettransaction";
const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  });
 const days = ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct']
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

const tr = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}
  const formatDate2 = (dateString) => {
    const tarih = new Date(dateString);
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0');
    const gun = String(tarih.getDate()).padStart(2, '0');
    const saat = String(tarih.getHours()).padStart(2, '0');
    const dakika = String(tarih.getMinutes()).padStart(2, '0');
    const saniye = String(tarih.getSeconds()).padStart(2, '0');
    const milisaniye = String(tarih.getMilliseconds()).padStart(3, '0');
    
    const isoTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}.${milisaniye}Z`;
    return isoTarih;
};
  useEffect(() => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
    };
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      });
  }, [resetPost]);

  const showChanges = () => {

    const postData = {
      date1: formatDate(startDate),
      date2: formatDate(endDate),
      username: accountActiviteInput,
      process: accountActiviteProcess,
      process_type: accountActiviteProcessType,
    };
    if (accountActiviteInput.trim() === "") {
      delete postData.username;
    }
    if (accountActiviteProcess.trim() === "") {
      delete postData.process;
    }
    if (accountActiviteProcessType.trim() === "") {
      delete postData.process_type;
    }
    setIsLoading(true);
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        setDatas(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };
  const formatDate = (dateString) => {
    const tarih = new Date(dateString);
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0');
    const gun = String(tarih.getDate()).padStart(2, '0');
    const saat = String(tarih.getHours()).padStart(2, '0');
    const dakika = String(tarih.getMinutes()).padStart(2, '0');
    const saniye = String(tarih.getSeconds()).padStart(2, '0');
    const milisaniye = String(tarih.getMilliseconds()).padStart(3, '0');
    
    const isoTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}.${milisaniye}Z`;
    return isoTarih;
};
  const handleStartDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(0);
    date.setMinutes(0);
    setStartDate(date);

  };
  const handleEndDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(23);
    date.setMinutes(59);
    setEndDate(date);
  };
  const handleReset = () => {
    accountActiviteInputValueUpdate("");
    accountActiviteProcessValueUpdate("");
    accountActiviteProcessTypeValueUpdate("");
    const resetStartDate = new Date();
    resetStartDate.setHours(0, 0, 0, 0);
    setStartDate(resetStartDate);
    const resetEndDate = new Date();
    resetEndDate.setHours(23, 59, 59, 999);
    setEndDate(resetEndDate);
    setResetPost(!resetPost)
  };
  const totalAdd = datas
    .filter((item) => item.transaction_type === 1)
    .reduce((acc, item) => acc + item.total_amount, 0);
  const totalSub = datas
    .filter((item) => item.transaction_type !== 1)
    .reduce((acc, item) => acc + item.total_amount, 0);

  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language["en"].accountActivite.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[15px]">
        <div className="forms-all-coupons flex flex-wrap xl:flex-nowrap justify-center xl:justify-start items-end forms-all-coupons-box-shadow pt-[5px] pr-[5px] pb-[10px] pl-[15px] min:h-20 relative border-[1px] border-t-[#FFF]">
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
              onClick={() => window.location.reload()}
            >
              {Language["en"].accountActivite.refreshButton}
            </button>
          </div>
          { Number(Cookies.get("user_type")) !== 9 &&
            <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <ActiviteInput textColor={textColor}/>
          </div>
          }
          <div className="w-[100px] flex flex-col justify-center h-[100%] ml-[50px] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].accountActivite.startingDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].accountActivite.endDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].accountActivite.process}
            </span>
            <div className="">
              <select
                name="process"
                id="process"
                value={accountActiviteProcess}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  accountActiviteProcessValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="">{Language["en"].accountActivite.processInput0}</option>
                <option value="1">{Language["en"].accountActivite.processInput1}</option>
                <option value="2">{Language["en"].accountActivite.processInput2}</option>
              </select>
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].accountActivite.processType}
            </span>
            <div className="">
              <select
                name="accountActiviteProcessType"
                id="accountActiviteProcessType"
                value={accountActiviteProcessType}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  accountActiviteProcessTypeValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="">{Language["en"].accountActivite.processTypeInput0}</option>
                <option value="1">{Language["en"].accountActivite.processTypeInput1}</option>
                <option value="2">{Language["en"].accountActivite.processTypeInput2}</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={showChanges}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language["en"].accountActivite.showButton}
            </button>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={handleReset}
              className="forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[#333] text-[#fff] cursour-pointer transition-all duration-300"
            >
              {Language["en"].accountActivite.resetButton}
            </button>
          </div>
        </div>

        <div>
          {isLoading ? (
            <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
              <IsLoading />
            </div>
          ) : (
            <ActiviteList
              datas={datas}
              totalAdd={totalAdd}
              totalSub={totalSub}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  allCouponsNo: state.allCouponsNo,
  accountActiviteProcess: state.accountActiviteProcess,
  accountActiviteProcessType: state.accountActiviteProcessType,
  allCouponsBet: state.allCouponsBet,
  allCouponsDeposit: state.allCouponsDeposit,
  allCouponsProbable: state.allCouponsProbable,
  accountActiviteInput: state.accountActiviteInput,
  textColor: state.textColor,
  backgroundColor: state.backgroundColor
});

const mapDispatchToProps = {
  accountActiviteProcessValueUpdate,
  accountActiviteProcessTypeValueUpdate,
  accountActiviteInputValueUpdate,
  setBackgroundColor,
  setTextColor
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountActivite);
