import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { CgSandClock } from "react-icons/cg";
import axios from "axios";
import IsLoading from "../IsLoading2";
import { TiTick, TiTimes, TiCancel,TiRefresh} from "react-icons/ti";
import Cookies from "js-cookie";
import Language from '../../language.json'


const AllCouponList = ({ datas ,sortDataByKey}) => {
  const token = Cookies.get("token");
  const [loader,setLoader]=useState(false)
  const [loader2,setLoader2]=useState(false)
  const [loadererror,setloadererror]=useState(false)
  const [copno,setcopno]=useState()
  const lang = "en"
  const [openRow, setOpenRow] = useState(null);
  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(prevKey => prevKey + 1);
  }, [datas]);
const handlecancelcoupon = (id) =>{
  setLoader(true)
  setcopno(id)

  axios
  .post(
   "https://rona.thoth.lt/api/cancelcoupon",
   {
    coupon_id: id,
   },
   {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   }
  )
  .then((response) => {
   if (response.data.error) {
    setloadererror(true)
   } else {
    setLoader("ok")
    setLoader2(false)
    setloadererror(false)
   }
  })
  .catch((error) => {
   console.error(`Error adding bet to coupon`);
   setloadererror(true)
  })

}
const handleAddToCoupon = (id) =>{
  setLoader2(true)
  setcopno(id)
  axios
   .post(
    "https://rona.thoth.lt/api/coupon_repeat",
    {
     coupon_no: id,
    },
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   )
   .then((response) => {
    if (response.data.length === 0) {
      setloadererror(true)
    } else {
      let selectedOddsbase = JSON.stringify(response.data);
      localStorage.setItem("selectedOdds", encodeBase64UTF8(selectedOddsbase));
     setLoader2("ok")
     setLoader(false)
     setloadererror(false)
    }
   })
   .catch((error) => {
    console.error('Error adding bet to coupon ');
    setloadererror(true)
   })

}
  const toggleRow = (rowIndex) => {
    if (openRow === rowIndex) {
      setOpenRow(null);
    } else {
      setOpenRow(rowIndex);
    }
  };
  const getStatusText = (isWin) => {
    switch (isWin) {
      case 0:
        return <CgSandClock size={16} />;
      case 1:
        return <TiTick size={16} />;
      case 2:
        return <TiTimes size={16} />;
        case 3:
        return "Tek Maç Bekleniyor";
      case 4:
        return "İki Maç Bekleniyor";
      case 5:
        return <TiCancel size={16} />;
      default:
        return "";
    }
  };
  const getStatusText2 = (isWin) => {
    switch (isWin) {
      case 0:
        return <CgSandClock size={16} />;
      case 1:
        return <TiTick size={16} />;
      case 2:
        return <TiTimes size={16} />
      case 3:
        return <TiCancel size={16}/>
      }
  };
  const formatDate = (dateString) => {
    const options = { 
        hour12: false,
    };
    
    const formattedDate = new Date(dateString).toLocaleString('tr-TR', options);
    return formattedDate;
};
  return (
    <div className="mt-2">    
      <table className="w-full">
        <thead>
          <tr className="table-row">
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]"></td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]"></td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("no")}>{Language[lang].AllCouponList.text1}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("user")}>{Language[lang].AllCouponList.text2}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("ip")}>{Language[lang].AllCouponList.text3}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px] underline">
            <button onClick={() => sortDataByKey("date")}>{Language[lang].AllCouponList.text4}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("type")}>{Language[lang].AllCouponList.text5}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("line")}>{Language[lang].AllCouponList.text6}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px] underline">
            <button onClick={() => sortDataByKey("odd")}>{Language[lang].AllCouponList.text7}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px] underline">
            <button onClick={() => sortDataByKey("count")}>{Language[lang].AllCouponList.text8}{" "}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("likely")}>{Language[lang].AllCouponList.text9}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("max_win")}>{Language[lang].AllCouponList.text10}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]">
            <button onClick={() => sortDataByKey("status")}>{Language[lang].AllCouponList.text11}</button>
            </td>
            <td className="bg-[#ccc] py-[15px] px-[10px] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[12px]"></td>
           </tr>
        </thead>
        <tbody>
          {datas?.map((data) => (
            <React.Fragment key={data.no}>
              <tr className="table-row">
                <td
                  id={data.no}
                  onClick={() => toggleRow(data.no)}
                  className="bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative"
                >
                  <div className="py-[5px] flex justify-center px-[5px] text-[#fff] font-bold bg-[#14892c] rounded-[2px] cursor-pointer">
                    <ImSearch size={12} style={{ fontWeight: "bold" }} />
                  </div>
                </td>
                <td
                  className={`bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                ></td>
                <td
                  className={`bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.no}
                </td>
                <td
                  className={`bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.user}
                </td>
                <td
                  className={`bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.name} / {data.ip}
                </td>
                <td
                  className={`bg-[#f9f9f9] font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.date}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.type}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.bet.length}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data && data.odd ? (data.odd).toFixed(2) : "-"}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.count}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.likely}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {data.max_win}
                </td>
                <td
                  className={`bg-[#f9f9f9] pl-4 font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >
                  {getStatusText(data.status)}
                </td>
                <td
                  className={`bg-[#f9f9f9] text-center font-semibold py-[15px] px-[10px] text-[12px] relative ${
                    data.is_win == 0 ? "text-[#222]" : ""
                  } ${data.is_win == 1 ? "text-green-600" : ""} ${
                    data.is_win == 2 ? "text-red-600" : ""
                  } ${data.is_win == 3 ? "text-[#222]" : ""} ${
                    data.is_win == 4 ? "text-[#222]" : ""
                  } ${data.is_win == 5 ? "text-gray-500" : ""}`}
                >                     
              {data.is_win === 0 ? (
    <>
      <button
        onClick={() => handleAddToCoupon(data.no)}
        className="newkuponda"
        style={{ position: "relative" }}
      >
        {loader2 === true && data.no===copno  ? (
        <IsLoading />
        ) : loader2 === "ok" && data.no===copno  ? ( 
          <TiTick size={20} />
        ) : (

          <TiRefresh size={20} />
        )}
        </button>


      <button
        onClick={() => handlecancelcoupon(data.no)}
        className="newkuponda"
        style={{ position: "relative", marginLeft: "5px" }}
              >
        {loader === true && data.no===copno ? (
        <IsLoading />
        ) : loader === "ok" && data.no===copno  ? ( 
          <TiTick size={20} />
        ) : (

          <TiTimes size={20} />
        )}
        </button>
        </>
        ) : (
          ""
        ) }
       { loadererror ===true && data.no===copno ?(<div class="text-red-600">İşlem Başarısız</div>):("") }
        </td>
              </tr>
              {openRow === data.no && (
                <tr className="table-row">
                  <td colSpan="14">
                    <div className="p-0 relative text-[12px] w-full ">
                      {data.bet.map((betItem, index) => (
                        <div
                          key={index}
                          className="forms-box-shadow p-[5px] border-b-[3px] border-b-[#333]"
                        >
                          <div className="flex flex-wrap min-h-[60px]">
                            <div className="forms-box-shadow w-[15%] justify-center min-w-[100px] flex text-[12px] items-center border-[1px] border-[#ccc] mb-[2px] bg-[#fff] rounded-[3px]">
                              {formatDate(betItem.event_date)}
                            </div>
                            <div className="all-coupons-box-shadow w-[30%] min-w-[250px] px-[10px] flex flex-col font-semibold gap-2 text-[13px] justify-center bg-[#f9f9f9] ">
                              <div>
                                {betItem.competitor1} - {betItem.competitor2}
                              </div>
                              <div className={`forms-label-box-shadow flex justify-between items-center whitespace-nowrap min-w-[350px] bg-[#f9f9f9] py-[5px] px-[15px] border-x-[1px] border-x-[rgba(0,0,0,0.1)] bg-[rgba(0,0,0,.05)] rounded-[5px]
                            ${betItem.is_win === 1 ? "text-green-600" : betItem.is_win === 2 ? "text-red-600" : "text-[#222]"}`
                            }>
                                <div>
                                  {betItem.market_name} - {betItem.odd_name}
                                </div>
                                <div>{getStatusText2(betItem.is_win)}</div>
                              </div>
                            </div>
                            <div className={`forms-box-shadow flex items-center min-w-[80px] w-[15%] text-[14px] pl-[20px] font-bold bg-[#fff] ${betItem.is_win === 1 ? "text-green-600" : betItem.is_win === 2 ? "text-red-600" : "text-[#222]"}`}>
                              { betItem && betItem.odd_price ? (betItem.odd_price).toFixed(2) : "-"}
                            </div>
                            <div className={`forms-box-shadow flex items-center min-w-[80px] w-[15%] text-[12px] pl-[20px] font-bold bg-[#fff] ${betItem.is_win === 1 ? "text-green-600" : betItem.is_win === 2 ? "text-red-600" : "text-[#222]"}`}>
                            <span>
                            {betItem.nowlive !== 0 ?
                              betItem.nowlive === 1 || betItem.nowlive === 2 ?
                              <div>Şuan Oynanıyor</div>
                              :
                              <div>Maç Sonu</div>
                              :
                              <div>Bekliyor</div>}
                            </span>
                            </div>
                            <div className={`forms-box-shadow flex items-center min-w-[80px] w-[25%] text-[12px] pl-[20px] font-bold bg-[#fff] ${betItem.is_win === 1 ? "text-green-600" : betItem.is_win === 2 ? "text-red-600" : "text-[#222]"}`}>
                            <span>
                              {betItem.nowlive !== 0 ? 
                                betItem.nowlive === 1 ?
                                  "Şu an:" + betItem?.score 
                                : betItem.nowlive === 2 ?
                                  <>
                                    IY: {betItem?.ht}
                                    <br />
                                    CANLI SKOR: {betItem?.score}
                                  </>
                                : betItem.nowlive === 3 ?
                                  <>
                                    IY: {betItem?.ht}
                                    <br />
                                    MS: {betItem?.ft}
                                  </>
                                : ""
                              : ""}
                            </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllCouponList;
