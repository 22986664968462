import React from "react";
import Language from '../../language.json'
const SportTypeComp = ({ selectedId, setSelectedId,backgroundColor }) => {
  const handleItemClick = (id) => {
    setSelectedId(id);
  };
  const lang = "en"
  return (
    <div className="flex flex-wrap">
      <div
        id="66"
        className={`w-[50%] p-[2px] ${
          selectedId === "66" ? `bg-[${backgroundColor}] text-[#fff]` : "text-[#000]"
        }`}
        onClick={() => handleItemClick("66")}
      >
        <span className="border-[#ddd] border-[1px] flex justify-center items-center p-[20px] cursor-pointer ">
          {Language[lang].SportTypeComp.text1}
        </span>
      </div>
      <div
        id="67"
        className={`w-[50%] p-[2px] ${
          selectedId === "67" ? `bg-[${backgroundColor}] text-[#fff]` : "text-[#000]"
        }`}
        onClick={() => handleItemClick("67")}
      >
        <span className="border-[#ddd] border-[1px] flex justify-center items-center p-[20px] cursor-pointer ">
        {Language[lang].SportTypeComp.text2}
        </span>
      </div>
      <div
        id="66-1"
        className={`w-[50%] p-[2px] ${
          selectedId === "66-1" ? `bg-[${backgroundColor}] text-[#fff]` : "text-[#000]"
        }`}
        onClick={() => handleItemClick("66-1")}
      >
        <span className="border-[#ddd] border-[1px] flex justify-center items-center p-[20px] cursor-pointer ">
        {Language[lang].SportTypeComp.text3}
        </span>
      </div>
      <div
        id="67-1"
        className={`w-[50%] p-[2px] ${
          selectedId === "67-1" ? `bg-[${backgroundColor}] text-[#fff]` : "text-[#000]"
        }`}
        onClick={() => handleItemClick("67-1")}
      >
        <span className="border-[#ddd] border-[1px] flex justify-center items-center p-[20px] cursor-pointer ">
        {Language[lang].SportTypeComp.text4}
        </span>
      </div>
    </div>
  );
};

export default SportTypeComp;
