import React, { useEffect, useState } from "react";
import Language from '../../language.json'
const MatchChanged = ({
  eventName,
  setEventName,
  footballMatch,
  eventId,
  handleEditClick,
  textColor,
  backgroundColor
}) => {
  const [preFootballMatch, setPreFootballMatch] = useState([]);
  const [liveFootballMatch, setLiveFootballMatch] = useState([]);
  const lang = "en"
  useEffect(() => {
    if (Array.isArray(footballMatch) && footballMatch.length > 0) {
      const preMatchs = footballMatch.filter(
        (preMatch) => preMatch.status === 0
      );
      const liveMatchs = footballMatch.filter(
        (liveMatch) => liveMatch.status === 1
      );
      setPreFootballMatch(preMatchs);
      setLiveFootballMatch(liveMatchs);
    } else {
      setPreFootballMatch([]);
      setLiveFootballMatch([]);
    }
  }, [footballMatch]);

  return (
    <div>
      <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] bg-[#f9f9f9]">
        <div className="p-[5px] font-medium text-[13px]">
          {Language[lang].MatchChanged.text1}
        </div>
        <div className="w-full p-[5px] flex justify-between">
          <div className="w-full mr-[5px] py-[5px]">
            <input
              type="text"
              className="forms-select-box-shadow p-[10px] w-full text-[13px] h-[37px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9]"
              placeholder="search team"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </div>
        </div>
      </div>
      {Array.isArray(preFootballMatch) && preFootballMatch.length > 0 ? (
        <div>
          <div className="text-[#444] border-b-[1px] border-[#ddd] p-[10px] font-semibold">
          {Language[lang].MatchChanged.text2}
          </div>
          {preFootballMatch.map((match, index) => (
            <div
              key={index}
              className="forms-select-box-shadow flex p-[10px] bg-[#fff] border-[1px] border-[#ddd]"
            >
              <div
                onClick={() => handleEditClick(match.event_id)}
                className={`mr-[5px] underline cursor-pointer`}
                style={{color:textColor}}
              >
                {Language[lang].MatchChanged.text3}
              </div>
              <div>
                {match.comp_1_name} - {match.comp_2_name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {Array.isArray(liveFootballMatch) && liveFootballMatch.length > 0 ? (
        <div>
          <div className="text-[#444] border-b-[1px] border-[#ddd] p-[10px] font-semibold">
          {Language[lang].MatchChanged.text4}
          </div>
          {liveFootballMatch.map((match, index) => (
            <div
              key={index}
              className="forms-select-box-shadow flex p-[10px] bg-[#fff] border-[1px] border-[#ddd]"
            >
              <div
                onClick={() => handleEditClick(match.event_id)}
                className={`text-[#BC2121] mr-[5px] underline cursor-pointer`}
              >
                {Language[lang].MatchChanged.text3}
              </div>
              <div>
                {match.comp_1_name} - {match.comp_2_name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MatchChanged;
