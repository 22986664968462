// NAVBAR

export const setBackgroundColor = (color) => {
    return {
      type: 'SET_BACKGROUND_COLOR',
      payload: color,
    };
  };

export const setTextColor = (color) => {
  return {
    type: 'SET_TEXT_COLOR',
    payload: color
  }
}

export const setPanel = (value) => {
  return {
    type: 'SET_PANEL',
    payload: value
  }
}

export const setDetailPopup = (value) => ({
  type: 'SET_DETAIL_POPUP',
  payload: value
})

export const systemOffSec = (selectedValue) => ({
    type: 'SYSTEM_OFF_SEC',
    payload: selectedValue,
});

export const footballTypeValueUpdate = (value) => ({
  type: "FOOTBALL_TYPE_VALUE_UPDATE",
  payload: value
})

export const liveFootballTypeValueUpdate = (value) => ({
  type: "LIVE_FOOTBALL_TYPE_VALUE_UPDATE",
  payload: value
})

export const systemStartUpTimeValueUpdate = (value) => ({
  type: "SYSTEM_START_UP_TIME_VALUE_UPDATE",
  payload: value
})

export const systemShutDownTimeValueUpdate = (value) => ({
  type: "SYSTEM_SHUT_DOWN_TIME_VALUE_UPDATE",
  payload: value
})

export const systemCouponValueUpdate = (value) =>({
  type: "SYSTEM_COUPON_UPDATE",
  payload: value
})

export const liveLastMin1ValueUpdate = (value) => ({
  type: "LIVE_LAST_MIN1_UPDATE",
  payload: value
})

export const liveLastMin2ValueUpdate = (value) => ({
  type: "LIVE_LAST_MIN2_UPDATE",
  payload: value
})

export const couponExtValueUpdate = (value) => ({
  type: "COUPON_EXT_UPDATE",
  payload: value
})

export const maxDepositValueUpdate = (value) => ({
  type: "MAX_DEPOSIT_UPDATE",
  payload: value
})

export const singleMaxDepositValueUpdate = (value) => ({
  type: "SINGLE_MAX_DEPOSIT_UPDATE",
  payload: value
})

export const liveMaxDepositValueUpdate = (value) => ({
  type: "LIVE_MAX_DEPOSIT_UPDATE",
  payload: value
})

export const minDepositValueUpdate = (value) => ({
  type: "MIN_DEPOSIT_UPDATE",
  payload: value
})

export const eventMaxDepositValueUpdate = (value) => ({
  type: "EVENT_MAX_DEPOSIT_UPDATE",
  payload: value
})

export const marketMaxDepositValueUpdate = (value) => ({
  type: "MARKET_MAX_DEPOSIT_UPDATE",
  payload: value
})

export const maxCouponLineValueUpdate = (value) => ({
  type: "MAX_COUPON_LINE_UPDATE",
  payload: value
})

export const minCouponOddValueUpdate = (value) => ({
  type: "MIN_COUPON_ODD_UPDATE",
  payload: value
})

export const maxCouponOddValueUpdate = (value) => ({
  type: "MAX_COUPON_ODD_UPDATE",
  payload: value
})

export const sysMaxXValueUpdate = (value) => ({
  type: "SYS_MAX_X_UPDATE",
  payload: value
})

export const couponWinLimitMaxPayValueUpdate = (value) => ({
  type: "COUPON_WIN_LIMIT_MAX_PAY_UPDATE",
  payload: value
})

export const couponWinLimitSingleMaxPayValueUpdate = (value) =>({
  type: "COUPON_WIN_LIMIT_SINGLE_MAX_PAY_UPDATE",
  payload: value
})

export const couponWinLimitLiveMaxPayValueUpdate = (value) => ({
  type: "COUPON_WIN_LIMIT_LIVE_MAX_PAY_UPDATE",
  payload: value
})

export const couponWinLimitLiveSingleMaxPayValueUpdate = (value) => ({
  type: "COUPON_WIN_LIMIT_LIVE_SINGLE_MAX_PAY_UPDATE",
  payload: value
})

export const couponWinLimitSysMaxPayValueUpdate = (value) => ({
  type: "COUPON_WIN_LIMIT_SYS_MAX_X_UPDATE",
  payload: value
})

export const oddSafeLiveMaxOddValueUpdate = (value) => ({
  type: "ODD_SAFE_LIVE_MAX_ODD_VALUE_UPDATE",
  payload: value
})

export const oddSafePreMaxOddValueUpdate = (value) => ({
  type: "ODD_SAFE_PRE_MAX_ODD_VALUE_UPDATE",
  payload: value
})

export const oddSafeLiveMinOddValueUpdate = (value) => ({
  type: "ODD_SAFE_LIVE_MIN_ODD_VALUE_UPDATE",
  payload: value
})

export const oddSafePreMinOddValueUpdate = (value) => ({
  type: "ODD_SAFE_PRE_MIN_ODD_VALUE_UPDATE",
  payload: value
})

export const videoGameShowGameValueUpdate = (value) => ({
  type: "VIDEO_GAME_SHOW_GAME_VALUE_UPDATE",
  payload: value
})

export const updateSelectedGames = (selectedGames) => {
  return {
    type: 'UPDATE_SELECTED_GAMES',
    payload: selectedGames,
  };
};

// NEW USERS

export const newUsersUserTypeValueUpdate = (value) => ({
  type: "NEW_USERS_USER_TYPE_VALUE_UPDATE",
  payload: value
})

export const newUsersUsernameValueUpdate = (value) => ({
  type: "NEW_USERS_USERNAME_VALUE_UPDATE",
  payload: value
})

export const newUsersPasswordValueUpdate = (value) => ({
  type: "NEW_USERS_PASSWORD_VALUE_UPDATE",
  payload: value
})

export const newUsersEmailValueUpdate = (value) => ({
  type: "NEW_USERS_EMAIL_VALUE_UPDATE",
  payload: value
})

export const newUsersRememberNameValueUpdate = (value) => ({
  type: "NEW_USERS_REMEMBER_NAME_VALUE_UPDATE",
  payload: value
})

export const newUsersPointValueUpdate = (value) => ({
  type: "NEW_USERS_POINT_VALUE_UPDATE",
  payload: value
})

export const newUsersPlayerLimitValueUpdate = (value) => ({
  type: "NEW_USERS_PLAYER_LIMIT_VALUE_UPDATE",
  payload: value
})
export const newUsersAgencyLimitValueUpdate = (value) => ({
  type: "NEW_USERS_AGENCY_LIMIT_VALUE_UPDATE",
  payload: value
})
export const newUsersSuperLimitValueUpdate = (value) => ({
  type: "NEW_USERS_SUPER_LIMIT_VALUE_UPDATE",
  payload: value
})
export const newUsersDealerLimitValueUpdate = (value) => ({
  type: "NEW_USERS_DEALER_LIMIT_VALUE_UPDATE",
  payload: value
})
export const newUsersPlayerLimitDeleteValueUpdate = (value) => ({
  type: 'NEW_USERS_PLAYER_LIMIT_DELETE_VALUE_UPDATE',
  payload: value,
});

export const newUsersPlayerLimitCheckbox = () => ({
  type: 'NEW_USERS_PLAYER_LIMIT_CHECKBOX',
});
export const newUsersAgencyLimitCheckbox = () => ({
  type: 'NEW_USERS_AGENCY_LIMIT_CHECKBOX',
});
export const newUsersSuperLimitCheckbox = () => ({
  type: 'NEW_USERS_SUPER_LIMIT_CHECKBOX',
});
export const newUsersDealerLimitCheckbox = () => ({
  type: 'NEW_USERS_DEALER_LIMIT_CHECKBOX',
});
export const newUsersAllSystemValueUpdate = (value) => ({
  type: 'NEW_USERS_ALL_SYSTEM_VALUE_UPDATE',
  payload: value
})

export const newUsersAllSystemRuleValueUpdate = (value) => ({
  type: 'NEW_USERS_ALL_SYSTEM_RULE_VALUE_UPDATE',
  payload: value
})

export const newUsersPreMatchValueUpdate = (value) => ({
  type: 'NEW_USERS_PRE_MATCH_VALUE_UPDATE',
  payload: value
})

export const newUsersPreMatchRuleValueUpdate = (value) => ({
  type: 'NEW_USERS_PRE_MATCH_RULE_VALUE_UPDATE',
  payload: value
})

export const newUsersLiveValueUpdate = (value) => ({
  type: 'NEW_USERS_LIVE_VALUE_UPDATE',
  payload: value
})

export const newUsersLiveRuleValueUpdate = (value) => ({
  type: 'NEW_USERS_LIVE_RULE_VALUE_UPDATE',
  payload: value
})

export const newUsersAllBetsValueUpdate = (value) => ({
  type: 'NEW_USERS_ALL_BETS_VALUE_UPDATE',
  payload: value
})

export const newUsersVideoGameValueUpdate = (value) => ({
  type: 'NEW_USERS_VIDEO_GAME_VALUE_UPDATE',
  payload: value
})
export const newUsersVideoGameRuleValueUpdate = (value) => ({
  type: 'NEW_USERS_VIDEO_GAME_RULE_VALUE_UPDATE',
  payload: value
})
// ALL COUPONS

export const allCouponsNoValueUpdate = (value) => ({
  type: "ALL_COUPONS_NO_VALUE_UPDATE",
  payload: value
})

export const allCouponsUserInputValueUpdate = (value) => ({
  type: "ALL_COUPONS_USER_INPUT_VALUE_UPDATE",
  payload: value
})

export const allCouponsPieceValueUpdate = (value) => ({
  type: "ALL_COUPONS_PIECE_VALUE_UPDATE",
  payload: value
})

export const allCouponsStatusValueUpdate = (value) => ({
  type: "ALL_COUPONS_STATUS_VALUE_UPDATE",
  payload: value
})

export const allCouponsBetValueUpdate = (value) => ({
  type: "ALL_COUPONS_BET_VALUE_UPDATE",
  payload: value
})

export const allCouponsDepositValueUpdate = (value) => ({
  type: "ALL_COUPONS_DEPOSIT_VALUE_UPDATE",
  payload: value
})

export const allCouponsProbableValueUpdate = (value) => ({
  type: "ALL_COUPONS_PROBABLE_VALUE_UPDATE",
  payload: value
})

// MY USERS

export const myUsersStatusValueUpdate = (value) => ({
  type: "MY_USERS_STATUS_VALUE_UPDATE",
  payload: value
})

// MY USERS UPDATE

export const myUsersUpdateUsernameValueUpdate = (value) => ({
  type: "MY_USERS_UPDATE_USERNAME_VALUE_UPDATE",
  payload: value
})

export const myUsersUpdatePasswordValueUpdate = (value) => ({
  type: "MY_USERS_UPDATE_PASSWORD_VALUE_UPDATE",
  payload: value
})

export const myUsersUpdateRememberNameValueUpdate = (value) => ({
  type: "MY_USERS_UPDATE_REMEMBER_NAME_VALUE_UPDATE",
  payload: value
})

export const myUsersUpdateDeductionValueUpdate = (value) => ({
  type: "MY_USERS_UPDATE_DEDUCTION_VALUE_UPDATE",
  payload: value
})

export const myUsersUpdateAllSystemValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_ALL_SYSTEM_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateAllSystemRuleValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_ALL_SYSTEM_RULE_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdatePreMatchValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_PRE_MATCH_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdatePreMatchRuleValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_PRE_MATCH_RULE_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateLiveValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_LIVE_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateLiveRuleValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_LIVE_RULE_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateVideoGameValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_VIDEO_GAME_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateVideoGameRuleValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_VIDEO_GAME_RULE_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateAllBetsValueUpdate = (value) => ({
  type: 'MY_USERS_UPDATE_USERS_ALL_BETS_VALUE_UPDATE',
  payload: value
})

export const myUsersUpdateSelectedGames = (selectedGames2) => {
  return {
    type: 'MY_USERS_UPDATE_SELECTED_GAMES',
    payload: selectedGames2,
  };
}

// DAILY REPORT

export const dailyReportUserInputValueUpdate = (value) => ({
  type: "DAILY_REPORT_USER_INPUT_VALUE_UPDATE",
  payload: value
})

export const dailyReportPieceValueUpdate = (value) => ({
  type: "DAILY_REPORT_PIECE_VALUE_UPDATE",
  payload: value
})

export const dailyReportBetValueUpdate = (value) => ({
  type: "DAILY_REPORT_BET_VALUE_UPDATE",
  payload: value
})

// REPORT DETAILS

export const reportDetailsUserInputValueUpdate = (value) => ({
  type: "REPORT_DETAILS_USER_INPUT_VALUE_UPDATE",
  payload: value
})

export const reportDetailsPieceValueUpdate = (value) => ({
  type: "REPORT_DETAILS_PIECE_VALUE_UPDATE",
  payload: value
})

export const reportDetailsBetValueUpdate = (value) => ({
  type: "REPORT_DETAILS_BET_VALUE_UPDATE",
  payload: value
})

// PERSON OPENING LIMIT

export const PersonOpeningLimitValueUpdate = (value) => ({
  type: "PERSON_OPENING_LIMIT_VALUE_UPDATE",
  payload: value
})

export const openingLimitValueUpdate = (value) => ({
  type: "OPENING_LIMIT_VALUE_UPDATE",
  payload: value
})

// ACCOUNT ACTIVITE 

export const accountActiviteInputValueUpdate = (value) => ({
  type: "ACCOUNT_ACTIVITE_INPUT_VALUE_UPDATE",
  payload: value
})

export const accountActiviteProcessTypeValueUpdate = (value) => ({
  type: "ACCOUNT_ACTIVITE_PROCESS_TYPE_VALUE_UPDATE",
  payload: value
})

export const accountActiviteProcessValueUpdate = (value) => ({
  type: "ACCOUNT_ACTIVITE_PROCESS_VALUE_UPDATE",
  payload: value
})

// RESET INPUT

export const ResetInputs = () => {
  return {
    type: 'RESET_INPUTS',
  };
};
