import React, { useEffect, useState } from "react";
import { MatchChanged, EditCompetitionOdds, IsLoading } from "./index";
import { Link } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { setBackgroundColor, setTextColor } from "../redux/actions/actions";
import axios from "axios";
import Language from "../language.json";
import { connect } from "react-redux";
import Cookies from "js-cookie";

const SingleMatchBased = ({ textColor, backgroundColor }) => {
  const lang = "en";
  const token = Cookies.get("token");
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/odd_config5";
  const apiPost2Url =
    process.env.REACT_APP_API_URL_2 + `/pre-odds-detail?eventId=`;
  const apiGetUrl = process.env.REACT_APP_API_URL_2 + "/getevents";
  const apiGet2Url = process.env.REACT_APP_API_URL + "/api/odd_config5get";

  const [eventName, setEventName] = useState("");
  const [footballMatch, setFootballMatch] = useState([]);
  const [matchDetails, setMatchDetails] = useState([]);
  const [eventId, setEventId] = useState("");
  const [marketTotals, setMarketTotals] = useState({});
  const [oddId, setOddId] = useState("");
  const [configRule, setConfigRule] = useState(0);
  const [rateDiff, setRateDiff] = useState([]);
  const [apiPostResponse, setApiPostResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const postData = {
      eventname: eventName,
    };
    axios
      .post(apiGetUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setFootballMatch(response.data);
      });
  }, [eventName]);

  useEffect(() => {
    const postData = {
      event_id: eventId,
    };

    axios
      .post(apiGet2Url, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRateDiff(response.data);
      });
  }, [eventId]);

  const handleEditClick = (e) => {
    setEventId(e);
    setIsLoading(true)
    axios
      .get(apiPost2Url + e, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setMatchDetails(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    setMarketTotals({});
    setConfigRule(0);
  };
  useEffect(() => {
    const postData = {
      event_id: eventId,
      odd_id: oddId,
      config_rule: configRule,
    };
    if (configRule === 0) {
      return;
    } else {
      axios
        .post(apiPostUrl, postData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setApiPostResponse(response.data);
        });
    }
  }, [marketTotals]);

  const formatMarketPrice = (price) => {
    if (isNaN(price) || price === undefined) {
      return "0.00";
    }
    const formatedPrice = parseFloat(price).toFixed(2);
    return formatedPrice;
  };

  const handleIncrement = (marketId) => {
    const incTotals = { ...marketTotals };
    incTotals[marketId] = (incTotals[marketId] || 0) + 0.05;
    setMarketTotals(incTotals);
    setOddId(marketId);
    setConfigRule(0.05);
  };

  const handleDecrement = (marketId) => {
    const decTotals = { ...marketTotals };

    decTotals[marketId] = (decTotals[marketId] || 0) - 0.05;
    setMarketTotals(decTotals);
    setOddId(marketId);
    setConfigRule(-0.05);
  };

  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language[lang].SingleMatchBasedPage.title}
      </div>
      <div className="p-[15px] bg-[#fff] gap-5">
        <div className="flex">
          <div className="flex-1">
            <div
              className={`p-[10px] pl-[35px] border-b-[1px] relative`}
              style={{ borderBottomColor: backgroundColor, color: textColor }}
            >
              <span
                className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}
              >
                {Language[lang].SingleMatchBasedPage.textSpan1}
              </span>
              {Language[lang].SingleMatchBasedPage.title2}
            </div>
            <MatchChanged
              eventName={eventName}
              setEventName={setEventName}
              footballMatch={footballMatch}
              setEventId={setEventId}
              eventId={eventId}
              handleEditClick={handleEditClick}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
          </div>
          <div className="flex-1">
            <div
              className={`p-[10px] pl-[35px] border-b-[1px] relative`}
              style={{ borderBottomColor: backgroundColor, color: textColor }}
            >
              <span
                className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}
              >
                {Language[lang].SingleMatchBasedPage.textSpan2}
              </span>
              {Language[lang].SingleMatchBasedPage.title3}
            </div>
            {isLoading ? (
            <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
              <IsLoading />
            </div>
          ) : (
            <EditCompetitionOdds
              footballMatch={footballMatch}
              matchDetails={matchDetails}
              marketTotals={marketTotals}
              setMarketTotals={setMarketTotals}
              formatMarketPrice={formatMarketPrice}
              setOddId={setOddId}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              rateDiff={rateDiff}
              apiPostResponse={apiPostResponse}
              textColor={textColor}
              backgroundColor={backgroundColor}
              isLoading={isLoading}
            />
          )
          }
          </div>
        </div>
        <div className=" flex p-[20px] bg-[rgba(0,0,0,0.1)] leading-6 rounded-[5px] mt-5 text-[#444] relative">
          {Language[lang].SingleMatchBasedPage.description1}{" "}
          <Link to={"/changes-center"} style={{ color: textColor }}>
            <div className="flex mx-1 items-center gap-1">
              <BsBoxArrowUpRight />{" "}
              {Language[lang].SingleMatchBasedPage.description2}
            </div>
          </Link>{" "}
          {Language[lang].SingleMatchBasedPage.description3}
        </div>
      </div>
    </div>
  );
};

const mapToStateProps = (state) => ({
  textColor: state.textColor,
  backgroundColor: state.backgroundColor,
});

export default connect(mapToStateProps, { setBackgroundColor, setTextColor })(
  SingleMatchBased
);
