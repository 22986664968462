import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdSettings } from "react-icons/io";
import { CgArrowsExchange } from "react-icons/cg";
import { LuListTodo } from "react-icons/lu";
import { PiUsersThreeFill } from "react-icons/pi";
import { VscGraphLine } from "react-icons/vsc";
import { RiGamepadFill } from "react-icons/ri";
import { TbExchange } from "react-icons/tb";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  setBackgroundColor,
  setTextColor,

} from "../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from "../language.json";
const DashboardHeader = ({
  backgroundColor,
}) => {
  const [toggle, setToggle] = useState(true);
  const [currentNavbar, setCurrentNavbar] = useState("")

  useEffect(() => {
    const currentPath = window.location.pathname;
    const current = currentPath.split('/')[1];
    setCurrentNavbar(current);
  }, []);

  const user_type = Cookies.get("user_type");

  return (
    <div className="mb-[15px]">
      <div
        className="cursor-pointer custom-box-shadow"
        onClick={() => setToggle(!toggle)}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="flex gap-3 items-center h-[58px] text-white p-[15px]">
          <GiHamburgerMenu />
          {Language["en"].dashboardHeader.menu}
        </div>
      </div>
      {toggle && (
        <div className="bg-[#333] custom-box-shadow rounded-b-[15px] pb-2">
          <div className="flex flex-wrap md:justify-center lg:justify-normal">
            {Number(user_type) === 7 && (
              <div className="p-[15px] pt-5 text-white text-[14px]">
                <div className="w-[165px]">
                  <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                    <IoMdSettings color="#ffcc00" size={18} />{" "}
                    {Language["en"].dashboardHeader.system}
                  </div>
                  <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                    <Link
                      to={"/system-settings"}
                      onClick={() => setCurrentNavbar("system-settings")}
                      className={`border-b-[1px] ${currentNavbar == "system-settings"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.systemSettings}
                    </Link>
                    <Link
                      to={"/game-toggle"}
                      onClick={() => setCurrentNavbar("game-toggle")}
                      className={`border-b-[1px] ${currentNavbar == "game-toggle"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.gameToggle}
                    </Link>
                    <Link
                      to={"/changes-center"}
                      onClick={() => setCurrentNavbar("changes-center")}
                      className={`flex items-center ${currentNavbar == "changes-center"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                    >
                      <b className="border-none">
                        <CgArrowsExchange size={22} />
                      </b>{" "}
                      {Language["en"].dashboardHeader.changesCenter}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="p-[15px] pt-5 text-white text-[14px]">
              <div className="w-[165px]">
                <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                  <LuListTodo color="#ffcc00" size={18} />{" "}
                  {Language["en"].dashboardHeader.coupons}
                </div>
                <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                  <Link
                    to={"/all-coupons"}
                    onClick={() => setCurrentNavbar("all-coupons")}
                    className={`border-b-[1px] ${currentNavbar == "all-coupons"
                        ? "bg-[#ffcc00] text-black"
                        : "hover:bg-[#111] hover:text-[#ffcc00]"
                      } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                  >
                    {Language["en"].dashboardHeader.allCoupons}
                  </Link>
                  {Number(user_type) === 7 || Number(user_type) === 3 || Number(user_type) === 1  || Number(user_type) === 2 ? (
                    <>
                      <Link
                        to={"/betting-dentisy"}
                        onClick={() => setCurrentNavbar("betting-dentisy")}
                        className={`border-b-[1px] ${currentNavbar == "betting-dentisy"
                            ? "bg-[#ffcc00] text-black"
                            : "hover:bg-[#111] hover:text-[#ffcc00]"
                          } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                      >
                        {Language["en"].dashboardHeader.bettingDensity}
                      </Link>
                      <Link
                        to={"/live-betting-dentisy"}
                        onClick={() => setCurrentNavbar("live-betting-dentisy")}
                        className={`flex items-center ${currentNavbar == "live-betting-dentisy"
                            ? "bg-[#ffcc00] text-black"
                            : "hover:bg-[#111] hover:text-[#ffcc00]"
                          } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                      >
                        {Language["en"].dashboardHeader.liveBettingDensity}
                      </Link>
                    </>
                  ) : ""}
                </div>
              </div>
            </div>

            {Number(user_type) !== 9 && (
              <div className="p-[15px] pt-5 text-white text-[14px]">
                <div className="w-[165px]">
                  <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                    <PiUsersThreeFill color="#ffcc00" size={18} />{" "}
                    {Language["en"].dashboardHeader.users}
                  </div>
                  <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                    <Link
                      to={"/my-users"}
                      onClick={() => setCurrentNavbar("my-users")}
                      className={`border-b-[1px] ${currentNavbar === "my-users"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.myUsers}
                    </Link>
                    <Link
                      to={"/new-user"}
                      onClick={() => setCurrentNavbar("new-user")}
                      className={`border-b-[1px] ${currentNavbar === "new-user"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.newUser}
                    </Link>
                    {Number(Cookies.get("user_type")) !== 5 && (
                      <Link
                        to={"/person-opening-limit"}
                        onClick={() => setCurrentNavbar("person-opening-limit")}
                        className={`border-b-[1px] ${currentNavbar === "person-opening-limit"
                            ? "bg-[#ffcc00] text-black"
                            : "hover:bg-[#111] hover:text-[#ffcc00]"
                          } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                      >
                        {Language["en"].dashboardHeader.personOpeningLimits}
                      </Link>
                    )}
                    {Number(user_type) !== 3 && Number(user_type) !== 1 && Number(user_type) !== 2 &&(
                      <Link
                        to={"/open-and-close-quick-game"}
                        onClick={() => setCurrentNavbar("open-and-close-quick-game")}
                        className={`border-b-[1px] ${currentNavbar === "open-and-close-quick-game"
                            ? "bg-[#ffcc00] text-black"
                            : "hover:bg-[#111] hover:text-[#ffcc00]"
                          } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                      >
                        {Language["en"].dashboardHeader.openandCloseQuickGame}
                      </Link>
                    )}
                    {(Number(user_type) === 7 || Number(user_type) === 3 || Number(user_type) === 1 ||  Number(user_type) === 2) && (
                      <Link
                        to={"/online-users"}
                        onClick={() => setCurrentNavbar("online-users")}
                        className={`flex items-center ${currentNavbar === "online-users"
                            ? "bg-[#ffcc00] text-black"
                            : "hover:bg-[#111] hover:text-[#ffcc00]"
                          } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                      >
                        {Language["en"].dashboardHeader.onlineUsers}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="p-[15px] pt-5 text-white text-[14px]">
              <div className="w-[165px]">
                <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                  <VscGraphLine color="#ffcc00" size={18} />{" "}
                  {Language["en"].dashboardHeader.reports}
                </div>
                <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                  { (Number(user_type) !== 9 && Number(user_type) !== 3 && Number(user_type) !== 1&& Number(user_type) !== 2)? (
                    <Link
                      to={"/daily-report"}
                      onClick={() => setCurrentNavbar("daily-report")}
                      className={`border-b-[1px] ${currentNavbar == "daily-report"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.dailyReports}
                    </Link>
                  ) : ""}
                  {(Number(user_type) !== 9 && Number(user_type) !== 3 && Number(user_type) !== 1&& Number(user_type) !== 2) ? (
                    <Link
                      to={"/report-details"}
                      onClick={() => setCurrentNavbar("report-details")}
                      className={`border-b-[1px] ${currentNavbar == "report-details"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.reportDetails}
                    </Link>
                  ) : ""}

                  <Link
                    to={"/account-activite"}
                    onClick={() => setCurrentNavbar("account-activite")}
                    className={`flex items-center ${currentNavbar == "account-activite"
                        ? "bg-[#ffcc00] text-black"
                        : "hover:bg-[#111] hover:text-[#ffcc00]"
                      } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                  >
                    {Language["en"].dashboardHeader.accountActivite}
                  </Link>
                </div>
              </div>
            </div>
            {Number(user_type) !== 9 && (
              <div className="p-[15px] pt-5 text-white text-[14px]">
                <div className="w-[165px]">
                  <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                    <RiGamepadFill color="#ffcc00" size={18} />{" "}
                    {Language["en"].dashboardHeader.videoGame}
                  </div>
                  <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                    <Link
                      to={"/video-game-activite"}
                      onClick={() => setCurrentNavbar("video-game-activite")}
                      className={`flex items-center ${currentNavbar == "video-game-activite"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                    >
                      {Language["en"].dashboardHeader.videoGameActivity}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {Number(user_type) === 7 && (
              <div className="p-[15px] pt-5 text-white text-[14px]">
                <div className="w-[165px]">
                  <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                    <TbExchange color="#ffcc00" size={18} />{" "}
                    {Language["en"].dashboardHeader.rateChange}
                  </div>
                  <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                    <Link
                      to={"/general-basis"}
                      onClick={() => setCurrentNavbar("general-basis")}
                      className={`border-b-[1px] ${currentNavbar == "general-basis"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.generalBasis}
                    </Link>
                    <Link
                      to={"/based-rate-type"}
                      onClick={() => setCurrentNavbar("based-rate-type")}
                      className={`border-b-[1px] ${currentNavbar == "based-rate-type"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.basedRateType}
                    </Link>
                    <Link
                      to={"/based-sport-type"}
                      onClick={() => setCurrentNavbar("based-sport-type")}
                      className={`border-b-[1px] ${currentNavbar == "based-sport-type"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.basedSportType}
                    </Link>
                    <Link
                      to={"/league-based"}
                      onClick={() => setCurrentNavbar("league-based")}
                      className={`border-b-[1px] ${currentNavbar == "league-based"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.leagueBasis}
                    </Link>
                    <Link
                      to={"/single-match-based"}
                      onClick={() => setCurrentNavbar("single-match-based")}
                      className={`flex items-center ${currentNavbar == "single-match-based"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                    >
                      {Language["en"].dashboardHeader.singleMatchBased}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {Number(user_type) === 7 && (
              <div className="p-[15px] pt-5 text-white text-[14px]">
                <div className="w-[165px]">
                  <div className="flex items-center px-[15px] py-[5px] gap-[4px]">
                    <BsFillCheckSquareFill color="#ffcc00" size={16} />{" "}
                    {Language["en"].dashboardHeader.onOff}
                  </div>
                  <div className="bg-[#292929] flex flex-col rounded-b-lg rounded-t-md">
                    <Link
                      to={"/ratio-type"}
                      onClick={() => setCurrentNavbar("ratio-type")}
                      className={`border-b-[1px] ${currentNavbar == "ratio-type"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.ratioType}
                    </Link>
                    <Link
                      to={"/league-type"}
                      onClick={() => setCurrentNavbar("league-type")}
                      className={`border-b-[1px] ${currentNavbar == "league-type"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } border-b-[rgba(0,0,0,.2)] border-t-[1px] rounded-t-md px-[13px] py-[7px] border-t-[rgba(255,255,255,.1)] transition-all duration-200 `}
                    >
                      {Language["en"].dashboardHeader.leagueToggle}
                    </Link>
                    <Link
                      to={"/match-details"}
                      onClick={() => setCurrentNavbar("match-details")}
                      className={`flex items-center ${currentNavbar == "match-details"
                          ? "bg-[#ffcc00] text-black"
                          : "hover:bg-[#111] hover:text-[#ffcc00]"
                        } gap-1 border-b-[1px] border-b-[#111] border-t-[1px] border-t-[rgba(255,255,255,0.1)] rounded-b-lg px-[13px] py-[7px] transition-all duration-200`}
                    >
                      {Language["en"].dashboardHeader.matchDetails}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
    textColor: state.textColor,
  };
};
export default connect(mapStateToProps, {
  setBackgroundColor,
  setTextColor,
})(DashboardHeader);
