import React, { useEffect, useState } from "react";
import {
  Alert,
  ChangeRate,
  ErrorAlert,
  ErrorUnAlert,
  IsLoading,
  SportTypeComp,
  UserAffected,
} from "./index";
import { Link } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { setBackgroundColor,setTextColor } from "../redux/actions/actions";
import axios from "axios";
import Language from '../language.json'
import Cookies from "js-cookie";
import { connect } from "react-redux";

const BasedSportType = ({textColor,backgroundColor}) => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/odd_config3";
  const apiGetUrl = process.env.REACT_APP_API_URL + "/api/odd_config3get";
  const [datas, setDatas] = useState([]);
  const [selectData, setSelectData] = useState("All");
  const [selectPercentageData, setSelectPercentageData] = useState("0");
  const [selectScore, setSelectScore] = useState("0");
  const [type, setType] = useState("0");
  const [rule, setRule] = useState("0");
  const [selectedId, setSelectedId] = useState("66");
  const [selectedUserId, setselectedUserId] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    setselectedUserId(e.target.value);
  };

  useEffect(() => {
    const postData2 = {
      user_id: selectedUserId,
    };
    axios
      .post(apiGetUrl, postData2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        
        if (
          response.data &&
          response.data.config_type &&
          response.data.config_rule
        ) {
          if (
            response.data.config_type[selectedId] &&
            response.data.config_rule[selectedId]
          ) {
            if (response.data.config_type[selectedId][0] === 0) {
              setSelectPercentageData(response.data.config_rule[selectedId][0]);
              setSelectScore(0);
            } else if (response.data.config_type[selectedId][0] === 1) {
              setSelectScore(response.data.config_rule[selectedId][0]);
              setSelectPercentageData(0);
            }
          } else {
            setSelectScore(0);
            setSelectPercentageData(0);
          }
        } else {
          setSelectScore(0);
          setSelectPercentageData(0);
        }
      })
      .catch((error) => {
        console.error("API ERROR:", error);
        setSelectScore(0);
        setSelectPercentageData(0);
      });
  }, [selectedUserId, selectedId]);

  useEffect(() => {
    if (selectPercentageData == 0) {
      setType("1");
      setRule(selectScore);
    }
    if (selectScore == 0) {
      setType("0");
      setRule(selectPercentageData);
    }

    if (selectPercentageData == 0 && selectScore == 0) {
      setType("1");
      setRule("0");
    }
  }, [selectPercentageData, selectScore]);

  const applyRate = () => {
    const postData = {
      user_id: selectData,
      sports_id: [selectedId],
      config_type: [parseFloat(type)],
      config_rule: [parseFloat(rule)],
    };

    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((error) => console.error("API ERROR:", error));
  };

  return (
    <div>
      {alert && (
          <Alert
          alert={alert}
          setAlert={setAlert}
          />
        )}
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].basedSportType.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[15px] p-[15px]">
        <div className="flex bg-[#fff] p-[15px]">
          <div className="flex-1 py-0 px-[5px] max-w-[290px]">
            <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
            style={{borderBottomColor:backgroundColor, color:textColor}}
            >
              <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
                1
              </span>
              {Language["en"].basedSportType.comp1}
            </div>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
                <IsLoading />
              </div>
            ) : (
              <UserAffected
                datas={datas}
                selectData={selectData}
                setSelectData={setSelectData}
                handleChange={handleChange}
                textColor={textColor}
              />
            )}
          </div>
          <div className="flex-1 py-0 px-[5px]">
            <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
            style={{borderBottomColor:backgroundColor, color:textColor}}
            >
              <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
                2
              </span>
              {Language["en"].basedSportType.comp2}
            </div>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
                <IsLoading />
              </div>
            ) : (
              <SportTypeComp
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                textColor={textColor}
                backgroundColor={backgroundColor}
              />
            )}
          </div>
          <div className="flex-1 py-0 px-[5px]">
            <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
            style={{borderBottomColor:backgroundColor, color:textColor}}
            >
              <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
                3
              </span>
              {Language["en"].basedSportType.comp3}
            </div>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
                <IsLoading />
              </div>
            ) : (
              <ChangeRate
                datas={datas}
                selectPercentageData={selectPercentageData}
                setSelectPercentageData={setSelectPercentageData}
                selectScore={selectScore}
                setSelectScore={setSelectScore}
                textColor={textColor}
                backgroundColor={backgroundColor}
              />
            )}
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <button
            className={`forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[${backgroundColor}] text-[#fff] cursor-pointer transition-all duration-300`}
            onClick={applyRate}
          >
            {Language["en"].basedSportType.applyButton}
          </button>
        </div>
        <div className=" flex p-[20px] bg-[rgba(0,0,0,0.1)] leading-6 rounded-[5px] mt-5 text-[#444] relative">
        {Language["en"].basedSportType.changeMessage}{" "}
          <Link to={"/changes-center"} style={{color:textColor}}>
            <div className="flex mx-1 items-center gap-1">
              <BsBoxArrowUpRight /> {Language["en"].basedSportType.changeMessageLink}
            </div>
          </Link>{" "}
          {Language["en"].basedSportType.changeMessage2}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
})

export default connect(mapStateToProps,{setBackgroundColor,setTextColor})(BasedSportType);
