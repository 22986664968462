import {useEffect} from "react";
import axios from "axios";
import Language from '../../language.json'
import { connect } from "react-redux";
import { setBackgroundColor, setTextColor } from "../../redux/actions/actions";
import Cookies from "js-cookie";

const MyUsersWithWorkSystem = ({
  user_id,
  mainType1,
  setMainType1,
  mainType2,
  setMainType2,
  mainType3,
  setMainType3,
  mainType4,
  setMainType4,
  mainType1Rule,
  setMainType1Rule,
  mainType2Rule,
  setMainType2Rule,
  mainType3Rule,
  setMainType3Rule,
  mainType4Rule,
  setMainType4Rule,
  backgroundColor,
  textColor
}) => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + `/api/getuserdetail/${user_id}`

  const targetUserId = parseInt(user_id); 

useEffect(()=>{
  axios.get(apiUrl,{
    headers: { Authorization: `Bearer ${token}` },
  })
  .then((response) => {
    const apiData = response.data;
    var a = apiData.user_comission.find((c) => c.comission_main_type == 1)
    var b = apiData.user_comission.find((c) => c.comission_main_type == 2)
    var c = apiData.user_comission.find((c) => c.comission_main_type == 3)
    var d = apiData.user_comission.find((c) => c.comission_main_type == 4)
    
    setMainType1(a.comission_type)
    setMainType2(b.comission_type)
    setMainType3(c.comission_type)
    setMainType4(d.comission_type)
    setMainType1Rule(a.comission_rule)
    setMainType2Rule(b.comission_rule)
    setMainType3Rule(c.comission_rule)
    setMainType4Rule(d.comission_rule)
    for (let i = 0; i < apiData.length; i++) {
      if (apiData[i].user_id === targetUserId) {
        break; 
      }
    }
  })
  .catch((error) => {
    console.error("API Error:", error);
  })
},[])

  return (
    <div className="px-[5px]">
      <div className="p-[10px] border-b-[1px] relative"
      style={{color:textColor,borderBottomColor:backgroundColor}}
      >
        {Language["en"].myUsersWithWorkSystem.title}
      </div>
      <div className="flex flex-col flex-wrap">
        <div className="forms-box-shadow flex flex-col p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
          <div className="flex flex-col p-[5px] font-medium text-[13px]">
            {Language["en"].myUsersWithWorkSystem.quickChoice}
          </div>
          <div className="p-[5px] flex gap-1 justify-between">
            <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].myUsersWithWorkSystem.allSystem}
            </div>
            <select
              className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              name="all_system"
              id="all_system"
              value={mainType1}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMainType1(selectedValue)
              }}
            >
                
              { 
              [
                Language["en"].myUsersWithWorkSystem.selectMainType1,
                Language["en"].myUsersWithWorkSystem.selectMainType2,
                Language["en"].myUsersWithWorkSystem.selectMainType3
              ].map((item,index)=>{
                return <option value={index}>{item}</option>
                
})}
            </select>
            <div className="relative WorkWithTheSystem">
              <input
                className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                
                type="number"
                value={mainType1Rule}
                onChange={(e) => {
                    const selectedValue = e.target.value
                    setMainType1Rule(selectedValue)
                }}
              />
            </div>
          </div>
        </div>
        <div className="forms-box-shadow flex flex-col p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
          <div className="flex flex-col p-[5px] font-medium text-[13px]">
          {Language["en"].myUsersWithWorkSystem.advancedOperating}
          </div>
          <div className="p-[5px] flex gap-1 justify-between">
            <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].myUsersWithWorkSystem.preMatchOnly}
            </div>
            <select
              className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              name="pre_match"
              id="pre_match"
              value={mainType2}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMainType2(selectedValue)
              }}
            >
              <option value="0">{Language["en"].myUsersWithWorkSystem.selectAdvanced0}</option>
              <option value="1">{Language["en"].myUsersWithWorkSystem.selectAdvanced1}</option>
              <option value="2">{Language["en"].myUsersWithWorkSystem.selectAdvanced2}</option>
              <option value="3">{Language["en"].myUsersWithWorkSystem.selectAdvanced3}</option>
            </select>
            <div className="relative WorkWithTheSystem">
              <input
                type="number"
                className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                
                value={mainType2Rule}
                onChange={(e) => {
                    const selectedValue = e.target.value
                    setMainType2Rule(selectedValue)
                }}   
/>
            </div>
          </div>
          <div className="p-[5px] flex gap-1 justify-between">
            <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              Live Only
            </div>
            <select
              className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              name=""
              id=""
              value={mainType3}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMainType3(selectedValue)
              }}
            >
              <option value="0">{Language["en"].myUsersWithWorkSystem.selectLive0}</option>
              <option value="1">{Language["en"].myUsersWithWorkSystem.selectLive1}</option>
              <option value="2">{Language["en"].myUsersWithWorkSystem.selectLive2}</option>
              <option value="3">{Language["en"].myUsersWithWorkSystem.selectLive3}</option>
            </select>
            <div className="relative WorkWithTheSystem">
              <input
                className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                
                type="number"
                value={mainType3Rule}
                onChange={(e) => {
                    const selectedValue = e.target.value
                    setMainType3Rule(selectedValue)
                }}
              />
            </div>
          </div>
          <div className="p-[5px] flex gap-1 justify-between">
            <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].myUsersWithWorkSystem.videoGameOnly}
            </div>
            <select
              className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              name=""
              id=""
              value={mainType4}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMainType4(selectedValue)
              }}
            >
              <option value="0">{Language["en"].myUsersWithWorkSystem.videoGame0}</option>
              <option value="1">{Language["en"].myUsersWithWorkSystem.videoGame1}</option>
              <option value="2">{Language["en"].myUsersWithWorkSystem.videoGame2}</option>
              <option value="3">{Language["en"].myUsersWithWorkSystem.videoGame3}</option>
            </select>
            <div className="relative WorkWithTheSystem">
              <input
                className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                
                type="number"
                value={mainType4Rule}
                onChange={(e) => {
                    const selectedValue = e.target.value
                    setMainType4Rule(selectedValue)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
    textColor: state.textColor
});
const mapDispatchToProps = {
  setBackgroundColor,
    setTextColor
}

export default connect(mapStateToProps,mapDispatchToProps)(MyUsersWithWorkSystem);
