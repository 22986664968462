import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTextColor,setBackgroundColor } from "../../redux/actions/actions";
import Language from '../../language.json'
import Cookies from "js-cookie";
const MyUsersBets = ({ user_id,setAllBets,allBets,sportGames,setSportGames,videoGames,setVideoGames,virtualGames,setVirtualGames,textColor,backgroundColor }) => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + `/api/getuserdetail/${user_id}`;

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const apiData = response.data;
        setSportGames(apiData.fast_game_config.sport_games);
        setVideoGames(apiData.fast_game_config.video_games);
        setVirtualGames(apiData.fast_game_config.virtual_games)
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [user_id, setSportGames,setVideoGames,setVirtualGames]);

  const handleClick = (id) => {
    const updatedSportData = sportGames.map((item) => {
      if (item.id === id) {
        return { ...item, active: item.active === 1 ? 0 : 1 };
      }
      return item;
    });
    const updatedVideoData = videoGames.map((item) => {
      if (item.id === id) {
        return { ...item, active: item.active === 1 ? 0 : 1 };
      }
      return item;
    });
    const updatedVirtualData = virtualGames.map((item) => {
      if (item.id === id) {
        return { ...item, active: item.active === 1 ? 0 : 1 };
      }
      return item;
    });

    setSportGames(updatedSportData);
    setVideoGames(updatedVideoData);
    setVirtualGames(updatedVirtualData);

  };
  useEffect(()=>{
    setAllBets([...sportGames,...videoGames,...virtualGames])
  },[sportGames,videoGames,virtualGames])

  return (
    <div>
      <div>
        <div className="p-[10px] pl-[35px] border-b-[1px] relative" style={{borderBottomColor:backgroundColor,color:textColor}}>
          {Language["en"].myUsersBets.title}
        </div>
        <div className="flex flex-wrap p-[2px]">
          {allBets.map((data, index) => (
            <label key={index} className="w-[33%] p-[1px] cursor-pointer min-w-[135px]">
              <div className="text-[13px] flex items-center forms-label-box-shadow bg-[#f9f9f9] p-[10px] rounded-[5px]">
                <input
                  className="cursor-pointer w-5 h-5 border-[1px] mr-[5px]"
                  style={{accentColor:backgroundColor,borderColor:backgroundColor}}
                  type="checkbox"
                  checked={data.active === 1 ? true : false}
                  onChange={() => handleClick(data.id)}
                />
                {data.name}
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

const mapDispatchToProps = {
  setBackgroundColor,
  setTextColor
};

export default connect(mapStateToProps, mapDispatchToProps)(MyUsersBets);