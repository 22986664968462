import React from 'react'
import Language from '../../language.json'
const AccountActiviteList = ({datas,totalAdd,totalSub}) => {
const lang = "en"
  const formatDate = (dateString) => {
    const options = { 
        hour12: false,
    };
    
    const formattedDate = new Date(dateString).toLocaleString('tr-TR', options);
    return formattedDate;
};
  return (
    <div>
        <table className='w-full'>
            <thead>
                <tr>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px]'>{Language[lang].ActiviteList.textTd1}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px]'>{Language[lang].ActiviteList.textTd2}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px] text-end'>{Language[lang].ActiviteList.textTd3}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px] text-end'>{Language[lang].ActiviteList.textTd4}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px] text-end'>{Language[lang].ActiviteList.textTd5}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px]'>{Language[lang].ActiviteList.textTd6}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px]'>{Language[lang].ActiviteList.textTd7}</td>
                    <td className='bg-[#ccc] text-[12px] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] py-[15px] px-[10px]'>{Language[lang].ActiviteList.textTd12}</td>
                </tr>
            </thead>
            <tbody>
                {
                    datas?.map((data) => (
                <tr>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>{formatDate(data.date)}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>{data.username}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'} text-end`}>{data.before_amount}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative text-end ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>
                    {
                        data?.total_amount ? data.total_amount : ""
                    }
                    </td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'} text-end`}>{data.last_amount}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>{data.opname}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>{data.detail}</td>
                    <td className={`font-bold transition-all duration-100 border-y-[1px] border-t-[rgba(255,255,255,0.5)] border-b-[rgba(0,0,0,0.1)] text-[12px] bg-[#f9f9f9] py-[8px] px-[10px] relative ${data.transaction_type === 1 || data.transaction_type === 3 || data.transaction_type === 5 ? 'text-[#14892c]' : 'text-[#BC2121]'}`}>{data.opip}</td>
                </tr>
                    ))
                }
                <tr>
                    <td className='bg-[#ccc] font-bold rounded-bl-[15px] border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'></td>
                    <td className='bg-[#ccc] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'>{Language[lang].ActiviteList.textTd8} {totalAdd.toFixed(2)}</td>
                    <td className='bg-[#ccc] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'>{Language[lang].ActiviteList.textTd9} {totalSub} </td>
                    <td className='bg-[#ccc] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px] text-end'>{Language[lang].ActiviteList.textTd10} {(totalAdd - totalSub).toFixed(2)}</td>
                    <td className='bg-[#ccc] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'></td>
                    <td className='bg-[#ccc] font-bold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'></td>
                    <td className='bg-[#ccc] font-bold rounded-br-[15px] border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] py-[15px] px-[10px] text-[12px]'></td>
                </tr>
                </tbody>
        </table>
    </div>
  )
}

export default AccountActiviteList