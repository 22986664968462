import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";

function VideoGamesTop() {
  const currentUrl = window.location.href;


 const [gameData, setGameData] = useState([]);

 const token = Cookies.get("token");
 const apiUrl = "https://rona.thoth.lt/api/video_game_list";
 const handleGameClick = (id) => {
  axios
  .post(
   "https://rona.thoth.lt/api/casino/authplay",
   { game_id: id, callback: currentUrl },
   {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   }
  )
  .then((response) => {
    if(response.data.success === "true"){
      window.open(response.data.url, "_blank");
    }else{
      
    }
   
  })
  .catch((error) => {
   console.error(error);
  });

 };
 useEffect(() => {
  axios
   .post(
    apiUrl,
    { type_id: 1, attribute_id: 1 },
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   )
   .then((response) => {
    setGameData(response.data);
   })
   .catch((error) => {
    console.error(error);
   });
 }, []);
 return (
  <div>
   <div className="text-[34px] font-bold text-white py-[15px] px-[25px]">
    Video Games
   </div>
   <div className="flex w-[100%] bg-[#000] p-[15px] justify-around rounded-tl-[25px]">
    {gameData.slice(0,5).map((game) => (
     <div
      onClick={() => handleGameClick(game.id)}
      style={{ backgroundImage: `url(${game.img})` }}
      className={`flex-1 h-[200px] bg-cover border border-[#222] shadow-slot-games flex relative mr-[5px] rounded-tl-[25px] rounded-tr-[25px] hover:border hover:border-[#919191] hover:shadow-shadow-hover cursor-pointer`}
     >
      <div className="text-white font-normal flex-col flex bottom-0 absolute w-[100%] bg-[rgba(0,0,0,.9)] p-[15px]">
       {game.name}
       <span className="text-[24px] text-white font-bold inherit">
        Vegas x500
       </span>
      </div>
     </div>
    ))}
   </div>
  </div>
 );
}

export default VideoGamesTop;
