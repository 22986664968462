import React, { useState, useEffect } from "react";
import "../mobile/mobile.css";
import { AiOutlineClose } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import MobileNavbar from "./MobileNavbar";
import { useSelectedOdds } from "./SelectedOddsContext";
import { FaDeleteLeft } from "react-icons/fa6";
import {FaPaperPlane} from 'react-icons/fa'
import '../mobile/mobile.css'

function MobileKupon() {
 const { selectedOdds } = useSelectedOdds();
 

 const [isSlipVisible, setSlipVisible] = useState(true);

 const handleCloseClick = () => {
  setSlipVisible(false);
 };

 return (
  <div>
   <div className="slip" style={{ display: isSlipVisible ? "block" : "none" }}>
    <div className="inslip">
     <div className="slip-title">Kuponunuz</div>
     <div className="slip-content">
      {selectedOdds.length > 0 ? (
       <div className="mt-[100px]">
        {selectedOdds.map((selectedOdd, index) => (
         <div>
          <div key={index} className="kuponline">
           <div className="kupondelete">
            <GrFormClose />
           </div>
           <div className="kuponinfo">
            <div className="tercih">
             {selectedOdd.title === "X"
              ? "Beraberlik"
              : selectedOdd.title === "1"
              ? "Ev Sahibi"
              : selectedOdd.title === "2"
              ? "Deplasman"
              : selectedOdd.title === "A"
              ? "Alt"
              : selectedOdd.title === "Ü"
              ? "Üst"
              : selectedOdd.title}
            </div>
            <div className="tahmin">Maç Sonucu</div>{" "}
            {/* buraya tahmin grubu gelecek */}
            <div className="mac">{selectedOdd.match.teams}</div>
           </div>
           <div className="kuponodd">{selectedOdd.value}</div>
          </div>
         </div>
        ))}
        <div className="clearkupon">Kuponu Temizle</div>
        <div className="kuponalt">
         <ul>
          <li>Bahis</li>
          <li>2</li>
         </ul>
         <ul>
          <li>Oran</li>
          <li>3.35</li>
         </ul>
         <ul>
          <li>Miktar</li>
          <li id="miktar">0.00</li>
         </ul>
        </div>
        <div className="muhtemel">
         <span>Muhtemel Kazanç</span>
         <span>0.00</span>
        </div>
        <div className="keyboard">
            <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
            </ul>
            <ul>
                <li>4</li>
                <li>5</li>
                <li>6</li>
            </ul>
            <ul>
                <li>7</li>
                <li>8</li>
                <li>9</li>
            </ul>
            <ul>
                <li>Max.</li>
                <li>0</li>
                <li>
                    <FaDeleteLeft className="mt-2.5 ml-[40%]"/>

                </li>
            </ul>
        </div>
        <div className="kabul">
            <span>Değişebilecek oranları kabul ediyorum</span>
            <div className="labelbox">
                <label className="checkbox">
                    <input type="checkbox" name="change_oran" data-callback="change_oran" checked/>
                    <span className="checkbox">
                        <span></span>
                    </span>
                </label>
            </div>
        </div>
        <div className="kname">
            <input type="text" placeholder="Kupon Adı (isteğe bağlı)" maxLength={10}/>
        </div>
        <div className="kuponb">
            <a href="" className="kuponbutton">
                KUPONU GÖNDER
            </a>
        </div>
       </div>
      ) : (
       <span id="slip">
        <span className="boskupon">
         Kuponunuzda herhangi bir müsabaka bulunamadı.
        </span>
       </span>
      )}
     </div>
     <div className="slip-close" onClick={handleCloseClick}>
      <AiOutlineClose style={{ marginLeft: "5px" }} />
     </div>
    </div>
   </div>
  </div>
 );
}

export default MobileKupon;
