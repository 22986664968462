import React, { useState } from "react";
import {
  newUsersPlayerLimitValueUpdate,
  newUsersPlayerLimitCheckbox,
  newUsersAgencyLimitValueUpdate,
  newUsersAgencyLimitCheckbox,
  newUsersSuperLimitValueUpdate,
  newUsersSuperLimitCheckbox,
  newUsersDealerLimitValueUpdate,
  newUsersDealerLimitCheckbox,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from '../../language.json'
import { useEffect } from "react";
const CreatingSubUser = ({
  newUsersPlayerLimit,
  newUsersPlayerLimitValueUpdate,
  playerLimitCheckbox,
  newUsersPlayerLimitCheckbox,
  newUsersAgencyLimit,
  newUsersAgencyLimitValueUpdate,
  agencyLimitCheckbox,
  newUsersAgencyLimitCheckbox,
  newUsersSuperLimit,
  newUsersSuperLimitValueUpdate,
  superLimitCheckbox,
  newUsersSuperLimitCheckbox,
  newUsersDealerLimit,
  newUsersDealerLimitValueUpdate,
  dealerLimitCheckbox,
  newUsersDealerLimitCheckbox,
  allreadyuserlimit,
  allreadydealerlimit,
  allreadyagencylimit,
  allreadysuperlimit,
  textColor,
  backgroundColor,
  limits
}) => {
  const [userlimit,setuserlimit]=useState("")
  const [dealerlimit,setdealerlimit]=useState("")
  const [agencylimit,setagencylimit]=useState("")
  const [superlimit,setsuperlimit]=useState("")
  useEffect(() => {
    setuserlimit(allreadyuserlimit);
    setdealerlimit(allreadydealerlimit);
    setagencylimit(allreadyagencylimit);
    setsuperlimit(allreadysuperlimit);
  }, [allreadyuserlimit,allreadydealerlimit,allreadyagencylimit,allreadysuperlimit])
  

  return (
    <div className="pt-[15px]">
      <div className="px-[5px] pt-[5px]">
        <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
        style={{borderBottomColor: backgroundColor,color:textColor}}
        >
          <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
            2
          </span>
          {Language["en"].creatingSubUser.title}
        </div>
        <div className="flex flex-wrap">
        {
                Number(Cookies.get("user_type")) !== 5  && 
                <>
          <div className="forms-box-shadow flex max-h-[92px] justify-between p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col justify-center p-[5px] font-medium text-[13px]">
              {Language["en"].creatingSubUser.playerLimit}
              <div style={{ fontSize: '10px', color: 'grey' }}>{limits.web_created_limit} Adet Kalan</div>
            </div>
            <div className="flex flex-col p-[5px]">
              <input
                className="forms-select-box-shadow w-[70px] dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] text-end placeholder:text-[#222]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                placeholder="0"
                name="player_limit"
                id="player_limit"
                type="number"
                value={userlimit}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setuserlimit(selectedValue);
                  newUsersPlayerLimitValueUpdate(selectedValue);
                }}
              />
              {/* <label
                htmlFor=""
                className="flex mt-1 items-center h-[35px] text-[13px]"
              > */}
                <input
                  className={`cursor-pointer w-5 h-5 border-[1px] border-[${backgroundColor}] mr-[5px]`}
                  style={{accentColor: backgroundColor}}
                  type="hidden"
                  checked={playerLimitCheckbox}
                  onChange={() => newUsersPlayerLimitCheckbox()}
                  defaultChecked={true}
                />
              {/* </label> */}
            </div>
          </div>
          {
                Number(Cookies.get("user_type")) !== 7  && 
                <>
          <div className="forms-box-shadow flex max-h-[92px] justify-between p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
          <div className="flex flex-col justify-center p-[5px] font-medium text-[13px]">
              {Language["en"].creatingSubUser.dealerLimit}
              <div style={{ fontSize: '10px', color: 'grey' }}>{limits.dealer_created_limit} Adet Kalan</div>
            </div>
            <div className="flex flex-col p-[5px]">
              <input
                className="forms-select-box-shadow w-[70px] dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] text-end placeholder:text-[#222]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                placeholder="0"
                name="dealer_limit"
                id="dealer_limit"
                type="number"
                value={dealerlimit}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setdealerlimit(selectedValue)
                  newUsersDealerLimitValueUpdate(selectedValue);
                }}
              />
              {/* <label
                htmlFor=""
                className="flex mt-1 items-center h-[35px] text-[13px]"
              > */}
                <input
                  className={`cursor-pointer w-5 h-5 border-[1px] border-[${backgroundColor}] mr-[5px]`}
                  style={{accentColor: backgroundColor}}
                  type="hidden"
                  checked={dealerLimitCheckbox}
                  onChange={() => newUsersDealerLimitCheckbox()}
                  defaultChecked={true}
                />
              {/* </label> */}
              </div>
          </div>
          {
                Number(Cookies.get("user_type")) !== 3  && 
                <>
            <div className="forms-box-shadow flex max-h-[92px] justify-between p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col justify-center p-[5px] font-medium text-[13px]">
              {Language["en"].creatingSubUser.agencyLimit}
              <div style={{ fontSize: '10px', color: 'grey' }}>{limits.admin_created_limit} Adet Kalan</div>
            </div>
            <div className="flex flex-col p-[5px]">
              <input
                className="forms-select-box-shadow w-[70px] dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] text-end placeholder:text-[#222]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                placeholder="0"
                name="agency_limit"
                id="agency_limit"
                type="number"
                value={agencylimit}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setagencylimit(selectedValue)
                  newUsersAgencyLimitValueUpdate(selectedValue);
                }}
              />
              {/* <label
                htmlFor=""
                className="flex mt-1 items-center h-[35px] text-[13px]"
              > */}
                <input
                  className={`cursor-pointer w-5 h-5 border-[1px] border-[${backgroundColor}] mr-[5px]`}
                  style={{accentColor: backgroundColor}}
                  type="hidden"
                  checked={agencyLimitCheckbox}
                  onChange={() => newUsersAgencyLimitCheckbox()}
                  defaultChecked={true}
                />
              {/* </label> */}
            </div>
          </div>
          </>
          }
                    {
                Number(Cookies.get("user_type")) === 2  && 
                <>
            <div className="forms-box-shadow flex max-h-[92px] justify-between p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col justify-center p-[5px] font-medium text-[13px]">
              {Language["en"].creatingSubUser.superlimit}
              <div style={{ fontSize: '10px', color: 'grey' }}>{limits.super_created_limit} Adet Kalan</div>
            </div>
            <div className="flex flex-col p-[5px]">
              <input
                className="forms-select-box-shadow w-[70px] dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] text-end placeholder:text-[#222]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                placeholder="0"
                name="super_limit"
                id="super_limit"
                type="number"
                value={superlimit}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setsuperlimit(selectedValue)
                  newUsersSuperLimitValueUpdate(selectedValue);
                }}
              />
              {/* <label
                htmlFor=""
                className="flex mt-1 items-center h-[35px] text-[13px]"
              > */}
                <input
                  className={`cursor-pointer w-5 h-5 border-[1px] border-[${backgroundColor}] mr-[5px]`}
                  style={{accentColor: backgroundColor}}
                  type="hidden"
                  checked={superLimitCheckbox}
                  onChange={() => newUsersSuperLimitCheckbox()}
                  defaultChecked={true}
                />
              {/* </label> */}
            </div>
          </div>
          </>
          }
          </>
          }
          </>
}
          </div>
        </div>
      </div>

    
  );
};
const mapStateToProps = (state) => ({
  newUsersPlayerLimitValueUpdate: state.newUsersPlayerLimitValueUpdate,
  newUsersPlayerLimitCheckbox: newUsersPlayerLimitCheckbox,
  newUsersAgencyLimitValueUpdate: state.newUsersAgencyLimitValueUpdate,
  newUsersAgencyLimitCheckbox: newUsersAgencyLimitCheckbox,
  newUsersSuperLimitValueUpdate: state.newUsersSuperLimitValueUpdate,
  newUsersSuperLimitCheckbox: newUsersSuperLimitCheckbox,
  newUsersDealerLimitValueUpdate: state.newUsersDealerLimitValueUpdate,
  newUsersDealerLimitCheckbox: newUsersDealerLimitCheckbox,
});

const mapDispatchToProps = {
  newUsersPlayerLimitValueUpdate,
  newUsersPlayerLimitCheckbox,
  newUsersAgencyLimitValueUpdate,
  newUsersAgencyLimitCheckbox,
  newUsersSuperLimitValueUpdate,
  newUsersSuperLimitCheckbox,
  newUsersDealerLimitValueUpdate,
  newUsersDealerLimitCheckbox,
};


export default connect(mapStateToProps, mapDispatchToProps)(CreatingSubUser);
