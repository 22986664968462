import axios from "axios";
import React, { useEffect, useState } from "react";
import { accountActiviteInputValueUpdate } from "../../redux/actions/actions";
import { connect } from "react-redux";
import Language from '../../language.json' 
import Cookies from "js-cookie";

const AccountActiviteInput = ({ accountActiviteInput,accountActiviteInputValueUpdate,textColor }) => {
  const lang = "en"
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";

  const [users, setUsers] = useState([]);


  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);

  return (
    <div>
      <span className="text-[13px] font-semibold py-[5px] px-[3px]">{Language[lang].ActiviteInput.text1}</span>

      <select
        name=""
        id=""
        onChange={(e) => {
          const selectedValue = e.target.value;
          accountActiviteInputValueUpdate(selectedValue)
        }}
        value={accountActiviteInput}
        className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] sm:w-[150px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
        onFocus={(e) => {
          e.target.style.borderColor = `${textColor}`;
        }}
        onBlur={(e) => {
          e.target.style.borderColor = "#999";
          e.target.style.boxShadow = "none";
        }}
      >
        <option value="">{Language[lang].ActiviteInput.text2}</option>
              {
        users?.map((item)=>(
          <React.Fragment key={item.user_id}>
            <option value={item.username}>
              {item.username}
            </option>
            {item.community.length > 0 && (
              item.community.map(communityItem => (
                <option key={communityItem.user_id} value={communityItem.username}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{communityItem.username}
                </option>
              ))
            )}
          </React.Fragment>
        ))}
      </select>
    </div>
  );
};
const mapStateToProps = (state) => ({
  accountActiviteInput: state.accountActiviteInput
})

const mapDispatchToProps = {
  accountActiviteInputValueUpdate

}
export default connect(mapStateToProps,mapDispatchToProps)(AccountActiviteInput);
