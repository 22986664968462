import React, { useEffect } from 'react';
import IsLoading from './IsLoading'
const Tracker = ({lmtId,lmtLoading,setLmtLoading}) => {


    return (
      
    <div className='coupon-container flex justify-center'>
      {
        lmtLoading ?
      <div className='flex w-[282.5px] min-h-[100px] mt-2 justify-center items-center bg-[#282828] h-[350px]'> </div>
        :
      <iframe
      title="YouTube Video"
      src={`https://tracker.yextof.com/preview.html?id=${lmtId}&logo=1`}
      frameBorder="0"
      allowFullScreen
      width="283px"
      height="350px"
      style={{overflowY: "hidden"}}
      
      />
    }
    </div>
  );
};

export default Tracker;