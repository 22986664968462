import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorAlert, ErrorUnAlert, IsLoading } from "./index";
import Language from '../language.json'
import Cookies from "js-cookie";

const OnlineUsers = () => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getliveuser";
  const [datas, setDatas] = useState([]);
  const [userAmount, setUserAmount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const sortedData = response.data.sort((a,b) =>{
          return a.username.localeCompare(b.username)
        })
        setDatas(sortedData);
        setUserAmount(response.data.length);
        setIsLoading(false);
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);


  
  return (
    <div>
             {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].onlineUsers.title}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        {userAmount == 0 ? (
          <div className="p-[10px] bg-[#fff4ce] text-[#222] text-xs">
          {Language["en"].onlineUsers.youHave}
        <b>
            {Language["en"].onlineUsers.noOnline}
        </b>
          {Language["en"].onlineUsers.users}
          </div>
        ) : (
          <div className="p-[10px] bg-[#fff4ce] text-[#222] text-xs">
            {Language["en"].onlineUsers.youHave}
            { " " }
            <b>
               {userAmount} 
               { " " }
               {Language["en"].onlineUsers.online}
            </b> 
            { " " }
            {Language["en"].onlineUsers.users}
          </div>
        )}
        <div>
          <table className="w-full">
            <tbody>
              <tr>
                <td className="bg-[#ccc] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[#FFF] py-[15px] px-[10px] text-[12px]">
                {Language["en"].onlineUsers.titleUser}
                </td>
                <td className="bg-[#ccc] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[#FFF] py-[15px] px-[10px] text-[12px]">
                {Language["en"].onlineUsers.titleIpAdress}
                </td>
                <td className="bg-[#ccc] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[#FFF] py-[15px] px-[10px] text-[12px]">
                {Language["en"].onlineUsers.titleNetwork}
                </td>
                <td className="bg-[#ccc] font-semibold border-x-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[#FFF] py-[15px] px-[10px] text-[12px]">
                {Language["en"].onlineUsers.titleDevice}
                </td>
              </tr>

              {isLoading ? (
                <div className="flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center">
                  {" "}
                  <IsLoading />{" "}
                </div>
              ) : (
                datas.map((data) => (
                  <tr key={data.user_id}>
                    <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] font-bold bg-[#f9f9f9] py-[8px] px-[10px]">
                      {data.username}
                    </td>
                    <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] font-bold bg-[#f9f9f9] py-[8px] px-[10px]"></td>
                    <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] font-bold bg-[#f9f9f9] py-[8px] px-[10px]"></td>
                    <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] font-bold bg-[#f9f9f9] py-[8px] px-[10px]"></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OnlineUsers;
