import React from "react";
import Language from '../language.json'

const ManagementPanel = () => {
  return (
    <div className="container mx-auto">
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].managmentPanel.title}
      </div>
      <div className="py-[30px] px-[50px] bg-[#dddddd] rounded-b-[15px]">
      {Language["en"].managmentPanel.descriptionWelcome} <br />
      {Language["en"].managmentPanel.descriptionMiddle}
       <br /> <br />
       {Language["en"].managmentPanel.descriptionBottom}
      </div>
    </div>
  );
};

export default ManagementPanel;
