import React, { useState, useRef, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
 BsTrashFill,
 BsChevronDoubleDown,
 BsChevronDoubleUp,
} from "react-icons/bs";
import { BiFootball } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { FaRotate } from "react-icons/fa6";
import { AiFillFile, AiFillCloseCircle } from "react-icons/ai";
import "../components/desktop.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Language from "../language.json";
import Cookies from "js-cookie";

function Coupon({
 selectedOdds = [],
 setSelectedOdds = [],
 marketName,
 eventsId,
 couponchange,
 setcouponchange
}) {
 const [name, setKuponName] = useState("");
 const [total_amount, setTotal_Amount] = useState(0);
 const [loading, setLoading] = useState(false);
 const [successMessage, setSuccessMessage] = useState("");
 const [backToSelectedOdds, setBackToSelectedOdds] = useState(false);
 const [message, setMessage] = useState("");
 const [showOdds, setShowOdds] = useState(true);
 const [isChecked, setIsChecked] = useState(true);
 const [errorMessage, setErrorMessage] = useState("");
 const [notAuthMessage,setNotAuthMessage] = useState("")
 const [enterAmount,setEnterAmount] = useState(false)

 function encodeBase64UTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
  }));
}
function decodeBase64UTF8(encodedStr) {
return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
}).join(''));
}
 useEffect(() => {
  handlegetBackOdds();
  const selectedOddsFromCookie = localStorage.getItem("selectedOdds");
  if (!selectedOddsFromCookie || selectedOddsFromCookie === "W10=") {
   setSelectedOdds([]);
   localStorage.removeItem("selectedOdds");
  }
 }, []);
 useEffect(() => {
  if (showOdds==false && backToSelectedOdds == false) {
    handleReset();
  }
 }, [selectedOdds]);
 useEffect(() => {
  setTimeout(() => {
    if (showOdds==false && backToSelectedOdds == false) {
      handleReset();
    }
  }, 10000);
  
   }, [showOdds]);
 const handleSwitchChange = () => {
  const newCheckedState = !isChecked;

  localStorage.setItem("changed_odd", String(newCheckedState));

  setIsChecked(() => newCheckedState);
 };

 const handlegetBackOdds = () => {
  setBackToSelectedOdds(true);

  const selectedOddsFromCookie = localStorage.getItem("selectedOdds");
  if (!selectedOddsFromCookie || selectedOddsFromCookie === "W10=") {
   setSelectedOdds([]);
   localStorage.removeItem("selectedOdds");
  } else {
   const decodedOdds = decodeBase64UTF8(selectedOddsFromCookie);
   const oddsArray = JSON.parse(decodedOdds);
   var selects;
   oddsArray.map((selecteds) => {
    selects = document.getElementById(selecteds.odd_api_id);
    if (selects) {
     selects.classList.add("selected");
    }
   });
   setSelectedOdds(oddsArray);
  }
  setShowOdds(true);
 };

 const [miktar, setMiktar] = useState();
 const couponRef = useRef(null);

 function calculateTotalOdds(selectedOdds) {
  if (selectedOdds.length === 0) {
   return "0.00";
  }
  const totalOdds = selectedOdds.reduce(
   (accumulator, currentMatch) =>
    accumulator * parseFloat(currentMatch.odd_price),
   1
  );

  return totalOdds.toFixed(2);
 }

 const handleReset = () => {
  setSelectedOdds([]);
  localStorage.removeItem("selectedOdds");
  selectedOdds.forEach((matchInfo) => {
   const oranId = matchInfo.odd_api_id;
   const oranElement = document.getElementById(oranId);
   if (oranElement) {
    oranElement.classList.remove("selected");
   }
  });
  setBackToSelectedOdds(true);
  setShowOdds(true);
 };

 function handleRemoveMatch(index) {
  const matchToRemove = selectedOdds[index];
  const oranId = matchToRemove.odd_api_id;
  const oranElement = document.getElementById(oranId);
  if (oranElement) {
   oranElement.classList.remove("selected");
  }
  const updatedSelectedOdds = [...selectedOdds];
  updatedSelectedOdds.splice(index, 1);
  setSelectedOdds(updatedSelectedOdds);
  const updatedOddsBase64 = encodeBase64UTF8(JSON.stringify(updatedSelectedOdds));
  localStorage.setItem("selectedOdds", updatedOddsBase64);
 }

 function handleMiktarChange(event) {
  setMiktar(event.target.value);
 }

 function handleMinClick() {
  if (!isNaN(miktar) && parseFloat(miktar) > 0) {
   setMiktar((prevMiktar) => (parseFloat(prevMiktar) - 1).toString());
  }
 }

 function handleMaxClick() {
  setMiktar((prevMiktar) => (parseFloat(prevMiktar) + 1).toString());
 }
 function handleClearCoupon() {
  setSelectedOdds([]);
  localStorage.removeItem("selectedOdds");

  selectedOdds.forEach((matchInfo) => {
   const oranId = matchInfo.odd_api_id;
   const oranElement = document.getElementById(oranId);
   if (oranElement) {
    oranElement.classList.remove("selected");
   }
  });
 }
useEffect(()=>{
  couponchange === 1 ? setShowOdds(true) : setShowOdds(false)
},[couponchange])
 useEffect(() => {
  const maksimumKazancValue = maksimumKazanc();
 }, [miktar, selectedOdds]);

 function maksimumKazanc() {
  if (!miktar || isNaN(parseFloat(miktar))) {
   return "0.00";
  }

  const toplamOran = parseFloat(calculateTotalOdds(selectedOdds));
  const maksimumKazanc = (parseFloat(miktar) * toplamOran).toFixed(2);

  return maksimumKazanc;
 }

 useEffect(() => {
  setLoading(false);
  let isChecked = localStorage.getItem("changed_odd");
  if (isChecked === "false") {
   setIsChecked(false);
  } else if (isChecked === "true") {
      setIsChecked(true);
  } else {
      setIsChecked(true);
  }
},[isChecked])

 const handleOnaylaClick = () => {
  setLoading(true);
  let isChecked = localStorage.getItem("changed_odd");
  let isCheckeds;
  if (isChecked === "true") {
   isCheckeds = true;
  } else if (isChecked === "false") {
   isCheckeds = false;
  } else {
   isCheckeds = true;
  }
  const coupon = selectedOdds;
  if (Number(total_amount) > 0) {
    setEnterAmount(false)
   const data = {
    name,
    changed_odd: Boolean(isCheckeds),
    coupon,
    total_amount,
   };
   if (!miktar || isNaN(parseFloat(miktar))) {
    document.querySelector(".miktar").style.border = "2px solid red";
    alert(`${Language["en"].coupon.alertMessage1}`);
    return;
   }
   const token = Cookies.get("token");
   const apiUrl = "https://rona.thoth.lt/api/coupon_control";

   axios
    .post(apiUrl, data, {
     headers: {
      Authorization: `Bearer: ${token}`,
     },
    })
    .then((response) => {
      setcouponchange(0);
      if(response?.data?.error){
        if(response.data.error === "Yetkili hesabında kupon yapılamaz!"){
          setNotAuthMessage(response.data.error)
          setcouponchange(1)
          setShowOdds(true)
        }
      }
     if (!response.data || !response.data.message) {
      setMessage(`${Language["en"].coupon.message1}`);
      setLoading(false);
      if (Cookies.get("user_type") === "7") {
       setErrorMessage(response.data.errorUn);
      }
     } else {
      if (
       response.data.message ===
        "Kupon oranı değişmiştir. Lütfen tekrar deneyiniz!"
      ) {
      setcouponchange(1);

       setSelectedOdds(response.data.coupon);
       setMessage(response.data.message);
       setLoading(false);
      } else {
       setMiktar("0");
       setTotal_Amount(0)
       setShowOdds(false);
       setKuponName("");
       setLoading(false);
       setSuccessMessage(response.data);
       setBackToSelectedOdds(false);
      }
     }
     isCheckeds = localStorage.getItem("changed_odd");
    })

    .catch((error) => {
     console.error("Coupon Error", error);
     setLoading(false);
    });
  } else {
   setLoading(false);
   setEnterAmount(true)
  }
 };

 if (selectedOdds.length === 0) {
 } else {
  let selectedOddsbase = JSON.stringify(selectedOdds);
  localStorage.setItem("selectedOdds", encodeBase64UTF8(selectedOddsbase));
 }

 return (
  <div className="coupon-container" ref={couponRef}>
   <div className="title rounded-2xl text-white rounded-bl-none rounded-br-none flex">
    {Language["en"].coupon.title}
   </div>

   <div className="bg-tblack rounded-3xl rounded-tl-none rounded-tr-none">
    {successMessage.message && couponchange === 0 &&(
     <div className="bg-[#222] relative">
      {!backToSelectedOdds  && (
       <>
        <div
         className={`p-[25px] ${
          successMessage.message === "Kupon Başarıyla Oluşturulmuştur!"
           ? "bg-[#14892c]"
           : "bg-red"
         } text-white flex flex-col justify-center items-center text-center w-full`}
        >
         {successMessage.message === "Geçersiz Kupon" ||
         successMessage.message === "Coupon Line Limit Exceeded" ||
         errorMessage === "Unauthorized login please log in." ||
          successMessage.message === "Market Group Kapanmıştır" ||
          successMessage.message === "Oran Kapanmıştır" ||
          successMessage.message === "Market Kapanmıştır" ||
          successMessage.message === "Sistem bahis almaya kapalıdır! Lütfen yöneticinizle iletişime geçiniz..." ||
         successMessage.message ===
          "Your current balance is lower than the amount you want to play. Please Upload Balance." ? (
          <AiFillCloseCircle className="text-[44px] mb-[5px]" />
         ) : (
          <TiTick className="text-[44px] mb-[5px]" />
         )}
         <span>
          {successMessage.message ? successMessage.message : errorMessage}
          <br />
          {successMessage.message === "Geçersiz Kupon" ||
          successMessage.message === "Coupon Line Limit Exceeded" ||
          successMessage.message ===
           "Your current balance is lower than the amount you want to play. Please Upload Balance." ||
          errorMessage === "Unauthorized login please log in."
           ? errorMessage || "Try Again"
           : "Good luck"}
         </span>
        </div>

        <div className="okkuponlinks">
         <Link onClick={() => handlegetBackOdds()}>
          <FaRotate className="text-[#ffcc00] w-[25px] mt-1" />
          {Language["en"].coupon.backOdds}
         </Link>
         <Link onClick={() => handleReset()}>
          <AiFillFile className="text-[#ffcc00] w-[25px] mt-1" />
          {Language["en"].coupon.makeNewCoupon}
         </Link>
        </div>
       </>
      )}
     </div>
    )}
    
    { showOdds &&  (
     <div className="p-4">
      <p className="text-yellow ml-2 text-[18px]">
       {selectedOdds && selectedOdds.length === 0
        ? `${Language["en"].coupon.emptyCoupon}`
        : ""}
      </p>

      {selectedOdds.length === 0 ? (
       <p className="text-coupon ml-2 text-[12px]">
        {selectedOdds && selectedOdds.length === 0
         ? `${Language["en"].coupon.makeCoupon}`
         : ""}
       </p>
      ) : (
       <div>
        {selectedOdds?.map((matchInfo, index) => (
         <div
          key={index}
          className="bg-white text-black h-[100½] mt-[10px] rounded-xl ml-[-10px] w-[282px]"
         >
          <div className="bg-[#f0f0f0] border-b-2 mt-1 border-gray-300 p-2 rounded-xl flex items-center">
           <BiFootball className="mr-[-3px] mt-1" />
           <div className="text-sm px-2 font-semibold hover-text-red-700 cursor-pointer hover:text-[#BC2121]">
            {matchInfo?.teams}
           </div>
           <div
            className="bg-[#777] ml-[250px] w-5 h-5 absolute rounded-full flex hover-bg-red justify-center items-center cursor-pointer hover:bg-[#BC2121] "
            onClick={() => handleRemoveMatch(index)}
           >
            <RiCloseLine className="text-white text-md " />
           </div>
          </div>
          <div className="p-2 relative">
           <div className="font-bold text-[#BC2121] text-[11px]">
            {matchInfo.market_name}
           </div>
           <div className="text-[#222] text-[12px] font-bold">
            {matchInfo.odd_name}
           </div>
           {errorMessage && (
            <div className="text-text-red text-[12px]"><b>{errorMessage}!</b></div>
           )}
           <div className="text-[11px] mb-[-5px] text-[#666]">
            {matchInfo.LiveCurrentTime} {matchInfo.LiveScore}
           </div>
           <div className="ml-[220px] mt-[-25px] font-bold">
            <div>
             {matchInfo.old_odd_price ? (
              <>
               <div>
                <div className="line-through text-[red] text-[12px] flex justify-center">
                 {matchInfo && matchInfo.odd_price ? Number(matchInfo.old_odd_price).toFixed(2) : "-"}
                </div>
                <div>{ matchInfo && matchInfo.odd_price ? Number(matchInfo.odd_price).toFixed(2) : "-"}</div>
               </div>
               <span className="absolute w-[200px] left-1 text-[red] ml-1 mt-2 line-1 font-medium leading-1 text-[10px] bottom-0">
                {message}
               </span>
              </>
             ) : (
              <div>{matchInfo && matchInfo.odd_price ? Number(matchInfo.odd_price).toFixed(2) : "-"}</div>
             )}
            </div>
           </div>
          </div>
         </div>
        ))}

        <button
         className="bg-[#BC2121] text-white p-2 w-[277px] ml-[-7px] mt-2 flex items-center justify-center hover:bg-white hover:text-[#BC2121] text-[12px] font-bold hover:border-red-600 shadow-md transition-all duration-200 outline-none rounded-[10px]"
         onClick={handleClearCoupon}
        >
         <BsTrashFill className="mr-1 " />
         {Language["en"].coupon.clearCoupon}
        </button>
        <div className="flex">
         <Switch
          className="mt-2"
          onChange={handleSwitchChange}
          checked={isChecked}
         />
         <p className="mt-3 ml-2 text-[12px] text-white">
          {isChecked
           ? "Değişen oranları kabul ediyorum."
           : "Değişen oranları kabul etmiyorum."}
         </p>
        </div>
         {notAuthMessage &&
          <div className="text-[#BC2121] text-[13px]">{notAuthMessage}</div>
         }
        <div className="kupon-alt mt-2">
         <div className="kupon-info">
          <div className="kinfo">
           <div>{Language["en"].coupon.totalMatch}</div>
           <div>{selectedOdds.length === 0 ? "0" : selectedOdds.length}</div>
          </div>
          <div className="kinfo">
           <div>{Language["en"].coupon.totalOdd}</div>
           <div>{calculateTotalOdds(selectedOdds)}</div>
          </div>
          <div className="kinfo">
           <div className="p-0">{Language["en"].coupon.couponName}</div>
           <div className="np">
            <input
             className="kupon-adi"
             type="text"
             onChange={(e) => setKuponName(e.target.value)}
            />
           </div>
          </div>
          <div className="kinfo">
           <div className="p-0">{Language["en"].coupon.amount}</div>
           <div className="np">
            <input
             className="miktar placeholder:text-[#222]"
             style={{border: enterAmount ? "2px solid red" : ""}}
                          type="text"
             value={miktar}
             placeholder="0"
             onChange={(e) => {
              setTotal_Amount(e.target.value);
              handleMiktarChange(e);
             }}
            />
            {
              enterAmount &&
              <div style={{color: "red"}}>Enter Amount</div>
              }
            <span className="min-kupon-desc">
             <button className="hover:brightness-110" onClick={handleMinClick}>
              <BsChevronDoubleDown className="text-[#BC2121]" />
              {Language["en"].coupon.min}
             </button>
             <button className="hover:brightness-110" onClick={handleMaxClick}>
              {Language["en"].coupon.max}
              <BsChevronDoubleUp className="text-[#14892c] ml-1" />
             </button>
            </span>
           </div>
          </div>
          <div className="kinfo">
           <div>{Language["en"].coupon.totalWin}</div>
           <div className="maks">{maksimumKazanc()}</div>
          </div>
         </div>
        </div>
        <button
         className="bg-[#14892c] text-white p-2 w-[277px] ml-[-7px] mt-2 flex items-center justify-center text-[16px] font-semibold shadow-inner-white border-t-1 hover:brightness-110 h-[50px] shadow-md transition-all duration-200 outline-none rounded-[10px]"
         onClick={handleOnaylaClick}
        >
         {Language["en"].coupon.couponConfirm}
        </button>
       </div>
      )}
     </div>
    )}
    <div
     className="sliploading rounded-lg"
     style={{ display: loading ? "block" : "none" }}
    >
     <span></span>
    </div>
   </div>
  </div>
 );
}
export default Coupon;
