import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineLine } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { TiUserDelete } from "react-icons/ti";
import { myUsersStatusValueUpdate,setBackgroundColor,setTextColor } from "../redux/actions/actions";
import axios from "axios";
import { connect } from "react-redux";
import IsLoading from "./IsLoading";
import Cookies from "js-cookie";
import Language from '../language.json'

const MyUsers = ({ myUsersStatus,backgroundColor }) => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";

  const navigate = useNavigate();
  const [stop, setStop] = useState(false);
  const [userNumber, setUserNumber] = useState("");
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [communityCounts, setCommunityCounts] = useState("");
  const [totalCommunityCounts, setTotalCommunityCounts] = useState("");
  const [filteredData, setFilteredData] = useState(users);
  const [isLoading,setIsLoading] = useState(true)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const[balances,setBalances]=useState(0);
  const [currentUser, setCurrentUser] = useState({ userId: null, username: null,limit:null ,gamelimit:null });


  function Popup({ onClose, user  }) {
    const [addValue, setAddValue] = useState('+');
    const [subtractValue, setSubtractValue] = useState('-');
    const [successMessage, setSuccessMessage] = useState('');
    const handleAddChange = (event) => {
      const value = event.target.value;
      setAddValue('+' + value.replace(/[^0-9]/g, ''));
    };
    const handleSubtractChange = (event) => {
      const value = event.target.value;
      setSubtractValue('-' + value.replace(/[^0-9]/g, ''));
    };
    const postLimit = (limit) => {
      axios.post('https://rona.thoth.lt/api/addlimit', {
        id: user.userId,
        limit: limit
      },{
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        if(response.data.message==="Successful"){
          setSuccessMessage('Limit Başarıyla Eklendi');
          setBalances(1);
          onClose();
        }else{
          setSuccessMessage(response.data.error);
        }
      })
      .catch(error => {
        console.error('Limit eklenirken hata oluştu:', error);
      });
    };
    const [successMessage2, setSuccessMessage2] = useState('');
    const [addValuegame, setAddValuegame] = useState('+');
    const [subtractValuegame, setSubtractValuegame] = useState('-');
    const handleAddChangegame = (event) => {
      const value = event.target.value;
      setAddValuegame('+' + value.replace(/[^0-9]/g, ''));
    };
    const handleSubtractChangegame = (event) => {
      const value = event.target.value;
      setSubtractValuegame('-' + value.replace(/[^0-9]/g, ''));
    };
    const postLimitgame = (limit) => {
      axios.post('https://rona.thoth.lt/api/addlimitgame', {
        id: user.userId,
        limit: limit
      },{
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        if(response.data.message==="İşlem Başarılı"){
          setSuccessMessage2('Limit Başarıyla Eklendi');
          setBalances(1);
          onClose();
        }else{
          setSuccessMessage2(response.data.error);
        }
      })
      .catch(error => {
        console.error('Limit eklenirken hata oluştu:', error);
      });
    };
    const successMessageStyle = {
      color: '#000',
      marginBottom: '10px',
    };
    const popupStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    };
  
    const popupContentStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'auto'
    };
    const popupContentStyle1 = {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'auto'
    };

    const inputGroupStyle = {
      display: 'flex',
      marginBottom: '10px',
    };
  
    const inputStyle = {
      padding: '10px',
      marginRight: '5px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      flex: '1 0 auto', // Esnek genişlik
    };
  
    const buttonStyle = {
      backgroundColor: 'grey',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    };
    const buttonStyle1 = {
      backgroundColor: 'red',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    };
    const buttonStyle2 = {
      backgroundColor: 'green',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    };
    const popupHeaderStyle = {
      backgroundColor: backgroundColor, // Başlık için mavi arka plan rengi
      color: 'white', // Beyaz metin rengi
      padding: '10px 15px', // İç boşluk
      borderRadius: '8px 8px 0 0', // Üst köşeleri yuvarlak
      fontSize: '18px',
      width:'100%'
    };
    return (
      <div style={popupStyle}>
        <div style={popupContentStyle1}>
        <div style={popupHeaderStyle}>Limit Ekle Çıkar / <b>{user.username}</b></div>
        <div style={popupContentStyle}>
        <h1><b><u>Spor Limiti Ekle:</u></b></h1>
        {successMessage && <div style={successMessageStyle}>{successMessage}</div>}
        <h3>Limit:<b>{user.limit}</b></h3>
          <div style={inputGroupStyle}>
            <input
          type="text"
          value={addValue}
          onChange={handleAddChange}
          style={inputStyle}
            />
            <button style={buttonStyle2} onClick={() => postLimit(parseInt(addValue))}>Ekle</button>
          </div>
          <div style={inputGroupStyle}>
            <input
          type="text"
          value={subtractValue}
          onChange={handleSubtractChange}
          style={inputStyle}
            />
            <button style={buttonStyle1} onClick={() => postLimit(parseInt(subtractValue))}>Çıkar</button>
          </div>
          <hr size="4" width="100%"></hr>
          <h1><b><u>Casino Limiti Ekle:</u></b></h1>
        {successMessage2 && <div style={successMessageStyle}>{successMessage2}</div>}
        <h3>Limit:<b>{user.gamelimit}</b></h3>
          <div style={inputGroupStyle}>
            <input
          type="text"
          value={addValuegame}
          onChange={handleAddChangegame}
          style={inputStyle}
            />
            <button style={buttonStyle2} onClick={() => postLimitgame(parseInt(addValuegame))}>Ekle</button>
          </div>
          <div style={inputGroupStyle}>
            <input
          type="text"
          value={subtractValuegame}
          onChange={handleSubtractChangegame}
          style={inputStyle}
            />
            <button style={buttonStyle1} onClick={() => postLimitgame(parseInt(subtractValuegame))}>Çıkar</button>
          </div>
          <button style={buttonStyle} onClick={onClose}>Kapat</button>
          </div>
        </div>
      </div>
    );
  }
  
  const openPopup = (userId, username,limit,gamelimit) => {
    setCurrentUser({ userId, username,limit,gamelimit });
    setIsPopupOpen(true);
  };
  const closePopup = () => setIsPopupOpen(false);
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          return dateA - dateB;
        });
        setUsers(sortedData);
        setFilteredData(sortedData);
        setUserNumber(response.data?.length);
        setIsLoading(false)
        const communityCounts = response.data.map(
          (user) => user.community.length
        );
        const totalCommunityCount = communityCounts.reduce(
          (total, count) => total + count,
          0
        );
        setCommunityCounts(communityCounts);
        setTotalCommunityCounts(totalCommunityCount);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
      setBalances(0);
  }, [stop,balances]);

  useEffect(() => {
    const query = searchQuery.toLowerCase();

    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(query)
    );

    setFilteredData(filtered);
  }, [searchQuery]);


  const handleStop = (e) => {
    const userId = e.target.getAttribute("data-id");
    const apiStopUrl = process.env.REACT_APP_API_URL + `/api/stopuser/${userId}`;

    const confirmStop = window.confirm(
      "There are also sub-users belonging to this user. Are you sure you want to stop?"
    );
    if (confirmStop) {
      const postData = {
        userId: parseInt(userId),
      };

      axios
        .post(apiStopUrl, postData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setStop(!stop);
        })
        .catch((error) => {
          console.error("API ERROR:", error);
        });
    } else {
    }
  };
  const handleNetworkClick = (networkId) => {
    navigate(`/my-users-user/${networkId}`);
  };
  const handleUpdateClick = (updateId) => {
    navigate(`/updateuser/${updateId}`);
  };
  const handleDeleteClick = (updateId) => {
    navigate(`/deleteuser/${updateId}`);
  };
  const totalVideoGameLimit = users.reduce((total, user) => total + parseFloat(user.video_game_limit), 0);
  const totalLimit = users.reduce((total, user) => total + parseFloat(user.limit), 0);
  const formatDate = (dateString) => {
    const options = {
      hour12: false
    }
    const formattedDate = new Date(dateString).toLocaleString("tr-TR",options)
    return formattedDate
  }
  return (
    <div>
      {isPopupOpen && (
        <Popup onClose={() => setIsPopupOpen(false)} user={currentUser} />
      )}
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language["en"].myUsers.title}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="flex border-b-[1px] my-[10px]"
        style={{borderBottomColor: backgroundColor}}
        >
          <Link className={`rounded-t-[10px] bg-[${backgroundColor}] text-[#fff] py-[15px] px-[25px] text-base font-bold`}>
          {Language["en"].myUsers.subTitle}
          </Link>
        </div>
        <div>
          <div>
            <input
              type="text"
              placeholder="find username"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="forms-select-box-shadow p-[10px] h-[37px] border-[1px] border-[#999] font-semibold text-[14px] outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                e.target.style.borderColor = `${backgroundColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
            />
          </div>
          <table className="w-[100%]">
            <thead>
              <tr className="h-[48px]">
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsers.titleUser}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsers.titleAuthority}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                 {Language["en"].myUsers.titleLimit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                {Language["en"].myUsers.titleVideGameLimit}
                </td>
                { 
                Number(Cookies.get("user_type")) !== 9 && Number(Cookies.get("user_type")) !== 5 &&
                  <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                  {Language["en"].myUsers.titleNetwork}
                </td>
                }
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                  {Language["en"].myUsers.titleCreate}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                  {Language["en"].myUsers.titleLastSeen}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsers.titleHire}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsers.titleSituation}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[90px]">
                  <div className="flex gap-1">
                    <div className="bg-[#f0f0f0] py-[5px] px-[10px] rounded-[4px] h-[24px] flex items-center cursor-pointer">
                      <AiOutlineLine color="blue" size={13} />
                    </div>
                    <div className="bg-[#f0f0f0] py-[5px] px-[10px] rounded-[4px] h-[24px] flex items-center cursor-pointer hover:text-[#fff] hover:bg-[#ccc]">
                      <AiOutlinePlus color="blue" size={13} />
                    </div>
                  </div>
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[15%]"></td>
                
              </tr>
            </thead>
            <tbody>
              {isLoading ?  
            <div className='flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center'> <IsLoading/> </div>
          : filteredData.map((user) => (
                <tr className="text-xs bg-[#f9f9f9]">
     <td className="py-1 px-2 font-bold">
    <div className="flex justify-between items-center">
      <div>{user.username}<div style={{fontSize: "10px",color: "#bc2121"}}>{user.note}</div></div>
      <div className="flex items-center">
        <button
          data-id={user.user_id}
          value={myUsersStatus}
          onClick={() => openPopup(user.user_id, user.username,user.limit,user.video_game_limit)}
          className="bg-[#BC2121] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[150px] cursor-pointer mx-1"
        >
          Limit Ekle/Çıkar
        </button>
      </div>
    </div>
  </td>
  <td className="py-1 px-2">{user.authority}</td>
  <td className="py-1 px-2 font-bold text-end">{user.limit}</td>
  <td className="py-1 px-2 font-bold text-end">
    {user.video_game_limit}
  </td>
                  { Number(Cookies.get("user_type")) !== 9 && Number(Cookies.get("user_type")) !== 5 &&
                  <td className="py-1 px-2">
                    <div className="flex items-center justify-center">
                      {user.community.length === 0 ? (
                        <div className={`bg-[${backgroundColor}] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px]`}>
                          0
                        </div>
                      ) : (
                        <div
                          onClick={() => handleNetworkClick(user.user_id)}
                          className={`bg-[${backgroundColor}] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px] cursor-pointer`}
                        >
                          {user.community.length}
                        </div>
                      )}
                    </div>
                  </td>
                  }

                  <td className="py-1 px-2">{formatDate(user.created)}</td>
                  <td className="py-1 px-2">{formatDate(user.last_seens)}</td>
                  <td></td>
                  <td>
                    {user.status == 1 ? (
                      <div className="py-1 px-2 font-bold text-green-800">
                        {Language["en"].myUsers.userActive}
                      </div>
                    ) : (
                      <div className="py-1 px-2 font-bold text-[#BC2121]">
                        {Language["en"].myUsers.userPassive}
                      </div>
                    )}
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex items-center justify-center">
                      {user.status == 1 ? (
                        <button
                          data-id={user.user_id}
                          value={myUsersStatus}
                          onClick={handleStop}
                          className="bg-[#BC2121] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px] cursor-pointer"
                        >
                          {Language["en"].myUsers.userStatusStop}
                        </button>
                      ) : (
                        <button
                          data-id={user.user_id}
                          value={myUsersStatus}
                          onClick={handleStop}
                          className="bg-[#54b42d] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px] cursor-pointer"
                        >
                          {Language["en"].myUsers.userStatusStart}
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex gap-1 items-center justify-center">
                      <div
                        onClick={() => handleUpdateClick(user.user_id)}
                        className="flex gap-1 px-2 bg-[#666666] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] cursor-pointer"
                      >
                        <FaUserEdit size={16} />{Language["en"].myUsers.buttonTextEdit}

                      </div>
                      <div
                        onClick={() => handleDeleteClick(user.user_id)}
                        className={`flex gap-1 px-2 bg-[#BC2121] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] cursor-pointer`}
                      >
                        <TiUserDelete size={16} />                         
                        {Language["en"].myUsers.buttonTextDelete}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr className="h-[48px]">
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                  {totalLimit}
                  </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                  {totalVideoGameLimit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[90px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[15%]"></td>
              </tr>
            </thead>
            <tbody>
              {
                Number(Cookies.get("user_type")) !== 9 &&
              <tr>
                <td className="py-2 px-[10px] text-xs">
                {Language["en"].myUsers.infoMessageStart} <b>{totalCommunityCounts}</b> {Language["en"].myUsers.infoMessageSubUserIn}
                {" "}
                  <b>{userNumber}</b> {Language["en"].myUsers.infoMessageUserCount}
                </td>
              </tr>
              }
              </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  myUsersStatus: state.myUsersStatus,
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

const mapDispatchToProps = {
  myUsersStatusValueUpdate,
  setBackgroundColor,
  setTextColor
};
export default connect(mapStateToProps, mapDispatchToProps)(MyUsers);
