import React from 'react'
import '../mobile/mobile.css'
import {HiSearch} from 'react-icons/hi'
import { Link } from 'react-router-dom'


function MobileNavbar() {
  return (
    <div className='header' >
      <div className='relative w-full top-[9px] box-border px-2'>
        <div className='p-0 box-border relative mb-[5px]'>
          <div className='text-[22px] text-text-white absolute right-5 top-[10px] z-[99999] font-bold '>Rona V2</div>
            <div className='search bg-[rgba(0,0,0,.3)]'>
              <HiSearch className='font-[900] text-[rgba(255,255,255,.5)] absolute ml-3 mt-4 text-[24px] '/>
            </div>
          <input type="text" placeholder='Ara' className='searchinput' />
        </div>
      </div>
          <Link to='/Basket'> 
        <div className='betslip'>
          <span className='countslip'>0</span>
          <span className='countodds' style={{display:'none'}}></span>
          <div className='left-1 p-1 text-[10px] relative mt-[7px] text-[rgba(255,255,255,.8)]'>Kuponunuz</div>
          <div className='icon'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          </div>
        </div>
          </Link>
    </div>
  )
}

export default MobileNavbar