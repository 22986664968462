import React from "react";
import Language from '../../language.json'
const UserAffected = ({ datas, selectData, setSelectData, handleChange,textColor }) => {
  const sortedDatas = [...datas].sort((a, b) =>
    a.username.localeCompare(b.username)
  );
  const lang = "en"
  return (
    <div>
      <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] mx-[0px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
        <div className="p-[5px] font-medium text-[13px]">
          {Language[lang].UserAffected.text1}
        </div>
        <div className="p-[5px] flex justify-between">
          <select
            className="forms-select-box-shadow dashboard-selects text-[13px] h-[40px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            name="user_affected"
            id="user_affected"
            value={selectData}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setSelectData(selectedValue);
              handleChange(e);
            }}
          >
            <option value="All">All User</option>
            {sortedDatas?.map((data) => (
              <option key={data.user_id} value={data.user_id}>
                {data.username}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default UserAffected;
