import React from "react";
import Language from '../../language.json'
const AllCouponTable = ({
  totalCount,
  totalCountLength,
  totalSureProfit,
  totalSureProfitLength,
  totalLose,
  totalLoseLength,
  totalWaiting,
  totalWaitingLength,
  totalCancel,
  totalCancelLength,
  riziko
}) => {
  const lang = "en"
  return (
    <div className="flex pb-[10px] justify-around">
      <div className="report-box-box-shadow rounded-bl-[10px] text-[#000000] flex-1 bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text1}</span>
          <span>({totalCountLength})</span>
        </div>
        <div className="text-[18px] font-bold">{totalCount}</div>
      </div>
      <div className="report-box-box-shadow-win flex-1 text-[#14892C] bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <div>{Language[lang].AllCouponTable.text2}</div>
          <div>({totalSureProfitLength})</div>
        </div>
        <div className="text-[18px] font-bold">{totalSureProfit.toFixed(2)}</div>
      </div>
      <div className="report-box-box-shadow-lose flex-1 text-[#BC2121] bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text3}</span>
          <span>({totalLoseLength})</span>
        </div>
        <div className="text-[18px] font-bold">{totalLose}</div>
      </div>
      <div className="report-box-box-shadow text-[#000000] flex-1 bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text4}</span>
          <span>({totalWaitingLength})</span>
        </div>
        <div className="text-[18px] font-bold">{totalWaiting}</div>
      </div>
      <div className="report-box-box-shadow text-[#000000] flex-1 bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text5}</span>
        </div>
        <div className="text-[18px] font-bold">
          {(totalCount - totalSureProfit - totalWaiting - totalCancel).toFixed(2)}
        </div>
      </div>
      <div className="report-box-box-shadow-cancel text-gray-500 flex-1 bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text6}</span>
          <span>({totalCancelLength})</span>
        </div>
        <div className="text-[18px] font-bold">{totalCancel}</div>
      </div>
      <div className="report-box-box-shadow-risk rounded-br-[10px] text-[#ff0000] flex-1 bg-[#fff] p-[20px] border-r-0 flex flex-col justify-between ">
        <div className="text-[12px] flex flex-1 font-semibold justify-between items-center">
          <span>{Language[lang].AllCouponTable.text7}</span>
        </div>
        <div className="text-[18px] font-bold">{riziko}</div>
      </div>
    </div>
    
  );
};

export default AllCouponTable;
