import React from "react";
import Language from '../../language.json'
import axios from "axios";
import IsLoading from "../IsLoading";


const ClosedRateTypes = ({oddTypeConfig,setOddTypeConfig,shortedName,isLoading,setIsLoading,apiUrl,token,backgroundColor,textColor}) => {
  const handleRemove = (id,sport,odd) =>{
    setIsLoading(true)
    if(odd){
      var postData = {
        odd_type_user: id,
        odd_type_sport: sport,
        odd_type_odd: odd
      } 
    }else {
      var postData = {
        odd_type_user: id,
        odd_type_sport: sport,
      }
    }
    axios.post(apiUrl,postData,{
      headers: {Authorization: `Bearer ${token}`}
    })
    .then((response) =>{
      setOddTypeConfig(response.data.odd_type_config)
    })
    .finally(() =>{
      setIsLoading(false)
    })
  }
  const handleAllRemove = () =>{
    setIsLoading(true)
    const postData = {
      removeAllConfigType: "1"
    }
    axios.post(apiUrl,postData,{
      headers: {Authorization: `Bearer ${token}`}
    })
    .then((response) =>{
      setOddTypeConfig(response.data.odd_type_config)
    })
    .finally(()=>{
      setIsLoading(false)
    })
  }
  return (
    <div>
      <div className="flex min-h-[290px]">
        <div className="forms-box-shadow py-[10px] px-[5px] w-[100%] bg-[#fff] m-[5px] border-[1px] border-[#ccc]">
          <div className="flex justify-between w-[100%] p-[10px] border-b text-[#BC2121]"
          style={{borderBottomColor:backgroundColor}}
          >
            <div style={{color:textColor}}>
              {Language["en"].closedRateTypes.title}
            </div>
            <button onClick={handleAllRemove} className="underline text-xs">
              {Language["en"].closedRateTypes.removeAllButton}
            </button>
          </div>
          { 
            isLoading 
            ? 
            <div className="mt-5">
            <IsLoading/> 
            </div>
            :
          oddTypeConfig?.length === 0 ?
            <div className="p-[10px] text-[#555] text-[12px]">
              {Language["en"].closedRateTypes.changeMessage}
            </div>
          :
          <div className="max-h-[300px] overflow-y-auto">
            { oddTypeConfig?.map((oddConfig,index)=>(
              <>
              { oddConfig.odds && oddConfig.odds.length > 0 &&
              <>
              <div key={index} id={oddConfig.user_id} className="flex font-semibold justify-between py-[5px] px-[10px] border-b bg-[rgba(0,0,0,0.05)] border-b-[#ddd] text-[12px] items-center">

                <span className="flex-1"><span> {shortedName(oddConfig.user_name, 30)}
                </span>
                {"  "}
                
                <span>
                  {oddConfig.sport_name}
                </span>
                </span>  
                <span onClick={() => handleRemove(oddConfig.user_id,oddConfig.sport_id)} className="flex-1 font-normal underline  inline-block text-[12px] cursor-pointer w-[160px] min-w-[40px] max-w-[160px] text-right text-[#BC2121]"
                >
                  {Language["en"].closedRateTypes.removeBlock}
                </span>
              </div>
              { oddConfig?.odds?.map((oddC,index)=>(
              <div key={index} id={oddC.odd_id} className="flex justify-between py-[5px] px-[10px] border-b border-b-[#ddd] text-[12px] items-center">
                <span className="flex-1">{shortedName(oddC.odd_name, 30)}</span>  
                <span className={`text-center font-bold text-[14px] flex-1 ${oddConfig.config_rule > 0 ? 'text-green-600' : 'text-[#BC2121]' }`}>{oddConfig.config_rule}</span>
                <span onClick={() => handleRemove(oddConfig.user_id,oddConfig.sport_id,oddC.odd_id)} className="flex-1 underline  inline-block text-[12px] cursor-pointer w-[40px] min-w-[40px] max-w-[40px] text-right text-[#BC2121]"
                
                >
                  {Language["en"].closedRateTypes.removeButton}
                </span>
              </div>
            ))
            }
            </>
                }
            
            </>
            ))
            }
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ClosedRateTypes;
