import React, { useState } from "react";
import Language from '../language.json'
import axios from "axios";
import {ErrorAlert,Alert} from "./index";
import Cookies from "js-cookie";

const ChangePassword = () => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/updatepass";
  
  const [oldPassword,setOldPassword] = useState([])
  const [newPassword,setNewPassword] = useState([])
  const [newPasswordAgain,setNewPasswordAgain] = useState([])
  const [oldPasswordError,setOldPasswordError] = useState([])
  const [newPasswordError,setNewPasswordError] = useState([])
  const [newPasswordAgainError,setNewPasswordAgainError] = useState([])
  const [alert,setAlert] = useState(null)
  const [errorAlert,setErrorAlert] = useState(null)

  const handleClick =() => {
    if (newPassword !== newPasswordAgain) {
      setNewPasswordAgainError("Passwords do not match");
      return;
    } else {
      setNewPasswordAgainError("");
    }
    const postData = {
      oldpass: oldPassword,
      newpass: newPassword,
      newpassrepeat: newPasswordAgain
    }
    if(newPassword.length > 3 && newPasswordAgain.length > 3) {
    axios
      .post(apiUrl,postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.message === "Your password has been changed successfully."){
          setAlert(response.data.message)
        } else if (response.data.message === "The old password is wrong!"){
          setErrorAlert(response.data.message)
        }
      });
    }
  }
  
  return (
    <div className="container mx-auto">
      {
        errorAlert && 
        <ErrorAlert
        errorAlert={errorAlert}
        setErrorAlert={setErrorAlert}
        />
      }
      { alert && 
      <Alert
      alert={alert}
      setAlert={setAlert}
      />
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].changePassword.title}
      </div>
      <div className="flex flex-col min-h-[550px] justify-center items-center gap-10 py-[30px] px-[50px] bg-[#dddddd] rounded-b-[15px] text-[20px]">
        <div className="flex flex-col text-center justify-center">
            {Language["en"].changePassword.oldPassword}
        <input 
        value={oldPassword}
        onChange={(e) => {
          const selectedValue = e.target.value;
          setOldPassword(selectedValue);
          if (selectedValue.length < 4) {
            setOldPasswordError(`${Language["en"].changePassword.passwordError}`);
          } else {
            setOldPasswordError("");
          }
        }}
        type="password" className="w-[260px] h-10 p-2 forms-select-box-shadow dashboard-selects border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999] focus:border-[#BC2121]" />
        {oldPasswordError && (
                <div className="text-red-500 text-xs ml-3">{oldPasswordError}</div>
              )}
        </div>
        <div className="flex flex-col text-center justify-center">
            {Language["en"].changePassword.newPassword}
        <input 
        value={newPassword}
        onChange={(e) => {
          const selectedValue = e.target.value;
          setNewPassword(selectedValue);
          if (selectedValue.length < 4) {
            setNewPasswordError(`${Language["en"].changePassword.passwordError}`);
          } else {
            setNewPasswordError("");
          }
        }}
        type="password" className="w-[260px] h-10 p-2 forms-select-box-shadow dashboard-selects border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999] focus:border-[#BC2121]" />
        {newPasswordError && (
                <div className="text-red-500 text-xs ml-3">{newPasswordError}</div>
              )}
        </div>
        <div className="flex flex-col text-center justify-center">
        {Language["en"].changePassword.newPasswordAgain}
        <input 
        value={newPasswordAgain}
        onChange={(e) => {
          const selectedValue = e.target.value;
          setNewPasswordAgain(selectedValue);
         
        }}
        type="password" className="w-[260px] h-10 p-2 forms-select-box-shadow dashboard-selects border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999] focus:border-[#BC2121]" />
        {newPasswordAgainError && (
                <div className="text-red-500 text-xs ml-3">{newPasswordAgainError}</div>
              )}
        </div>
        <button 
          className="forms-button w-[260px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[#BC2121] text-[#fff] cursor-pointer transition-all duration-300"
          onClick={handleClick}
          >
          {Language["en"].changePassword.changeButton}
          </button>
      </div>
    </div>
  );
};
export default ChangePassword;