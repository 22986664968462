import React, { useEffect } from "react";
import Language from '../../language.json'
import 
{
  oddSafeLiveMaxOddValueUpdate,
  oddSafePreMaxOddValueUpdate,
  oddSafeLiveMinOddValueUpdate,
  oddSafePreMinOddValueUpdate,
} from '../../redux/actions/actions'
import { connect } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import IsLoading from "../IsLoading";
const RateProtection = (
{  oddSafeLiveMaxOdd,
  oddSafeLiveMaxOddValueUpdate,
  oddSafePreMaxOdd,
  oddSafePreMaxOddValueUpdate,
  oddSafeLiveMinOdd,
  oddSafeLiveMinOddValueUpdate,
  oddSafePreMinOdd,
  oddSafePreMinOddValueUpdate,
  isLoading,
  setIsLoading,
  textColor,
  backgroundColor
}) => {

  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config";
  useEffect(() => {
        axios.get(apiUrl,{
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then((response) => {
      setIsLoading(false)
      const oddSafeLiveMaxOddValue = response.data.odd_safe.live_max_odd
      const oddSafePreMaxOddValue = response.data.odd_safe.pre_max_odd
      const oddSafeLiveMinOddValue = response.data.odd_safe.live_min_odd
      const oddSafePreMinOddValue = response.data.odd_safe.pre_min_odd
      if(!oddSafeLiveMaxOdd){
        oddSafeLiveMaxOddValueUpdate(oddSafeLiveMaxOddValue)
      }
      if(!oddSafePreMaxOdd){
        oddSafePreMaxOddValueUpdate(oddSafePreMaxOddValue)
      }
      if(!oddSafeLiveMinOdd){
        oddSafeLiveMinOddValueUpdate(oddSafeLiveMinOddValue)
      }
      if(!oddSafePreMinOdd){
        oddSafePreMinOddValueUpdate(oddSafePreMinOddValue)
      }
    })
    .catch((error) => {
      console.error("API error:", error);
    })
  },[])


  return (
    <>
    {
    isLoading ?  
    <div className='flex w-full min-h-[100px] mt-3 justify-center items-center'> <IsLoading/> </div>
  :
    
    <div className="p-[5px] mt-[-3px]">
      <div className={`pl-[35px] p-[10px] border-b-[1px] text-base relative`}
      style={{borderBottomColor:backgroundColor,color:textColor}}
      >
      {Language["en"].rateProtection.title}
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].rateProtection.liveMaxRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium  focus:shadow-md"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            type="text"
            value={oddSafeLiveMaxOdd}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              oddSafeLiveMaxOddValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].rateProtection.preMatchMaxRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            type="text"
            value={oddSafePreMaxOdd}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              oddSafePreMaxOddValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].rateProtection.liveMinRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            type="text"
            value={oddSafeLiveMinOdd}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              oddSafeLiveMinOddValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].rateProtection.preMatchMinRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={oddSafePreMinOdd}
            onChange={(e)=>{
              const selectedValue = e.target.value;
              oddSafePreMinOddValueUpdate(selectedValue)
            }}
          />
        </div>
      </div>
    </div>
  }</>
  );
};
const mapStateToProps = (state) => ({
  oddSafeLiveMaxOdd: state.oddSafeLiveMaxOdd,
  oddSafePreMaxOdd: state.oddSafePreMaxOdd,
  oddSafeLiveMinOdd: state.oddSafeLiveMinOdd,
  oddSafePreMinOdd: state.oddSafePreMinOdd,
})

const mapDispatchToProps = {
  oddSafeLiveMaxOddValueUpdate,
  oddSafePreMaxOddValueUpdate,
  oddSafeLiveMinOddValueUpdate,
  oddSafePreMinOddValueUpdate,
}
export default connect(mapStateToProps,mapDispatchToProps)(RateProtection);
