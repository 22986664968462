import React, { useState, useEffect } from "react";
import { Dashboard } from "./pages";
import "./App.css";
import Cookies from "js-cookie";
import Login from "./components/Login";

function App() {

  function click(a) {
    var element = document.getElementById(a);
    if (element.className.includes("selected")) {
      element.classList.remove("selected");
    } else {
      element.classList.add("selected");
    }
  }
  return (
    <div>
        <>
          {!Cookies.get("token") ? (
            <Login />
          ) : (
            <Dashboard click={click} />
          )}
        </>
    </div>
  );
}

export default App;
