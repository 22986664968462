import React, { useEffect, useState } from "react";
import {
  ErrorAlert,
  ErrorUnAlert,
  IsLoading,
  LimitsWhenDepositingCoupons,
  LimitsWhenWinningCoupons,
  RateProtection,
  VariousDisplaySettings,
  VideoGameLimited,
} from "./index";
import { connect } from "react-redux";
import axios from "axios";
import {Alert} from './index'
import { setBackgroundColor,setTextColor } from "../redux/actions/actions";
import Cookies from "js-cookie";
import Language from '../language.json'
const SystemSettings = ({
  selectedSystemOffValue,
  liveFootballType,
  footballType,
  systemCoupon,
  systemStartUpTime,
  systemShutDownTime,
  liveLastMin1,
  liveLastMin2,
  couponExt,
  maxDeposit,
  singleMaxDeposit,
  liveMaxDeposit,
  minDeposit,
  eventMaxDeposit,
  marketMaxDeposit,
  maxCouponLine,
  minCouponOdd,
  maxCouponOdd,
  sysMaxX,
  couponWinLimitSingleMaxPay,
  couponWinLimitMaxPay,
  couponWinLimitLiveMaxPay,
  couponWinLimitLiveSingleMaxPay,
  couponWinLimitSysMaxX,
  oddSafeLiveMaxOdd,
  oddSafePreMaxOdd,
  oddSafeLiveMinOdd,
  oddSafePreMinOdd,
  videoGameShowGame,
  backgroundColor,
  textColor
}) => {
  const lang = "en"
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  const saveChanges = () => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config"
  const postData = {
    system_off: Number(selectedSystemOffValue),
    live_football_type: Number(liveFootballType),
    football_type: Number(footballType),
    sys_coupon: Number(systemCoupon),
    system_time: `${systemStartUpTime},${systemShutDownTime}`,
    live_lost_min1: Number(liveLastMin1),
    live_lost_min2: Number(liveLastMin2),
    coupon_ext: Number(couponExt),
    max_deposit: Number(maxDeposit),
    single_max_deposit: Number(singleMaxDeposit),
    live_max_deposit: Number(liveMaxDeposit),
    min_deposit: Number(minDeposit),
    event_max_deposit: Number(eventMaxDeposit),
    market_max_deposit: Number(marketMaxDeposit),
    max_coupon_line: Number(maxCouponLine),
    min_coupon_odd: Number(minCouponOdd),
    max_coupon_odd: Number(maxCouponOdd),
    sys_max_x: Number(sysMaxX),
    max_pay: Number(couponWinLimitMaxPay),
    single_max_pay: Number(couponWinLimitSingleMaxPay),
    live_max_pay: Number(couponWinLimitLiveMaxPay),
    live_single_max_pay: Number(couponWinLimitLiveSingleMaxPay),
    sys_max_pay: Number(couponWinLimitSysMaxX),
    live_max_odd: Number(oddSafeLiveMaxOdd),
    pre_max_odd: Number(oddSafePreMaxOdd),
    live_min_odd: Number(oddSafeLiveMinOdd),
    pre_min_odd: Number(oddSafePreMinOdd),
    show_game: Number(videoGameShowGame)
  }
  
  axios.post(apiUrl,postData,{
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then((response)=>{
      if(response.data.message){
        setAlert(response.data.message);
      }
      if(response.data.error){
        setErrorAlert(response.data.error)
      }
      if(response.data.errorUn){
        setErrorUnAlert(response.data.errorUn)
      }
    })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };
  return (
    <div>
      {
      alert && (
          <Alert
          alert={alert}
          setAlert={setAlert}
          />
        )}
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language[lang].SystemSettingsPage.title}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        
        <div className="flex flex-wrap xl:flex-nowrap p-[15px] bg-[#fff]">
          <div className="max-w-[375px] w-[100%]">
            <VariousDisplaySettings 
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            backgroundColor={backgroundColor}
            textColor={textColor}
            />
          </div>
          <div className="max-w-[375px] w-[100%]">
            <LimitsWhenDepositingCoupons
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            backgroundColor={backgroundColor}
            textColor={textColor}
            />
          </div>
          <div className="max-w-[375px] w-[100%]">
            <LimitsWhenWinningCoupons
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            backgroundColor={backgroundColor}
            textColor={textColor}
            />
            <RateProtection
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            backgroundColor={backgroundColor}
            textColor={textColor}
            />
          </div>
          <div className="max-w-[375px] w-[100%]">
            <VideoGameLimited
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            backgroundColor={backgroundColor}
            textColor={textColor}
            />
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <button
            onClick={saveChanges}
            className={`forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[${backgroundColor}] text-[#fff] cursor-pointer transition-all duration-300`}
          >
            {Language[lang].SystemSettingsPage.textSave}
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  systemOff: state.systemOff,
  selectedSystemOffValue: state.selectedSystemOffValue,
  liveFootballType: state.liveFootballType,
  footballType: state.footballType,
  systemCoupon: state.systemCoupon,
  systemStartUpTime: state.systemStartUpTime,
  systemShutDownTime: state.systemShutDownTime,
  liveLastMin1: state.liveLastMin1,
  liveLastMin2: state.liveLastMin2,
  couponExt: state.couponExt,
  maxDeposit: state.maxDeposit,
  singleMaxDeposit: state.singleMaxDeposit,
  liveMaxDeposit: state.liveMaxDeposit,
  minDeposit: state.minDeposit,
  eventMaxDeposit: state.eventMaxDeposit,
  marketMaxDeposit: state.marketMaxDeposit,
  maxCouponLine: state.maxCouponLine,
  minCouponOdd: state.minCouponOdd,
  maxCouponOdd: state.maxCouponOdd,
  sysMaxX: state.sysMaxX,
  couponWinLimitMaxPay: state.couponWinLimitMaxPay,
  couponWinLimitSingleMaxPay: state.couponWinLimitSingleMaxPay,
  couponWinLimitLiveMaxPay: state.couponWinLimitLiveMaxPay,
  couponWinLimitLiveSingleMaxPay: state.couponWinLimitLiveSingleMaxPay,
  couponWinLimitSysMaxX: state.couponWinLimitSysMaxX,
  oddSafeLiveMaxOdd: state.oddSafeLiveMaxOdd,
  oddSafePreMaxOdd: state.oddSafePreMaxOdd,
  oddSafeLiveMinOdd: state.oddSafeLiveMinOdd,
  oddSafePreMinOdd: state.oddSafePreMinOdd,
  videoGameShowGame: state.videoGameShowGame,
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

export default connect(mapStateToProps,{setBackgroundColor,setTextColor})(SystemSettings);
