import React, { createContext, useContext, useState } from "react";


const SelectedOddsContext = createContext();

export function SelectedOddsProvider({ children }) {
  const [selectedOdds, setSelectedOdds] = useState([]);


  function addSelectedOdds(selectedOdd) {
    setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, selectedOdd]);
  }

  return (
    <SelectedOddsContext.Provider value={{ selectedOdds, addSelectedOdds }}>
      {children}
    </SelectedOddsContext.Provider>
  );
}

export function useSelectedOdds() {
  return useContext(SelectedOddsContext);
}
