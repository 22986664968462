import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  allCouponsNoValueUpdate,
  allCouponsPieceValueUpdate,
  allCouponsStatusValueUpdate,
  allCouponsBetValueUpdate,
  allCouponsUserInputValueUpdate,
  allCouponsDepositValueUpdate,
  allCouponsProbableValueUpdate,
} from "../redux/actions/actions";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import axios from "axios";
import UsersInput from "./AllCoupons/UsersInput";
import UsersBet from "./AllCoupons/UsersBet";
import { AllCouponList, AllCouponTable, IsLoading } from "./index";
import { setTextColor,setBackgroundColor } from "../redux/actions/actions";
import Cookies from "js-cookie";
import Language from '../language.json'
const AllCoupons = ({
  allCouponsNo,
  allCouponsNoValueUpdate,
  allCouponsPiece,
  allCouponsPieceValueUpdate,
  allCouponsStatus,
  allCouponsStatusValueUpdate,
  allCouponsBet,
  allCouponsBetValueUpdate,
  allCouponsDeposit,
  allCouponsDepositValueUpdate,
  allCouponsProbable,
  allCouponsProbableValueUpdate,
  allCouponsUserInput,
  allCouponsUserInputValueUpdate,
  textColor,
  backgroundColor
}) => {
 const days = ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct']
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
const [isDescending,setisDescending] = useState(false); 
const sortDataByKey = (key) => {
  console.log("Sorting by key:", key);

  const sortedData = [...datas].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    
    // Düz sıralama durumunda ise ters sıralama yap, aksi halde ters sıralama yapma
    if (isDescending) {
      comparison *= -1;
    }

    return comparison;
  });

  // Sıralama durumunu her zaman tersine çevir
  setisDescending(!isDescending);

  console.log("Sorted data:", sortedData);
  setDatas(sortedData);
};

const tr = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getallcoupon";
  const [resetPost,setResetPost] = useState(false)
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate()-1);
    yesterday.setHours(0, 0, 0, 0);
    return yesterday;
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const formatDate2 = (dateString) => {
    const tarih = new Date(dateString);
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0');
    const gun = String(tarih.getDate()).padStart(2, '0');
    const saat = String(tarih.getHours()).padStart(2, '0');
    const dakika = String(tarih.getMinutes()).padStart(2, '0');
    const saniye = String(tarih.getSeconds()).padStart(2, '0');
    const milisaniye = String(tarih.getMilliseconds()).padStart(3, '0');
    
    const isoTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}.${milisaniye}Z`;
    return isoTarih;
};
  useEffect(() => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
    };
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      });
  }, [resetPost]);

  const showChanges = () => {
    const postData = {
      no: allCouponsNo,
      name: allCouponsNo,
      ip: allCouponsNo,
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
      coupon_length: allCouponsPiece,
      status: allCouponsStatus,
      sportid: allCouponsBet,
      count: allCouponsDeposit,
      max_win: allCouponsProbable,
      user: allCouponsUserInput,
    };
    if (allCouponsNo.trim() === "") {
      delete postData.name;
      delete postData.ip;
      delete postData.no;
    }
    if (allCouponsDeposit.trim() === "") {
      delete postData.count;
    }
    if (allCouponsProbable.trim() === "") {
      delete postData.max_win;
    }
    if (allCouponsUserInput.trim() === "") {
      delete postData.user;
    }
    if (allCouponsStatus.trim() === "") {
      delete postData.status;
    }
    if (allCouponsPiece.trim() === "") {
      delete postData.coupon_length;
    }
    if (allCouponsBet.trim() === "") {
      delete postData.sportid;
    }
    setIsLoading(true);
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };
  const handleStartDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(0);
    date.setMinutes(0);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(23);
    date.setMinutes(59);
    setEndDate(date);
  };
  const handleReset = () => {
    allCouponsNoValueUpdate("");
    allCouponsPieceValueUpdate("");
    allCouponsStatusValueUpdate("");
    allCouponsBetValueUpdate("");
    allCouponsDepositValueUpdate("");
    allCouponsProbableValueUpdate("");
    allCouponsUserInputValueUpdate("");
    const resetStartDate = new Date();
    resetStartDate.setHours(0, 0, 0, 0);
    setStartDate(resetStartDate);
    const resetEndDate = new Date();
    resetEndDate.setHours(23, 59, 59, 999);
    setEndDate(resetEndDate);
    setResetPost(!resetPost)
  };
  const totalCount = datas.reduce((total, item) => total + item.count, 0);

  const totalCountLength = datas.length;
  const totalSureProfit = datas.reduce(
    (total, item) => total + item.max_win,
    0
  );
  const totalSureProfitLength = datas.filter((item) => item.max_win > 0).length;
  const totalLose = datas
    .filter((item) => item.is_win === 2)
    .reduce((total, item) => total + item.count, 0);
  const totalLoseLength = datas.filter((item) => item.is_win === 2).length;
  const totalWaiting = datas
    .filter((item) => [0].includes(item.is_win))
    .reduce((total, item) => total + item.count, 0);
    const riziko = datas
    .filter((item) => [0].includes(item.is_win))
    .reduce((total, item) => total + item.likely, 0);
  const totalWaitingLength = datas.filter((item) =>
    [0].includes(item.is_win)
  ).length;
  const totalCancel = datas
    .filter((item) => item.is_win === 5)
    .reduce((total, item) => total + item.count, 0);
  const totalCancelLength = datas.filter((item) => item.is_win === 5).length;

  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].allCoupons.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[15px]">
        <div className="forms-all-coupons flex flex-wrap xl:flex-nowrap justify-center xl:justify-start items-end forms-all-coupons-box-shadow pt-[5px] pr-[5px] pb-[10px] pl-[15px] min:h-20 relative border-[1px] border-t-[#FFF]">
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
              onClick={() => window.location.reload()}
            >
              {Language["en"].allCoupons.refreshButton}
            </button>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.noInput}
            </span>
            <input
              className="forms-select-box-shadow h-[37px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] focus:shadow-md"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              value={allCouponsNo}
              onChange={(e) => {
                const selectedValue = e.target.value;
                allCouponsNoValueUpdate(selectedValue);
              }}
            />
          </div>
          {
            Number(Cookies.get("user_type")) !== 9 &&
            <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <UsersInput backgroundColor={backgroundColor} textColor={textColor}/>
          </div>
          }
          <div className="w-[100px] flex flex-col justify-center h-[100%] ml-[50px] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.startingDate}
            </span>
            <div className="h-[37px]">
              <DatePicker
              locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999]  focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.endDate}
            </span>
            <div className="h-[37px]">
              <DatePicker
              locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999]  focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
              {Language["en"].allCoupons.piece}
            </span>
            <div className="">
              <select
                name="allCouponsPiece"
                id="allCouponsPiece"
                value={allCouponsPiece}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  allCouponsPieceValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="">{Language["en"].allCoupons.pieceInputAll}</option>
                <option value="0">{Language["en"].allCoupons.pieceInput0}</option>
                <option value="1">{Language["en"].allCoupons.pieceInput1}</option>
                <option value="2">{Language["en"].allCoupons.pieceInput2}</option>
                <option value="3">{Language["en"].allCoupons.pieceInput3}</option>
                <option value="4">{Language["en"].allCoupons.pieceInput4}</option>
              </select>
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.status}
            </span>
            <div className="">
              <select
                name="allCouponsStatus"
                id="allCouponsStatus"
                value={allCouponsStatus}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  allCouponsStatusValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="">{Language["en"].allCoupons.statusInputAll}</option>
                <option value="0">{Language["en"].allCoupons.statusInput0}</option>
                <option value="1">{Language["en"].allCoupons.statusInput1}</option>
                <option value="2">{Language["en"].allCoupons.statusInput2}</option>
                <option value="3">{Language["en"].allCoupons.statusInput3}</option>
                <option value="4">{Language["en"].allCoupons.statusInput4}</option>
                <option value="5">{Language["en"].allCoupons.statusInput5}</option>
              </select>
            </div>
          </div>
          
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <UsersBet textColor={textColor} backgroundColor={backgroundColor}/>
          </div>
          
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.deposited}
            </span>
            <input
              value={allCouponsDeposit}
              onChange={(e) => {
                const selectedValue = e.target.value;
                allCouponsDepositValueUpdate(selectedValue);
              }}
              className="forms-select-box-shadow h-[37px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] focus:shadow-md placeholder:text-sm placeholder:font-medium"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              placeholder="over amount"
            />
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].allCoupons.probable}
            </span>
            <input
              value={allCouponsProbable}
              onChange={(e) => {
                const selectedValue = e.target.value;
                allCouponsProbableValueUpdate(selectedValue);
              }}
              className="forms-select-box-shadow h-[37px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] focus:shadow-md placeholder:text-sm placeholder:font-medium"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              placeholder="over amount"
            />
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={showChanges}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language["en"].allCoupons.showButton}
            </button>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={handleReset}
              className="forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[#333] text-[#fff] cursour-pointer transition-all duration-300"
            >
              {Language["en"].allCoupons.resetButton}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#f0f0f0] w-[100%] rounded-b-[20px] ">
        {datas.length === 0 ? (
          <div className="text-[#444] p-[25px]" style={{fontFamily:"ui-rounded"}}>{Language["en"].allCoupons.noCouponFound}</div>
        ) : (
          <>
            <div className="px-[10px]">
              <AllCouponTable
                totalCount={totalCount}
                riziko={riziko}
                totalCountLength={totalCountLength}
                totalSureProfit={totalSureProfit}
                totalSureProfitLength={totalSureProfitLength}
                totalLose={totalLose}
                totalLoseLength={totalLoseLength}
                totalWaiting={totalWaiting}
                totalWaitingLength={totalWaitingLength}
                totalCancel={totalCancel}
                totalCancelLength={totalCancelLength}
              />
            </div>
            <div className="w-[100%]">
              {isLoading ? (
                <div className="flex w-full mt-3 justify-center items-center">
                  {" "}
                  <IsLoading />{" "}
                </div>
              ) : (
                <AllCouponList datas={datas} sortDataByKey={sortDataByKey} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  allCouponsNo: state.allCouponsNo,
  allCouponsPiece: state.allCouponsPiece,
  allCouponsStatus: state.allCouponsStatus,
  allCouponsBet: state.allCouponsBet,
  allCouponsDeposit: state.allCouponsDeposit,
  allCouponsProbable: state.allCouponsProbable,
  allCouponsUserInput: state.allCouponsUserInput,
  textColor: state.textColor,
  backgroundColor: state.backgroundColor
});

const mapDispatchToProps = {
  allCouponsNoValueUpdate,
  allCouponsPieceValueUpdate,
  allCouponsStatusValueUpdate,
  allCouponsBetValueUpdate,
  allCouponsDepositValueUpdate,
  allCouponsProbableValueUpdate,
  allCouponsUserInputValueUpdate,
  setTextColor,
  setBackgroundColor,
};
export default connect(mapStateToProps, mapDispatchToProps)(AllCoupons);
