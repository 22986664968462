import React, { useEffect } from "react";
import { videoGameShowGameValueUpdate } from "../../redux/actions/actions";
import axios from "axios";
import { connect } from "react-redux";
import Language from '../../language.json'
import Cookies from "js-cookie";
import IsLoading from "../IsLoading";
const VideoGameLimited = ({
  videoGameShowGame,
  videoGameShowGameValueUpdate,
  isLoading,
  setIsLoading,
  textColor,
  backgroundColor
}) => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config";
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        const videoGameShowGameValue = response.data.video_game_conf.show_game;
        if (!videoGameShowGame) {
          videoGameShowGameValueUpdate(videoGameShowGameValue);
        }
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
          {" "}
          <IsLoading />{" "}
        </div>
      ) : (
        <div className="p-[5px]">
          <div className={`pl-[35px] p-[10px] border-b-[1px] text-base relative`}
          style={{borderBottomColor:backgroundColor,color:textColor}}
          >

            {Language["en"].videoGameLimited.title}
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].videoGameLimited.videoGames}
            </div>
            <div className="p-[5px]">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="video_game_conf"
                id="video_game_conf"
                value={videoGameShowGame}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  videoGameShowGameValueUpdate(selectedValue);
                }}
              >
                <option value="0">{Language["en"].videoGameLimited.optionShowAll}</option>
                <option value="1">{Language["en"].videoGameLimited.optionOnlyShowVideoGame}</option>
                <option value="2">{Language["en"].videoGameLimited.optionCloseAll}</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  videoGameShowGame: state.videoGameShowGame,
});

const mapDispatchToProps = {
  videoGameShowGameValueUpdate,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoGameLimited);
