import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {ErrorAlert, ErrorUnAlert, IsLoading} from './index'
import Language from '../language.json'
import { setBackgroundColor } from "../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";

const OpenAndCloseQuickGame = ({backgroundColor}) => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";
  const apiBaseUrl = process.env.REACT_APP_API_URL + "/api/updatefastgame";
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const [checkedValues, setCheckedValues] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);

        const initialCheckedValues = response.data.reduce((acc, user) => {
          const userCheckedValues = {};
          user.fast_game_config[0].sport_games.forEach((game) => {
            userCheckedValues[game.id] = game.active === 1;
          });
          user.fast_game_config[0].virtual_games.forEach((game) => {
            userCheckedValues[game.id] = game.active === 1;
          });
          user.fast_game_config[0].video_games.forEach((game) => {
            userCheckedValues[game.id] = game.active === 1;
          });
          acc[user.user_id] = userCheckedValues;
          return acc;
        }, {});
        setCheckedValues(initialCheckedValues);
      })
      .catch((error) => {
        console.error("API HATASI:", error);
      });
  }, []);

  const handleCheckboxChange = (userId, gameId) => {
    setCheckedValues((prevState) => {
      const userCheckedValues = { ...prevState };
      if (!userCheckedValues[userId]) {
        userCheckedValues[userId] = {};
      }
      userCheckedValues[userId][gameId] = !userCheckedValues[userId][gameId];
      return { ...userCheckedValues };
    });

    const postData = {
      user_id: userId,
      id: gameId,
      active: checkedValues[userId][gameId] ? 0 : 1,
    };

    setIsSaving(true);
    axios
      .post(apiBaseUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsSaving(false);
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => {
        console.error("API ERROR:", error);
        setIsSaving(false);
      });
  };

    const sortedDatas = [...datas].sort((a, b) => a.username.localeCompare(b.username));
    return (
      <div className="bg-[#ddd] rounded-[20px]">
        {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
        <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
          {Language["en"].openAndCloseQuickGame.title}
        </div>
        <div>
          <div className="p-[10px] m-[5px] rounded-[10px] text-[#222] text-[13px] bg-[#ffcc00]">
              {Language["en"].openAndCloseQuickGame.description}
              {" "}
            <Link to={"/game-toggle"} className="underline">
              {" "}
              {Language["en"].openAndCloseQuickGame.openCloseGame}
            </Link>{" "}
              {Language["en"].openAndCloseQuickGame.infoText} 
          </div>
          <table className="w-[100%]">
            <thead>
              <tr>
                <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]">
                 {Language["en"].openAndCloseQuickGame.titleUser}
                </td>
                <td
                  id="66"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                 {Language["en"].openAndCloseQuickGame.titleFootball}
                </td>
                <td
                  id="67"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleLiveFootball}
                </td>
                <td
                  id="68"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleBasketball}
                </td>
                <td
                  id="66-1"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleLiveBasketball}
                </td>
                <td
                  id="67-1"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleTennis}
                </td>
                <td
                  id="68-1"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleLiveTennis}
                </td>
                <td
                  id="3"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleVideoSlots}
                </td>
                <td
                  id="4"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleVideoCasino}
                </td>
                <td
                  id="1"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleVirtualSports}
                </td>
                <td
                  id="2"
                  className="py-[15px] px-[10px] text-center bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff] text-[13px] w-[150px] h-[47px]"
                >
                  {Language["en"].openAndCloseQuickGame.titleVirtualRace}
                </td>
              </tr>
            </thead>
            <tbody className="rounded-bl-[20px]">
              {isLoading ?  
            <div className='flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center'> <IsLoading/> </div>
          :sortedDatas.map((data) => (
                <tr className="bg-[#f9f9f9] hover:bg-[#fff]" key={data.user_id}>
                  <td className="py-[8px] px-[10px] transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs font-bold">
                    {data.username}
                  </td>
                  {data.fast_game_config[0].sport_games.map((game) => (
                    <td
                      className="py-[8px] px-[10px] text-center transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs font-bold"
                      key={game.id}
                    >
                      <input
                        id={game.id}
                        type="checkbox"
                        className={`w-[20px] h-[20px] border-[1px] border-[${backgroundColor}]`}
                        style={{accentColor:backgroundColor}}
                        checked={checkedValues[data.user_id][game.id]}
                        onChange={() => handleCheckboxChange(data.user_id, game.id)}
                      />
                    </td>
                  ))}
                  {data.fast_game_config[0].virtual_games.map((game) => (
                    <td
                      className="py-[8px] px-[10px] text-center transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs font-bold"
                      key={game.id}
                    >
                      <input
                        id={game.id}
                        type="checkbox"
                        className={`w-[20px] h-[20px] border-[1px] border-[${backgroundColor}]`}
                        style={{accentColor:backgroundColor}}
                        checked={checkedValues[data.user_id][game.id]}
                        onChange={() => handleCheckboxChange(data.user_id, game.id)}
                      />
                    </td>
                  ))}
                  {data.fast_game_config[0].video_games.map((game) => (
                    <td
                      className="py-[8px] px-[10px] text-center transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs font-bold"
                      key={game.id}
                    >
                      <input
                        id={game.id}
                        type="checkbox"
                        className={`w-[20px] h-[20px] border-[1px] border-[${backgroundColor}]`}
                        style={{accentColor:backgroundColor}}
                        checked={checkedValues[data.user_id][game.id]}
                        onChange={() => handleCheckboxChange(data.user_id, game.id)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
};
const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor
})
export default connect(mapStateToProps,{setBackgroundColor})(OpenAndCloseQuickGame);
