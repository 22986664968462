import React, { useEffect, useState } from "react";
import axios from "axios";
import VideoGetGames from "./VideoGetGames";
import Cookies from "js-cookie";
function VideoGamesFree() {
 const token = Cookies.get("token");
 const [attrData, setAttrData] = useState([]);

 useEffect(() => {
  axios
   .get("https://rona.thoth.lt/api/video_game_attribute", {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   })
   .then((response) => {
    setAttrData(response.data);
   });
 }, []);

 return (
  <div>
   {attrData.map((attr) => (
    <div key={attr.id}>
     <div
      style={{ paddingBottom: "0px" }}
      className="text-[34px] font-bold text-white py-[15px] px-[25px]"
     >
      {attr.name}
     </div>
     <div className="flex flex-wrap p-[15px] items-start">
      <VideoGetGames attribute_id={attr.id} />
     </div>
    </div>
   ))}
  </div>
 );
}

export default VideoGamesFree;
