import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { setBackgroundColor, setTextColor } from '../redux/actions/actions';
import LeftMenu from "./LeftMenu";
import "./desktop.css";
import { Link, useNavigate } from "react-router-dom";
import Football from "./Football";
import { TailSpin } from "react-loader-spinner";
import Language from '../language.json'
import Cookies from "js-cookie";
function Main({ backgroundColor, textColor,setTextColor, setBackgroundColor, socket, click }) {
 const [clickedLink, setClickedLink] = useState(null);
 const [liveData, setLiveData] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const [eventIsLoading, setEventIsLoading] = useState(true)
 const [date2, setDate2] = useState();
 const [date1, setDate1] = useState(new Date());
 const [filterId ,setFilterId] = useState("65673d7cf8cbc1a84d058226")
 const [name, setName] = useState(null)
 const [color, setColor] = useState(Language["en"].main.filterToday)
 const [catId, setCatId] = useState(null)
 const [footballGames,setFootballGames] = useState()
 const [basketballGames,setBasketballGames] = useState()
 function encodeBase64UTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
  }));
}
function decodeBase64UTF8(encodedStr) {
return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
}).join(''));
}

 useEffect(() => {
  const base64EncodedString = Cookies.get("gameConfig");
  const decodedString = decodeBase64UTF8(base64EncodedString);
  const gameConfigObject = JSON.parse(decodedString);
  gameConfigObject?.sport_games?.pre_sports?.map((video) => (
    video.id === "66" ? setFootballGames(video.active) : 
    video.id === "67" ? setBasketballGames(video.active) : ""
  ))
},[])

if(footballGames === 0 && basketballGames === 1) {
  window.location.href = '/Basketball'
} else if (footballGames === 0 && basketballGames === 0){
  window.location.href = '/Live'
}

 const sportId = 66;
 const count = null;
 const champId = null;
 let date = new Date()
 date.setDate(date.getDate());
 date.setHours(23);
 date.setMinutes(59);
let nextDay = date.toISOString();

 useEffect(() => {
  socket.on('disconnect', function () {
    window.location.reload();
  });
  socket.on('reconnect', function () {
    socket.emit("upcoming", sportId, count, catId, champId, date1, color == Language["en"].main.filterToday ? nextDay : date2 , name);

  });  
  if( name === null || name.length === 0 || name.length >= 3){
    
    socket.emit("upcoming", sportId, count, catId, champId, date1, color == Language["en"].main.filterToday ? nextDay : date2 , name);
   socket.on("upcoming", (data) => {
    const events = data.Result;
    setLiveData(events);
    setIsLoading(false);
    setEventIsLoading(false)
   });
   
}
 }, [date1,date2, color, catId]);

 if (isLoading) {
  return (
   <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
    <TailSpin
      height={80}
      width={80}
      color={`${backgroundColor}`}
      ariaLabel="tail-spin-loading"
      radius={1}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
   </div>
  );
 }

 const backgroundImages = {
  content: '',
  backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`
 }

 const handleInputChange = (e) => {
   setName(e.target.value);
   setDate2(null)
   setDate1(new Date())
   setColor("All")

};

 const handleCatIdClick = (catId) => {
  setCatId(catId)
  setColor("All")
  setDate1(new Date())
  setDate2(null)
}


 const handleLinkClick = (e,a) => {
  setEventIsLoading(true)

  switch (e) {
    case Language["en"].main.filterAll:
      setDate2(null)
      setDate1(new Date())
      setColor(Language["en"].main.filterAll)
    break;
    case getFormattedDate(a) :
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate()+a);
      date.setDate(date.getDate()+a);
      date.setHours(23);
      date.setMinutes(59);
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      nextDay = date.toISOString();
      setDate2(nextDay)
      setDate1(currentDate)
      setColor(getFormattedDate(a))
    break;
   case Language["en"].main.filterToday:
      date.setDate(date.getDate());
      date.setHours(23);
      date.setMinutes(59);
      nextDay = date.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterToday)
    break;
    case Language["en"].main.filterMin30:
      const date30 = new Date()
      date30.setTime(date30.getTime() + 30 * 60 * 1000)
      nextDay = date30.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterMin30)
    break;
    case Language["en"].main.filterHour1:
      const dateOne = new Date()
      dateOne.setTime(dateOne.getTime() + 60 * 60 * 1000)
      nextDay = dateOne.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterHour1)
    break;
    case Language["en"].main.filterHour3:
      const dateThree = new Date()
      dateThree.setTime(dateThree.getTime() + 180 * 60 * 1000)
      nextDay = dateThree.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterHour3)
    break;
    case Language["en"].main.filterHour6:
      const dateSix = new Date()
      dateSix.setTime(dateSix.getTime() + 360 * 60 * 1000)
      nextDay = dateSix.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterHour6)
    break;
    case Language["en"].main.filterHour12:
      const dateTwelve = new Date()

      dateTwelve.setTime(dateTwelve.getTime() + 720 * 60 * 1000)
      nextDay = dateTwelve.toISOString();
      setDate2(nextDay);
      setDate1(new Date())
      setColor(Language["en"].main.filterHour12)
    break;
    case Language["en"].main.filterHour24:
    const dateTwentyFour = new Date()
    dateTwentyFour.setTime(dateTwentyFour.getTime() + 1440 * 60 * 1000)
    nextDay = dateTwentyFour.toISOString();
    setDate2(nextDay);
      setDate1(new Date())
    setColor(Language["en"].main.filterHour24)
    break;
    

   default:
    break;
  }
 };

 const handleMarketClick = (id) => {
  setFilterId(id);
}; 

 const navbarStyle = {
  backgroundColor: backgroundColor,
  textColor: textColor
};

const getFormattedDate = (offset) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + offset);

  const options = {
    weekday: "short", 
  };

  return currentDate.toLocaleDateString("tr-TR", options);
};

 return (
  <div>
   <LeftMenu liveData={liveData} socket={socket} setLiveData={setLiveData} handleCatIdClick={handleCatIdClick} setDate1={setDate1} setDate2={setDate2} setColor={setColor}/>
   <div className="sport-selected-tab">
    <div style={backgroundImages} className="h-[3px] right-0 left-[22px] bottom-0 w-[100%] absolute rounded-[5px]"></div>
    {
      footballGames !== 0 &&
    <Link to="/Results" style={{backgroundColor: backgroundColor }}>
    {Language["en"].main.selectFootball}
    </Link>
    }
    {
      basketballGames === 1 &&
      <Link
     to="/Basketball"
     className="bg-[#333]"
    >
     {Language["en"].main.selectBasketball}
    </Link>
    }
   </div>
   <div className="ml-[240px] w-[950px] mt-[-150px] rounded-2xl absolute bg-tblack text-text-white">
    <div className="p-2 bg-[#333] flex flex-grow rounded-2xl rounded-bl-none rounded-br-none" onClick={() => setName("")}>
     <input className="p-[10px] border-[#999] font-bold outline-none w-[33%] cursor-text
      bg-[#f9f9f9] shadow-lg h-[40px] text-black rounded-md" type="search" placeholder="Search Team" value={name} onChange={handleInputChange} />
     <div className="flex flex-1 shadow-custom items-center pl-[5px] justify-between rounded-2xl">
      <Link
       className={`search-links hover:brightness-110 cursor-pointer`}
       onClick={() => handleLinkClick(Language["en"].main.filterToday)}
       style={color === Language["en"].main.filterToday ? navbarStyle : undefined}
      >
       {Language["en"].main.filterToday}
      </Link>
      {[1, 2, 3].map((offset) => (
        <Link
          key={offset}
          className={`search-links hover:brightness-110 cursor-pointer`}
          onClick={() => handleLinkClick(getFormattedDate(offset),offset)}
          style={color === getFormattedDate(offset) ? navbarStyle : undefined}
        >
          {getFormattedDate(offset)}
        </Link>
      ))}
      <Link
       className={`search-links hover:brightness-110 cursor-pointer`}
       onClick={() => handleLinkClick(Language["en"].main.filterAll)}
       style={color === Language["en"].main.filterAll ? navbarStyle : undefined}
      >
      {Language["en"].main.filterAll}
      </Link>
     </div>
    </div>
    <div className="flex">
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      onClick={() => handleLinkClick(Language["en"].main.filterMin30)}
      style={color === Language["en"].main.filterMin30 ? navbarStyle : undefined}
     >
      {Language["en"].main.filterMin30}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language["en"].main.filterHour1 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language["en"].main.filterHour1)}
     >
      {Language["en"].main.filterHour1}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language["en"].main.filterHour3 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language["en"].main.filterHour3)}
     >
      {Language["en"].main.filterHour3}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language["en"].main.filterHour6 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language["en"].main.filterHour6)}
     >
      {Language["en"].main.filterHour6}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language["en"].main.filterHour12 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language["en"].main.filterHour12)}
     >
      {Language["en"].main.filterHour12}
     </div>
     <div
      className="search-links hover:brightness-110 cursor-pointer"
      style={color === Language["en"].main.filterHour24 ? navbarStyle : undefined}
      onClick={() => handleLinkClick(Language["en"].main.filterHour24)}
     >
      {Language["en"].main.filterHour24}
     </div>
    </div>
    <div className="flex w-[100%] justify-between border-b-2 border-gray-800">
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "1" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("1")}
     >
      {Language["en"].main.filterMarket1x2}
     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "29" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("29")}
     >
      {Language["en"].main["filterMarketGG/NG"]}
     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "18" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("18")}
     >
      {Language["en"].main.filterMarketTotal}
     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "11" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("11")}
     >
      {Language["en"].main.filterMarketDrawNoBet}
     </div>
     <div
      className="market-list  hover:brightness-110 cursor-pointer"
      style={filterId === "10" ? navbarStyle : undefined}
      onClick={() => handleMarketClick("10")}
     >
      {Language["en"].main.filterMarketDoubleChance}
     </div>
    </div>
    {eventIsLoading ?      <div className="flex items-center justify-center py-5">
    <TailSpin
     height={80}
     width={80}
     color= {backgroundColor}
     ariaLabel="tail-spin-loading"
     radius={1}
     wrapperStyle={{}}
     wrapperClass=""
     visible={true}
    />
   </div>:<Football click={click} liveData={liveData.Items} socket={socket} filterId={filterId}/>}
    
   </div>
  </div>
 );
}

const mapStateToProps = (state) => {
 return {
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
 };
};

export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(Main);
