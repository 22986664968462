import React from 'react'
import '../mobile/mobile.css'
import { Link } from 'react-router-dom'
import {FaKey} from 'react-icons/fa'
import {FaMoneyBillTransfer} from 'react-icons/fa6'
import {BiSolidCalendar} from 'react-icons/bi'
import {AiOutlineLineChart} from 'react-icons/ai'
import MobileNavbar from './MobileNavbar'
import MobileFooter from './MobileFooter'

function MobileHesap() {
  return (
    <div>
      <MobileNavbar/>
      <div className="center">
        <div className="incenter">
          <div className="casinocenter">
            <div className="pagetitle">Hesabım</div>
            <div className="gamelist">
              <ul className="games">
                <li>
                  <Link to='/Sifre' >
                    <FaKey className='ikon'/>
                    Şifre Değişikliği
                  </Link>
                </li>
                <li><Link href="">
                    <BiSolidCalendar className='ikon'/>
                    Günlük Raporlar
                  </Link></li>
                <li><Link href="" >
                    <AiOutlineLineChart className='ikon'/>
                    Detaylı Raporlar
                  </Link></li>
                <li><a href="" >
                    <FaMoneyBillTransfer className='ikon'/>
                    Nakit Akışı
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <MobileFooter/>
    </div>
  )
}

export default MobileHesap