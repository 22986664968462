import React from "react";
import "../mobile/mobile.css";
import MobileNavbar from "./MobileNavbar";

function MobilePassword() {
 return (
  <div>
    <MobileNavbar/>
   <div className="center">
    <div className="incenter">
     <div className="casinocenter">
      <div className="pagetitle">Şifre Değişikliği</div>
      <form id="changePasswordForm">
       <div className="mform">
        <div className="mformdesc">
         Şifrenizi değiştirmek için öncelikle mevcut şifrenizi, sonra da yeni
         şifrenizi iki kere yazmanız gerekmektedir.
        </div>
        <div className="formi">
            <span>Mevcut Şifreniz</span>
            <input type="password" className="minput" name="oldpass" />
        </div>
        <div className="formi">
            <span>Yeni Şifreniz</span>
            <input type="password" className="minput" name="oldpass" />
        </div>
        <div className="formi">
            <span>Yeni Şifreniz (Tekrar)</span>
            <input type="password" className="minput" name="oldpass" />
        </div>
        <div className="formi">
             <button className="fbutton">DEĞİŞİKLİKLERİ KAYDET</button>
        </div>
       </div>
      </form>
     </div>
    </div>
   </div>
  </div>
 );
}

export default MobilePassword;
