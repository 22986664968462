import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "./desktop.css";
import { TailSpin } from "react-loader-spinner";
import Language from "../language.json";
import Coupon from "./Coupon";
import { setBackgroundColor } from "../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
function LiveBasketbol({ socket, click, setBackgroundColor, backgroundColor }) {
  const [selectedOdds, setSelectedOdds] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState({});
  const [liveData, setLiveData] = useState([]);
  const [headers, setHeader] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLink, setSelectedLink] = useState(Language["en"].live.liveall);
  const [searchTerm, setSearchTerm] = useState("");
  const [eventData, setEventData] = useState([]);
  const [goals, setGoals] = useState([]);
  const [footballGames,setFootballGames] = useState()
  const [couponchange, setcouponchange] = useState(1);

  useEffect(() => {
    socket.on("disconnect", function () {
      window.location.reload();
    });
    socket.on("reconnect", function () {
      socket.emit("live-general", sportId);
    });
    const sportId = 67;
    socket.emit("live-general", sportId);

    socket.on("live-general", (data) => {
      let events = data?.Result?.Items[0]?.Items;
      let headers = data?.Result?.Items[0]?.Headers;
      setLiveData(events);
      setHeader(headers);
      setIsLoading(false);
    });
  }, [socket]);


  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  function decodeBase64UTF8(encodedStr) {
  return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
  useEffect(() => {
    const base64EncodedString = Cookies.get("gameConfig");
    const decodedString = decodeBase64UTF8(base64EncodedString);
    const gameConfigObject = JSON.parse(decodedString);
    
    gameConfigObject?.sport_games?.live_sports?.map((video) => (
      video.id === "66-1" ? setFootballGames(video.active) : ""
    ))
  }, []);

  const filterMatches = (filter) => {
    setSelectedFilter(filter);
    setSelectedLink(filter);
  };
  const handleOddClick = (clickedOdd, marketName, eventsId, events, item) => {
    const matchId = events.Id;
    const clickedOddApiId = clickedOdd?.ApiId;
    const isOddSelected = selectedOdds.some(
      (odd) => odd.odd_api_id === clickedOddApiId
    );

    if (isOddSelected) {
      removeOddFromSelection(clickedOddApiId);
      localStorage.removeItem("selectedOdds", clickedOddApiId);
    } else {
      const clickedOddName = clickedOdd.SPOV+" "+clickedOdd.name;
      const clickedOddPrice = clickedOdd.price;
      const clickedOddTime = events?.LiveCurrentTime;
      const clickedOddScore = events?.LiveScore;
      const teams = events?.Competitors?.map((competitor) => competitor.Name);
      const previousSelectedOddIndex = selectedOdds.findIndex(
        (odd) => odd.event_id === matchId
      );

      if (previousSelectedOddIndex !== -1) {
        removeOddFromSelection(
          selectedOdds[previousSelectedOddIndex].odd_api_id
        );
      }
      click(clickedOdd.ApiId);
      const oddInfo = {
        odd_name: clickedOddName,
        market_name: item?.Name,
        teams: teams.join(" - "),
        odd_price: clickedOddPrice,
        LiveCurrentTime: clickedOddTime,
        LiveScore: clickedOddScore,
        is_live: events?.Status,
        org_market_type:clickedOdd?.OrgMarketTypeId,
        event_id: events.Id,
        market_api_id: item?.ApiId,
        market_id: item?._id,
        marketSportId: item?.SportMarketId,
        org_market_type:clickedOdd?.OrgMarketTypeId,
        odd_api_id: clickedOddApiId,
        oddSelectionTypeId: clickedOdd?.SelectionTypeId,
        odd_id: clickedOdd?._id,
        api_group_id: 1
      };
      setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, oddInfo]);
      setSelectedMatches({ ...selectedMatches, [matchId]: true });
    }
  };

  const backgroundImages = {
    content: "",
    backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
  };

  const removeOddFromSelection = (clickedOddApiId) => {
    setSelectedOdds((prevSelectedOdds) => {
      return prevSelectedOdds.filter(
        (odd) => odd.odd_api_id !== clickedOddApiId
      );
    });
    const element = document.getElementById(clickedOddApiId);
    if (element) {
      element.classList.remove("selected");
    }
  };
  const filteredData = liveData?.filter((live) => {
    return live?.Events?.some((matches) => {
      return (
        matches.Competitors[0].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        ) ||
        matches.Competitors[1].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        )
      );
    });
  });

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <TailSpin
          height="80"
          width="80"
          color={`${backgroundColor}`}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  const backgroundColors = {
    backgroundColor: backgroundColor,
  };
  let noMatchMessageDisplayed = false;
  return (
    <div className="mt-[15px]">
      <div
        style={{ padding: "0px" }}
        className="h-full relative flex container mx-auto mt-[35px]"
      >
        <div className="live mx-auto flex">
          <div>
            <div>
              <div className="sport-selected-tab-live ml-[-220px]">
                <div
                  style={backgroundImages}
                  className="h-[3px] right-0 left-0 bottom-0 w-[100%] absolute rounded-[5px]"
                ></div>
              {  
                footballGames === 1 &&
              <Link to="/Live" className="bg-[#333]">
                  {Language["en"].live.soccer}
                </Link>
                }
                <Link style={backgroundColors}>
                  {Language["en"].live.basketball}
                </Link>
              </div>
              <div className="pt-[52px]">
                <div className="p-2 bg-[#333] w-[1220px] mt-2 h-[50px] flex flex-grow">
                  <input
                    className="w-[45.6%] p-[5px] outline-none text-[14px] border-[#999] rounded-lg pl-[6px] text-[black]"
                    type="search"
                    placeholder={Language["en"].live.teamsearch}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="flex flex-1 items-center pl-[5px] justify-between rounded-2xl">
                    <Link
                      className="search-links hover:brightness-110 cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedLink === Language["en"].live.liveall
                            ? `${backgroundColor}`
                            : "transparent",
                      }}
                      onClick={() => filterMatches(Language["en"].live.liveall)}
                    >
                      {Language["en"].live.liveall}
                    </Link>
                    <Link
                      className="search-links hover:brightness-110 cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedLink === Language["en"].live.Q1
                            ? `${backgroundColor}`
                            : "transparent",
                      }}
                      onClick={() => filterMatches(Language["en"].live.Q1)}
                    >
                      {Language["en"].live.Q1}
                    </Link>
                    <Link
                      className="search-links hover:brightness-110 cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedLink === Language["en"].live.Q2
                            ? `${backgroundColor}`
                            : "transparent",
                      }}
                      onClick={() => filterMatches(Language["en"].live.Q2)}
                    >
                      {Language["en"].live.Q2}
                    </Link>
                    <Link
                      className="search-links hover:brightness-110 cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedLink === Language["en"].live.Q3
                            ? `${backgroundColor}`
                            : "transparent",
                      }}
                      onClick={() => filterMatches(Language["en"].live.Q3)}
                    >
                      {Language["en"].live.Q3}
                    </Link>
                    <Link
                      className="search-links hover:brightness-110 cursor-pointer"
                      style={{
                        backgroundColor:
                          selectedLink === Language["en"].live.Q4
                            ? `${backgroundColor}`
                            : "transparent",
                      }}
                      onClick={() => filterMatches(Language["en"].live.Q4)}
                    >
                      {Language["en"].live.Q4}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {filteredData?.length > 0 ? (
              filteredData.map((league) => {
                const showLeague =
                  !selectedFilter ||
                  selectedFilter === Language["en"].live.liveall ||
                  league?.Events?.some((event) => {
                    const filterCondition =
                      (selectedFilter === Language["en"].live.Q1 &&
                        event.LiveCurrentTime ===
                          Language["en"].live.Q1stquarter) ||
                      (selectedFilter === Language["en"].live.Q2 &&
                        event.LiveCurrentTime ===
                          Language["en"].live.Q2stquarter) ||
                      (selectedFilter === Language["en"].live.Q3 &&
                        event.LiveCurrentTime ===
                          Language["en"].live.Q3stquarter) ||
                      (selectedFilter === Language["en"].live.Q4 &&
                        event.LiveCurrentTime ===
                          Language["en"].live.Q4stquarter);

                    return filterCondition;
                  });
                if (!showLeague && !noMatchMessageDisplayed) {
                  noMatchMessageDisplayed = true;
                  return (
                    <div>
                      <div
                        className={`flex w-[100%] bg-[#111] rounded-[10px] border-t-[#000] border-t-4 
                          mt-[15px] justify-center items-center text-white h-[50px] text-[14px]`}
                      >
                        {Language["en"].live.noshow}
                      </div>
                    </div>
                  );
                } else if (showLeague) {
                  return league.Events.filter(
                    (o) =>
                      o.Competitors[0].Name.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) ||
                      o.Competitors[1].Name.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      )
                  ).map((events) => (
                    <div className="livebasket-box mb-3 hover:border-[#ffcc00]">
                      {events && (
                        <div className="livebasket-info">
                          <div className="text-[12px] p-[5px] text-[#888]">
                            {league.Name}
                          </div>
                          <div className="font-semibold w-[90%] whitespace-nowrap overflow-ellipsis overflow-hidden">
                            <div className="team-name">
                              <span>{events?.LiveScore?.split(":")[0]}</span>
                              {events.Competitors[0].Name}
                            </div>
                            <div className="team-name">
                              <span>{events?.LiveScore?.split(":")[1]}</span>
                              {events?.Competitors[1]?.Name}
                            </div>
                          </div>
                          <div className="text-[12px] text-[#999] p-[5px]">
                            {events?.LiveCurrentTime}
                          </div>
                          {
                            <div className="flex flex-col w-[300px] text-[12px] border-[#333] bg-[#111] mt-[3px] border-b-0 shadow-md">
                              {
                                <ul className="flex flex-1">
                                  <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                    {Language["en"].live.Q1}
                                  </li>
                                  <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                    {Language["en"].live.Q2}
                                  </li>
                                  <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                    {Language["en"].live.Q3}
                                  </li>
                                  <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                    {Language["en"].live.Q4}
                                  </li>
                                  {events?.Results?.periods?.p5 && (
                                    <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                      {Language["en"].live.Q5}
                                    </li>
                                  )}
                                </ul>
                              }
                              <ul className="flex flex-1">
                                {
                                  <>
                                    {events?.Results?.periods?.p1 ? (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {events.Results.periods.p1.home} :{" "}
                                        {events.Results.periods.p1.away}
                                      </li>
                                    ) : (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        0:0
                                      </li>
                                    )}
                                    {events?.Results?.periods?.p2 ? (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {events.Results.periods.p2.home} :{" "}
                                        {events.Results.periods.p2.away}
                                      </li>
                                    ) : (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        0:0
                                      </li>
                                    )}
                                    {events?.Results?.periods?.p3 ? (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {events.Results.periods.p3.home} :{" "}
                                        {events.Results.periods.p3.away}
                                      </li>
                                    ) : (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        0:0
                                      </li>
                                    )}
                                    {events?.Results?.periods?.p4 ? (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {events.Results.periods.p4.home} :{" "}
                                        {events.Results.periods.p4.away}
                                      </li>
                                    ) : (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        0:0
                                      </li>
                                    )}
                                    {events?.Results?.periods?.p5 && (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {events.Results.periods.p5.home} :{" "}
                                        {events.Results.periods.p5.away}
                                      </li>
                                    )}
                                  </>
                                }
                              </ul>
                              <ul className="flex flex-1">
                                {
                                  <>
                                    <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                      (
                                      {events?.Results?.periods?.p1
                                        ? Number(
                                            events.Results.periods.p1.home
                                          ) +
                                          Number(events.Results.periods.p1.away)
                                        : 0}
                                      )
                                    </li>
                                    <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                      (
                                      {events?.Results?.periods?.p2
                                        ? Number(
                                            events.Results.periods.p2.home
                                          ) +
                                          Number(events.Results.periods.p2.away)
                                        : 0}
                                      )
                                    </li>
                                    <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                      (
                                      {events?.Results?.periods?.p3
                                        ? Number(
                                            events.Results.periods.p3.home
                                          ) +
                                          Number(events.Results.periods.p3.away)
                                        : 0}
                                      )
                                    </li>
                                    <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                      (
                                      {events?.Results?.periods?.p4
                                        ? Number(
                                            events.Results.periods.p4.home
                                          ) +
                                          Number(events.Results.periods.p4.away)
                                        : 0}
                                      )
                                    </li>

                                    {events?.Results?.periods?.p5 && (
                                      <li className="flex-1 text-center p-[3px] border-b-[#333]">
                                        {Number(
                                          events.Results.periods.p5.home
                                        ) +
                                          Number(
                                            events.Results.periods.p5.away
                                          )}
                                      </li>
                                    )}
                                  </>
                                }
                              </ul>
                            </div>
                          }
                        </div>
                      )}
                      {events && (
                        <div className="flex flex-1">
                          <div className="basket-odds">
                            {events.Items.slice(
                              0,
                              events.Items.length / 2 + 1
                            ).map((items, itemKey) => (
                              <div className="odds-line">
                                <div
                                  className="flex flex-col border-b-[#444]"
                                  key={itemKey}
                                >
                                  <div className="odds-title">
                                    {items?.Name}
                                  </div>
                                  <div className="flex">
                                    {items?.Name === "Handikap (uzatmalar dahil)" ||
                                    items?.Name === "Toplam (uzatmalar dahil)" ? (
                                      <div className="barem">
                                        {items.Items[0]?.SPOV}
                                      </div>
                                    ) : null}
                                    {items?.Items.map((odd, oddKey) => (
                                      <div
                                        className={`odds-in-basket ${
                                          items.Items.length === 0
                                            ? "disabled"
                                            : ""
                                        }${odd?.RecentStatus}`}
                                        key={oddKey}
                                        onClick={() => (
                                          handleOddClick(
                                            odd,
                                            liveData,
                                            headers,
                                            events,
                                            items
                                          ),
                                          setcouponchange(1)
                                        )}
                                        id={odd?.ApiId}
                                      >
                                        <span className="text-[#999] box-shadow-custom hover:brightness-110">
                                          {odd?.name}
                                        </span>
                                        <span className="text-white absolute right-[5px] box-shadow-custom">
                                          {odd && odd.price
                                            ? odd?.price?.toFixed(2)
                                            : "-"}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="basket-odds">
                            {events.Items.slice(
                              (0, events.Items.length / 2 + 1, -1)
                            ).map((items, itemKey) => (
                              <div className="odds-line">
                                <div
                                  className="flex flex-col border-b-[#444]"
                                  key={itemKey}
                                >
                                  <div className="odds-title">
                                    {items?.Name}
                                  </div>
                                  <div className="flex">
                                    {items?.Name === "Handikap (uzatmalar dahil)" ||
                                    items?.Name === "Toplam (uzatmalar dahil)" ? (
                                      <div className="barem">
                                        {items.Items[0]?.SPOV}
                                      </div>
                                    ) : null}
                                    {items?.Items.map((odd, oddKey) => (
                                      <div
                                        className={`odds-in-basket ${
                                          items.Items.length === 0
                                            ? "disabled"
                                            : ""
                                        } ${odd?.RecentStatus}`}
                                        key={oddKey}
                                        onClick={() => (
                                          handleOddClick(
                                            odd,
                                            liveData,
                                            headers,
                                            events,
                                            items
                                          ),
                                          setcouponchange(1)
                                        )}
                                        id={odd?.ApiId}
                                      >
                                        <span className="text-[#999] box-shadow-custom hover:brightness-110">
                                          {odd?.name.slice(-4)}
                                        </span>
                                        <span className="text-white absolute right-[5px] box-shadow-custom">
                                          {odd && odd.price
                                            ? odd?.price?.toFixed(2)
                                            : "-"}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ));
                }
                return null;
              })
            ) : (
              <div
                className={`flex w-[100%] bg-[#111] rounded-[10px] border-t-[#000] border-t-4 
              mt-[15px] justify-center items-center text-white h-[50px] text-[14px]`}
              >
                {Language["en"].live.noshow}
              </div>
            )}
          </div>
          <div className="ml-[5px] mt-[50px]">
            <Coupon
              selectedOdds={selectedOdds}
              setSelectedOdds={setSelectedOdds}
              couponchange={couponchange}
              setcouponchange={setcouponchange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
  };
};

export default connect(mapStateToProps, { setBackgroundColor })(LiveBasketbol);
