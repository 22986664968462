import React from 'react'
import '../mobile/mobile.css'
import MobileNavbar from './MobileNavbar'
import MobileFooter from './MobileFooter'

function MobileCasino() {
  return (
    <div>
        <MobileNavbar/>
        <div className="center pt-5">
            <div className="incenter">
                <div className="casinocenter">
                    <div className="pagetitle">Casino</div>
                    <div className="gamelist">
                        <ul className="games">
                            <li><a href="" className='canli'>Rulet</a></li>
                            <li><a href="">BlackJack</a></li>
                            <li><a href="" className='canli'>Tombala</a></li>
                            <li><a href="">Sana Futbol</a></li>
                            <li><a href="">Sanal Atlar</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <MobileFooter/>
    </div>
  )
}

export default MobileCasino