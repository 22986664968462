import React, { useState } from "react";
import { AiFillPlusSquare, AiOutlineClose } from "react-icons/ai";
import Coupon from "./Coupon";
import "./desktop.css";
import Popup from "./Popup";
import { GiWorld } from "react-icons/gi";
import { connect } from "react-redux";
import { setBackgroundColor, setTextColor } from "../redux/actions/actions";
import Language from "../language.json";
function FixtureMatch({
 date,
 teams,
 setSelectedOdds,
 selectedOdds,
 league,
 click,
 socket,
 filterId,
 setcouponchange

}) {
 const [isPopupOpen, setPopupOpen] = useState(false);
 const [selectedMatches, setSelectedMatches] = useState({});
 const [preData, setPreData] = useState();
 let markets = league.Markets;
 socket.on('disconnect', function () {
  window.location.reload();
});
socket.on('reconnect', function () {
  preDetailClick()
});
 const preDetailClick = () => {
  socket.emit("pre-detail", Number(league?.EventId));
  socket.on("pre-detail", (data) => {
   setPreData(data);
  });
 };
 if (filterId === "219") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId === filterId || obj.OrgMarketTypeId === 219
     ).slice(0, 2)
   : league.Markets;
 }
 if (filterId === "223") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId === filterId || obj.OrgMarketTypeId === 223
     ).slice(0, 2)
   : league.Markets;
 }
 if (filterId === "225") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId === filterId || obj.OrgMarketTypeId === 225
     ).slice(0, 2)
   : league.Markets;
 }

 const handleOddClick = (clickedOdd, market, league) => {
  const matchId = league?.EventId;
  const clickedOddApiId = clickedOdd.ApiId;
  const isOddSelected = selectedOdds.some(
   (odd) => odd.odd_api_id === clickedOddApiId
  );

  if (isOddSelected) {
   removeOddFromSelection(clickedOddApiId);
   localStorage.removeItem("selectedOdds", clickedOddApiId);
  } else {
   const clickedOddName = clickedOdd.name;
   const teams = league?.Name;
   const marketName = market?.Name;

   const previousSelectedOddIndex = selectedOdds.findIndex(
    (odd) => odd.event_id === matchId
   );

   if (previousSelectedOddIndex !== -1) {
    removeOddFromSelection(selectedOdds[previousSelectedOddIndex].odd_api_id);
   }
   click(clickedOdd?.ApiId);
   const oddInfo = {
    odd_name: clickedOddName,
    market_name: marketName,
    teams: teams,
    date: league.EventDate,
    odd_price: clickedOdd.Price,
    is_live: league?.IsLiveStream,
    event_id: league.EventId,
    market_api_id: market?.ApiId,
    org_market_type:clickedOdd?.OrgMarketTypeId,
    market_id: market?._id,
    marketSportId: market?.SportMarketId,
    odd_api_id: clickedOddApiId,
    oddSelectionTypeId: clickedOdd?.SelectionTypeId,
    odd_id: clickedOdd?._id,
    api_group_id: market?.api_group_id
   };
   setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, oddInfo]);

   setSelectedMatches({ ...selectedMatches, [matchId]: true });
  }
 };

 const removeOddFromSelection = (clickedOddApiId) => {
  setSelectedOdds((prevSelectedOdds) => {
   return prevSelectedOdds.filter((odd) => odd.odd_api_id !== clickedOddApiId);
  });
  const element = document.getElementById(clickedOddApiId);
  if (element) {
   element.classList.remove("selected");
  }
 };
 const inputDate = new Date(date);
 const formattedDate = `${inputDate.toLocaleDateString("tr-TR", {
  month: "2-digit",
  day: "2-digit",
 })} ${inputDate.toLocaleTimeString("tr-TR", {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
 })}`;

 function formatedDateandClock(dateandClock) {
  var [date, clock] = dateandClock.split(" ");
  var match = clock.match(/(\d{1,2}):(\d{2})/);
  if (match) {
   var clock = parseInt(match[1], 10);
   var minutes = parseInt(match[2], 10);
   clock = clock >= 24 ? clock - 24 : clock;
   var formatDateandClock = `${date} ${clock
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
   return formatDateandClock;
  } else {
   return `${Language["en"].basketball.dateErrorMessage}`;
  }
 }

 const openPopup = () => {
  setPopupOpen(true);
 };

 const closePopup = () => {
  setPopupOpen(false);
 };
 let spreatInclOtRendered = false;
 let totalInclOtRendered = false;
 const modifiedTeams = teams.replace("vs.", "-");
 return (
  <tr className="flex">
   <td className="text-[#ffcc00] text-[13px] font-normal w-[350px] flex items-center border-b border-[rgba(0,0,0,.3)]">
    <td className="text-[rgba(255,255,255,.5)] w-[100px] ml-3 outline-none text-center">
     {formatedDateandClock(formattedDate)}
    </td>
    <span className="ml-3 text-yellow">{modifiedTeams}</span>
   </td>
   {league.Markets.length > 0 &&
    markets.map((market) =>
     market.Odds.map((odd) => {
      if (
       market.OrgMarketTypeId === "219" ||
       market.OrgMarketTypeId === "223" ||
       filterId === "225"
      ) {
       totalInclOtRendered = true;
      }
      return (
       <td className="p-0" key={odd.id}>
        <div className="flex w-[140px] justify-between h-[38px] border-l-[rgba(255,255,255,.05)]">
         <div
          className={`flex flex-1 px-10 py-0 bg-[#333] border-r-[rgba(255,255,255,.05)] border-l-[rgba(0,0,0,.2)] 
            items-center transition-all-[100ms] relative shadow-custom hover:brightness-110 cursor-pointer ${odd?.RecentStatus}`}
          id={odd?.ApiId}
          onClick={() =>
           (handleOddClick(odd, market, league, league.Name, market.Name, date),setcouponchange(1))
          }
         >
          <span className="text-[#ffcc00] font-bold text-[11px] whitespace-nowrap w-[50%] text-ellipsis overflow-hidden absolute left-[10px]">
           {odd.additionalName === "bos" || odd.additionalName === null ? odd.name : odd.additionalName}{" "}
           {/* {odd.additionalName === "bos" || odd.additionalName === null ? odd.SPOV : ""} */}
          </span>
          <span className="text-white text-[13px] absolute bottom-0 right-0 top-0 w-[40px] flex justify-center items-center font-normal">
           {odd && odd.Price ? odd.Price.toFixed(2) : "-"}
          </span>
         </div>
        </div>
       </td>
      );
     })
    )}

   <div
    className={`flex justify-center items-center h-full w-[40px] min-w-30 max-w-30 ${
     selectedOdds.find((i) => i.event_id === league.EventId) && "selected"
    }`}
   >
    <div className=" mt-1 text-[20px] text-gray-200">
     <button
      onClick={() => {
       openPopup();
       preDetailClick();
      }}
     >
      <AiFillPlusSquare className="rounded-3xl ml-1 relative" />
     </button>
    </div>
   </div>
   {isPopupOpen && (
    <Popup
     teams={teams}
     preData={preData}
     closePopup={closePopup}
     selectedOdds={selectedOdds}
     handleOddClick={handleOddClick}
     league={league}
     date={date}
    />
   )}
  </tr>
 );
}

function FixtureLeague({
 leagueName,
 country,
 leagueISO,
 setSelectedOdds,
 selectedOdds,
 league,
 socket,
 click,
 navbarStyle,
 backgroundColor,
 filterId,
 setcouponchange
}) {
 const backgroundImages = {
  content: "",
  backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
 };
 return (
  <>
   <tr>
    <td className="bg-[#111] text-[#e4e4e4] text-[13px] h-[50px] mt-3">
     <div className="flex ml-2">
      {leagueISO ? (
       <img
        src={require(`../assest/4x3/${leagueISO.toLowerCase()}.svg`)}
        width={"25px"}
        alt="svg"
        className="mr-2"
       />
      ) : (
       <span className="flex items-center mr-3">
        <GiWorld color="white" size={20} />
       </span>
      )}
      <b>{country && <span className="ml-3">{country}</span>}</b>
      <b className="ml-3">{leagueName}</b>
     </div>
     <div
      style={backgroundImages}
      className="h-[2px] w-[100%] relative right-0  bottom-0 rounded-[5px] top-[13px]"
     ></div>
    </td>
   </tr>
   {league &&
    league.map((match, index) => (
     <FixtureMatch
     setcouponchange={setcouponchange}
     key={index}
      date={match.EventDate}
      teams={match.Name}
      odds={match.odds}
      otherOdds={match.otherOdds}
      setSelectedOdds={setSelectedOdds}
      selectedOdds={selectedOdds}
      league={match}
      click={click}
      socket={socket}
      navbarStyle={navbarStyle}
      filterId={filterId}
     />
    ))}
  </>
 );
}
function Basketball({
 liveData,
 click,
 socket,
 backgroundColor,
 filterId,
 textColor,
}) {
 const [selectedOdds, setSelectedOdds] = useState([]);
 const [couponchange,setcouponchange] = useState(1)
 const navbarStyle = {
  backgroundColor: backgroundColor,
  textColor: textColor,
 };
 if (!liveData || liveData.length === 0) {
  return (
   <div className="text-center p-3">
    {Language["en"].basketball.noshow}
   </div>
  );
 }

 return (
  <div className="text-text-white">
   <table className="fixture" cellSpacing={0} cellPadding={0}>
    <tbody className="table-row-group border-inherit items-center">
     {liveData.map((league, index) => {
      const country =
       league.Events && league.Events.length > 0
        ? league.Events[0].CategoryName
        : "";

      const teams = league.Events && league.Events.Name;

      return (
       <FixtureLeague
       setcouponchange={setcouponchange}
        key={index}
        leagueName={league?.Name}
        country={country}
        matches={league.matches}
        setSelectedOdds={setSelectedOdds}
        selectedOdds={selectedOdds}
        teams={teams}
        league={league.Events}
        click={click}
        filterId={filterId}
        socket={socket}
        leagueISO={league?.ISO}
        navbarStyle={navbarStyle}
        backgroundColor={backgroundColor}
       />
      );
     })}
    </tbody>
   </table>
   <div
    style={{
     position: "absolute",
     top: "-30px",
     left: "970px",
     zIndex: "9999",
    }}
   >
    <Coupon selectedOdds={selectedOdds} setSelectedOdds={setSelectedOdds} couponchange={couponchange} setcouponchange={setcouponchange} />
   </div>
  </div>
 );
}

const mapStateToProps = (state) => {
 return {
  backgroundColor: state.backgroundColor,
  textColor: state.textColor,
 };
};

export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(
 Basketball
);
