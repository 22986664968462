import axios from "axios";
import React, { useEffect, useState } from "react";
import { dailyReportUserInputValueUpdate } from "../../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from '../../language.json'
const DailyUsersInput = ({
  dailyReportUserInput,
  dailyReportUserInputValueUpdate,
  textColor
}) => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";

  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);

  return (
    <div>
      <span className="text-[13px] font-semibold py-[5px] px-[3px]">{Language["en"].dailyUsersInput.users}</span>

      <select
        onChange={(e) => {
          const selectedValue = e.target.value;
          dailyReportUserInputValueUpdate(selectedValue);
        }}
        value={dailyReportUserInput}
        className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] sm:w-[150px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
        onFocus={(e) => {
          e.target.style.borderColor = `${textColor}`;
        }}
        onBlur={(e) => {
          e.target.style.borderColor = "#999";
          e.target.style.boxShadow = "none";
        }}
      >
        <option value="">{Language["en"].dailyUsersInput.usersInputAll}</option>
        {users?.map((item) => (
          <React.Fragment key={item.user_id}>
            <option value={item.user_id}>{item.username}</option>
            {item.community.length > 0 &&
              item.community.map((communityItem) => (
                <option
                  key={communityItem.user_id}
                  value={communityItem.user_id}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{communityItem.username}
                </option>
              ))}
          </React.Fragment>
        ))}
      </select>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dailyReportUserInput: state.dailyReportUserInput,
});

const mapDispatchToProps = {
  dailyReportUserInputValueUpdate,
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyUsersInput);
