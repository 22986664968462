import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  ErrorAlert,
  ErrorUnAlert,
  MyUsersBets,
  CreatingSubUser,
  MyUsersUpdateLoginInfo,
  MyUsersWithWorkSystem,
} from "../index";
import { setBackgroundColor,setTextColor } from "../../redux/actions/actions";
import Cookies from "js-cookie";
import Language from "../../language.json";
const MyUsersUpdate = ({
  backgroundColor,
  newUsersAgencyLimit,
  newUsersSuperLimit,
  newUsersDealerLimit,
  newUsersPlayerLimit,
  textColor
}) => {
  const { user_id } = useParams();

  const [sportGames, setSportGames] = useState([]);
  const [videoGames, setVideoGames] = useState([]);
  const [virtualGames, setVirtualGames] = useState([]);
  const [editUsername, setEditUsername] = useState("");
  const [rememberName, setRememberName] = useState("");
  const [deduction, setDeduction] = useState(0);
  const [comissionType, setComissionType] = useState([]);
  const [mainType1, setMainType1] = useState("");
  const [mainType2, setMainType2] = useState("");
  const [mainType3, setMainType3] = useState("");
  const [mainType4, setMainType4] = useState("");
  const [mainType1Rule, setMainType1Rule] = useState("");
  const [mainType2Rule, setMainType2Rule] = useState("");
  const [mainType3Rule, setMainType3Rule] = useState("");
  const [mainType4Rule, setMainType4Rule] = useState("");
  const [allBets, setAllBets] = useState([]);
  const [myUsersUpdatePassword, setMyUsersUpdatePassword] = useState("");
  const [alert, setAlert] = useState(null);
  const [errorAlert,setErrorAlert] = useState(null)
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  const dispatch = useDispatch();
  const selectedGames2 = useSelector((state) => state.selectedGames2);
  const [myUsersUpdateUsername,setMyUsersUpdateUsername] = useState("")
  const [myUsersUpdateRememberName,setMyUsersUpdateRememberName] = useState("")
  const [myUsersUpdateDeduction,setMyUsersUpdateDeduction] = useState("")
  const [allreadyuserlimit,setallreadyuserlimit]=useState("")
  const [allreadydealerlimit,setallreadydealerlimit]=useState("")
  const [allreadyagencylimit,setallreadyagencylimit]=useState("")
  const [allreadysuperlimit,setallreadysuperlimit]=useState("")
  const [limits,setlimits] = useState([])
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/on_off_games";
  const apilimitUrl = process.env.REACT_APP_API_URL + '/api/getuserlimits'; 
  const apiGetUrl =
    process.env.REACT_APP_API_URL + `/api/getuserdetail/${user_id}`;
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSportGames(response.data.sport_games);
        setVideoGames(response.data.video_games);
        setVirtualGames(response.data.virtual_games);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
      axios.get(apilimitUrl,{
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setlimits(response.data);
        
      })
      .catch((error) => {
        console.error("API Error:", error);
      })
  }, []);

  useEffect(() => {
    axios
      .get(apiGetUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRememberName(response.data.remember_name);
        setDeduction(response.data.earning_deduction);
        setEditUsername(response.data.username);
        setComissionType(response.data.user_comission);
        setallreadyuserlimit(response.data.user_created_limit);
        setallreadydealerlimit(response.data.dealer_created_limit);
        setallreadyagencylimit(response.data.agency_created_limit);
        setallreadysuperlimit(response.data.super_created_limit);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  const updateUser = () => {
    
    const token = Cookies.get("token");
    const apiPostUrl = `https://rona.thoth.lt/api/updateuser/${user_id}`;

    const comission = [
      {
        comission_main_type: 1,
        comission_type: mainType1,
        comission_rule: parseInt(mainType1Rule) ? parseInt(mainType1Rule) : 25,
      },
      {
        comission_main_type: 2,
        comission_type: mainType2,
        comission_rule: parseInt(mainType2Rule) ? parseInt(mainType2Rule) : 25,
      },
      {
        comission_main_type: 3,
        comission_type: mainType3,
        comission_rule: parseInt(mainType3Rule) ? parseInt(mainType3Rule) : 25,
      },
      {
        comission_main_type: 4,
        comission_type: mainType4,
        comission_rule: parseInt(mainType4Rule) ? parseInt(mainType4Rule) : 25,
      },
    ];

    const postData = {
      username: myUsersUpdateUsername,
      pass: myUsersUpdatePassword,
      note: myUsersUpdateRememberName,
      user_created_limit:parseInt(newUsersPlayerLimit),
      agency_created_limit:parseInt(newUsersAgencyLimit),
      super_created_limit:parseInt(newUsersSuperLimit),
      dealer_created_limit:parseInt(newUsersDealerLimit),
      earning_deduction: Number(myUsersUpdateDeduction),
      comission: comission,
      sport_games: sportGames,
      video_games: videoGames,
      virtual_games: virtualGames,
    };

    if (myUsersUpdateUsername.trim() === "") {
      delete postData.username;
    }

    if (myUsersUpdatePassword.trim() === "") {
      delete postData.pass;
    }

    if (myUsersUpdateRememberName.trim() === "") {
      delete postData.remembername;
    }

    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data === "ok"){
          setAlert("İşlem Başarılı");
        } 
        if(response.data.message){
          setAlert(response.data.message);
        }
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => {
        console.error("API ERROR", error);
      });
  };
  return (
    <div>
      {alert && <Alert alert={alert} setAlert={setAlert} />}
      {
        errorAlert && 
        <ErrorAlert
        errorAlert={errorAlert}
        setErrorAlert={setErrorAlert}
        />
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language["en"].myUsersUpdate.title}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="bg-[#fff]">
          <div className="flex flex-wrap w-[100%]">
            <div className="flex-1 max-w-[400px]">
              <MyUsersUpdateLoginInfo
                user_id={user_id}
                editUsername={editUsername}
                deduction={deduction}
                rememberName={rememberName}
                setMyUsersUpdatePassword={setMyUsersUpdatePassword}
                myUsersUpdatePassword={myUsersUpdatePassword}
                myUsersUpdateUsername={myUsersUpdateUsername}
                setMyUsersUpdateUsername={setMyUsersUpdateUsername}
                setMyUsersUpdateRememberName={setMyUsersUpdateRememberName}
                myUsersUpdateRememberName={myUsersUpdateRememberName}
                myUsersUpdateDeduction={myUsersUpdateDeduction}
                setMyUsersUpdateDeduction={setMyUsersUpdateDeduction}
              />
            </div>
            {Number(Cookies.get("user_type")) !== 5 && (
              <div className="flex-1">
              <CreatingSubUser 
              textColor={textColor}
              backgroundColor={backgroundColor}
              allreadyuserlimit={allreadyuserlimit}
              allreadydealerlimit={allreadydealerlimit}
              allreadyagencylimit={allreadyagencylimit}
              allreadysuperlimit={allreadysuperlimit}
              limits={limits}
              />
               {Cookies.get("user_type") == 7 || Cookies.get("user_type")== 5 ?
                <MyUsersWithWorkSystem
                  user_id={user_id}
                  mainType1={mainType1}
                  setMainType1={setMainType1}
                  mainType2={mainType2}
                  setMainType2={setMainType2}
                  mainType3={mainType3}
                  setMainType3={setMainType3}
                  mainType4={mainType4}
                  setMainType4={setMainType4}
                  mainType1Rule={mainType1Rule}
                  setMainType1Rule={setMainType1Rule}
                  mainType2Rule={mainType2Rule}
                  setMainType2Rule={setMainType2Rule}
                  mainType3Rule={mainType3Rule}
                  setMainType3Rule={setMainType3Rule}
                  mainType4Rule={mainType4Rule}
                  setMainType4Rule={setMainType4Rule}
                />:""}
              </div>
            )}

            <div
              className={`flex-1 ${
                Number(Cookies.get("user_type")) !== 5 ? "max-w-[420px]" : ""
              }`}
            >
              <MyUsersBets
                selectedGames2={selectedGames2}
                dispatch={dispatch}
                user_id={user_id}
                allBets={allBets}
                setAllBets={setAllBets}
                sportGames={sportGames}
                setSportGames={setSportGames}
                videoGames={videoGames}
                setVideoGames={setVideoGames}
                virtualGames={virtualGames}
                setVirtualGames={setVirtualGames}
              />
            </div>
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <Link>
            <button
              onClick={updateUser}
              className="forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] text-[#fff] cursor-pointer transition-all duration-300"
              style={{backgroundColor:backgroundColor}}
            >
              {Language["en"].myUsersUpdate.updateButton}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  myUsersUpdateAllSystem: state.myUsersUpdateAllSystem,
  myUsersUpdateAllSystemRule: state.myUsersUpdateAllSystemRule,
  myUsersUpdatePreMatch: state.myUsersUpdatePreMatch,
  myUsersUpdatePreMatchRule: state.myUsersUpdatePreMatchRule,
  myUsersUpdateLive: state.myUsersUpdateLive,
  newUsersPlayerLimit: state.newUsersPlayerLimit,
  newUsersAgencyLimit: state.newUsersAgencyLimit,
  newUsersSuperLimit: state.newUsersSuperLimit,
  newUsersDealerLimit: state.newUsersDealerLimit,
  myUsersUpdateLiveRule: state.myUsersUpdateLiveRule,
  myUsersUpdateVideoGame: state.myUsersUpdateVideoGame,
  myUsersUpdateVideoGameRule: state.myUsersUpdateVideoGameRule,
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

const mapDispatchToProps = {
  setBackgroundColor,
  setTextColor
};
export default connect(mapStateToProps, mapDispatchToProps)(MyUsersUpdate);
