import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
function VideoGamesAll({ name, brandId, selecTypeId, brandName }) {


 const [gameData, setGameData] = useState([]);
 const typeId = 2;
 const token = Cookies.get("token");
 const apiUrl = "https://rona.thoth.lt/api/video_game_list";
 const currentUrl = window.location.href;
 const handleGameClick = (id) => {
  
  axios
  .post(
   "https://rona.thoth.lt/api/casino/authplay",
   { game_id: id, callback: currentUrl },
   {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   }
  )
  .then((response) => {
    if(response.data.success === "true"){
      window.open(response.data.url, "_blank");
    }else{
      
    }
   
  })
  .catch((error) => {
   console.error(error);
  });

 };
 useEffect(() => {
  if (name.trim() !== "") {
   const requestData = { type_id: selecTypeId, name: name, brand_id: brandId };

   axios
    .post(apiUrl, requestData, {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    })
    .then((response) => {
     setGameData(response.data);
    })
    .catch((error) => {
     console.error(error);
    });
  } else {
   axios
    .post(
     apiUrl,
     { type_id: typeId, brand_id: brandId },
     {
      headers: {
       Authorization: `Bearer ${token}`,
      },
     }
    )
    .then((response) => {
     setGameData(response.data);
    })
    .catch((error) => {
     console.error(error);
    });
  }
 }, [name, token, brandId, selecTypeId]);

 return (
  <div>
   <div
    style={{ paddingBottom: "0px" }}
    className="text-[34px] font-bold text-white py-[15px] px-[25px]"
   >
    {brandName ? brandName : "All Video Games"}
   </div>
   <div className="flex flex-wrap p-[15px] items-start">
    {gameData.map((game) => (
     <div
      key={game.id}
      onClick={() => handleGameClick(game.id)}
      className="w-[20%] h-[170px] relative bg-[#111] hover:bg-[#217cbc] hover:shadow-[#217cbc] cursor-pointer"
     >
      <div className="text-white text-[16px] relative z-[999] flex p-[10px] items-end h-[100%] shadow-slot-free-spin transition-all">
       {game.name}
      </div>
      <div
       style={{ backgroundImage: `url(${game.img})` }}
       className="absolute left-[2px] top-[2px] right-[2px] bottom-[2px] bg-cover bg-no-repeat border border-[1px solid rgba(255,255,255,.4)]"
      ></div>
     </div>
    ))}
   </div>
  </div>
 );
}

export default VideoGamesAll;
