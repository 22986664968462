import React, { useState, useEffect } from "react";
import "../mobile/mobile.css";
import { FaPlus } from "react-icons/fa";
import MobileNavbar from "./MobileNavbar";
import MobileMenu from "./MobileMenu";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import MobileKupon from "./MobileKupon";
import { useSelectedOdds } from "./SelectedOddsContext";
import io from "socket.io-client";

Modal.setAppElement("#root");

function MobileResults() {
 const [fixtureData, setFixtureData] = useState([]);
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [selectedMatch, setSelectedMatch] = useState(null);
 const { setSelectedOdds } = useSelectedOdds();
 const { addSelectedOdds } = useSelectedOdds();

 useEffect(() => {
  const socket = io('http://localhost:8083', { transports: ['websocket'] });

  socket.on("connect", () => {
    console.log("Socket bağlantısı başarılı.");
  });

  socket.on("fixtureData", (data) => {
    setFixtureData(data);
  });

  socket.on("disconnect", () => {
    console.log("Socket bağlantısı kesildi.");
  });
}, []);

 const openModal = (match) => {
  setSelectedMatch(match);
  setIsModalOpen(true);
 };

 const closeModal = () => {
  setIsModalOpen(false);
 };
 const selectOdds = (selectedOdd, selectedMatch) => {
  const oddsWithMatchInfo = { ...selectedOdd, match: selectedMatch };
  addSelectedOdds(oddsWithMatchInfo);
};

 return (
  <div>
   <MobileNavbar />
   <MobileMenu />
   <div className="bulten h-[1000px]">
    {fixtureData.map((league, leagueIndex) => (
     <div key={leagueIndex}>
      <div className="country-name">
       <div className="countryinfo">
        <i className="chFlag"></i>
        <span>
         <b>{league.country}</b>
         <span>{league.leagueName}</span>
        </span>
       </div>
      </div>

      <div className="matchlines">
       {league.matches.map((match, matchIndex) => (
        <div className="matchmore" key={matchIndex}>
         <a href="#" className="morebet" onClick={() => openModal(match)}>
          <FaPlus className="text-white" />
         </a>
         <div className="matchname">
          <span>{match.teams}</span>
          <span>{match.date}</span>
         </div>
         <div className="intheodds">
          <table className="matchodds">
           <tbody>
            <tr className="thead livethead">
             <td colSpan={3}>Maç Sonucu</td>
             <td colSpan={6}>Alt/Üst</td>
             <td colSpan={4}>İlk Yarı Alt/Üst</td>
             <td colSpan={2}>Karşılıklı Gol</td>
            </tr>
            <tr className="head">
            {match.odds.map((odd, oddIndex) => (
  <td
    key={oddIndex}
    onClick={() => selectOdds(odd, match)}
    className="clickable-odd"
  >
    {odd.title} {odd.value}
  </td>
))}
            </tr>
           </tbody>
          </table>
         </div>
        </div>
       ))}
      </div>
     </div>
    ))}
   </div>
   <div>
    <Modal isOpen={isModalOpen} className="morebets">
     <div className="">
      <div className="inmorebet">
       <div className="morebetheader">
        <div className="morebet-title"></div>
        <div className="slip-close text-center mt-1" onClick={closeModal}>
         <AiOutlineClose className="ml-1.5 mt-0.5" />
        </div>
       </div>
       <div className="morebet-teamnames">
        <div className="tlig">
         {selectedMatch && selectedMatch.teams && (
          <div>
           <span>{selectedMatch.date}</span>
          </div>
         )}
         {selectedMatch && selectedMatch.teams && (
          <div className="tname">{selectedMatch.teams}</div>
         )}
        </div>
       </div>
       {selectedMatch && selectedMatch.teams && (
        <div className="morebet-odds">
         <div className="oranbox">
          <div className="orantip">Maç Sonucu</div>
          <div className="subodds">
           <div className="suboddstable">
            <ul>
             <li>
              <div className="odds">
               <span>Ev Sahibi</span>
               <span>
                {selectedMatch &&
                 selectedMatch.odds.find((odd) => odd.title === "1")?.value}
               </span>
              </div>
             </li>
            </ul>
            <ul>
             <li>
              <div className="odds">
               <span>Berabere</span>
               <span>
                {selectedMatch &&
                 selectedMatch.odds.find((odd) => odd.title === "X")?.value}
               </span>
              </div>
             </li>
            </ul>
            <ul>
             <li>
              <div className="odds">
               <span>Deplasman</span>
               <span>
                {selectedMatch &&
                 selectedMatch.odds.find((odd) => odd.title === "2")?.value}
               </span>
              </div>
             </li>
            </ul>
           </div>
          </div>
         </div>
         {/* Diğer bahis türleri */}
         <div className="oranbox">
          <div className="orantip">Handikaplı</div>
          <div className="subodds">
           <div className="suboddstable">
            <ul>
             <li>
              <div className="odds">
               <span>Ev Sahibi</span>
               <span>
                {" "}
                {selectedMatch &&
                 selectedMatch.otherOdds.find(
                  (odd) => odd.title === "-1 Ev Sahibi"
                 )?.value}
               </span>
              </div>
             </li>
            </ul>
            <ul>
             <li>
              <div className="odds">
               <span>Deplasman</span>
               {selectedMatch && (
                <span >
                 {
                  selectedMatch.otherOdds.find(
                   (odd) => odd.title === "+1 Ev Sahibi"
                  )?.value
                 }
                </span>
               )}
              </div>
             </li>
            </ul>
           </div>
          </div>
         </div>
         {/* Diğer bahis türleri */}
        </div>
       )}
      </div>
     </div>
    </Modal>
   </div>
  </div>
 );
}

export default MobileResults;
