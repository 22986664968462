import React from 'react'
import '../mobile/mobile.css'
import { Link } from 'react-router-dom'
import {BsListCheck, BsFillPlayFill} from 'react-icons/bs'
import {MdOutlineCasino} from 'react-icons/md'
import {RiCoupon5Line} from 'react-icons/ri'
import {FiSettings} from 'react-icons/fi'

function MobileFooter() {
  return (
    <div className='footer'>
        <div className="userbar">
            <div className="username">Leon13</div>
            <div className="footerlinks">
                <a href="" className='logout'>Log Out</a>
                <a href="" className='desktop'>Desktop</a>
            </div>
            <div className="balance">
                <span className='balance-info'>0.00</span>
            </div>
        </div>
        <div className="footer-menu">
            <Link className='fmenu' to="/Result">
                <BsListCheck className='text-[28px] text-[#ffcc00]'/>
                Bahisler
            </Link>
            <Link className='fmenu' to="/Canlı">
                <BsFillPlayFill className='text-[28px] text-[#ffcc00]'/>
                Canlı
            </Link>
            <Link className='fmenu' to="/Casino">
                <MdOutlineCasino className='text-[28px] text-[#ffcc00]'/>
                Casino
            </Link>
            <Link className='fmenu' to="/Kupon">
                <RiCoupon5Line className='text-[28px] text-[#ffcc00]'/>
                Kuponlar
            </Link>
            <Link className='fmenu' to="/Hesap">
                <FiSettings className='text-[28px] text-[#ffcc00]'/>
                Hesabım
            </Link>
        </div>
    </div>
  )
}

export default MobileFooter