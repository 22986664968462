import axios from "axios";
import React, { useEffect, useState } from "react";
import { openingLimitValueUpdate,setBackgroundColor } from "../redux/actions/actions";
import {Alert, ErrorAlert, ErrorUnAlert, IsLoading} from './index'
import { connect } from "react-redux";
import Language from '../language.json'
import Cookies from "js-cookie";
const PersonOpeningLimit = ({
  openingLimit,
  backgroundColor
}) => {
  const lang = "en"
  const token = Cookies.get("token")
    const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";
    const updateUrl = process.env.REACT_APP_API_URL + "/api/updateuser"
    const [users, setUsers] = useState([]);
    const [limits,setlimits] = useState([])
    const [userOpeningLimits, setUserOpeningLimits] = useState({});
    const [isLoading,setIsLoading] = useState(true)
    const [sortedUser,setSortedUser] = useState([])
    const [searchTerm,setSearchTerm] = useState("")
    const [successMessage,setSuccessMessage] = useState({})
    const [errorAlert, setErrorAlert] = useState(null);
    const [errorUnAlert, setErrorUnAlert] = useState(null);
    const apilimitUrl = process.env.REACT_APP_API_URL + '/api/getuserlimits'; 
    useEffect(() => {
      axios
        .get(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUsers(response.data);
          setIsLoading(false)
          const initialLimits = {};
        response.data.forEach((user) => {
          initialLimits[user.user_id] = {
            user_created_limit: user.user_created_limit,
            agency_created_limit: user.agency_created_limit,
            super_created_limit: user.super_created_limit,
            dealer_created_limit: user.dealer_created_limit,
            delete_rule: user.delete_rule,
          };
        });
        setUserOpeningLimits(initialLimits);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
      axios.get(apilimitUrl,{
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setlimits(response.data);
        
      })
      .catch((error) => {
        console.error("API Error:", error);
      })
  }, []);
  
  const saveChanges = (userId) => {
    const apiUrl = `${updateUrl}/${userId}`;
    const postData = {
      user_created_limit: parseInt(userOpeningLimits[userId].user_created_limit) || 0,
      dealer_created_limit: parseInt(userOpeningLimits[userId].dealer_created_limit) || 0,
      agency_created_limit: parseInt(userOpeningLimits[userId].agency_created_limit) || 0,
      super_created_limit: parseInt(userOpeningLimits[userId].super_created_limit) || 0,
      delete_rule: userOpeningLimits[userId].delete_rule || 0,
    };
    
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSuccessMessage((prevMessages) => ({
          ...prevMessages,
          [userId]: true
        }))
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
        setTimeout(()=>{
          setSuccessMessage((prevMessages) =>({
            ...prevMessages,
            [userId]: false
          }))
        },2000)
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };

  const handleOpeningLimitChange = (userId, newValue) => {
    setUserOpeningLimits((prevLimits) => ({
      ...prevLimits,
      [userId]: {
        ...prevLimits[userId],
        user_created_limit: newValue,
      },
    }));
  };
  const handleOpeningAgencyLimitChange = (userId, newValue) => {
    setUserOpeningLimits((prevLimits) => ({
      ...prevLimits,
      [userId]: {
        ...prevLimits[userId],
        agency_created_limit: newValue,
      },
    }));
  };
  const handleOpeningDealerLimitChange = (userId, newValue) => {
    setUserOpeningLimits((prevLimits) => ({
      ...prevLimits,
      [userId]: {
        ...prevLimits[userId],
        dealer_created_limit: newValue,
      },
    }));
  };
  const handleOpeningSuperLimitChange = (userId, newValue) => {
    setUserOpeningLimits((prevLimits) => ({
      ...prevLimits,
      [userId]: {
        ...prevLimits[userId],
        super_created_limit: newValue,
      },
    }));
  };

  const handleDeleteRuleChange = (userId, newValue) => {
    setUserOpeningLimits((prevLimits) => ({
      ...prevLimits,
      [userId]: {
        ...prevLimits[userId],
        delete_rule: newValue ? 1 : 0,
      },
    }));
  };

  useEffect(() => {
    users.sort((a,b) => a.username.localeCompare(b.username))
    setSortedUser([...users])
  },[users])
  const user_type = Cookies.get("user_type");
  const filteredUsers = sortedUser.filter( user => user.username.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <div>
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language[lang].PersonOpeningLimitPage.title}
      </div>
      <div className="pt-0 flex flex-col p-[10px] bg-[#ddd] rounded-b-[20px]">
        <div className="py-[5px]">
          <input
            type="text"
            className="forms-select-box-shadow p-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer text-[13px] bg-[#f9f9f9]"
            placeholder="search username"
            value={searchTerm}
            onChange={(e)=>setSearchTerm(e.target.value)}
          />
        </div>
        <table className="w-[100%]">
          <thead>
            <tr>
              <td className="w-[200px] py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]"></td>
              {user_type !=5 && user_type !=9  && (
                <>
              <td className="text-[13px] w-[130px] py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]">
                <div className="flex flex-col leading-[14px] justify-center items-center">
                  {Language[lang].PersonOpeningLimitPage.titlePlayer}
                  <span>({
                    user_type !=2 ?
                  limits.web_created_limit:""
                  })</span>
                </div>
              </td>
              {user_type == 1 || user_type == 3 || user_type == 2 ? (
              <td className="text-[13px] w-[130px] py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]">
              <div className="flex flex-col leading-[14px] justify-center items-center">
                  {Language[lang].PersonOpeningLimitPage.titleDealer}
                  <span>({
                   user_type !=2 ?
                  limits.dealer_created_limit:""})</span>
                </div>
              </td>
              ):""}
              {user_type == 1 || user_type == 2 ? (
              <td className="text-[13px] w-[130px] py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]">
              <div className="flex flex-col leading-[14px] justify-center items-center">
                  {Language[lang].PersonOpeningLimitPage.titleAgency}
                  <span>({
                   user_type !=2 ?
                  limits.admin_created_limit:""})</span>
                </div>
              </td>
              ):""}
              {user_type == 2 ? (
              <td className="text-[13px] w-[130px] py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]">
              <div className="flex flex-col leading-[14px] justify-center items-center">
                  {Language[lang].PersonOpeningLimitPage.titleSuper}
                  <span>({
                   user_type !=2 ?
                  limits.super_created_limit:""})</span>
                </div>
              </td>
              ):""}
              </>
              )}
              <td className="py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]"></td>
              <td className="py-[15px] px-[10px] h-[60px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-[#fff]"></td>
            </tr>
          </thead>
          <tbody>
        {isLoading ?  
            <div className='flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center'> <IsLoading/> </div>
          : filteredUsers?.map((user) => (
          <tr key={user.user_id}>
            <td className="py-[4px] px-[8px] bg-[#f9f9f9] font-semibold text-[13px]">
              {user.username}
            </td>
            {user_type !=5 && user_type !=9 && (
              <>
            <td className=" py-[4px] px-[8px] bg-[#f9f9f9] font-bold text-[13px]">
              <div className="flex items-center">
                <input
                  type="number"
                  className="forms-select-box-shadow h-[37px] w-[70px] mr-[5px] text-center text-[14px] p-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#222]"
                  value={userOpeningLimits[user.user_id] !== undefined ? userOpeningLimits[user.user_id].user_created_limit : openingLimit}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleOpeningLimitChange(user.user_id, selectedValue);
                  }}
                />
                <button
                  onClick={() => saveChanges(user.user_id)}
                  className={`forms-button text-center p-[5px] ml-3 w-[50px] bg-[${backgroundColor}] text-[12px]  rounded-[5px] text-white`}
                >
                {Language[lang].PersonOpeningLimitPage.textSave}

                </button>
                {
                  successMessage[user.user_id] && 
                  <div className="ml-2 italic text-green-600">Saved</div>
                }
              </div>
            </td>
            {user_type == 1 || user_type == 3 || user_type == 2 ? (
            <td className=" py-[4px] px-[8px] bg-[#f9f9f9] font-bold text-[13px]">
              <div className="flex items-center">
                <input
                  type="number"
                  className="forms-select-box-shadow h-[37px] w-[70px] mr-[5px] text-center text-[14px] p-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#222]"
                  value={userOpeningLimits[user.user_id] !== undefined ? userOpeningLimits[user.user_id].dealer_created_limit : openingLimit}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleOpeningDealerLimitChange(user.user_id, selectedValue);
                  }}
                />
                <button
                  onClick={() => saveChanges(user.user_id)}
                  className={`forms-button text-center p-[5px] ml-3 w-[50px] bg-[${backgroundColor}] text-[12px]  rounded-[5px] text-white`}
                >
                {Language[lang].PersonOpeningLimitPage.textSave}

                </button>
                {
                  successMessage[user.user_id] && 
                  <div className="ml-2 italic text-green-600">Saved</div>
                }
              </div>
            </td>
            ):""}
            {user_type == 1 || user_type == 2  ? (
            <td className=" py-[4px] px-[8px] bg-[#f9f9f9] font-bold text-[13px]">
              <div className="flex items-center">
                <input
                  type="number"
                  className="forms-select-box-shadow h-[37px] w-[70px] mr-[5px] text-center text-[14px] p-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#222]"
                  value={userOpeningLimits[user.user_id] !== undefined ? userOpeningLimits[user.user_id].agency_created_limit : openingLimit}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleOpeningAgencyLimitChange(user.user_id, selectedValue);
                  }}
                />
                <button
                  onClick={() => saveChanges(user.user_id)}
                  className={`forms-button text-center p-[5px] ml-3 w-[50px] bg-[${backgroundColor}] text-[12px]  rounded-[5px] text-white`}
                >
                {Language[lang].PersonOpeningLimitPage.textSave}

                </button>
                {
                  successMessage[user.user_id] && 
                  <div className="ml-2 italic text-green-600">Saved</div>
                }
              </div>
            </td>
            ):""}
                        { user_type == 2  ? (
            <td className=" py-[4px] px-[8px] bg-[#f9f9f9] font-bold text-[13px]">
              <div className="flex items-center">
                <input
                  type="number"
                  className="forms-select-box-shadow h-[37px] w-[70px] mr-[5px] text-center text-[14px] p-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#222]"
                  value={userOpeningLimits[user.user_id] !== undefined ? userOpeningLimits[user.user_id].super_created_limit : openingLimit}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleOpeningSuperLimitChange(user.user_id, selectedValue);
                  }}
                />
                <button
                  onClick={() => saveChanges(user.user_id)}
                  className={`forms-button text-center p-[5px] ml-3 w-[50px] bg-[${backgroundColor}] text-[12px]  rounded-[5px] text-white`}
                >
                {Language[lang].PersonOpeningLimitPage.textSave}

                </button>
                {
                  successMessage[user.user_id] && 
                  <div className="ml-2 italic text-green-600">Saved</div>
                }
              </div>
            </td>
            ):""}
            </>
            )}
            <td className="bg-[#f9f9f9]"></td>
            <td className="bg-[#f9f9f9]"></td>
            <td className="bg-[#f9f9f9]"></td>
            <td className="bg-[#f9f9f9]"></td>
          </tr>
        ))}
      </tbody>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  openingLimit: state.openingLimit,
  backgroundColor: state.backgroundColor
});

const mapDispatchToProps = {
  openingLimitValueUpdate,
  setBackgroundColor
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonOpeningLimit);
