import React, { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import { connect } from "react-redux";
import { setBackgroundColor, setTextColor,setPanel } from "../redux/actions/actions";
import { Link, useNavigate } from "react-router-dom";
import { TbArrowsExchange } from "react-icons/tb";
import "./desktop.css";
import NavbarLinks from "./NavbarLinks";
import axios from "axios";
import Cookies from "js-cookie";
import Language from "../language.json";
import Slot from "../assest/images/slot.gif";

function Navbar({
  backgroundColor,
  setBackgroundColor,
  textColor,
  setTextColor,
  setPanel,
  panel
}) {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/balance";
  const apiUrl3 = process.env.REACT_APP_API_URL + "/api/logout";

  const [activeCategory, setActiveCategory] = useState(null);
  const [balance, setBalance] = useState([]);
  const [videoCasino,setVideoCasino] = useState()
  const [videoSlot,setVideoSlot] = useState()
  const [domains, setdomains] = useState("");
  let width=window.innerWidth;
  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const domain = url.hostname;
    setdomains(domain.replace("www.",""));

  }, []);
  
  const usertype = Cookies.get("user_type")
  const navigate = useNavigate();
  function handleMobile() {
    let cleanedDomain="https://m."+domains;
    window.location.href = cleanedDomain;
  }
  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  function decodeBase64UTF8(encodedStr) {
  return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
  useEffect(() => {
    const base64EncodedString = Cookies.get("gameConfig");
    const decodedString = decodeBase64UTF8(base64EncodedString);
    const gameConfigObject = JSON.parse(decodedString);
    gameConfigObject?.video_games?.map((video) => (
      video.id === "3" ? setVideoCasino(video.active) : 
      video.id === "4" ? setVideoSlot(video.active) : ""
    ))
  },[])

  function getbalance() {
    if (Cookies.get("token") !== null) {
      axios
        .post(apiUrl, {
          user_type: usertype
        }, {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        })
        .then((response) => {
          if (response.data.errorUn) {
            Cookies.remove("token");
           window.location.href="/login";
          } else {
            setBalance(response.data);
          }
        })
        .catch((error) => {
          console.error("API ERROR:", error);
          Cookies.remove("token");
          Cookies.remove("panel")
          window.location.href="/login";
        });
    } else {
      window.location.href="/login";
    }
  }

  useEffect(() => {
    if (Cookies.get("token")) {
      getbalance();
      setInterval(() => {
        getbalance();
      }, 10000);
    } else {
      window.location.href="/login";
    }
  },[]);

  const handleExitClick = () => {
    
    axios.post(apiUrl3,{
      headers: { Authorization: `Bearer ${token}` },
    })
    Cookies.remove("token");
    Cookies.remove("username");
    Cookies.remove("user_type")
    Cookies.remove("panel")
    window.location.href="/login";

  };

  const handleCategoryClick = (category) => {
    if (activeCategory === category) {
      setActiveCategory(null);
    } else {
      setActiveCategory(category);
    }
  };

  const handleButtonClick = (color) => {
    setBackgroundColor(color);
    setTextColor(color);
    localStorage.setItem("user_color",color)
  };

  const navbarStyle = {
    backgroundColor: backgroundColor,
  };
  const textStyle = {
    color: textColor,
  };

  useEffect(() => {
    Cookies.set("panel",panel,{ path: '/', domain: domains })
  },[panel])

  return (
    <div
      style={{ padding: "0px" }}
      className="container bg-[#f0f0f0] mx-auto mt-[20px] relative rounded-2xl h-full block items-center justify-center"
    >
      <div className="flex items-center justify-between w-full pb-5 pr-5">
        <div className="flex items-center">
          <div onClick={() => setPanel(1)}>
            <Link to={"/"}>
              <div className="flex mt-[12px] flex-col text-center tracking-[-1px] leading-[25px] pt-[10px] px-[30px] h-[100%] cursor-pointer">
                <b className="text-[34px] font-bold">Rona V2</b>
                <p className="text-[9px] tracking-[5px]">MASTER</p>
              </div>
            </Link>
          </div>
          <div>
            <div className="userbox relative text-[13px] mt-[20px] font-medium h-[70%] px-[20px] py-[10px] bg-white border border-[#ccc]">
              {Language["en"].navbar.titleWelcome}
              <div className="flex gap-2">
                <p className="font-semibold" style={textStyle}>
                  {Cookies.get("username")}
                </p>
                <button
                  onClick={handleExitClick}
                  className={`px-2 tracking-wider rounded text-[10px] hover:bg-hover-black text-white`}
                  style={navbarStyle}
                >
                  {Language["en"].navbar.exitButtonText}
                </button>
              </div>
            </div>
          </div>
          {usertype==5 || usertype ==9 ?           <div>
            <div className="userbox relative text-[13px]  font-medium flex flex-col mt-[20px] px-[10px] py-[10px] w-[140px] ml-4 bg-white border border-[#ccc]">
              {Language["en"].navbar.pointText}
              <div className="flex items-center" style={textStyle}>
                <div className="font-bold mr-1 text-[15px]">
                  {balance.balance}
                </div>{" "}
                <div className="mt-0.5">{Language["en"].navbar.pointInfo}</div>
              </div>
            </div>
          </div>:""}

          <div>
            <div className="userbox relative text-[13px] font-medium flex flex-col mt-[20px] px-[10px] py-[10px] w-[140px] ml-4 bg-white border border-[#ccc]">
              {Language["en"].navbar.timeText}
              <div className="flex">
                <p className=" font-semibold" style={textStyle}>
                  {new Date().getMonth() + 1}/{new Date().getDate()}/
                  {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
          <Link className="md:hidden flex">
            <div
              onClick={handleMobile}
              className="bg-mobile text-text-black flex flex-row text-[13px] font-bold py-[10px] px-[5px] rounded-[3px] transition-all-[300ms] ml-[25px] w-[120px] items-center justify-center mt-[20px] hover:bg-mobile-button-hover cursor-pointer border-b-2 border-black border-opacity-20"
            >
              <GiSmartphone />
              {Language["en"].navbar.switchMobile}
            </div>
          </Link>
          {
            (videoCasino === 1 || videoSlot === 1) &&
            <Link to="/video-games">
            <div className="absolute top-[20px] w-[250px] h-[60px] p-[5px] ml-[220px] transition-all flex justify-center bg-[#111] rounded-[5px] border border-b-[#000] shadow-casino-shadow">
              <img
                className="transition-all shadow-slot-gif mr-[25px]"
                style={{ height: "50px" }}
                src={Slot}
                alt=""
              />
              <div className="flex-col">
                <div className="text-white font-bold">VIDEO GAMES</div>
                <div className="text-white text-[12px]">Now active.</div>
              </div>
            </div>
          </Link>
          }

          {Number(Cookies.get("user_type")) !== 9 && (
            <>
              {Number(panel) ? (
                <Link to="/managementPanel">
                  <div
                    className="bg-custom-green text-text-white flex text-[12px] font-bold py-[10px] px-[20px] rounded-[3px] transition-all-[300ms] ml-[25px] w-[180px] items-center justify-center mt-[20px] hover:bg-hover-black cursor-pointer border-b-2 border-black border-opacity-20"
                    style={navbarStyle}
                    onClick={() => setPanel(0)}
                  >
                    <BsFillPersonFill className="mr-1" />
                    {Language["en"].navbar.managmentPanelText}
                  </div>
                </Link>
              )
                :
               
                <Link to="/">
                  <div
                    className="bg-custom-green text-text-white flex text-[12px] font-bold py-[10px] px-[20px] rounded-[3px] transition-all-[300ms] ml-[25px] w-[150px] items-center justify-center mt-[20px] hover:bg-hover-black cursor-pointer border-b-2 border-black border-opacity-20"
                    style={navbarStyle}
                    onClick={() => setPanel(1)}
                  >
                    <TbArrowsExchange
                      className="right-[12px] relative"
                      size={20}
                    />
                    {Language["en"].navbar.backToSiteText}
                  </div>
                </Link>
              
              }
            </>
          )}
        </div>
        <div className="flex flex-col mt-[15px]">
          <p className="text-[10px] font-medium ml-[90px] mb-1">
            {Language["en"].navbar.changeTheme}:
          </p>
          <div className="flex">
            <button
              className="bg-[#BC2121] text-[#BC2121] ml-[10px] cursor-pointer"
              onClick={() => handleButtonClick("#BC2121")}
            >
              ---
            </button>
            <button
              className="bg-[#217cbc] text-[#217cbc] ml-[10px] cursor-pointer"
              onClick={() => handleButtonClick("#217cbc")}
            >
              ---
            </button>
            <button
              className="bg-[#ca8210] text-[#ca8210] ml-[10px] cursor-pointer"
              onClick={() => handleButtonClick("#ca8210")}
            >
              ---
            </button>
            <button
              className="bg-[#2f9015] text-[#2f9015] ml-[10px] cursor-pointer"
              onClick={() => handleButtonClick("#2f9015")}
            >
              ---
            </button>
            <button
              className="bg-[#83154f] text-[#83154f] ml-[10px] cursor-pointer"
              onClick={() => handleButtonClick("#83154f")}
            >
              ---
            </button>
          </div>
        </div>
      </div>
      {Number(panel) === 1 && (
        <NavbarLinks
          navbarStyle={navbarStyle}
          activeCategory={activeCategory}
          handleCategoryClick={handleCategoryClick}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
    textColor: state.textColor,
    panel: state.panel
  };
};

export default connect(mapStateToProps, { setBackgroundColor, setTextColor,setPanel })(
  Navbar
);
