import React, { useState, useEffect } from "react";
import { MdAccountCircle, MdOutlineChecklist } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import {BsFillTrash3Fill} from "react-icons/bs"
import { TbWorld } from "react-icons/tb";
import { GiWorld } from "react-icons/gi";
import Language from '../language.json';
import "./desktop.css";
import { Link } from "react-router-dom";
function LeftMenu({ liveData, handleCatIdClick }) {

  const [selectedCatId, setSelectedCatId] = useState();

  const handleClick = (catId) => {
    catId = String(catId);
    if (selectedCatId?.includes(catId)) {
     
      const arr = selectedCatId.split(",");

      const deletedCatIndex = arr.indexOf(catId);

      if (deletedCatIndex !== -1) {
        arr.splice(deletedCatIndex, 1);

        setSelectedCatId(arr.join(","));
        handleCatIdClick(arr.join(","));
      }
    } else {
      const newValue = selectedCatId ? `${selectedCatId},${catId}` : catId;
      setSelectedCatId(newValue);
      handleCatIdClick(newValue);
    }
  };

  const handleClearMenu = () => {
    setSelectedCatId(null);
    handleCatIdClick(null);
  };

  return (
    <div>
      <div className="w-[200px] bg-[#282828]  text-white  mt-[25px] relative rounded-3xl ml-[20px]">
        <div className="title rounded-2xl rounded-bl-none rounded-br-none flex">
          <MdAccountCircle className="mt-0.5 mr-2 text-[18px]" />
          {Language["en"].leftmenu.myaccount}
        </div>
        <Link to="/all-coupons">
          <div className="links  hover:text-yellow hover:bg-hover-black cursor-pointer">
            <MdOutlineChecklist className="mr-2 mt-0.5 text-md text-yellow" />
            {Language["en"].leftmenu.coupons}
          </div>
        </Link>
        <Link to='/changePassword'>
        <div className="links hover:text-yellow cursor-pointer hover:bg-hover-black">
          <FaKey className="mr-2 mt-0.5 text-md text-yellow" />
          {Language["en"].leftmenu.changepass}
        </div>
        </Link>
        <Link to="/daily-report">
          <div className="links  hover:text-yellow hover:bg-hover-black cursor-pointer">
            <MdOutlineChecklist className="mr-2 mt-0.5 text-md text-yellow" />
            {Language["en"].leftmenu.dailyreports}
          </div>
        </Link>
        <Link to="/account-activite">
          <div className="links rounded-2xl rounded-tl-none rounded-tr-none hover:text-yellow  cursor-pointer hover:bg-hover-black">
            <BiSolidPieChartAlt2 className="mr-2 mt-0.5 text-md text-yellow " />
            {Language["en"].leftmenu.accountactivities}
          </div>
        </Link>
      </div>
      <div className="w-[200px] mt-[20px] absolute rounded-3xl ml-[20px]">
        <div className="title text-white rounded-2xl rounded-bl-none rounded-br-none flex">
          <TbWorld className="mt-0.5 mr-2 text-[18px]" />
          {Language["en"].leftmenu.countries}
        </div>
        <div className="bg-[#282828] text-white">
          <div className="links cursor-pointer hover:text-yellow bg-[#434343] hover:bg-hover-black" onClick={() => handleClearMenu()} >
            <BsFillTrash3Fill className="mr-4 ml-1"/>
            {Language["en"].leftmenu.deselect}
          </div>
          {liveData.Country.sort((a, b) =>
            a.CategoryName.localeCompare(b.CategoryName)
          ).map((league, index) => (
            <div
              key={index}
              className={`links cursor-pointer hover:text-yellow hover:bg-hover-black ${
                selectedCatId?.includes(league.CategoryId)
                  ? "bg-[#ffcc00] text-[#222]"
                  : "#282828"
              }`}
              onClick={() => handleClick(league.CategoryId)}
            >
              {league.ISO ? (
                <img
                  src={require(`../assest/4x3/${league.ISO.toLowerCase()}.svg`)}
                  width={"25px"}
                  alt="svg"
                  className="mr-2"
                />
              ) : (
                <span className="flex items-center mr-3">
                  <GiWorld color="white" size={20} />
                </span>
              )}
              {league.CategoryName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;
