import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Language from '../../language.json'
import { connect } from "react-redux";
import { setTextColor,setBackgroundColor } from "../../redux/actions/actions";
import Cookies from "js-cookie";
const MyUsersDelete = ({textColor,backgroundColor}) => {

    const { user_id } = useParams();

  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";

  const [users, setUsers] = useState([]);
  const [usersUsername,setUsersUsername] = useState("")
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);

  useEffect(() => {
    const matchingUser = users.find((user) => user.user_id == user_id);
    if (matchingUser) {
        setUsersUsername(matchingUser.username);
    }
}, [users, user_id]);

const deleteUser = () => {
    
const apiPostUrl = `https://rona.thoth.lt/api/deleteuser/${user_id}`; 

 const postData = {
   delete: user_id
 } 

  axios
    .post(apiPostUrl, postData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {})
    .catch((error) => {
      console.error("API ERROR", error);
    });
};

const handleClick = () => {
  deleteUser();

  window.location.href = '/my-users';
};

  return (
    <div>
    <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].myUsersDelete.title}
    </div>
    <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
      <div className='text-center mt-5 mb-2 text-[#222]'>{Language["en"].myUsersDelete.message} <b>{usersUsername}</b></div>
      <div className="bg-[#fff] min-h-[30px] text-center p-[15px]" style={{color:textColor}}>{Language["en"].myUsersDelete.messageDesc}</div>
      <div className='flex justify-center gap-4 m-5'>
        <Link to={'/my-users'} ><button className='forms-button p-[10px] bg-[#333333] font-bold text-[#fff] cursor-pointer transition-all duration-300 decoration-none rounded-[5px] outline-none text-[18px] '>{Language["en"].myUsersDelete.messageCancel}</button></Link>
        <Link><button onClick={handleClick} className='forms-button p-[10px] font-bold text-[#fff] cursor-pointer transition-all duration-300 decoration-none rounded-[5px] outline-none text-[18px]' style={{backgroundColor:backgroundColor}}>{Language["en"].myUsersDelete.messageOkay}</button></Link>
      </div>
    </div>
  </div>
  );
};
const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

const mapDispatchToProps = {
  setBackgroundColor,
  setTextColor
};
export default connect(mapStateToProps,mapDispatchToProps)(MyUsersDelete);
