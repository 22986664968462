import React, { useEffect, useState } from "react";
import { AiFillPlusSquare, AiOutlineClose } from "react-icons/ai";
import Coupon from "./Coupon";
import "./desktop.css";
import { connect } from "react-redux";
import { setBackgroundColor, setTextColor } from "../redux/actions/actions";
import Popup from "./Popup";
import { GiWorld } from "react-icons/gi";
import Language from '../language.json'
import Cookies from "js-cookie";

function FixtureMatch({
 date,
 teams,
 setSelectedOdds,
 selectedOdds,
 league,
 click,
 filterId,
 socket,
 setcouponchange
}) {
 const [isPopupOpen, setPopupOpen] = useState(false);
 const [selectedMatches, setSelectedMatches] = useState({});
 let markets = league.Markets;
 const [preData, setPreData] = useState([]);
 const [loading, setLoading] = useState(true);
 function encodeBase64UTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
  }));
}
function decodeBase64UTF8(encodedStr) {
return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
}).join(''));
}
 socket.on("disconnect", function () {
  window.location.reload();
 });
 socket.on("reconnect", function () {
  preDetailClick();
 });
 const preDetailClick = () => {
  socket.emit("pre-detail", Number(league?.EventId));
  socket.on("pre-detail", (data) => {
   setPreData(data);
   setLoading(false);
  });
 };
 const [footballGames,setFootballGames] = useState()

 useEffect(() => {
  const base64EncodedString = Cookies.get("gameConfig");
  const decodedString = decodeBase64UTF8(base64EncodedString);
  const gameConfigObject = JSON.parse(decodedString);
  gameConfigObject?.sport_games?.pre_sports?.map((video) => (
    video.id === "66" ? setFootballGames(video.active) :  ""
  ))
},[])

 if (filterId === "1") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId == filterId || obj.OrgMarketTypeId == 18
     )
   : league.Markets;
 }
 if (filterId === "18") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId == filterId || obj.OrgMarketTypeId == 18
     )
   : league.Markets;
 }
 if (filterId === "29") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId == filterId || obj.OrgMarketTypeId == 10
     )
   : league.Markets;
 }
 if (filterId === "11") {
  markets = filterId
   ? league.Markets.filter(
      (obj) => obj.OrgMarketTypeId == filterId || obj.OrgMarketTypeId == 10
     )
   : league.Markets;
 }
 if (filterId == "10") {
  markets = filterId
   ? league.Markets.filter((obj) => obj.OrgMarketTypeId == filterId)
   : league.Markets;
 }

 const handleOddClick = (clickedOdd, market, league) => {
  const matchId = league?.EventId;
  const clickedOddApiId = clickedOdd.ApiId;
  const isOddSelected = selectedOdds.some(
   (odd) => odd.odd_api_id === clickedOddApiId
  );

  if (isOddSelected) {
   removeOddFromSelection(clickedOddApiId);
   localStorage.removeItem("selectedOdds", clickedOddApiId);
  } else {
   const clickedOddName = clickedOdd.name;
   const teams = league?.Name;
   const marketName = market?.Name;
   const previousSelectedOddIndex = selectedOdds.findIndex(
    (odd) => odd.event_id === matchId
   );

   if (previousSelectedOddIndex !== -1) {
    removeOddFromSelection(selectedOdds[previousSelectedOddIndex].odd_api_id);
   }
   click(clickedOdd?.ApiId);
   const oddInfo = {
    odd_name: clickedOddName,
    market_name: marketName,
    teams: teams,
    date: league.EventDate,
    odd_price: clickedOdd.Price,
    is_live: league?.Status,
    org_market_type:clickedOdd?.OrgMarketTypeId,
    event_id: league.EventId,
    market_api_id: market?.ApiId,
    market_id: market?._id,
    marketSportId: market?.SportMarketId,
    odd_api_id: clickedOddApiId,
    oddSelectionTypeId: clickedOdd?.SelectionTypeId,
    odd_id: clickedOdd?._id,
    api_group_id: market?.api_group_id
   };
   setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, oddInfo]);

   setSelectedMatches({ ...selectedMatches, [matchId]: true });
  }
 };

 const removeOddFromSelection = (clickedOddApiId) => {
  setSelectedOdds((prevSelectedOdds) => {
   return prevSelectedOdds.filter((odd) => odd.odd_api_id !== clickedOddApiId);
  });
  const element = document.getElementById(clickedOddApiId);
  if (element) {
   element.classList.remove("selected");
  }
 };

 const openPopup = () => {
  setTimeout(()=>{
    setPopupOpen(true);
  },80)
 };

 const closePopup = () => {
  setPopupOpen(false);
 };

 const inputDate = new Date(date);

 if (inputDate.getHours() === 0 && inputDate.getMinutes() === 0) {
  inputDate.setHours(0);
 }

 let formattedDate;

 if (inputDate.getHours() === 0 && inputDate.getMinutes() === 0) {
  formattedDate = `${inputDate.toLocaleDateString("tr-TR", {
   month: "2-digit",
   day: "2-digit",
  })} 00:00`;
 } else {
  formattedDate = `${inputDate.toLocaleDateString("tr-TR", {
   month: "2-digit",
   day: "2-digit",
  })} ${inputDate.toLocaleTimeString("tr-TR", {
   hour: "2-digit",
   minute: "2-digit",
   hour12: false,
  })}`;
 }
 function formatTarihVeSaat(tarihVeSaat) {
  var [tarih, saatDegeri] = tarihVeSaat.split(" ");

  var match = saatDegeri.match(/(\d{1,2}):(\d{2})/);

  if (match) {
   var saat = parseInt(match[1], 10);
   var dakika = parseInt(match[2], 10);

   saat = saat >= 24 ? saat - 24 : saat;

   var formatliTarihVeSaat = `${tarih} ${saat
    .toString()
    .padStart(2, "0")}:${dakika.toString().padStart(2, "0")}`;
   return formatliTarihVeSaat;
  } else {
   return "Geçersiz saat formatı";
  }
 }

 const modifiedTeams = teams.replace("vs.", "-");
 return (
  <tr className="flex">
    {
      footballGames === 1 &&
   <td className="text-[#ffcc00] text-[13px] font-normal w-[360px] flex items-center border-b border-[rgba(99,99,99,0.3)]">
    <td className="text-[rgba(255,255,255,.5)] w-[100px] ml-3 flex outline-none text-center">
     {formatTarihVeSaat(formattedDate)}
    </td>
    <span className="ml-3 text-yellow">{modifiedTeams}</span>
   </td>
    }

   {league.Markets.length > 0 && footballGames === 1 &&
    markets.map((market) =>
    market===null ? console.log(league):
     market.Odds.map((odd) => {
      if (
       market.OrgMarketTypeId === 18 ||
       market.OrgMarketTypeId === 1 ||
       filterId == 29 ||
       filterId == 11 ||
       filterId == 10
      ) {
       return (
        <td className="p-0" key={odd.id}>
          {
            footballGames === 1 &&
         <div
         className={`flex w-[110px] justify-between h-[38px] border-l-[rgba(255,255,255,.02)]  ${odd?.RecentStatus} `}
         >
          <div
           className={`flex flex-1 px-10 py-0 bg-[#333] border-r-[rgba(255,255,255,.05)] border-l-[rgba(0,0,0,.2)] 
              items-center transition-all-[100ms] relative shadow-custom hover:brightness-110 cursor-pointer`}
           id={odd?.ApiId}
           onClick={() =>
            (handleOddClick(odd, market, league, league.Name, market.Name, date),
            setcouponchange(1))
           }
          >
           <span className="text-[#ffcc00] font-bold text-[11px] whitespace-nowrap w-[50%] text-ellipsis overflow-hidden absolute left-[10px]">
            {odd.additionalName === "bos" || odd.additionalName === null ? odd.name : odd.additionalName}{" "}
            {/* {odd.additionalName === "bos"  || odd.additionalName === null? odd.SPOV : ""} */}
           </span>
           <span className="text-white text-[13px] absolute bottom-0 right-0 top-0 w-[40px] flex justify-center items-center font-normal">
            { odd?.Price ? odd.Price.toFixed(2) : "-"}
           </span>
          </div>
         </div>
        }
        </td>
       );
      }
     })
    )}

   <div
    className={`flex justify-center items-center h-[38px] w-[40px] min-w-30 max-w-30 ${
     selectedOdds?.find((i) => i?.event_id == league?.EventId) && "selected"
    }`}
   >
    {
      footballGames === 1 &&
      <div className=" mt-1 text-[20px] text-gray-200 ">
      <button
      onClick={() => {
       openPopup();
       preDetailClick();
      }}
     >
      <AiFillPlusSquare className="rounded-3xl relative" />
     </button>
    </div>
  }
   </div>
   {isPopupOpen && footballGames === 1 &&(
    <Popup
     teams={teams}
     preData={preData}
     loading={loading}
     closePopup={closePopup}
     selectedOdds={selectedOdds}
     handleOddClick={handleOddClick}
     league={league}
     date={date}
    />
   )}
  </tr>
 );
}

function FixtureLeague({
 leagueName,
 leagueISO,
 country,
 setSelectedOdds,
 selectedOdds,
 league,
 click,
 filterId,
 socket,
 navbarStyle,
 backgroundColor,
 setcouponchange
}) {
  const [footballGames,setFootballGames] = useState()
  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  function decodeBase64UTF8(encodedStr) {
  return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
  useEffect(() => {
   const base64EncodedString = Cookies.get("gameConfig");
   const decodedString = decodeBase64UTF8(base64EncodedString);
   const gameConfigObject = JSON.parse(decodedString);
   gameConfigObject?.sport_games?.pre_sports?.map((video) => (
     video.id === "66" ? setFootballGames(video.active) :  ""
   ))
 },[])

 const backgroundImages = {
  content: "",
  backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
 };
 return (
  <>
  {
    footballGames === 1 &&
   <tr>
    <td className="bg-[#111] text-[#e4e4e4] text-[13px] h-[50px] mt-3">
     <div className="flex ml-2">
      {leagueISO ? (
       <img
        src={require(`../assest/4x3/${leagueISO.toLowerCase()}.svg`)}
        width={"25px"}
        alt="svg"
        className="mr-2"
       />
      ) : (
       <span className="flex items-center mr-3">
        <GiWorld color="white" size={20} />
       </span>
      )}
      <b>{country && <span className="ml-3">{country}</span>}</b>
      <b className="ml-3">{leagueName}</b>
     </div>
     <div
      style={backgroundImages}
      className="h-[2px] w-[100%] right-0 bottom-0  relative rounded-[5px] top-[13px]"
     ></div>
    </td>
   </tr>
  }
   {league && footballGames === 1 &&
    league.map((match, index) => (
     <FixtureMatch
     setcouponchange={setcouponchange}
      key={index}
      date={match.EventDate}
      teams={match.Name}
      odds={match.odds}
      otherOdds={match.otherOdds}
      setSelectedOdds={setSelectedOdds}
      selectedOdds={selectedOdds}
      league={match}
      click={click}
      filterId={filterId}
      socket={socket}
      navbarStyle={navbarStyle}
     />
    ))}
  </>
 );
}

function Football({
 click,
 liveData,
 socket,
 filterId,
 backgroundColor,
 textColor,
}) {
 const [selectedOdds, setSelectedOdds] = useState([]);
 const navbarStyle = {
  backgroundColor: backgroundColor,
  textColor: textColor,
 };

 const [footballGames,setFootballGames] = useState()

  useEffect(() => {
    function encodeBase64UTF8(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
          return String.fromCharCode('0x' + p1);
      }));
    }
    function decodeBase64UTF8(encodedStr) {
    return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    }
   const base64EncodedString = Cookies.get("gameConfig");
   const decodedString = decodeBase64UTF8(base64EncodedString);
   const gameConfigObject = JSON.parse(decodedString);
   gameConfigObject?.sport_games?.pre_sports?.map((video) => (
     video.id === "66" ? setFootballGames(video.active) :  ""
   ))
 },[])

 const [couponchange,setcouponchange] = useState(1) 
 useEffect(() => {}, [selectedOdds]);

 if (!liveData || liveData.length === 0) {
  return (
   <div className="text-center p-3">
    {Language["en"].football.noshow}
   </div>
  );
 }
 return (
  <div className="text-text-white h-[100%]">
    {
      footballGames === 1 &&
   <table className="fixture" cellSpacing={0} cellPadding={0}>
    <tbody className="table-row-group border-inherit items-center">
     { footballGames === 1 && liveData.map((league, index) => {
      const country =
       league.Events && league.Events.length > 0
        ? league.Events[0].CategoryName
        : "";

      const teams = league.Events && league.Events.Name;

      return (
        
       <FixtureLeague
       setcouponchange={setcouponchange}
        key={index}
        leagueName={league?.Name}
        leagueISO={league?.ISO}
        country={country}
        matches={league.matches}
        setSelectedOdds={setSelectedOdds}
        selectedOdds={selectedOdds}
        teams={teams}
        league={league.Events}
        click={click}
        filterId={filterId}
        socket={socket}
        navbarStyle={navbarStyle}
        backgroundColor={backgroundColor}
       />
      );
     })}
    </tbody>
   </table>
    }
    
   <div
    style={{
     position: "absolute",
     top: "-30px",
     left: "970px",
    }}
   >
    <Coupon selectedOdds={selectedOdds} setSelectedOdds={setSelectedOdds} couponchange={couponchange} setcouponchange={setcouponchange}/>
   </div>
  </div>
 );
}

const mapStateToProps = (state) => {
 return {
  backgroundColor: state.backgroundColor,
  textColor: state.textColor,
 };
};

export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(
 Football
);
