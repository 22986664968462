import React, { useEffect } from "react";
import axios from "axios";
import Language from '../../language.json'
import {
  maxDepositValueUpdate,
  singleMaxDepositValueUpdate,
  liveMaxDepositValueUpdate,
  minDepositValueUpdate,
  eventMaxDepositValueUpdate,
  marketMaxDepositValueUpdate,
  maxCouponLineValueUpdate,
  minCouponOddValueUpdate,
  maxCouponOddValueUpdate,
  sysMaxXValueUpdate,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import {IsLoading} from "../index";
import Cookies from "js-cookie";

const LimitsWhenDepositingCoupons = ({
  maxDeposit,
  maxDepositValueUpdate,
  singleMaxDeposit,
  singleMaxDepositValueUpdate,
  liveMaxDeposit,
  liveMaxDepositValueUpdate,
  minDeposit,
  minDepositValueUpdate,
  eventMaxDeposit,
  eventMaxDepositValueUpdate,
  marketMaxDeposit,
  marketMaxDepositValueUpdate,
  maxCouponLine,
  maxCouponLineValueUpdate,
  minCouponOdd,
  minCouponOddValueUpdate,
  maxCouponOdd,
  maxCouponOddValueUpdate,
  sysMaxX,
  sysMaxXValueUpdate,
  isLoading,
  setIsLoading,
  textColor,
  backgroundColor
}) => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config";
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false)
        const maxDepositValue = response.data.coupon_deposit_limit.max_deposit;
        const singleMaxDepositValue =
          response.data.coupon_deposit_limit.single_max_deposit;
        const liveMaxDepositValue =
          response.data.coupon_deposit_limit.live_max_deposit;
        const minDepositValue = response.data.coupon_deposit_limit.min_deposit;
        const eventMaxDepositValue =
          response.data.coupon_deposit_limit.event_max_deposit;
        const marketMaxDepositValue =
          response.data.coupon_deposit_limit.market_max_deposit;
        const maxCouponLineValue =
          response.data.coupon_deposit_limit.max_coupon_line;
        const minCouponOddValue =
          response.data.coupon_deposit_limit.min_coupon_odd;
        const maxCouponOddValue =
          response.data.coupon_deposit_limit.max_coupon_odd;
        const sysMaxXValue = response.data.coupon_deposit_limit.sys_max_x;
        if (!maxDeposit) {
          maxDepositValueUpdate(maxDepositValue);
        }
        if (!singleMaxDeposit) {
          singleMaxDepositValueUpdate(singleMaxDepositValue);
        }
        if (!liveMaxDeposit) {
          liveMaxDepositValueUpdate(liveMaxDepositValue);
        }
        if (!minDeposit) {
          minDepositValueUpdate(minDepositValue);
        }
        if (!eventMaxDeposit) {
          eventMaxDepositValueUpdate(eventMaxDepositValue);
        }
        if (!marketMaxDeposit) {
          marketMaxDepositValueUpdate(marketMaxDepositValue);
        }
        if (!maxCouponLine) {
          maxCouponLineValueUpdate(maxCouponLineValue);
        }
        if (!minCouponOdd) {
          minCouponOddValueUpdate(minCouponOddValue);
        }
        if (!maxCouponOdd) {
          maxCouponOddValueUpdate(maxCouponOddValue);
        }
        if (!sysMaxX) {
          sysMaxXValueUpdate(sysMaxXValue);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  }, []);

  return (
    <>
    {
      isLoading ?  
      <div className='flex w-full min-h-[100px] mt-3 justify-center items-center'> <IsLoading/> </div>
    :
    <div className="p-[5px]">
      <div className={`pl-[35px] p-[10px] border-b-[1px] text-base relative`}
      style={{borderBottomColor:backgroundColor,color:textColor}}
      >
        {Language["en"].limitsWhenDepistingCoupons.title}
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.generalMaxDeposit}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={maxDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              maxDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.singleMaxDeposit}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={singleMaxDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              singleMaxDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.liveMaxDeposit}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={liveMaxDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              liveMaxDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.minDeposit}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={minDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              minDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.competitionMaxDeposit} <br />
          <p className="text-[#777777] text-[11px] font-normal text-xs mt-[-8px]">
          {Language["en"].limitsWhenDepistingCoupons.infoText}
          </p>
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={eventMaxDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              eventMaxDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.optionMaxDeposit}<br />
          <p className="text-[#777777] text-[11px] font-normal text-xs mt-[-8px]">
          {Language["en"].limitsWhenDepistingCoupons.infoText}
          </p>
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={marketMaxDeposit}
            onChange={(e) => {
              const selectedValue = e.target.value;
              marketMaxDepositValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.maxCouponLine}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={maxCouponLine}
            onChange={(e) => {
              const selectedValue = e.target.value;
              maxCouponLineValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.minCouponRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={minCouponOdd}
            onChange={(e) => {
              const selectedValue = e.target.value;
              minCouponOddValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.maxCouponRate}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={maxCouponOdd}
            onChange={(e) => {
              const selectedValue = e.target.value;
              maxCouponOddValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-center my-[5px] p-[5px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
        <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
        {Language["en"].limitsWhenDepistingCoupons.systemOfMaxThought}
        </div>
        <div className="p-[5px]">
          <input
            className="forms-select-box-shadow w-[85px] max-h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
            onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
            type="text"
            value={sysMaxX}
            onChange={(e) => {
              const selectedValue = e.target.value;
              sysMaxXValueUpdate(selectedValue);
            }}
          />
        </div>
      </div>
    </div>
      }
      </>
  );
};

const mapStateToProps = (state) => ({
  maxDeposit: state.maxDeposit,
  singleMaxDeposit: state.singleMaxDeposit,
  liveMaxDeposit: state.liveMaxDeposit,
  minDeposit: state.minDeposit,
  eventMaxDeposit: state.eventMaxDeposit,
  marketMaxDeposit: state.marketMaxDeposit,
  maxCouponLine: state.maxCouponLine,
  minCouponOdd: state.minCouponOdd,
  maxCouponOdd: state.maxCouponOdd,
  sysMaxX: state.sysMaxX,
});

const mapDispatchToProps = {
  maxDepositValueUpdate,
  singleMaxDepositValueUpdate,
  liveMaxDepositValueUpdate,
  minDepositValueUpdate,
  eventMaxDepositValueUpdate,
  marketMaxDepositValueUpdate,
  maxCouponLineValueUpdate,
  minCouponOddValueUpdate,
  maxCouponOddValueUpdate,
  sysMaxXValueUpdate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LimitsWhenDepositingCoupons);
