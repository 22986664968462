import React from "react";
import Language from '../../language.json'
const SettingsToUse = ({textColor,backgroundColor}) => {
  return (
    <div className="p-[15px]">
      <div className="p-[5px]">
        <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
        style={{borderBottomColor: backgroundColor,color:textColor}}
        >
          <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
            5
          </span>
          {Language["en"].settingsToUse.title}
        </div>
        <div className="p-[10px] bg-[#fff4ce] text-[#222] text-xs">
          {Language["en"].settingsToUse.description}
        </div>
      </div>
    </div>
  );
};

export default SettingsToUse;
