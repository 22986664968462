import React, { useEffect, useState } from "react";
import Language from '../../language.json'

const EditCompetitionOdds = ({
  matchDetails,
  marketTotals,
  rateDiff,
  formatMarketPrice,
  handleIncrement,
  handleDecrement,
  textColor,
  backgroundColor
}) => {
  const [teamName, setTeamName] = useState([]);
  const [matchId, setMatchId] = useState("");
  const [marketGroups, setMarketGroups] = useState([]);
  const lang = "en"
  useEffect(() => {
    if (matchDetails) {
      setTeamName(matchDetails.Competitors);
      setMatchId(matchDetails.Id);
      setMarketGroups(matchDetails.MarketGroups);
    }
  }, [matchDetails]);

  if (!matchDetails || !teamName || teamName.length < 2) {
    return null;
  }


    return (
      <div id={matchId}>
        <div className="text-[18px] p-[25px] border-b-[1px] border-b-[#DDD]">
          {teamName[0].competitor1.name} - {teamName[1].competitor2.name}
        </div>
        <div className="my-[5px] p-[10px] rounded-[10px] text-[#222] text-[13px] bg-[#ffcc00]">
          {Language[lang].EditCompetitonOdds.text1}
        </div>
        {marketGroups.map((marketGroupa, index) => {
          return marketGroupa.Markets.map((marketGroup, index) => (
            <div key={index} className="my-[5px] border-[1px] border-[#DDD]">
              <div className="p-[8px] text-[12px] border-[1px] border-[rgba(0,0,0,0.2)] font-bold bg-[rgba(0,0,0,0.1)]">
                {marketGroup.Name}
              </div>

              <div className="flex flex-wrap">
                {marketGroup.Odds.map((market, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      marketGroup.Odds.length === 3 ? "w-[33%]" : "w-[50%]"
                    }`}
                  >
                    <div
                      className={`flex p-[8px] w-full justify-between items-center border-[1px] border-[#DDD] margin-[1px] pb-[23px] ${
                        market.length === 3 ? "w-[50%]" : "w-[33%]"
                      }`}
                    >
                      <div className="text-[12px] flex-1">
                        <div className="">{market.name}</div>
                        {}
                        <div className="font-bold ">
                          ({formatMarketPrice(market.Price)})
                        </div>
                        <div
                          id={market._id}
                          className={`${
                            market.Price +
                              (rateDiff.find(
                                (rate) => rate.odd_id === market._id
                              )?.config_rule || 0) +
                              (marketTotals[market._id] || 0) -
                              market.Price ===
                            0
                              ? "text-[#222]"
                              : market.Price +
                                  (rateDiff.find(
                                    (rate) => rate.odd_id === market._id
                                  )?.config_rule || 0) +
                                  (marketTotals[market._id] || 0) -
                                  market.Price >
                                0
                              ? "text-green-600"
                              : "text-[#BC2121]"
                          }`}
                        >
                          (
                          {formatMarketPrice(
                            market.Price +
                              (rateDiff.find(
                                (rate) => rate.odd_id === market._id
                              )?.config_rule || 0) +
                              (marketTotals[market._id] || 0) -
                              market.Price
                          )}
                          )
                        </div>
                      </div>
                      <div className="max-w-[70px] min-w-[70px] w-[70px] relative flex-1">
                        <div className="absolute flex bottom-[-15px] w-full">
                          <button
                            className={`bg-[#BC2121] max-w-[35px] min-w-[35px] w-[35px]`}
                            onClick={() => handleDecrement(market._id)}
                          >
                            -
                          </button>
                          <button
                            className="bg-[#14892C] max-w-[35px] min-w-[35px] w-[35px]"
                            onClick={() => handleIncrement(market._id)}
                          >
                            +
                          </button>
                        </div>
                        <input
                          className="forms-select-box-shadow text-center p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
                          type="text"
                          id={market._id}
                          value={formatMarketPrice(
                            market.Price +
                              (rateDiff.find(
                                (rate) => rate.odd_id === market._id
                              )?.config_rule || 0) +
                              (marketTotals[market._id] || 0)
                          )}
                          onChange={(e) => parseFloat(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ));
        })}
      </div>
    );
};

export default EditCompetitionOdds;
