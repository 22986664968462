import React, { useEffect, useState } from 'react'
import { MdOutlineChecklist, MdOutlineMonitor } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Language from '../language.json'
import Cookies from 'js-cookie'

const NavbarLinks = ({navbarStyle,activeCategory,handleCategoryClick,}) => {
 
  const [footballActive,setFootballActive] = useState()
  const [basketballActive,setBasketballActive] = useState()
  const [tennisActive,setTennisActive] = useState()
  const [liveFootballActive,setLiveFootballActive] = useState()
  const [liveBasketballActive,setLiveBasketballActive] = useState()
  const [liveTennisActive,setLiveTennisActive] = useState()
  const [virtualSport,setVirtualSport] = useState()
  const [virtualHorses,setVirtualHorses] = useState()
  const [videoCasino,setVideoCasino] = useState()
  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  function decodeBase64UTF8(encodedStr) {
  return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
  useEffect(() => {
    const base64EncodedString = Cookies.get("gameConfig");
    const decodedString = decodeBase64UTF8(base64EncodedString);
    const gameConfigObject = JSON.parse(decodedString);
    
    gameConfigObject?.sport_games?.pre_sports.map((pre) =>(
      pre.id === "66" ? setFootballActive(pre.active) :
      pre.id === "67" ? setBasketballActive(pre.active) :
      pre.id === "68" ? setTennisActive(pre.active) : ""
    ))
    gameConfigObject?.sport_games?.live_sports.map((live)=>(
      live.id === "66-1" ? setLiveFootballActive(live.active) : 
      live.id === "67-1" ? setLiveBasketballActive(live.active) :
      live.id === "68-1" ? setLiveTennisActive(live.active) : ""
    ))
    gameConfigObject?.virtual_games?.map((virtual) => (
      virtual.id === "1" ? setVirtualSport(virtual.active) :
      virtual.id === "2" ? setVirtualHorses(virtual.active) : ""
    ))
    gameConfigObject?.video_games?.map((video) => (
      video.id === "3" ? setVideoCasino(video.active) : ""
    ))
  },[])
 
return (
    <div className="flex w-full bg-custom-green rounded-2xl rounded-tl-none rounded-tr-none mt-[20px] shadow-inset-[0px -4px 0px rgba(0,0,0,.3) inset, 0px -1px 0px rgba(255,255,255,.2) inset, 0px 3px 6px rgba(0,0,0,.2) inset shadow-[0px 4px 0px rgba(0,0,0,.3) inset, 0px 1px 0px rgba(255,255,255,.2) inset, 0px -3px 6px rgba(0,0,0,.2) inset] border-t-2 border-black border-opacity-40 rounded-b-15 overflow-hidden"
        style={navbarStyle}>
          {
            (footballActive === 1 || basketballActive === 1) && 
            <Link to='/Results'>
        <div  className={`categori ${activeCategory === 'Sports Betting' ? 'active' : ''} hover:bg-hover-black `}
        onClick={() => handleCategoryClick('Sports Betting')}>
          <div>
            {Language["en"].navbarLinks.sportBettingLinkText}
        </div>
        </div>
          </Link>
          }
        {
          (liveFootballActive === 1 || liveBasketballActive === 1) &&
          <Link to='/Live'>
        <div className={`categori ${activeCategory === 'Live Betting' ? 'active' : ''} hover:bg-hover-black `}
        onClick={() => handleCategoryClick('Live Betting')}>
          <div>
          {Language["en"].navbarLinks.liveBettingLinkText}
          </div>
        </div>
          </Link>
          }
        {
          (virtualSport === 1) &&
          <div className={`categori ${activeCategory === 'Virtual Football' ? 'active' : ''} hover:bg-hover-black `}
        onClick={() => handleCategoryClick('Sanal Futbol')}>
          <div>
            {Language["en"].navbarLinks.virtualFootballLinkText}
          </div>
        </div>
        }
        {
          (virtualHorses === 1) &&
          <div className="categori hover:bg-hover-black ">
          <div>
          {Language["en"].navbarLinks.virtualHorsesLinkText}
          </div>
        </div>
        }
        {
            videoCasino === 1 &&
        <div className="categori hover:bg-hover-black ">
          <div>
          {Language["en"].navbarLinks.bingoLinkText}
          </div>
        </div>
        }
        {
          videoCasino === 1 &&
        <div className="categori hover:bg-hover-black ">
          <div>
          {Language["en"].navbarLinks.blackJackLinkText}
          </div>
        </div>
        }
        <Link to='/all-coupons'>
        <div className={`categori ${activeCategory === 'Coupons' ? 'active' : ''} hover:bg-hover-black `}
         onClick={() => handleCategoryClick('Coupons')}>
          <MdOutlineChecklist className="mr-2 mt-0.5 text-xl" />
          <div>
          {Language["en"].navbarLinks.couponsLinkText}
          </div>
        </div>
        </Link>
        <div className="categori hover:bg-hover-black ">
          <MdOutlineMonitor className="mr-2 mt-0.5 text-xl" />
          <div>
          {Language["en"].navbarLinks.liveTvLinkText}
          </div>
        </div>
      </div> 
  )
}


export default NavbarLinks