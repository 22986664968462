import React, { useEffect, useState } from "react";
import Language from '../../language.json'

const ChangeRate = ({
  selectPercentageData,
  setSelectPercentageData,
  selectScore,
  setSelectScore,
  textColor,
  backgroundColor
}) => {

  const [resetOther, setResetOther] = useState(null);
  const lang = "en"
  const handlePercentageChange = (e) => {
    setSelectPercentageData(e.target.value);
    setResetOther("selectPercentageData");
  };

  const handleScoreChange = (e) => {
    setSelectScore(e.target.value);
    setResetOther("selectScore");
  };
  
  useEffect(() => {
    if (resetOther === "selectPercentageData") {
      setSelectScore("0");
    } else if (resetOther === "selectScore") {
      setSelectPercentageData("0");
    }
  }, [resetOther]);

  return (
    <div>
      <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] mx-[0px] bg-[#f9f9f9]  cursor-pointer hover:bg-[#fff]">
        <div className="p-[5px]">
          <div className="font-medium text-[13px]">
            {Language[lang].ChangeRate.text1}
          </div>
          <div className="text-[11px] font-normal text-[#777]">
          {Language[lang].ChangeRate.text2}
          </div>
        </div>
        <div className="p-[5px] flex justify-between gap-[5px]">
          <div className="flex-1">
            <select
              name="selectPercentageData"
              id="selectPercentageData"
              className="forms-select-box-shadow dashboard-selects text-[13px] h-[40px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              value={selectPercentageData}
              onChange={handlePercentageChange}
            >
              <option value={-15}>-15</option>
              <option value={-14}>-14</option>
              <option value={-13}>-13</option>
              <option value={-12}>-12</option>
              <option value={-11}>-11</option>
              <option value={-10}>-10</option>
              <option value={-9}>-9</option>
              <option value={-8}>-8</option>
              <option value={-7}>-7</option>
              <option value={-6}>-6</option>
              <option value={-5}>-5</option>
              <option value={-4}>-4</option>
              <option value={-3}>-3</option>
              <option value={-2}>-2</option>
              <option value={-1}>-1</option>
              <option value={0}>{Language[lang].ChangeRate.textOption}</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
            </select>
          </div>
          <div className="flex-1">
            <select
              name="selectScore"
              id="selectScore"
              className="forms-select-box-shadow dashboard-selects text-[13px] h-[40px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              value={selectScore}
              onChange={handleScoreChange}
            >
              <option value={-1.0}>-1.00</option>
              <option value={-0.95}>-0.95</option>
              <option value={-0.9}>-0.90</option>
              <option value={-0.85}>-0.85</option>
              <option value={-0.8}>-0.80</option>
              <option value={-0.75}>-0.75</option>
              <option value={-0.7}>-0.70</option>
              <option value={-0.65}>-0.65</option>
              <option value={-0.6}>-0.60</option>
              <option value={-0.55}>-0.55</option>
              <option value={-0.5}>-0.50</option>
              <option value={-0.45}>-0.45</option>
              <option value={-0.4}>-0.40</option>
              <option value={-0.35}>-0.35</option>
              <option value={-0.3}>-0.30</option>
              <option value={-0.25}>-0.25</option>
              <option value={-0.2}>-0.20</option>
              <option value={-0.15}>-0.15</option>
              <option value={-0.1}>-0.10</option>
              <option value={-0.05}>-0.05</option>
              <option value={0}>{Language[lang].ChangeRate.textOption2}</option>
              <option value={0.05}>0.05</option>
              <option value={0.1}>0.10</option>
              <option value={0.15}>0.15</option>
              <option value={0.2}>0.20</option>
              <option value={0.25}>0.25</option>
              <option value={0.3}>0.30</option>
              <option value={0.35}>0.35</option>
              <option value={0.4}>0.40</option>
              <option value={0.45}>0.45</option>
              <option value={0.5}>0.50</option>
              <option value={0.55}>0.55</option>
              <option value={0.6}>0.60</option>
              <option value={0.65}>0.65</option>
              <option value={0.7}>0.70</option>
              <option value={0.75}>0.75</option>
              <option value={0.8}>0.80</option>
              <option value={0.85}>0.85</option>
              <option value={0.9}>0.90</option>
              <option value={0.95}>0.95</option>
              <option value={1.0}>1.00</option>
            </select>
          </div>
        </div>
        <div className={`mt-[15px] p-[10px] pl-[35px] border-b-[1px] text-[16px] relative`}
        style={{borderBottomColor:backgroundColor,color:textColor}}
        >
        {Language[lang].ChangeRate.text3}
        </div>
        <div className="table w-full table-fixed border-collapse mt-[10px] rounded-[5px]">
          <ul className="table-row">
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              1.08
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              1.20
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              1.50
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              2.00
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              2.50
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              5.00
            </li>
            <li className="changeRate bg-[#ddd] font-semibold relative table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]" style={{color:"#000"}}>
              10.00
            </li>
          </ul>
          <ul className="table-row">
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              1.08
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              1.20
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              1.50
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              2.00
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              2.50
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              5.00
            </li>
            <li className="table-cell text-[13px] border-[1px] border-[#ccc] text-center py-[10px] px-[0px]">
              10.00
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChangeRate;
