import React, { useEffect, useState } from 'react'

import IsLoading from '../IsLoading';
import Language from '../../language.json'

const VirtualBettingGroup = ({backgroundColor,textColor,virtualGames,setVirtualGames,isLoading}) => {

  const [selectedSports, setSelectedSports] = useState([]);

  useEffect(() => {
    const initiallySelectedSports = virtualGames.filter((sport) => sport.active === 1);
    setSelectedSports(initiallySelectedSports);
  }, [virtualGames]);

  const handleCheckboxChange = (event, sport) => {
    const isChecked = event.target.checked;

    if (isChecked) {

      setSelectedSports((prevSelectedSports) => [...prevSelectedSports, sport]);
    } else {

      setSelectedSports((prevSelectedSports) =>
        prevSelectedSports.filter((selectedSport) => selectedSport.id !== sport.id)
      );
    }

    const updatedVirtualGames = virtualGames.map((originalSport) =>
      originalSport.id === sport.id ? { ...originalSport, active: isChecked ? 1 : 0 } : originalSport
    );
  
    setVirtualGames(updatedVirtualGames);
  };
  return (
    <div className='p-[15px]'>
    <div className='p-[5px]'>
  <div className='p-[10px] pl-[35px] border-b-[1px] relative' style={{borderBottomColor:backgroundColor,color:textColor}}>
  <span className='absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff]' style={{backgroundColor:backgroundColor}}>2</span>
  {Language["en"].virtualBettingGroup.title}
  </div> 
  <div className='flex flex-wrap p-[2px]'>
      {isLoading ?  
        <div className='flex w-full mt-3 justify-center items-center'> <IsLoading/> </div>
      : virtualGames.map((data) => (
        <label key={data.id} className='w-[50%] p-[1px] cursor-pointer min-w-[135px]'>
          <div className='text-[13px] flex items-center forms-label-box-shadow bg-[#f9f9f9] p-[10px] rounded-[5px]'>
            <input
              type='checkbox'
              id={data.id}
              className='cursor-pointer w-5 h-5 border-[1px] mr-[5px]'
              style={{borderColor:backgroundColor,accentColor:backgroundColor}}
              checked={selectedSports.some((selectedSport) => selectedSport.id === data.id)}
              onChange={(event) => handleCheckboxChange(event, data)}
            />
            {data.name}
          </div>
        </label>
      ))}
    </div>
  </div>
  </div>
  )
}

export default VirtualBettingGroup