import React, { useEffect, useState } from "react";
import { Alert, ErrorAlert, ErrorUnAlert, IsLoading, OddType, SportType, UserAffected } from "./index";
import axios from "axios";
import Language from '../language.json'
import { setBackgroundColor,setTextColor } from "../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
const RatioType = ({textColor,backgroundColor}) => {
  const lang = "en"
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/odd_type_config";
  const apiPost2Url= process.env.REACT_APP_API_URL + "/api/odd_type_configget";
  const apiGetUrl = process.env.REACT_APP_API_URL_2 + "/getmarketsandodds";

  const [datas, setDatas] = useState([]);
  const [selectData, setSelectData] = useState("All");
  const [selectPercentageData,setSelectPercentageData] = useState("0");
  const [selectScore,setSelectScore] = useState("0")
  const [type,setType] = useState("0")
  const [rule,setRule] = useState("0")
  const [getData,setGetData] = useState([])
  const [usersBet, setUsersBet] = useState([]);
  const [selectSportType,setSelectSportType] = useState("66")
  const [allSportType,setAllSportType] = useState(selectSportType)
  const [oddTypes,setOddTypes] = useState([])
  const [selectedOdds, setSelectedOdds] = useState([]);
  const [oddId,setOddId] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [isDataLoading,setIsDataLoading] = useState(true)
  const [alert,setAlert] = useState(null)
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false)
      });
  }, []);

  useEffect(() =>{
    setAllSportType(selectSportType)    
    const postData = {
      sports_id: allSportType,
    }
    axios.post(apiGetUrl,postData,{
      headers: {Authorization: `Bearer ${token}`}
    })
    .then((response) => {
      setOddTypes(response.data)
      setGetData(response.data)
      setIsDataLoading(false)
    })
    .catch((error) => {
      console.error("API ERROR:", error);
    })
  },[selectData,selectSportType,allSportType])


  const handleChange = (e) => {
    setSelectData(e.target.value)
  }

  const handleSportType = (e) => {
        setSelectSportType(e.target.value);
  }

const applyRate = () => {

    const postData = {
      user_id: selectData,
      sports_id: allSportType,
      odd_id: oddId || [],
      is_off: 1
    };

    if(type == 2){
      return
    }
    else{
      axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response)=>{
        if(response.data.message){
          setAlert(response.data.message);
        }
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => console.error("API ERROR:", error));}
  };

  useEffect(() => {
    const postData = {
      user_id: selectData,
      sports_id: allSportType
    }
    axios
    .post(apiPost2Url, postData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response)=>{
      setOddId(response.data.odd_id)
    })
    .catch((error) => {
      console.error("API ERROR:", error);
      setSelectScore(0);
      setSelectPercentageData(0);
    });
  },[selectData,allSportType])

  return (
    <div>
      { alert &&
        <Alert
        alert={alert}
        setAlert={setAlert}
        />
      }
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language[lang].RatioTypePage.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[15px] p-[15px]">
        <div className="flex flex-wrap bg-[#fff] p-[15px]">
          <div className="flex-1 py-0 px-[5px] max-w-[290px]">
          <div className="p-[10px] pl-[35px] border-b-[1px] relative"
          style={{color:textColor,borderBottomColor:backgroundColor}}
          >
        <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
          1
        </span>
        {Language[lang].RatioTypePage.title2}
      </div>
      {
        isLoading ?  
        <div className='flex w-full min-h-[100px] mt-3 justify-center items-center'> <IsLoading/> </div>
      : <>
            <UserAffected
              datas={datas}
              selectData={selectData}
              setSelectData={setSelectData}
              handleChange={handleChange}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
            <SportType
              usersBet={usersBet}
              setUsersBet={setUsersBet}
              selectSportType={selectSportType}
              setSelectSportType={setSelectSportType}
              handleSportType={handleSportType}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
      </>
      }
          </div>

          <div className="flex-1 py-0 px-[5px]">
          <div className="p-[10px] pl-[35px] border-b-[1px] relative"
          style={{color:textColor,borderBottomColor:backgroundColor}}
          >
        <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
          2
        </span>
        {Language[lang].RatioTypePage.titleOdd}
      </div>
            <OddType
              oddTypes={oddTypes}
              setSelectedOdds={setSelectedOdds}
              selectedOdds={selectedOdds}
              setOddId={setOddId}
              oddId={oddId}
              allSportType={allSportType}
              isDataLoading={isDataLoading}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <button
            className={`forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[${backgroundColor}] text-[#fff] cursor-pointer transition-all duration-300`}
            onClick={applyRate}
          >
          {Language[lang].RatioTypePage.textRate}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
})

export default connect(mapStateToProps,{setBackgroundColor,setTextColor})(RatioType);
