import React from 'react'
import '../mobile/mobile.css'
import {FaRotate} from 'react-icons/fa6'
import MobileNavbar from './MobileNavbar'
import MobileFooter from './MobileFooter'

function MobileKupon() {
  return (
    <div>
        <MobileNavbar/>
        <div className="center">
            <div className="incenter">
                <div className="pagetitle">Kuponlar</div>
                <div className="filterslog">
                    <div id='filters'>
                        <div className="filters">
                            <div>
                                <span>&nbsp;</span>
                                <span className='refresh'>
                                    <a href="#">
                                        <FaRotate style={{bottom:"30px", position:'relative'}}/>
                                    </a>
                                </span>
                            </div>
                            <div>
                                <span>Kupon no</span>
                                <input type="number" className='input' name='no' style={{width:"90px"}} />
                            </div>
                            <div>
                                <span>Başlangıç</span>
                                <input type="text" className='input hasDatepicker' readOnly name='start_date' value={"05.10.2023"} style={{width:"90px"}} />
                            </div>
                            <div>
                                <span>Bitiş</span>
                                <input type="text" className='input hasDatepicker' readOnly name='start_date' value={"06.10.2023"} style={{width:"90px"}} />
                            </div>
                            <div>
                                <span>Kullanıcı</span>
                                <select className='input' name='ktype'>
                                    <option value="0">Hepsi</option>
                                </select>
                            </div>
                            <div>
                                <span>Durum</span>
                                <select className='input' name='status'>
                                    <option value="0">Hepsi</option>
                                    <option value="1">Bekleyen</option>
                                    <option value="2">Kazanan</option>
                                    <option value="3">Kaybeden</option>
                                    <option value="4">İptal</option>
                                    <option value="5">Teke Kalan</option>
                                </select>
                            </div>
                            <div>
                                <span>Kupon</span>
                                <select className='input' name='kupontip'>
                                    <option value="0">Hepsi</option>
                                    <option value="1">Maç Öncesi</option>
                                    <option value="2">Maç Öncesi Tekli</option>
                                    <option value="3">Maç Öncesi Kombine</option>
                                    <option value="4">Canlı</option>
                                    <option value="5">Canlı Tekli</option>
                                    <option value="6">Canlı Tekli Kombine</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="boskupon">Herhangi bir kupon bulunamadı</div>
                </div>
            </div>
        </div>
        <MobileFooter/>
    </div>
  )
}

export default MobileKupon