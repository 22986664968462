import React, { useEffect, useState } from "react";
import Language from '../language.json'
import {
  AllChangesCenter,
  ChangeBasedOnSportType,
  ClosedCompetitions,
  ClosedLeagues,
  ClosedRateTypes,
  LeagueOddsChange,
  RatetypeBasedChange,
  SingleMatchOddsChange,
} from "./index";
import axios from "axios";
import { setBackgroundColor, setTextColor } from '../redux/actions/actions';
import Cookies from "js-cookie";
import { connect } from "react-redux";
const ChangesCenter = ({backgroundColor,textColor}) => {
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + '/api/change_center'
  const [isLoading,setIsLoading] = useState(true)

  const [oddConfig1,setOddConfig1] = useState([])
  const [oddConfig2,setOddConfig2] = useState([])
  const [oddConfig3,setOddConfig3] = useState([])
  const [oddConfig4,setOddConfig4] = useState([])
  const [oddConfig5,setOddConfig5] = useState([])
  const [eventConfig,setEventConfig] = useState([])
  const [leagueConfig,setLeagueConfig] = useState([])
  const [oddTypeConfig,setOddTypeConfig] = useState([])
  useEffect(() =>{
    axios.post(apiUrl,null,{
      headers: {Authorization: `Bearer ${token}`}})
      .then((response) =>{
        setOddConfig1(response.data.odd_config1)
        setOddConfig2(response.data.odd_config2)
        setOddConfig3(response.data.odd_config3)
        setOddConfig4(response.data.odd_config4)
        setOddConfig5(response.data.odd_config5)
        setEventConfig(response.data.event_config)
        setOddTypeConfig(response.data.odd_type_config)
        setLeagueConfig(response.data.league_config)
        console.log(response.data)
      })
      .catch((error)=>{
        console.error("Error Message:",error);
      })
      .finally(() =>{
        setIsLoading(false)
      })
  },[])

  const shortedName = (name, maxLength) => {
    if (name?.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  };


  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].changesCenter.title}
      </div>
      <div className="bg-[#ddd] p-[15px] px-[20px] flex flex-wrap rounded-b-[15px]">
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <AllChangesCenter 
            oddConfig1={oddConfig1}     
            setOddConfig1={setOddConfig1}       
            shortedName={shortedName}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            apiUrl={apiUrl}
            token={token}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        </div>
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <RatetypeBasedChange
            oddConfig2={oddConfig2}
            setOddConfig2={setOddConfig2}
            shortedName={shortedName}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            apiUrl={apiUrl}
            token={token}
            textColor={textColor}
            backgroundColor={backgroundColor}
          />
        </div>
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <ChangeBasedOnSportType 
         oddConfig3={oddConfig3}
         setOddConfig3={setOddConfig3}       
         shortedName={shortedName}
         isLoading={isLoading}
         setIsLoading={setIsLoading}
         apiUrl={apiUrl}
         token={token}
         backgroundColor={backgroundColor}
         textColor={textColor}
          />
        </div>
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <LeagueOddsChange 
          oddConfig4={oddConfig4}
          setOddConfig4={setOddConfig4}
          shortedName={shortedName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          apiUrl={apiUrl}
          token={token}
          textColor={textColor}
          backgroundColor={backgroundColor}
          />
        </div>
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <SingleMatchOddsChange 
          oddConfig5={oddConfig5}
          setOddConfig5={setOddConfig5}       
          shortedName={shortedName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          apiUrl={apiUrl}
          token={token}
          backgroundColor={backgroundColor}
          textColor={textColor}
          />
        </div>
        <div className="w-[33%] min-w-[300px] pt-[5px]">
          <ClosedRateTypes 
          oddTypeConfig={oddTypeConfig}
          setOddTypeConfig={setOddTypeConfig}
          shortedName={shortedName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          apiUrl={apiUrl}
          token={token}
          textColor={textColor}
          backgroundColor={backgroundColor}
          />
        </div>
        <div className="w-[49.5%] min-w-[300px] pt-[5px]">
          <ClosedLeagues 
          leagueConfig={leagueConfig}
          setLeagueConfig={setLeagueConfig}
          shortedName={shortedName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          apiUrl={apiUrl}
          token={token}
          textColor={textColor}
          backgroundColor={backgroundColor}
          />
        </div>
        <div className="w-[49.5%] min-w-[300px] pt-[5px]">
          <ClosedCompetitions 
          eventConfig={eventConfig}
          setEventConfig={setEventConfig}
          shortedName={shortedName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          apiUrl={apiUrl}
          token={token}
          textColor={textColor}
          backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
    textColor: state.textColor
  };
};
export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(ChangesCenter);
