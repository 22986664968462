import React from 'react'
import Language from '../language.json'
const Alert = ({alert,setAlert}) => {
  return (
    <div className="w-[100vw] h-full fixed inset-0 bg-[rgba(0,0,0,0.8)] flex flex-col items-center justify-center z-[999999999]">
            <div className="flex absolute top-24 rounded-lg flex-col justify-center items-center min-w-[200px] max-w-[600px] min-h-[100px] w-[auto] h-[auto] bg-white">
            <div className="mb-5 relative text-center">
            <p>{Language["en"].alertComponent.Alert}</p>
            <pre className="italic px-5">{JSON.stringify(alert, null, 2)}</pre>
            </div>
            <button className="absolute bottom-0 rounded-b-lg w-full bg-[#2f9015] text-white font-bold p-1" onClick={() => setAlert(null)}>{Language["en"].alertComponent.button}</button>
            </div>
          </div>
  )
}

export default Alert