import React from "react";
import {
  newUsersAllSystemValueUpdate,
  newUsersAllSystemRuleValueUpdate,
  newUsersPreMatchValueUpdate,
  newUsersPreMatchRuleValueUpdate,
  newUsersLiveValueUpdate,
  newUsersLiveRuleValueUpdate,
  newUsersVideoGameValueUpdate,
  newUsersVideoGameRuleValueUpdate,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import Language from '../../language.json'
const WorkWithTheSystem = ({
  newUsersAllSystem,
  newUsersAllSystemValueUpdate,
  newUsersAllSystemRule,
  newUsersAllSystemRuleValueUpdate,
  newUsersPreMatch,
  newUsersPreMatchValueUpdate,
  newUsersPreMatchRule,
  newUsersPreMatchRuleValueUpdate,
  newUsersLive,
  newUsersLiveValueUpdate,
  newUsersLiveRule,
  newUsersLiveRuleValueUpdate,
  newUsersVideoGame,
  newUsersVideoGameValueUpdate,
  newUsersVideoGameRule,
  newUsersVideoGameRuleValueUpdate,
  textColor,
  backgroundColor
}) => {
  return (
    <div className="">
      <div className="px-[5px]">
        <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
        style={{borderBottomColor:backgroundColor,color:textColor}}
        >
          <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
            3
          </span>
          {Language["en"].workWithTheSystem.title}
        </div>
        <div className="flex flex-col flex-wrap">
          <div className="forms-box-shadow flex flex-col p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col p-[5px] font-medium text-[13px]">
            {Language["en"].workWithTheSystem.quickChoice}
            </div>
            <div className="p-[5px] flex gap-1 justify-between">
              <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].workWithTheSystem.allSystem}
              </div>
              <select
                className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="all_system"
                id="all_system"
                value={newUsersAllSystem}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  newUsersAllSystemValueUpdate(selectedValue);
                }}
              >
                <option value="0">{Language["en"].workWithTheSystem.selectAllSystem0}</option>
                <option value="1">{Language["en"].workWithTheSystem.selectAllSystem1}</option>
                <option value="2">{Language["en"].workWithTheSystem.selectAllSystem2}</option>
              </select>
              <div className="relative WorkWithTheSystem">
                <input
                  className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder="25"
                  type="number"
                  value={newUsersAllSystemRule}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersAllSystemRuleValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="forms-box-shadow flex flex-col p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col p-[5px] font-medium text-[13px]">
              {Language["en"].workWithTheSystem.advancedOperating}
            </div>
            <div className="p-[5px] flex gap-1 justify-between">
              <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
                {Language["en"].workWithTheSystem.preMatchOnly}
              </div>
              <select
                className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="pre_match"
                id="pre_match"
                value={newUsersPreMatch}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  newUsersPreMatchValueUpdate(selectedValue);
                }}
              >
                <option value="0">{Language["en"].workWithTheSystem.selectPreMatch0}</option>
                <option value="1">{Language["en"].workWithTheSystem.selectPreMatch1}</option>
                <option value="2">{Language["en"].workWithTheSystem.selectPreMatch2}</option>
                <option value="3">{Language["en"].workWithTheSystem.selectPreMatch3}</option>
              </select>
              <div className="relative WorkWithTheSystem">
                <input
                  type="number"
                  className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder="25"
                  value={newUsersPreMatchRule}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersPreMatchRuleValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>
            <div className="p-[5px] flex gap-1 justify-between">
              <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].workWithTheSystem.liveOnly}
              </div>
              <select
                name=""
                id=""
                className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                value={newUsersLive}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  newUsersLiveValueUpdate(selectedValue);
                }}
              >
                <option value="0">{Language["en"].workWithTheSystem.selectLive0}</option>
                <option value="1">{Language["en"].workWithTheSystem.selectLive1}</option>
                <option value="2">{Language["en"].workWithTheSystem.selectLive2}</option>
                <option value="3">{Language["en"].workWithTheSystem.selectLive3}</option>
              </select>
              <div className="relative WorkWithTheSystem">
                <input
                  className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                  onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                  placeholder="25"
                  type="number"
                  value={newUsersLiveRule}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersLiveRuleValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>
            <div className="p-[5px] flex gap-1 justify-between">
              <div className="mr-[5px] min-w-[120px] max-w-[120px] text-xs flex items-center">
              {Language["en"].workWithTheSystem.videoGameOnly}

              </div>
              <select
                name=""
                id=""
                className="forms-select-box-shadow dashboard-selects px-[10px] h-[37px] border-[1px] border-[#999] font-medium outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9]"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                value={newUsersVideoGame}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  newUsersVideoGameValueUpdate(selectedValue);
                }}
              >
                <option value="0">{Language["en"].workWithTheSystem.videoGame0}</option>
                <option value="1">{Language["en"].workWithTheSystem.videoGame1}</option>
                <option value="2">{Language["en"].workWithTheSystem.videoGame2}</option>
                <option value="3">{Language["en"].workWithTheSystem.videoGame3}</option>
              </select>
              <div className="relative WorkWithTheSystem">
                <input
                  className="forms-select-box-shadow pl-[25px] p-[10px] h-[37px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-[100%] text-[13px] cursor-pointer bg-[#f9f9f9] placeholder:text-[#222]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder="25"
                  type="number"
                  value={newUsersVideoGameRule}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersVideoGameRuleValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  newUsersAllSystem: state.newUsersAllSystem,
  newUsersAllSystemRule: state.newUsersAllSystemRule,
  newUsersPreMatch: state.newUsersPreMatch,
  newUsersPreMatchRule: state.newUsersPreMatchRule,
  newUsersLive: state.newUsersLive,
  newUsersLiveRule: state.newUsersLiveRule,
  newUsersVideoGame: state.newUsersVideoGame,
  newUsersVideoGameRule: state.newUsersVideoGameRule,
});

const mapDispatchToProps = {
  newUsersAllSystemValueUpdate,
  newUsersAllSystemRuleValueUpdate,
  newUsersPreMatchValueUpdate,
  newUsersPreMatchRuleValueUpdate,
  newUsersLiveValueUpdate,
  newUsersLiveRuleValueUpdate,
  newUsersVideoGameValueUpdate,
  newUsersVideoGameRuleValueUpdate,
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkWithTheSystem);
