import React, { useEffect, useState } from "react";
import Language from "../../language.json";
import { FaBroadcastTower } from "react-icons/fa";
import axios from "axios";
import {
  systemOffSec,
  liveFootballTypeValueUpdate,
  footballTypeValueUpdate,
  systemCouponValueUpdate,
  systemStartUpTimeValueUpdate,
  systemShutDownTimeValueUpdate,
  liveLastMin1ValueUpdate,
  liveLastMin2ValueUpdate,
  couponExtValueUpdate,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { IsLoading } from "../index";
const VariousDisplaySettings = ({
  isLoading,
  setIsLoading,
  systemOffSec,
  selectedSystemOffValue,
  liveFootballType,
  liveFootballTypeValueUpdate,
  footballType,
  footballTypeValueUpdate,
  systemCoupon,
  systemCouponValueUpdate,
  systemStartUpTime,
  systemStartUpTimeValueUpdate,
  systemShutDownTime,
  systemShutDownTimeValueUpdate,
  liveLastMin1,
  liveLastMin1ValueUpdate,
  liveLastMin2,
  liveLastMin2ValueUpdate,
  couponExt,
  couponExtValueUpdate,
  textColor,
  backgroundColor
}) => {
  const [systemOffValue, setSystemOffValue] = useState(selectedSystemOffValue);
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/config";
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        const systemOff = response.data.other_config.system_off;
        const footballTypeValue = response.data.other_config.football_type;
        const liveFootballTypeValue =
          response.data.other_config.live_football_type;
        const systemCouponValue = response.data.other_config.sys_coupon;
        const systemTime = (response.data.other_config.system_time).split(",")
        const systemStartUpTimeValue = systemTime[0];
        const systemShutDownTimeValue = systemTime[1];
        const liveLastMin1Value = response.data.other_config.live_lost_min1;
        const liveLastMin2Value = response.data.other_config.live_lost_min2;
        const couponExtValue = response.data.other_config.coupon_ext;

        if (!selectedSystemOffValue) {
          setSystemOffValue(systemOff);
          systemOffSec(systemOff);
        }
        if (!footballType) {
          footballTypeValueUpdate(footballTypeValue);
        }
        if (!liveFootballType) {
          liveFootballTypeValueUpdate(liveFootballTypeValue);
        }
        if (!systemCoupon) {
          systemCouponValueUpdate(systemCouponValue);
        }  
          systemStartUpTimeValueUpdate(systemStartUpTimeValue);
          systemShutDownTimeValueUpdate(systemShutDownTimeValue);
        if (!liveLastMin1) {
          liveLastMin1ValueUpdate(liveLastMin1Value);
        }
        if (!liveLastMin2) {
          liveLastMin2ValueUpdate(liveLastMin2Value);
        }
        if (!couponExt) {
          couponExtValueUpdate(couponExtValue);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
          {" "}
          <IsLoading />{" "}
        </div>
      ) : (
        <div className="p-[5px]">
          <div 
          className={`pl-[35px] p-[10px] border-b-[1px] text-base relative`}
          style={{borderBottomColor: backgroundColor,color:textColor}}
          >
            
            {Language["en"].variusDisplaySettings.title}
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.systemStatus}
              <div className="bg-green-700 w-5 h-5 flex items-center justify-center rounded-[5px]">
                {" "}
                <FaBroadcastTower color="white" />
              </div>
            </div>
            <div className="p-[5px]">
  <select
    name="system_off"
    id="system_off"
    value={selectedSystemOffValue}
    onChange={(e) => {
      const selectedValue = e.target.value;
      setSystemOffValue(selectedValue);
      systemOffSec(selectedValue);
    }}
    className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none border-[1px] border-[#999] focus:shadow-md"
    onFocus={(e) => {
      e.target.style.borderColor = `${textColor}`;
    }}
    onBlur={(e) => {
      e.target.style.borderColor = "#999";
      e.target.style.boxShadow = "none";
    }}
  >
    <option value="0">
      {Language["en"].variusDisplaySettings.optionOpen}
    </option>
    <option value="1">
      {Language["en"].variusDisplaySettings.optionClose}
    </option>
  </select>
</div>
            <p className="px-[5px] text-[#555] text-xs">
              {Language["en"].variusDisplaySettings.message}
            </p>
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.typeOfLeague}
            </div>
            <div className="p-[5px]">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="football_type"
                id="football_type"
                value={footballType}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  footballTypeValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.allLeagueAndMatches}
                </option>
                <option value="1">
                  {
                    Language["en"].variusDisplaySettings
                      .onlyMajorLeaguesAndMatches
                  }
                </option>
              </select>
            </div>
            <p className="px-[5px] text-[#555] text-xs">
              {Language["en"].variusDisplaySettings.suggestion}
            </p>
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.leaguesToShowInFootball}
            </div>
            <div className="p-[5px]">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="live_football_type"
                id="live_football_type"
                value={liveFootballType}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  liveFootballTypeValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.allLeagueAndMatches}
                </option>
                <option value="1">
                  {
                    Language["en"].variusDisplaySettings
                      .onlyMajorLeaguesAndMatches
                  }
                </option>
              </select>
            </div>
            <p className="px-[5px] text-[#555] text-xs">
              {Language["en"].variusDisplaySettings.chooseLiveFootball}
            </p>
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.systemStartUpShutDown}
            </div>
            <div className="p-[5px] flex gap-2">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="system_time"
                id="system_time"
                value={systemStartUpTime}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  systemStartUpTimeValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.alwaysOpen}
                </option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                <option value="24:00">24:00</option>
              </select>
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name=""
                id=""
                value={systemShutDownTime}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  systemShutDownTimeValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.alwaysOpen}
                </option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                <option value="24:00">24:00</option>
              </select>
            </div>
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.sysCoupon}
            </div>
            <div className="p-[5px]">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="system_coupon"
                id="system_coupon"
                value={systemCoupon}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  systemCouponValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.open}
                </option>
                <option value="1">
                  {Language["en"].variusDisplaySettings.close}
                </option>
              </select>
            </div>
          </div>
          <div className="my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex items-center gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.lastliveBetMinutes}
            </div>
            <div className="p-[5px] flex gap-2">
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="live_last_min1"
                id="live_last_min1"
                value={liveLastMin1}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  liveLastMin1ValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.untilTheFirstHalf}
                </option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
              </select>
              <select
                className="forms-select-box-shadow dashboard-selects bg-[#f9f9f9] w-[100%] rounded-[3px] p-[9px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                name="live_last_min2"
                id="live_last_min2"
                value={liveLastMin2}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  liveLastMin2ValueUpdate(selectedValue);
                }}
              >
                <option value="0">
                  {Language["en"].variusDisplaySettings.untilTheSecondtHalf}
                </option>
                <option value="70">70</option>
                <option value="71">71</option>
                <option value="72">72</option>
                <option value="73">73</option>
                <option value="74">74</option>
                <option value="75">75</option>
                <option value="76">76</option>
                <option value="77">77</option>
                <option value="78">78</option>
                <option value="79">79</option>
                <option value="80">80</option>
                <option value="81">81</option>
                <option value="82">82</option>
                <option value="83">83</option>
                <option value="84">84</option>
                <option value="85">85</option>
                <option value="86">86</option>
                <option value="87">87</option>
                <option value="88">88</option>
                <option value="89">89</option>
                <option value="90">90</option>
              </select>
            </div>
          </div>
          <div className="flex justify-between items-center my-[5px] p-[10px] bg-[#f9f9f9] forms-box-shadow border-[1px] cursor-pointer hover:bg-[#fff]">
            <div className="flex flex-col gap-2 font-medium text-[13px] p-[5px]">
              {Language["en"].variusDisplaySettings.couponCancellation}
              <p className="text-[#777777] text-[11px] font-normal text-xs mt-[-8px]">
                {Language["en"].variusDisplaySettings.minutes}
                <br />
                {Language["en"].variusDisplaySettings.untilStart}
              </p>
            </div>
            <div className="p-[5px]">
              <input
                className="forms-select-box-shadow w-[85px] h-[37px] bg-[#f9f9f9] placeholder:text-[black] p-[10px] border-[1px] border-[#999] outline-none rounded-[3px] cursor-pointer text-right font-medium focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                type="text"
                value={couponExt}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  couponExtValueUpdate(selectedValue);
                }}
              />
            </div>
          </div>
          <div className="p-[10px] bg-[#fff4ce] text-[#222] text-xs">
            {Language["en"].variusDisplaySettings.noCancellationLiveBets}
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  selectedSystemOffValue: state.selectedSystemOffValue,
  liveFootballType: state.liveFootballType,
  footballType: state.footballType,
  systemCoupon: state.systemCoupon,
  systemStartUpTime: state.systemStartUpTime,
  systemShutDownTime: state.systemShutDownTime,
  liveLastMin1: state.liveLastMin1,
  liveLastMin2: state.liveLastMin2,
  couponExt: state.couponExt,
});

const mapDispatchToProps = {
  systemOffSec,
  liveFootballTypeValueUpdate,
  footballTypeValueUpdate,
  systemCouponValueUpdate,
  systemStartUpTimeValueUpdate,
  systemShutDownTimeValueUpdate,
  liveLastMin1ValueUpdate,
  liveLastMin2ValueUpdate,
  couponExtValueUpdate,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariousDisplaySettings);
