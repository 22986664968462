import React from 'react'
import '../mobile/mobile.css'
import MainTop from './MainTop'

function MobileMenu() {
  return (
    <div>

    <div style={{backgroundColor:'white'}}>
        <div className="main-sport-tab">
        <a className='selected' href="futbol">Futbol</a>
        <a href="basketbol">Basketbol</a>
        <a href="makina">Makina</a>
      </div>
      <div className="main-time-tab">
        <a className='selected' href="futbol">Bugün</a>
        <a href="basketbol">Perş</a>
        <a href="makina">Tümü</a>
      </div>
      <div className="main-time-tab main-time-reverse-tab">
        <a  href="futbol">30dk</a>
        <a href="basketbol">1s</a>
        <a href="makina">3s</a>
        <a  href="futbol">6s</a>
        <a href="basketbol">12s</a>
        <a href="makina">24s</a>
      </div>
    </div>
    </div>
  )
}

export default MobileMenu