import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus, AiOutlineLine } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { TiUserDelete } from "react-icons/ti";
import axios from "axios";
import { connect } from "react-redux";
import Language from '../../language.json'
import { setBackgroundColor,setTextColor } from "../../redux/actions/actions";
import Cookies from "js-cookie";

const MyUsersUser = ({ myUsersStatus,textColor,backgroundColor }) => {
  const navigate = useNavigate();

  const { user_id } = useParams();
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";

  const [stop, setStop] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersCom, setUsersCom] = useState([]);
  const [usersUsername, setUsersUsername] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredUsers = usersCom.filter((user) => {
    return user.username.toLowerCase().includes(searchText.toLowerCase());
  });
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, [stop]);
  usersCom.sort((a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    return dateA - dateB;
  });
  const handleStop = (e) => {
    const userId = e.target.getAttribute("data-id");
    const apiStopUrl = `https://rona.thoth.lt/api/stopuser/${userId}`;

    const confirmStop = window.confirm(
      `${Language["en"].myUsersUser.confirmStop}`
    );
    if (confirmStop) {
      const postData = {
        userId: parseInt(userId),
      };

      axios
        .post(apiStopUrl, postData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setStop(!stop);
        })
        .catch((error) => {
          console.error("API ERROR:", error);
        });
    } else {
    }
  };
  const handleUpdateClick = (updateId) => {
    navigate(`/updateusersuser/${updateId}`);
  };
  const handleDeleteClick = (updateId) => {
    navigate(`/deleteuser/${updateId}`);
  };

  useEffect(() => {
    const matchingUser = users.find((user) => user.user_id == user_id);
    if (matchingUser) {
      setUsersUsername(matchingUser.username);
      setUsersCom(matchingUser.community);
    }
  }, [users, user_id]);
  const totalVideoGameLimit = usersCom.reduce((total, user) => total + parseFloat(user.video_game_limit), 0);
  const totalLimit = usersCom.reduce((total, user) => total + parseFloat(user.limit), 0);
  
  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language["en"].myUsersUser.users}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="flex border-b-[1px] my-[10px]"
        style={{borderBottomColor:backgroundColor}}
        >
          <Link
            to={"/my-users"}
            className="rounded-tl-[10px] bg-[#CCCCCC] text-[#222] py-[15px] px-[25px] text-base font-bold"
          >
            {Language["en"].myUsersUser.myUsers}
          </Link>
          <Link className="rounded-tr-[10px] text-[#fff] py-[15px] px-[25px] text-base font-bold"
          style={{backgroundColor:backgroundColor}}>
            {usersUsername}
          </Link>
        </div>
        <div></div>
        <div>
          <div>
            <input
              type="text"
              onChange={handleSearch}
              placeholder="find username"
              className="forms-select-box-shadow p-[10px] h-[37px] border-[1px] border-[#999] font-semibold text-[14px] outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] "
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
            />
          </div>
          <table className="w-[100%]">
            <thead>
              <tr className="h-[48px]">
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.user}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.authority}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                {Language["en"].myUsersUser.limit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                {Language["en"].myUsersUser.videoGameLimit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.network}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.create}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.lastSeen}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.hire}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]">
                {Language["en"].myUsersUser.situation}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[90px]">
                  <div className="flex gap-1">
                    <div className="bg-[#f0f0f0] py-[5px] px-[10px] rounded-[4px] h-[24px] flex items-center cursor-pointer">
                      <AiOutlineLine color="blue" size={13} />
                    </div>
                    <div className="bg-[#f0f0f0] py-[5px] px-[10px] rounded-[4px] h-[24px] flex items-center cursor-pointer hover:text-[#fff] hover:bg-[#ccc]">
                      <AiOutlinePlus color="blue" size={13} />
                    </div>
                  </div>
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[15%]"></td>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr className="text-xs bg-[#f9f9f9]">
                  <td className="py-1 px-2 font-bold">{user.username}</td>
                  <td className="py-1 px-2">{user.authority}</td>
                  <td className="py-1 px-2 font-bold text-end">{user.limit}</td>
                  <td className="py-1 px-2 font-bold text-end">
                    {user.video_game_limit}
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex items-center justify-center">{Language["en"].myUsersUser.none}</div>
                  </td>
                  <td className="py-1 px-2">{user.created}</td>
                  <td className="py-1 px-2">{user.last_seens}</td>
                  <td></td>
                  <td>
                    {user.status == 1 ? (
                      <div className="py-1 px-2 font-bold text-green-800">
                        {Language["en"].myUsersUser.active}
                      </div>
                    ) : (
                      <div className="py-1 px-2 font-bold text-[#BC2121]">
                        {Language["en"].myUsersUser.passive}
                      </div>
                    )}
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex items-center justify-center">
                      {user.status == 1 ? (
                        <button
                          data-id={user.user_id}
                          value={myUsersStatus}
                          onClick={handleStop}
                          className="bg-[#BC2121] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px] cursor-pointer"
                        >
                          {Language["en"].myUsersUser.stop}
                        </button>
                      ) : (
                        <button
                          data-id={user.user_id}
                          value={myUsersStatus}
                          onClick={handleStop}
                          className="bg-[#54b42d] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] w-[50px] cursor-pointer"
                        >
                          {Language["en"].myUsersUser.start}
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="py-1 px-2">
                    <div className="flex gap-1 items-center justify-center">
                      <div
                        onClick={() => handleUpdateClick(user.user_id)}
                        className="flex gap-1 px-2 bg-[#666666] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] cursor-pointer"
                      >
                        <FaUserEdit size={16} /> {Language["en"].myUsersUser.edit}
                      </div>
                      <div
                        onClick={() => handleDeleteClick(user.user_id)}
                        className="flex gap-1 px-2 bg-[#BC2121] font-bold text-[#fff] text-center items-center rounded-[5px] leading-[25px] cursor-pointer"
                      >
                        <TiUserDelete size={16} /> {Language["en"].myUsersUser.delete}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr className="h-[48px]">
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                  {totalLimit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] text-end">
                  {totalVideoGameLimit}
                </td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[90px]"></td>
                <td className="bg-[#ccc] text-xs font-semibold border-[1px] border-r-[rgba(255,255,255,.3)] border-l-[rgba(0,0,0,.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[#fff] py-[15px] px-[10px] h-[48px] w-[15%]"></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-[10px] text-xs">
                {Language["en"].myUsersUser.subtitle1} <b>{usersCom.length}</b> {Language["en"].myUsersUser.subtitle2} <b>0</b>{Language["en"].myUsersUser.subtitle3}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  myUsersStatus: state.myUsersStatus, 
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});
const mapDispatchToProps = {
  setBackgroundColor,
  setTextColor
}
export default connect(mapStateToProps,mapDispatchToProps)(MyUsersUser);
