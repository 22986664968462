import axios from "axios";
import { useEffect, useState } from "react";
import { setTextColor,setBackgroundColor } from "../redux/actions/actions";
import Language from '../language.json';
import { connect } from "react-redux";
import Cookies from "js-cookie";
const LiveBettingDensity = ({textColor,backgroundColor}) => {
  const [datas, setDatas] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matchingBets, setMatchingBets] = useState([]);
  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getbetdens";

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data.livebets);
      });
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const handleMatchClick = (match) => {
    setSelectedMatch(match);
    const matchingBets = datas.filter((data) => data.event_id === match.event_id);
    setMatchingBets(matchingBets);
  };
  const formatDate = (dateString) => {
    const options = {
      hour12: false
    }
    const formattedDate = new Date(dateString).toLocaleString('tr-TR',options);
    return formattedDate;
  };
  return (
    <div>
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
       {Language["en"].livebettingdenity.LiveBettingDensity}
      </div>
      <div className="p-[15px] flex bg-[#dddddd] rounded-b-[15px]">
        <div className="bg-[#fff] flex-1 p-[15px] flex flex-col min-h-[30px]">
          {datas.map((data, index) => (
            <div className="flex" key={index}>
              <div className="py-0 px-[5px] flex-1">
                <table className="w-[100%]">
                  <tbody>
                    <tr>
                      <td className="transition-all w-[325px] min-w-[325px] max-w-[325px] duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                        {formatDate(data.event_date)}
                      </td>
                      <td className="transition-all w-[370px] min-w-[370px] max-w-[370px] duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                        {truncateText(
                          `${data.competitor1} - ${data.competitor2}`,
                          40
                        )}
                      </td>
                      <td className="transition-all w-[100px] min-w-[100px] max-w-[100px] duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                        {data.market_name}
                      </td>
                      <td className="transition-all w-[120px] min-w-[120px] max-w-[120px] duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                        {data.odd_name}
                      </td>
                      <td className="transition-all w-[80px] min-w-[80px] max-w-[80px] duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                        {data && data.odd_price ? Number(data.odd_price).toFixed(2) : "-"}
                      </td>
                      <td
                        onClick={() => handleMatchClick(data)}
                        className="transition-all text-center duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold underline cursor-pointer"
                        style={{color:textColor}}
                      >
                        {Language["en"].livebettingdenity.matchbets}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
        {selectedMatch && (
          <div className="p-[15px] flex-1 bg-[#fff] rounded-b-[15px]">
            <div className="text-[24px] font-bold p-[10px]">
              {selectedMatch.competitor1} - {selectedMatch.competitor2}
            </div>
            <table className="w-[100%]">
              <tbody>
                {matchingBets.map((bet, index) => (
                  <tr key={index}>
                    <td className="transition-all duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                      {bet.market_name}
                    </td>
                    <td className="transition-all duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold">
                      {bet.odd_name}
                    </td>
                    <td className="transition-all duration-100 py-[8px] px-[10px] relative border-b-[1px] border-b[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-[12px] bg-[#f9f9f9] font-bold text-end">
                      {bet && bet.odd_price ? (bet.odd_price).toFixed(2) : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
})
const mapDispatchToProps = {
  setTextColor,
  setBackgroundColor,
};
export default connect(mapStateToProps,mapDispatchToProps)(LiveBettingDensity);
