import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { setBackgroundColor } from "../redux/actions/actions";
import { TailSpin } from "react-loader-spinner";

function Popup({
 backgroundColor,
 teams,
 preData,
 loading,
 closePopup,
 selectedOdds,
 handleOddClick,
 league,
 date,
}) {
 const [selectedGroupId, setSelectedGroupId] = useState(null);

 const backgroundImages = {
  content: "",
  backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
 };

 const textColor = {
  color: backgroundColor,
 };

 const borderColor = {
  borderBottom: `2px solid ${backgroundColor}`,
 };

 const handleGroupButtonClick = (groupId) => {
  setSelectedGroupId(groupId);
 };

 return (
  <div className="fixed top-0 left-0 w-full z-50 h-full  bg-black bg-opacity-50 flex justify-center items-center">
   <div className="bg-black rounded-xl w-[800px] h-[95%] absolute">
    <button
     className="text-text-white bg-red border-[4px] p-4 top-0 right-0 z-10 rounded-full absolute hover:bg-white 
          hover:text-text-red hover:border-red-600"
     onClick={closePopup}
    >
     <AiOutlineClose />
    </button>

    {loading ? (
     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
      <TailSpin
       height={80}
       width={80}
       color={`${backgroundColor}`}
       ariaLabel="tail-spin-loading"
       radius={1}
       wrapperStyle={{}}
       wrapperClass=""
       visible={true}
      />
     </div>
    ) : (
     <div className="popup">
      <div className="bg-[#222] text-center text-text-white py-4 rounded-t-xl">
       <div className="font-semibold text-2xl">{teams}</div>
      </div>

      <div className="overflow-y-auto h-[95%] bg-black rounded-xl w-[800px] absolute px-5">
      <div className="flex space-x-4 p-4">
        <button
         key="all"
         style={{ ...(!selectedGroupId ? borderColor : {})}}
         className={`text-[#fff] w-full bg-[#333333] p-2 rounded hover:bg-[#444] ${
          !selectedGroupId && `bg-[#1b1b1b] hover:bg-[#1b1b1b]`
         }`}
         onClick={() => handleGroupButtonClick(null)}
        >
         All
        </button>
        {preData?.MarketGroups?.map((group) => (
         <button
          key={group._id}
          style={{...(selectedGroupId === group._id ? borderColor : {})}}
          className={`text-[#fff] w-full bg-[#333333] p-2 rounded hover:bg-[#444] ${
           selectedGroupId === group._id && "bg-[#1b1b1b] hover:bg-[#1b1b1b]"
          }`}
          onClick={() => handleGroupButtonClick(group._id)}
         >
          {group.nameTr}
         </button>
        ))}
       </div>
       <div>
       <ul>
        {preData?.MarketGroups?.filter(
         (group) => !selectedGroupId || group._id === selectedGroupId
        ).map((group) =>
         group.Markets.map((market, index) => (
          
          <div key={index} className="">
           <div className={`text-[13px] py-[10px] px-[15px]`} style={textColor}>
            {market.Name}
           </div>
           <div
            style={backgroundImages}
            className="h-[3px] right-0 left-[22px] bottom-0 w-[100%] rounded-[5px]"
           ></div>
           <div
            className={
             market.ColumnCount == 1 && !market.ColumnCount
              ? "grid grid-cols-1"
              : market.ColumnCount == 2
              ? "grid grid-cols-2"
              : market.ColumnCount == 3
              ? "grid grid-cols-3"
              : market.ColumnCount == 4
              ? "grid grid-cols-4"
              : "grid grid-cols-1"
            }
           >
            {market.Odds[0]?.SPOV
              ? market.Odds ==
               market.Odds?.sort(function (a, b) {
              return a?.SPOV - b?.SPOV;
              })
              : ""}
            {market.Odds.map((odd, index) => (
             <div
              key={index}
              className={`flex justify-between flex-grow pr-[10px] p-[10px] text-[13px] bg-[#333] border-r-[rgba(255,255,255,.1)] 
                               border-l-[rgba(0,0,0,.2)] shadow-custom  border-t-[rgba(255,255,255,.1)] border-b-[rgba(0,0,0,.2)] 
                               top-1 hover:brightness-110 cursor-pointer w-[100%] border border-l-black ${
                                selectedOdds.find(
                                 (i) => i.odd_api_id == odd.ApiId
                                ) && "selected"
                               } ${odd?.RecentStatus}`}
              id={odd?.ApiId}
              onClick={() => {
               handleOddClick(
                odd,
                market,
                league,
                league.Name,
                market.Name,
                date
               );
               closePopup();
              }}
             >
              <span className="text-[#fff] ">
               {odd.additionalName == null || odd.additionalName == "bos"
                ? odd.name
                : odd.additionalName}
              </span>
              <span className="text-[#ffcc00]">{ odd && odd.Price ? odd.Price.toFixed(2) : "-"}</span>
             </div>
            ))}
           </div>
          </div>
         ))
        )}
       </ul>
       </div>
      </div>
     </div>
    )}
   </div>
  </div>
 );
}

const mapStateToProps = (state) => {
 return {
  backgroundColor: state.backgroundColor,
 };
};

export default connect(mapStateToProps, { setBackgroundColor })(Popup);
