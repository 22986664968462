import React, { useState } from "react";
import {Alert, ErrorAlert, ErrorUnAlert, SportsBettingGroup, VideoGames, VirtualBettingGroup} from "./index";
import {Link} from 'react-router-dom'
import { setBackgroundColor, setTextColor } from '../redux/actions/actions';
import { connect } from "react-redux";
import { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const GameToggle = ({backgroundColor,textColor}) => {

  const token = Cookies.get("token")
  const apiUrl = process.env.REACT_APP_API_URL + '/api/on_off_games'
  const [sportGames,setSportGames] = useState([])
  const [videoGames,setVideoGames] = useState([])
  const [virtualGames,setVirtualGames] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const [alert,setAlert] = useState(null)
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  useEffect(() =>{
    axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      setSportGames(response.data.sport_games) 
      setVideoGames(response.data.video_games)
      setVirtualGames(response.data.virtual_games)
      setIsLoading(false)
    })
    .catch((err) => {
      console.error('api error:', err);
    });
  },[])

  function handleSaveChanges(){
    const allGames = {
      sport_games: sportGames,
      video_games: videoGames,
      virtual_games: virtualGames
      };
    axios
      .post(apiUrl, allGames, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.message){
          setAlert(response.data.message);
        }
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => {
        console.error('API ERROR:', error);
      });
  }

  return (
    <div>
      {
        alert && 
        <Alert
        alert={alert}
        setAlert={setAlert}
        />
      }
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        Game On/Off
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="mb-[15px] p-[10px] rounded-[10px] text-[#222] text-[13px] bg-[#ffcc00]">
        The changes you make here have general effects. However, you can use the <Link to={'/open-and-close-quick-game'} className="underline"> Quick Game Open/Close </Link> link under the Users tab to open any game you closed here for a single user.
        </div>
        <div className="bg-[#fff]">
        <div className="flex flex-wrap w-[100%]">
            <div className="w-[33%]">
            <SportsBettingGroup backgroundColor={backgroundColor} textColor={textColor} sportGames={sportGames} setSportGames={setSportGames} isLoading={isLoading}/>
            </div>
            <div className="w-[33%]">
            <VirtualBettingGroup backgroundColor={backgroundColor} textColor={textColor} virtualGames={virtualGames} setVirtualGames={setVirtualGames} isLoading={isLoading}/>
            </div>
            <div className="w-[33%]">
            <VideoGames backgroundColor={backgroundColor} textColor={textColor} videoGames={videoGames} setVideoGames={setVideoGames} isLoading={isLoading}/>
            </div>
        </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <button 
          className="forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] text-[#fff] cursor-pointer transition-all duration-300"
          style={{backgroundColor:backgroundColor}}
          onClick={handleSaveChanges}
          >
            SAVE CHANGES
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
    textColor: state.textColor
  };
};
export default connect(mapStateToProps, { setBackgroundColor, setTextColor })(GameToggle);
