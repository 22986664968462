import Cookies from "js-cookie";

const color = localStorage.getItem("user_color")
const coPanel = Cookies.get("panel")
const initialState = {
  backgroundColor: color || '#2f9015',
  textColor: color || '#2f9015',
  panel: coPanel || 1,
  selectedSystemOffValue: "",
  footballType: "",
  liveFootballType: "",
  systemStartUpTime: "0",
  systemShutDownTime: "0",
  systemCoupon: "",
  liveLastMin1: 0,
  liveLastMin2: 0,
  couponExt: "",
  maxDeposit: "",
  singleMaxDeposit: "",
  liveMaxDeposit: "",
  minDeposit: "",
  eventMaxDeposit: "",
  marketMaxDeposit: "",
  maxCouponLine: "",
  minCouponOdd: "",
  maxCouponOdd: "",
  sysMaxX: "",
  couponWinLimitMaxPay: "",
  couponWinLimitSingleMaxPay: "",
  couponWinLimitLiveMaxPay: "",
  couponWinLimitLiveSingleMaxPay: "",
  couponWinLimitSysMaxX: "",
  oddSafeLiveMaxOdd: "",
  oddSafePreMaxOdd: "",
  oddSafeLiveMinOdd: "",
  oddSafePreMinOdd: "",
  videoGameShowGame: "",
  sportGames: [],
  videoGames: [],
  virtualGames: [],
  changedGames: [],
  newUsersUserType: 5,
  newUsersUsername: "",
  newUsersPassword: "",
  newUsersEmail: "",
  newUsersRememberName: "",
  newUsersPoint: 0,
  newUsersPlayerLimit: 0,
  playerLimitCheckbox: true,
  newUsersAllSystem: "0",
  newUsersAllSystemRule: 25,
  newUsersPreMatch: "0",
  newUsersPreMatchRule: 25,
  newUsersLive: "0",
  newUsersLiveRule: 25,
  newUsersVideoGame: "0",
  newUsersVideoGameRule: 25,
  newUsersAllBets: true,
  allCouponsNo: "",
  allCouponsUserInput: "",
  allCouponsPiece: "",
  allCouponsStatus: "",
  allCouponsBet: "",
  allCouponsDeposit: "",
  allCouponsProbable: "",
  myUsersStatus: "",
  myUsersUpdateUsername: "",
  myUsersUpdatePassword: "",
  myUsersUpdateRememberName: "",
  myUsersUpdateDeduction: 0,
  myUsersUpdateAllSystem: "",
  myUsersUpdateAllSystemRule: 25,
  myUsersUpdatePreMatch: "",
  myUsersUpdatePreMatchRule: 25,
  myUsersUpdateLive: "",
  myUsersUpdateLiveRule: 25,
  myUsersUpdateVideoGame: "",
  myUsersUpdateVideoGameRule: 25,
  myUsersUpdateAllBets: true,
  selectedGames: [],
  selectedGames2: [],
  openingLimit: "",
  openingDeleteRule: true,
  dailyReportUserInput: "",
  dailyReportPiece: "",
  dailyReportBet: "",
  reportDetailsUserInput: "",
  reportDetailsPiece: "",
  reportDetailsBet: "",
  accountActiviteInput: "",
  accountActiviteProcessType: "",
  accountActiviteProcess: "",
  detailToggle: true,
}


const rootReducer = (state = initialState, action) => {

  switch (action.type) {

    //NAVBAR

    case 'SET_BACKGROUND_COLOR':
      return { ...state, backgroundColor: action.payload };
    case 'SET_TEXT_COLOR':
      return { ...state, textColor: action.payload }
    case 'SET_PANEL':
      return { ...state, panel: action.payload }

    // SYSTEM 
    case 'SYSTEM_OFF_SEC':
      return { ...state, selectedSystemOffValue: action.payload };

    case 'FOOTBALL_TYPE_VALUE_UPDATE':
      return { ...state, footballType: action.payload }

    case 'LIVE_FOOTBALL_TYPE_VALUE_UPDATE':
      return { ...state, liveFootballType: action.payload }

    case 'SYSTEM_START_UP_TIME_VALUE_UPDATE':
      return { ...state, systemStartUpTime: action.payload }

    case 'SYSTEM_SHUT_DOWN_TIME_VALUE_UPDATE':
      return { ...state, systemShutDownTime: action.payload }

    case 'SYSTEM_COUPON_UPDATE':
      return { ...state, systemCoupon: action.payload }

    case 'LIVE_LAST_MIN1_UPDATE':
      return { ...state, liveLastMin1: action.payload }

    case 'LIVE_LAST_MIN2_UPDATE':
      return { ...state, liveLastMin2: action.payload }

    case 'COUPON_EXT_UPDATE':
      return { ...state, couponExt: action.payload }

    case 'MAX_DEPOSIT_UPDATE':
      return { ...state, maxDeposit: action.payload }

    case 'SINGLE_MAX_DEPOSIT_UPDATE':
      return { ...state, singleMaxDeposit: action.payload }

    case 'LIVE_MAX_DEPOSIT_UPDATE':
      return { ...state, liveMaxDeposit: action.payload }

    case 'MIN_DEPOSIT_UPDATE':
      return { ...state, minDeposit: action.payload }

    case 'EVENT_MAX_DEPOSIT_UPDATE':
      return { ...state, eventMaxDeposit: action.payload }

    case 'MARKET_MAX_DEPOSIT_UPDATE':
      return { ...state, marketMaxDeposit: action.payload }

    case 'MAX_COUPON_LINE_UPDATE':
      return { ...state, maxCouponLine: action.payload }

    case 'MIN_COUPON_ODD_UPDATE':
      return { ...state, minCouponOdd: action.payload }

    case 'MAX_COUPON_ODD_UPDATE':
      return { ...state, maxCouponOdd: action.payload }

    case 'SYS_MAX_X_UPDATE':
      return { ...state, sysMaxX: action.payload }

    case 'COUPON_WIN_LIMIT_MAX_PAY_UPDATE':
      return { ...state, couponWinLimitMaxPay: action.payload }

    case 'COUPON_WIN_LIMIT_SINGLE_MAX_PAY_UPDATE':
      return { ...state, couponWinLimitSingleMaxPay: action.payload }

    case 'COUPON_WIN_LIMIT_LIVE_MAX_PAY_UPDATE':
      return { ...state, couponWinLimitLiveMaxPay: action.payload }

    case 'COUPON_WIN_LIMIT_LIVE_SINGLE_MAX_PAY_UPDATE':
      return { ...state, couponWinLimitLiveSingleMaxPay: action.payload }

    case 'COUPON_WIN_LIMIT_SYS_MAX_X_UPDATE':
      return { ...state, couponWinLimitSysMaxX: action.payload }

    case 'ODD_SAFE_LIVE_MAX_ODD_VALUE_UPDATE':
      return { ...state, oddSafeLiveMaxOdd: action.payload }

    case 'ODD_SAFE_PRE_MAX_ODD_VALUE_UPDATE':
      return { ...state, oddSafePreMaxOdd: action.payload }

    case 'ODD_SAFE_LIVE_MIN_ODD_VALUE_UPDATE':
      return { ...state, oddSafeLiveMinOdd: action.payload }

    case 'ODD_SAFE_PRE_MIN_ODD_VALUE_UPDATE':
      return { ...state, oddSafePreMinOdd: action.payload }

    case 'VIDEO_GAME_SHOW_GAME_VALUE_UPDATE':
      return { ...state, videoGameShowGame: action.payload }


    //NEW USERS

    case 'NEW_USERS_USER_TYPE_VALUE_UPDATE':
      return { ...state, newUsersUserType: action.payload }

    case 'NEW_USERS_USERNAME_VALUE_UPDATE':
      return { ...state, newUsersUsername: action.payload }

    case 'NEW_USERS_PASSWORD_VALUE_UPDATE':
      return { ...state, newUsersPassword: action.payload }

    case 'NEW_USERS_EMAIL_VALUE_UPDATE':
      return { ...state, newUsersEmail: action.payload }

    case 'NEW_USERS_REMEMBER_NAME_VALUE_UPDATE':
      return { ...state, newUsersRememberName: action.payload }

    case 'NEW_USERS_POINT_VALUE_UPDATE':
      return { ...state, newUsersPoint: action.payload }

    case 'NEW_USERS_PLAYER_LIMIT_VALUE_UPDATE':
      return { ...state, newUsersPlayerLimit: action.payload }

    case 'NEW_USERS_PLAYER_LIMIT_CHECKBOX':
      return { ...state, playerLimitCheckbox: !state.playerLimitCheckbox };

    case 'NEW_USERS_AGENCY_LIMIT_VALUE_UPDATE':
      return { ...state, newUsersAgencyLimit: action.payload }

    case 'NEW_USERS_AGENCY_LIMIT_CHECKBOX':
      return { ...state, agencyLimitCheckbox: !state.agencyLimitCheckbox };
      
    case 'NEW_USERS_SUPER_LIMIT_VALUE_UPDATE':
      return { ...state, newUsersSuperLimit: action.payload }

    case 'NEW_USERS_SUPER_LIMIT_CHECKBOX':
      return { ...state, superLimitCheckbox: !state.superLimitCheckbox };


    case 'NEW_USERS_DEALER_LIMIT_VALUE_UPDATE':
      return { ...state, newUsersDealerLimit: action.payload }

    case 'NEW_USERS_DEALER_LIMIT_CHECKBOX':
      return { ...state, dealerLimitCheckbox: !state.dealerLimitCheckbox };

    case 'NEW_USERS_ALL_SYSTEM_VALUE_UPDATE':
      return { ...state, newUsersAllSystem: action.payload }

    case 'NEW_USERS_ALL_SYSTEM_RULE_VALUE_UPDATE':
      return { ...state, newUsersAllSystemRule: action.payload }

    case 'NEW_USERS_PRE_MATCH_VALUE_UPDATE':
      return { ...state, newUsersPreMatch: action.payload }

    case 'NEW_USERS_PRE_MATCH_RULE_VALUE_UPDATE':
      return { ...state, newUsersPreMatchRule: action.payload }

    case 'NEW_USERS_LIVE_VALUE_UPDATE':
      return { ...state, newUsersLive: action.payload }

    case 'NEW_USERS_LIVE_RULE_VALUE_UPDATE':
      return { ...state, newUsersLiveRule: action.payload }

    case 'NEW_USERS_ALL_BETS_VALUE_UPDATE':
      return { ...state, newUsersAllBets: action.payload }


    case 'NEW_USERS_VIDEO_GAME_VALUE_UPDATE':
      return { ...state, newUsersVideoGame: action.payload }

    case 'NEW_USERS_VIDEO_GAME_RULE_VALUE_UPDATE':
      return { ...state, newUsersVideoGameRule: action.payload }
    // ALL GAMES

    case 'ALL_COUPONS_NO_VALUE_UPDATE':
      return { ...state, allCouponsNo: action.payload }

    case 'ALL_COUPONS_USER_INPUT_VALUE_UPDATE':
      return { ...state, allCouponsUserInput: action.payload }

    case 'ALL_COUPONS_PIECE_VALUE_UPDATE':
      return { ...state, allCouponsPiece: action.payload }

    case 'ALL_COUPONS_STATUS_VALUE_UPDATE':
      return { ...state, allCouponsStatus: action.payload }

    case 'ALL_COUPONS_BET_VALUE_UPDATE':
      return { ...state, allCouponsBet: action.payload }

    case 'ALL_COUPONS_DEPOSIT_VALUE_UPDATE':
      return { ...state, allCouponsDeposit: action.payload }

    case 'ALL_COUPONS_PROBABLE_VALUE_UPDATE':
      return { ...state, allCouponsProbable: action.payload }

    // MY USERS

    case 'MY_USERS_STATUS_VALUE_UPDATE':
      return { ...state, myUsersStatus: action.payload }

    // MY USERS UPDATE

    case 'MY_USERS_UPDATE_USERNAME_VALUE_UPDATE':
      return { ...state, myUsersUpdateUsername: action.payload }

    case 'MY_USERS_UPDATE_PASSWORD_VALUE_UPDATE':
      return { ...state, myUsersUpdatePassword: action.payload }

    case 'MY_USERS_UPDATE_REMEMBER_NAME_VALUE_UPDATE':
      return { ...state, myUsersUpdateRememberName: action.payload }

    case 'MY_USERS_UPDATE_DEDUCTION_VALUE_UPDATE':
      return { ...state, myUsersUpdateDeduction: action.payload }

    case 'MY_USERS_UPDATE_ALL_SYSTEM_VALUE_UPDATE':
      return { ...state, myUsersUpdateAllSystem: action.payload }

    case 'MY_USERS_UPDATE_ALL_SYSTEM_RULE_VALUE_UPDATE':
      return { ...state, myUsersUpdateAllSystemRule: action.payload }

    case 'MY_USERS_UPDATE_PRE_MATCH_VALUE_UPDATE':
      return { ...state, myUsersUpdatePreMatch: action.payload }

    case 'MY_USERS_UPDATE_PRE_MATCH_RULE_VALUE_UPDATE':
      return { ...state, myUsersUpdatePreMatch: action.payload }

    case 'MY_USERS_UPDATE_LIVE_VALUE_UPDATE':
      return { ...state, myUsersUpdateLive: action.payload }

    case 'MY_USERS_UPDATE_LIVE_RULE_VALUE_UPDATE':
      return { ...state, myUsersUpdateLiveRule: action.payload }

    case 'MY_USERS_UPDATE_VIDEO_GAME_VALUE_UPDATE':
      return { ...state, myUsersUpdateVideoGame: action.payload }

    case 'MY_USERS_UPDATE_VIDEO_GAME_RULE_VALUE_UPDATE':
      return { ...state, myUsersUpdateVideoGameRule: action.payload }

    case 'MY_USERS_UPDATE_USERS_ALL_BETS_VALUE_UPDATE':
      return { ...state, myUsersUpdateAllBets: action.payload }

    case 'UPDATE_SELECTED_GAMES':
      return { ...state, selectedGames: action.payload };

    case 'MY_USERS_UPDATE_SELECTED_GAMES':
      return { ...state, selectedGames2: action.payload };

    // DAILY REPORT
    case 'DAILY_REPORT_USER_INPUT_VALUE_UPDATE':
      return { ...state, dailyReportUserInput: action.payload }

    case 'DAILY_REPORT_PIECE_VALUE_UPDATE':
      return { ...state, dailyReportPiece: action.payload }

    case 'DAILY_REPORT_BET_VALUE_UPDATE':
      return { ...state, dailyReportBet: action.payload }

    // REPORT DETAILS

    case 'REPORT_DETAILS_USER_INPUT_VALUE_UPDATE':
      return { ...state, reportDetailsUserInput: action.payload }

    case 'REPORT_DETAILS_PIECE_VALUE_UPDATE':
      return { ...state, reportDetailsPiece: action.payload }

    case 'REPORT_DETAILS_BET_VALUE_UPDATE':
      return { ...state, reportDetailsBet: action.payload }

    // PERSON OPENING LIMIT

    case 'OPENING_LIMIT_VALUE_UPDATE':
      return { ...state, openingLimit: action.payload }

    case 'OPENING_DELETE_RULE_VALUE_UPDATE':
      return { ...state, openingDeleteRule: action.payload }

    // ACTION ACTIVITE

    case 'ACCOUNT_ACTIVITE_INPUT_VALUE_UPDATE':
      return { ...state, accountActiviteInput: action.payload }

    case 'ACCOUNT_ACTIVITE_PROCESS_TYPE_VALUE_UPDATE':
      return { ...state, accountActiviteProcessType: action.payload }

    case 'ACCOUNT_ACTIVITE_PROCESS_VALUE_UPDATE':
      return { ...state, accountActiviteProcess: action.payload }


    case 'SET_DETAIL_POPUP':
      return { ...state, detailToggle: action.payload }

    // RESET INPUT

    case 'RESET_INPUTS':
      return {
        ...initialState,

      };


    default:
      return state;
  }

};

export default rootReducer;
