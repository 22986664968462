import React, { useEffect, useState } from "react";
import { Alert, ChangeRate, ErrorAlert, ErrorUnAlert, IsLoading, UserAffected } from "./index";
import { Link } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import axios from "axios";
import { setTextColor,setBackgroundColor } from "../redux/actions/actions";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import Language from '../language.json'
const GeneralBasis = ({backgroundColor,textColor}) => {
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/getusers";
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/odd_config1";
  const apiGetUrl = process.env.REACT_APP_API_URL + "/api/odd_config1get";

  const [datas, setDatas] = useState([]);
  const [selectData, setSelectData] = useState("All");
  const [selectPercentageData, setSelectPercentageData] = useState("0");
  const [selectScore, setSelectScore] = useState("0");
  const [type, setType] = useState("0");
  const [rule, setRule] = useState("0");
  const [getData, setGetData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  useEffect(() => {
    const postData2 = {
      user_id: selectedUserId,
    };
    axios
      .post(apiGetUrl, postData2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setGetData(response.data);
        if(response.data.error){
          setType("1");
          setRule("0");
          setSelectScore(0)
          setSelectPercentageData(0)
          
        } else {
        if (response.data.config_type === 0) {
          setSelectPercentageData(response.data.config_rule);
          setSelectScore("0");
        } else {
          setSelectScore(response.data.config_rule);
          setSelectPercentageData("0");
        }
      }
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, [selectedUserId]);

  useEffect(() => {
    if (selectPercentageData == 0) {
      setType("1");
      setRule(selectScore);
    }
    if (selectScore == 0) {
      setType("0");
      setRule(selectPercentageData);
    }

    if (selectPercentageData == 0 && selectScore == 0) {
      setType("1");
      setRule("0");
    }
  }, [selectPercentageData, selectScore]);

  const applyRate = () => {
    
    const postData = {
      user_id: selectData,
      config_type: parseFloat(type),
      config_rule: parseFloat(rule),
    };
    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response)=>{
        if(response.data.message){
          setAlert(response.data.message);
        }
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
      })
      .catch((error) => console.error("API ERROR:", error));
  };

  return (
    <div>
      {alert && (
          <Alert
          alert={alert}
          setAlert={setAlert}
          />
        )}
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        General Basis
      </div>
      <div className="bg-[#dddddd] rounded-b-[15px] p-[15px]">
        <div className="flex bg-[#fff] p-[15px]">
          <div className="flex-1 py-0 px-[5px]">
            <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
            style={{borderBottomColor:backgroundColor,color:textColor}}
            >
              <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
                1
              </span>
              User Affected
            </div>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
                <IsLoading />
              </div>
            ) : (
              <UserAffected
                datas={datas}
                selectData={selectData}
                setSelectData={setSelectData}
                handleChange={handleChange}
                textColor={textColor}
                backgroundColor={backgroundColor}
              />
            )}
          </div>
          <div className="flex-1 py-0 px-[5px]">
            <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
            style={{borderBottomColor:backgroundColor,color:textColor}}
            >
              <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
                2
              </span>
              Change Rate
            </div>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
                <IsLoading />
              </div>
            ) : (
              <ChangeRate
                datas={datas}
                selectPercentageData={selectPercentageData}
                setSelectPercentageData={setSelectPercentageData}
                selectScore={selectScore}
                setSelectScore={setSelectScore}
                getData={getData}
                backgroundColor={backgroundColor}
                textColor={textColor}
              />
            )}
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <button
            className={`forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] bg-[${backgroundColor}] text-[#fff] cursor-pointer transition-all duration-300`}
            onClick={applyRate}
          >
            {Language["en"].generalBasis.applyButtonText}
          </button>
        </div>
        <div className=" flex p-[20px] bg-[rgba(0,0,0,0.1)] leading-6 rounded-[5px] mt-5 text-[#444] relative">
          You can access the changes you have previously made here and similar
          rate changes from the{" "}
          <Link to={"/changes-center"} style={{color:textColor}}>
            <div className="flex mx-1 items-center gap-1">
              <BsBoxArrowUpRight /> Changes Center
            </div>
          </Link>{" "}
          link under the System tab in the management menu.
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
})
export default connect(mapStateToProps,{setBackgroundColor,setTextColor})(GeneralBasis);
