import React from 'react'
import '../mobile/mobile.css'
import {FaPlus} from 'react-icons/fa'
import MobileNavbar from './MobileNavbar'
import MobileFooter from './MobileFooter'

function MobileLiveResult() {
  return (
    <div>
        <MobileNavbar/>
    <div className='bulten h-full'>
        <div className="country-name">
            <div className="countryinfo">
                <i className='chFlag'></i>
                <span>
                    <b>Popüler</b>
                    <span>En Çok Tercih Edilen Canlı Maçlar</span>
                </span>
            </div>
            <div className="matchlines">
                <div className="matchbox">
                    <div className="live-infobar">
                        <div className="live-matchnames">
                            <div className="live-teamname">Fener</div>
                            <div className="live-teamname">Galatasaray</div>
                        </div>
                    <div className="live-scores">
                        <div>2</div>
                        <div>0</div>
                    </div>
                    <div className="live-scores halfscores">
                        <div>1</div>
                        <div>0</div>
                    </div>
                    <div className="matchstatus">
                        <span>İkinci Yarı</span>
                        <font className='text-[14px]'>
                            "82"

                        <i>'</i>
                        </font>
                    </div>
                        <div className="matchmore">
                        <a href="" className="morebet ">
                            <FaPlus className='text-white '/>
                        </a>
                        </div>
                    </div>
                    <div className="intheodds">
                        <span className='leftgol'></span>
                        <span className='rightgol'></span>
                        <div className="intheodds">
                            <span className='leftgol'></span>
                            <span className='rightgol'></span>
                            <div className="oddsmain">
                                <table className='matchodds'>
                                    <tbody>
                                        <tr className='thead livethead'>
                                            <td colSpan={3}>Maç Sonucu</td>
                                            <td colSpan={6}>Alt/Üst</td>
                                            <td colSpan={4}>İlk Yarı Alt/Üst</td>
                                            <td colSpan={2}>Karşılıklı Gol</td>
                                        </tr>
                                        <tr className="head">
                                            <td>1</td>
                                            <td>X</td>
                                            <td>2</td>
                                            <td>Alt 2.5</td>
                                            <td>Üst 2.5</td>
                                            <td>Alt 3.5</td>
                                            <td>Üst 3.5</td>
                                            <td>Alt 4.5</td>
                                            <td>Üst 4.5</td>
                                            <td>Alt 0.5</td>
                                            <td>Üst 0.5</td>
                                            <td>Alt 1.5</td>
                                            <td>Üst 1.5</td>
                                            <td>Var</td>
                                            <td>Yok</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="matchlines">
                <div className="matchbox">
                    <div className="live-infobar">
                        <div className="live-matchnames">
                            <div className="live-teamname">Fener</div>
                            <div className="live-teamname">Galatasaray</div>
                        </div>
                    <div className="live-scores">
                        <div>2</div>
                        <div>0</div>
                    </div>
                    <div className="live-scores halfscores">
                        <div>1</div>
                        <div>0</div>
                    </div>
                    </div>
                    <div className="intheodds">
                        <span className='leftgol'></span>
                        <span className='rightgol'></span>
                        <div className="intheodds">
                            <span className='leftgol'></span>
                            <span className='rightgol'></span>
                            <div className="oddsmain">
                                <table className='matchodds'>
                                    <tbody>
                                        <tr className='thead livethead'>
                                            <td colSpan={3}>Maç Sonucu</td>
                                            <td colSpan={6}>Alt/Üst</td>
                                            <td colSpan={4}>İlk Yarı Alt/Üst</td>
                                            <td colSpan={2}>Karşılıklı Gol</td>
                                        </tr>
                                        <tr className="head">
                                            <td>1</td>
                                            <td>X</td>
                                            <td>2</td>
                                            <td>Alt 2.5</td>
                                            <td>Üst 2.5</td>
                                            <td>Alt 3.5</td>
                                            <td>Üst 3.5</td>
                                            <td>Alt 4.5</td>
                                            <td>Üst 4.5</td>
                                            <td>Alt 0.5</td>
                                            <td>Üst 0.5</td>
                                            <td>Alt 1.5</td>
                                            <td>Üst 1.5</td>
                                            <td>Var</td>
                                            <td>Yok</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <MobileFooter/>
            </div>
        
  )
}

export default MobileLiveResult