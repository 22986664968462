import axios from "axios";
import React, { useEffect, useState } from "react";
import { allCouponsBetValueUpdate } from "../../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from '../../language.json'
const UsersBet = ({ allCouponsBet, allCouponsBetValueUpdate,textColor,backgroundColor }) => {
  const lang = "en"
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/games";

  const [usersBet, setUsersBet] = useState([]);

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsersBet(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);
  return (
    <div>
      <span className="text-[13px] font-semibold py-[5px] px-[3px]">{Language[lang].UserBet.text1}</span>

      <select
        onChange={(e) => {
          const selectedValue = e.target.value;
          allCouponsBetValueUpdate(selectedValue);
        }}
        value={allCouponsBet}
        className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
        onFocus={(e) => {
          e.target.style.borderColor = `${textColor}`;
        }}
        onBlur={(e) => {
          e.target.style.borderColor = "#999";
          e.target.style.boxShadow = "none";
        }}
      >
        <option value="">{Language[lang].UserBet.text2}</option>
        {usersBet?.map((item, index) => (
          <React.Fragment key={index}>
            {item?.id !=="3" && item?.id !=="4" &&
            <option value={item?.id}>{Language[lang].Games[item.id]}</option>
            }
          </React.Fragment> 
        ))}
      </select>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allCouponsBet: state.allCouponsBet,
});

const mapDispatchToProps = {
  allCouponsBetValueUpdate,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersBet);
