import React from "react";
import { connect } from "react-redux";
import { updateSelectedGames } from "../../redux/actions/actions";
import Language from '../../language.json'
const Bets = ({ selectedGames, dispatch, AllBets,textColor,backgroundColor }) => {
  const handleCheckboxChange = (data) => {
    const updatedSelectedGames = selectedGames.includes(data)
      ? selectedGames.filter((game) => game !== data)
      : [...selectedGames, data];
    dispatch(updateSelectedGames(updatedSelectedGames));
  };

  return (
    <div className="pt-[15px]">
      <div className="p-[5px]">
        <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
        style={{borderBottomColor:backgroundColor,color:textColor}}
        >
          <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
            4
          </span>
          {Language["en"].bets.title}
        </div>
        <div className="flex flex-wrap p-[2px]">
          {AllBets.map((data, index) => (
            <label
              key={index}
              className="w-[33%] p-[1px] cursor-pointer min-w-[135px]"
            >
              <div className="text-[13px] flex items-center forms-label-box-shadow bg-[#f9f9f9] p-[10px] rounded-[5px]">
                <input
                  className={`cursor-pointer w-5 h-5 border-[1px] border-[${backgroundColor}] mr-[5px]`}
                  style={{accentColor: backgroundColor}}
                  type="checkbox"
                  checked={selectedGames.includes(data) ? false : true}
                  onChange={() => handleCheckboxChange(data)}
                />
                {data.name}
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  newUsersAllBets: state.newUsersAllBets,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Bets);
