import React, { useState,useEffect } from "react";
import {IsLoading} from "../index";
import Language from '../../language.json'

const OddType = ({ oddTypes, oddId = [], setOddId,isDataLoading,textColor }) => {
  const [showSelectAll, setShowSelectAll] = useState({});
  const lang = "en"
  
  const handleMouseEnter = (id) => {
    setShowSelectAll((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id) => {
    setShowSelectAll((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleSelectAllClick = (rateType) => {
    const rateTypeOdds = oddTypes.find((oddType) => oddType.Name === rateType);
    const oddIds = rateTypeOdds.Odds.map((odd) => odd._id);
    setOddId(
      oddId.includes(oddIds[0])
        ? oddId.filter((id) => !oddIds.includes(id))
        : [...oddId, ...oddIds]
    );
  };

const [filteredData, setFilteredData] = useState([oddTypes]);
useEffect(() => {
  setFilteredData(oddTypes)

}, [oddTypes])

  const [filter, setFilter] = useState('');


 
  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilter(value);
    const filtered = oddTypes.filter(item => item.Name.toLowerCase().includes(value));
    
    setFilteredData(filtered ? filtered : oddTypes);

  };

 

  const handleOddClick = (id) => {
    if (oddId.includes(id)) {
      const updatedId = oddId.filter((odd) => odd !== id);
      setOddId(updatedId);
    } else {
      setOddId([...oddId, id]);
    }
  };

  return (
    <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] bg-[#f9f9f9]">
      <div className="p-[5px] font-medium text-[13px]">
        {Language[lang].OddType.text1}
        <div className="text-[12px] text-[#555] py-[0px] px-[5px]">
        {Language[lang].OddType.text2}
        </div>
        <div className="p-[10px] text-left bg-[rgba(0,0,0,0.1)] mt-[5px] text-[12px] text-[#555] relative">
        {Language[lang].OddType.text3} <b> {oddId.length} </b>
        </div>
        <input
            className="forms-select-box-shadow p-[10px] h-[37px] text-[13px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
            type="text"
            value={filter}
            placeholder="Market Adı Arama"
            onChange={handleFilterChange}
          />
      </div>
      <div className="h-[400px] overflow-auto flex flex-col justify-start p-[5px]">
        {isDataLoading ? 
<div className="flex w-full min-h-[100px] mt-3 justify-center items-center">
  
  <IsLoading />
  
</div>
: filteredData?.map((oddType, index) => (
          <div
            key={oddType._id}
            className="mr-[5px] text-[13px] relative py-[5px] px-[10px] mt-[10px]"
          >
            <div
              className={`py-[10px] px-[15px] text-[16px] font-semibold relative cursor-pointer hover:text-[${textColor}]`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onClick={() => handleSelectAllClick(oddType.Name)}
            >
              {oddType.Name}
              <font
                className={`absolute font-normal top-[-2px] left-[15px] ${
                  showSelectAll[index] ? "text-[13px]" : "hidden"
                }`}
                style={{color:textColor}}
              >
                {oddId.includes(oddType.Odds[0]?._id)
                  ? "Drop All"
                  : "Select All"}
              </font>
            </div>
            <div
              className={`flex flex-wrap border-l-[1px] border-l-[#DDD] border-t-[1px] border-t-[#DDD] cursor-pointer`}
            >
              {oddType.Odds.map((odd) => {
                return (
                  <div
                    key={odd._id}
                    id={odd._id}
                    onClick={() => handleOddClick(odd._id)}
                    className={`relative min-w-[80px] border-r-[1px] border-[#ddd] border-b-[1px] border-b-[#ddd] p-[10px] font-normal ${ oddType.ColumnCount === 2 ? 'w-[50%]' : oddType.ColumnCount === 9 ? 'w-[33.33%]' : oddType.ColumnCount === 3 ? 'w-[33.33%]' : 'w-full'} ${
                      oddId.includes(odd._id)
                        ? `bg-[${textColor}] text-[#fff]`
                        : "bg-transparent"
                    }`}
                  >
                    {odd.name}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OddType;
