import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import {setTextColor} from '../redux/actions/actions'
import { connect } from 'react-redux'
const IsLoading = ({textColor}) => {
  return (
    <div className='flex items-center justify-center z-50'>
        <TailSpin
          height="50"
          width="50"
          color={`${textColor}`}
          ariaLabel='tail-spin-loading'
          radius="1"
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
        />
    </div>
  )
}

const mapStateToProps = (state) => ({
  textColor: state.textColor
})

export default connect(mapStateToProps,{setTextColor})(IsLoading)