import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Coupon from "./Coupon";
import { AiOutlineStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa6";
import { TailSpin } from "react-loader-spinner";
import { GiWorld } from "react-icons/gi";
import "./desktop.css";
import Language from "../language.json";
import { setBackgroundColor } from "../redux/actions/actions";
import { connect } from "react-redux";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Cookies from "js-cookie";
function Live({ socket, click, backgroundColor }) {
  const [liveData, setLiveData] = useState([]);
  const [headers, setHeader] = useState([]);
  const [selectedOdds, setSelectedOdds] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState({});
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectLink, setSelectLink] = useState(Language["en"].live.liveall);
  const [favoriteMatches, setFavoriteMatches] = useState([]);
  const [favEventIds, setFavEventIds] = useState([]);
  const [couponchange, setcouponchange] = useState(1);
  const [footballGames,setFootballGames] = useState()
  const [basketballGames,setBasketballGames] = useState()
  function encodeBase64UTF8(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
  }
  function decodeBase64UTF8(encodedStr) {
  return decodeURIComponent(Array.prototype.map.call(atob(encodedStr), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
  const MAX_LOCAL_STORAGE_SIZE = 1024;

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("favoriteMatch")) {
      const storedData = localStorage.getItem("favoriteMatch");
      setFavEventIds(storedData.split(","));
    }
    const base64EncodedString = Cookies.get("gameConfig");
    const decodedString = decodeBase64UTF8(base64EncodedString);
    const gameConfigObject = JSON.parse(decodedString);
    
    gameConfigObject?.sport_games?.live_sports?.map((video) => (
      video.id === "66-1" ? setFootballGames(video.active) : 
      video.id === "67-1" ? setBasketballGames(video.active) : ""
    ))
  }, []);

  if(footballGames === 0 && basketballGames === 1) {
    navigate('/Live-Basketbol')
  } else if (footballGames === 0 && basketballGames === 0){
    navigate('/video-games')
  }
  

  const updateLocalStorage = (data) => {
    localStorage.setItem("favoriteMatch", data);
  };

  const isLocalStorageFull = () => {
    const totalSize = Object.keys(localStorage).reduce((acc, key) => {
      return acc + localStorage[key].length;
    }, 0);
    if (totalSize >= MAX_LOCAL_STORAGE_SIZE) {
      setTimeout(() => {
        clearLocalStorage();
      }, 1000);
    }
  };

  const clearLocalStorage = () => {
    alert("Your favorite match limit has been exceeded. Try again.");
    window.location.reload();
    localStorage.removeItem("favoriteMatch");
  };

  const toggleFavorite = (eventId) => {
    eventId = String(eventId);
    if (favEventIds.includes(eventId)) {
      const updatedIds = favEventIds.filter((id) => id !== eventId);
      updateLocalStorage(updatedIds.join(","));
      setFavEventIds(updatedIds);
    } else {
      const updatedIds = [...favEventIds, eventId];
      const updatedData = updatedIds.join(",");

      if (isLocalStorageFull()) {
        clearLocalStorage();
      }

      updateLocalStorage(updatedData);
      setFavEventIds(updatedIds);
    }
  };

  const flags = require("country-iso-3-to-2");

  useEffect(() => {
    socket.on("disconnect", function () {
      window.location.reload();
    });
    socket.on("reconnect", function () {
      socket.emit("live-general", 66);
    });
    socket.emit("live-general", 66);
    socket.on("live-general", (data) => {
      const events = data?.Result?.Items[0]?.Items;
      const headers = data?.Result?.Items[0].Headers;
      setLiveData(events);
      setHeader(headers);
      setIsLoading(false);
    });
  }, [socket]);
  const filterMatches = (filter) => {
    setSelectedFilter(filter);
    setSelectLink(filter);
  };

  const navbarStyle = {
    boxShadow: "0px -1px 0px rgba(255, 255, 255, .1) inset",
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <TailSpin
          height={80}
          width={80}
          color={`${backgroundColor}`}
          ariaLabel="tail-spin-loading"
          radius={1}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  const d = 0;
  const handleOddClick = (clickedOdd, marketName, eventsId, events, item) => {
    const matchId = events.Id;
    const clickedOddApiId = clickedOdd.ApiId;
    const isOddSelected = selectedOdds.some(
      (odd) => odd.odd_api_id === clickedOddApiId
    );

    if (isOddSelected) {
      removeOddFromSelection(clickedOddApiId);
      localStorage.removeItem("selectedOdds", clickedOddApiId);
    } else {
      const clickedOddName = clickedOdd.name;
      const clickedOddTime = events?.LiveCurrentTime;
      const clickedOddScore = events?.LiveScore;
      const teams = events?.Competitors?.map((competitor) => competitor.Name);
      const previousSelectedOddIndex = selectedOdds.findIndex(
        (odd) => odd.event_id === matchId
      );

      if (previousSelectedOddIndex !== -1) {
        removeOddFromSelection(
          selectedOdds[previousSelectedOddIndex].odd_api_id
        );
      }
      // click(clickedOdd.ApiId);
      const oddInfo = {
        odd_name: clickedOddName,
        market_name: item?.Name,
        teams: teams.join(" - "),
        odd_price: clickedOdd.price,
        LiveCurrentTime: clickedOddTime,
        LiveScore: clickedOddScore,
        org_market_type:clickedOdd?.OrgMarketTypeId,
        is_live: events?.Status,
        event_id: events.Id,
        market_api_id: item?.ApiId,
        market_id: item?._id,
        marketSportId: item?.SportMarketId,
        odd_api_id: clickedOddApiId,
        oddSelectionTypeId: clickedOdd?.SelectionTypeId,
        odd_id: clickedOdd?._id,
        api_group_id: item?.api_group_id
      };
      setSelectedOdds((prevSelectedOdds) => [...prevSelectedOdds, oddInfo]);

      setSelectedMatches({ ...selectedMatches, [matchId]: true });
    }
  };
  const backgroundImages = {
    content: "",
    backgroundImage: `linear-gradient(90deg, ${backgroundColor} 20%, transparent)`,
  };

  const removeOddFromSelection = (clickedOddApiId) => {
    setSelectedOdds((prevSelectedOdds) => {
      return prevSelectedOdds.filter(
        (odd) => odd.odd_api_id !== clickedOddApiId
      );
    });
    const element = document.getElementById(clickedOddApiId);
    if (element) {
      element.classList.remove("selected");
    }
  };

  const showAllMatches = () => {
    setLiveData(filteredMatches);
  };

  const backgroundColors = {
    backgroundColor: backgroundColor,
  };
  const filteredData = liveData?.filter((live) => {
    return live?.Events?.some((matches) => {
      return (
        matches.Competitors[0].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        ) ||
        matches.Competitors[1].Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        )
      );
    });
  });
  let noMatchMessageDisplayed = false;
  return (
    <div className="mt-[15px]">
      <div
        style={{ padding: "0px" }}
        className="h-full relative flex container mx-auto mt-[35px]"
      >
        <div className="live mx-auto flex">
          <div>
            <div>
              <div className="sport-selected-tab-live ml-[-220px]">
                <div
                  style={backgroundImages}
                  className="h-[3px] right-0 left-0 bottom-0 w-[100%] absolute rounded-[5px]"
                ></div>

                { footballGames === 1 &&
                  <Link style={backgroundColors} to="/Live">
                  {Language["en"].live.soccer}
                </Link>
                }
                { basketballGames === 1 &&
                  <Link
                  className="bg-[#333]"
                  to="/Live-Basketbol"
                  onClick={() => showAllMatches("Basketbol")}
                >
                  {Language["en"].live.basketball}
                </Link>
                }
              </div>
            </div>
            <div className="pt-[52px]">
              <div className="p-2 bg-[#333] w-[1220px] mt-2 h-[50px] flex flex-grow">
                <input
                  className="w-[45.6%] p-[5px] outline-none text-[14px] border-[#999] rounded-lg pl-[6px] text-[black]"
                  type="search"
                  placeholder={Language["en"].live.teamsearch}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="flex flex-1 items-center pl-[5px] justify-between rounded-2xl">
                  <Link
                    className="search-links hover:brightness-110 cursor-pointer"
                    style={{
                      ...navbarStyle,
                      backgroundColor:
                        selectLink === Language["en"].live.liveall
                          ? `${backgroundColor}`
                          : "transparent",
                    }}
                    onClick={() => filterMatches(Language["en"].live.liveall)}
                  >
                    {Language["en"].live.liveall}
                  </Link>
                  <Link
                    className="search-links hover:brightness-110 cursor-pointer"
                    style={{
                      ...navbarStyle,
                      backgroundColor:
                        selectLink === Language["en"].live.firsthalf
                          ? `${backgroundColor}`
                          : "transparent",
                    }}
                    onClick={() => filterMatches(Language["en"].live.firsthalf)}
                  >
                    {Language["en"].live.firsthalf}
                  </Link>
                  <Link
                    className="search-links hover:brightness-110 cursor-pointer"
                    style={{
                      ...navbarStyle,
                      backgroundColor:
                        selectLink === Language["en"].live.secondhalf
                          ? `${backgroundColor}`
                          : "transparent",
                    }}
                    onClick={() =>
                      filterMatches(Language["en"].live.secondhalf)
                    }
                  >
                    {Language["en"].live.secondhalf}
                  </Link>
                  <Link
                    className="search-links hover:brightness-110 cursor-pointer"
                    style={{
                      ...navbarStyle,
                      backgroundColor:
                        selectLink === Language["en"].live.goalless
                          ? `${backgroundColor}`
                          : "transparent",
                    }}
                    onClick={() => filterMatches(Language["en"].live.goalless)}
                  >
                    {Language["en"].live.goalless}
                  </Link>
                  <Link
                    className="search-links hover:brightness-110 cursor-pointer"
                    style={{
                      ...navbarStyle,
                      backgroundColor:
                        selectLink === Language["en"].live.scored
                          ? `${backgroundColor}`
                          : "transparent",
                    }}
                    onClick={() => filterMatches(Language["en"].live.scored)}
                  >
                    {Language["en"].live.scored}
                  </Link>
                </div>
              </div>
            </div>
            <div>
              {favEventIds.length > 0 && (
                <div className="ligbox mt-[350px]">
                  <div className="live-box">
                    <div className="lig-title flex justify-between">
                      <div> {Language["en"].live.favoriteMatches} </div>
                      <div>
                        <button
                          onClick={() => (
                            localStorage.removeItem("favoriteMatch"),
                            setFavEventIds([])
                          )}
                          className="p-1 px-2 rounded-[4px]"
                          style={{ backgroundColor: backgroundColor }}
                        >
                          {Language["en"].live.favoriteMatchesButton}
                        </button>
                      </div>
                    </div>
                    {headers
                      .filter(
                        (head) =>
                          head.MarketTypeId == "1_" ||
                          head.MarketTypeId == "29_" ||
                          head.MarketTypeId == "18_all"
                      )
                      ?.map((head) => (
                        <div
                          className={`live-odds-header `}
                          key={head.MarketTypeId}
                        >
                          <div>{head.Name}</div>
                        </div>
                      ))}
                  </div>
                  {liveData?.map((live) =>
                    live?.Events?.map((event) => {
                      const check = favEventIds.find((obj) => obj == event.Id);
                      if (check) {
                        return (
                          <div className="live-box" key={event.Id}>
                            <div
                              onClick={() => toggleFavorite(event.Id)}
                              className="live-favorite cursor-pointer"
                            >
                              <FaStar className={`ml-1 text-lg text-yellow`} />
                            </div>
                            <div className="live-info">
                              <div className="live-dakika">
                                {event.LiveCurrentTime === "Not started"
                                  ? "-"
                                  : event.LiveCurrentTime}
                              </div>
                              <div className="live-devre">
                                {parseInt(event.LiveCurrentTime) > 45
                                  ? Language["en"].live.secondhalf
                                  : Language["en"].live.firsthalf}
                              </div>
                              <div className="live-score">
                                {event.LiveScore}
                              </div>
                              <div className="live-teams">
                                <Link to={`/Detail/${event?.Id}`}>
                                  {event?.Competitors[0].Name} -{" "}
                                  {event?.Competitors[1].Name}
                                </Link>
                              </div>
                            </div>
                            <div className="donneme ml-4">
                              <div className="live-odds" key={event.Id}>
                                <div className="live-odds">
                                  <div
                                    className={`in-odds ${
                                      event.Items?.length === 0
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    {event.Items?.map((item) =>
                                      item.Name === "1x2"
                                        ? item.Items?.map((odd, oddkey) => (
                                            <div
                                              key={oddkey}
                                              className={` ${odd?.RecentStatus}
                                                  ${
                                                    selectedOdds.find(
                                                      (obj) =>
                                                        obj.odd_api_id ==
                                                        odd?.ApiId
                                                    )
                                                      ? `selected cursor-pointer`
                                                      : "cursor-pointer"
                                                  }
                                                `}
                                            >
                                              <div
                                                id={odd?.ApiId}
                                                onClick={() => (
                                                  handleOddClick(
                                                    odd,
                                                    liveData,
                                                    headers,
                                                    event,
                                                    item
                                                  ),
                                                  setcouponchange(1)
                                                )}
                                              >
                                                <span className="text-[#999] text-sm">
                                                  {`${
                                                    odd.additionalName ===
                                                      "bos" ||
                                                    odd.additionalName === null
                                                      ? odd?.name
                                                      : odd.additionalName
                                                  }`}
                                                </span>
                                                <span className="odd">
                                                  {odd && odd.price
                                                    ? odd?.price.toFixed(2)
                                                    : "-"}
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        : null
                                    )}
                                  </div>

                                  <div
                                    className={`in-odds ${
                                      event.Items?.length === 0
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    {event.Items?.find(
                                      (item) => item.Name === "Toplam gol"
                                    )
                                      ?.Items?.slice(0, 2)
                                      .map((odd, oddkey) => {
                                        const item = event.Items?.find(
                                          (item) => item.Name === "Toplam gol"
                                        );
                                        return (
                                          <React.Fragment key={oddkey}>
                                            {oddkey === 0 && (
                                              <div className="handicap">
                                                {odd?.SPOV}
                                              </div>
                                            )}
                                            <div
                                              className={` ${odd?.RecentStatus}
                                                  ${
                                                    selectedOdds.find(
                                                      (obj) =>
                                                        obj.odd_api_id ==
                                                        odd?.ApiId
                                                    )
                                                      ? `selected cursor-pointer`
                                                      : "cursor-pointer"
                                                  }
                                                `}
                                            >
                                              <div
                                                id={odd?.ApiId}
                                                onClick={() => (
                                                  handleOddClick(
                                                    odd,
                                                    liveData,
                                                    headers,
                                                    event,
                                                    item
                                                  ),
                                                  setcouponchange(1)
                                                )}
                                              >
                                                <span className="text-[#999] text-sm">
                                                  {`${
                                                    odd?.additionalName ===
                                                      "bos" ||
                                                    odd.additionalName === null
                                                      ? odd?.name
                                                      : odd?.additionalName
                                                  }`}
                                                </span>
                                                <span className="odd">
                                                  {odd && odd.price
                                                    ? odd.price.toFixed(2)
                                                    : "-"}
                                                </span>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>

                                  <div
                                    className={`in-odds ${
                                      event.Items?.length === 0
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    {event.Items?.map((item) =>
                                      item.Name === "Çifte Şans"
                                        ? item.Items?.map((odd, oddkey) => (
                                            <div
                                              key={oddkey}
                                              className={` ${odd?.RecentStatus}
                                                  ${
                                                    selectedOdds.find(
                                                      (obj) =>
                                                        obj.odd_api_id ==
                                                        odd?.ApiId
                                                    )
                                                      ? `selected cursor-pointer`
                                                      : "cursor-pointer"
                                                  }
                                                `}
                                            >
                                              <div
                                                id={odd?.ApiId}
                                                onClick={() => (
                                                  handleOddClick(
                                                    odd,
                                                    liveData,
                                                    headers,
                                                    event,
                                                    item
                                                  ),
                                                  setcouponchange(1)
                                                )}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <span className="text-[#999] text-sm">
                                                  {`${
                                                    odd.additionalName !== "bos"
                                                      ? odd.additionalName
                                                      : odd?.name
                                                  }`}
                                                </span>
                                                <span className="odd">
                                                  {odd && odd.price
                                                    ? odd?.price.toFixed(2)
                                                    : "-"}
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        : null
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  )}
                </div>
              )}
            </div>

            {filteredData?.length > 0 ? (
              filteredData.map((league, i) => {
                const showLeague =
                  !selectedFilter ||
                  selectedFilter === Language["en"].live.liveall ||
                  league?.Events?.some((event) => {
                    const filterCondition =
                      (selectedFilter === Language["en"].live.firsthalf &&
                        parseInt(event.LiveCurrentTime) < 45) ||
                      (selectedFilter === Language["en"].live.secondhalf &&
                        parseInt(event.LiveCurrentTime) >= 45) ||
                      (selectedFilter === Language["en"].live.goalless &&
                        event.LiveScore === "0:0") ||
                      (selectedFilter === Language["en"].live.scored &&
                        event.LiveScore !== "0:0");

                    return filterCondition;
                  });
                if (
                  !showLeague &&
                  !selectedFilter &&
                  !noMatchMessageDisplayed
                ) {
                  noMatchMessageDisplayed = true;
                  return (
                    <div>
                      <div
                        className={`flex w-[100%] bg-[#111] rounded-[10px] border-t-[#000] border-t-4 
                                    mt-[15px] justify-center items-center text-white h-[50px] text-[14px]`}
                      >
                        {Language["en"].live.noshow}
                      </div>
                    </div>
                  );
                } else if (showLeague) {
                  return (
                    <div>
                      <div className="ligbox" key={i}>
                        <div className="live-box">
                          {league.ISO ? (
                            <span
                              className={`fi fi-${String(
                                flags(
                                  league.ISO === "ENG"
                                    ? (league.ISO = "GBR")
                                    : league.ISO
                                )
                              ).toLowerCase()} mr-2`}
                            ></span>
                          ) : (
                            <span className="flex items-center">
                              <GiWorld color="white" />
                            </span>
                          )}
                          <div className="lig-title">{league.Name}</div>
                          {headers
                            .filter(
                              (head) =>
                                head.MarketTypeId === "1_" ||
                                head.MarketTypeId === "18_all" ||
                                head.MarketTypeId === "10_"
                            )
                            ?.map((head) => (
                              <div
                                className={`live-odds-header ${
                                  head.MarketTypeId === "10_"
                                    ? "bg-[#393939]"
                                    : ""
                                }`}
                                key={head._id}
                              >
                                <div>{head.Name}</div>
                              </div>
                            ))}
                        </div>

                        {league.Events.filter(
                          (o) =>
                            o.Competitors[0].Name.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            o.Competitors[1].Name.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                        ).map((event) => {
                          if (event !== "yok") {
                            const filterCondition =
                              (selectedFilter ===
                                Language["en"].live.firsthalf &&
                                parseInt(event.LiveCurrentTime) < 45) ||
                              (selectedFilter ===
                                Language["en"].live.secondhalf &&
                                parseInt(event.LiveCurrentTime) >= 45) ||
                              (selectedFilter ===
                                Language["en"].live.goalless &&
                                event.LiveScore === "0:0") ||
                              (selectedFilter === Language["en"].live.scored &&
                                event.scored !== "0:0") ||
                              selectedFilter === Language["en"].live.liveall;

                            if (!selectedFilter || filterCondition) {
                              return (
                                <div className="live-box" key={event.Id}>
                                  <div className="live-favorite cursor-pointer">
                                    {favEventIds.includes(String(event.Id)) ? (
                                      <FaStar
                                        className={`ml-1 text-lg text-yellow`}
                                        onClick={() => toggleFavorite(event.Id)}
                                      />
                                    ) : (
                                      <AiOutlineStar
                                        className={`ml-1 text-lg text-white ${favEventIds.includes(
                                          event.Id
                                        )}`}
                                        onClick={() => toggleFavorite(event.Id)}
                                      />
                                    )}
                                  </div>
                                  <div className="live-info">
                                    <div className="live-dakika">
                                      {event.LiveCurrentTime === "Not started"
                                        ? "-"
                                        : event.LiveCurrentTime}
                                    </div>
                                    <div className="live-devre">
                                      {parseInt(event.LiveCurrentTime) > 45
                                        ? Language["en"].live.secondhalf
                                        : Language["en"].live.firsthalf}
                                    </div>
                                    <div className="live-score">
                                      {event.LiveScore}
                                    </div>
                                    <div className="live-teams">
                                      <Link to={`/Detail/${event?.Id}`}>
                                        {event?.Competitors[0].Name} -{" "}
                                        {event?.Competitors[1].Name}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="donneme ml-4">
                                    <div className="live-odds" key={event.Id}>
                                      <div className="live-odds">
                                        <div
                                          className={`in-odds ${
                                            event.Items?.length === 0
                                              ? "disabled"
                                              : ""
                                          }`}
                                        >
                                          {event.Items?.map((item) =>
                                            item.Name === "1x2"
                                              ? item.Items?.map(
                                                  (odd, oddkey) => (
                                                    <div
                                                      key={oddkey}
                                                      className={` ${
                                                        odd?.RecentStatus
                                                      }
                                                  ${
                                                    selectedOdds.find(
                                                      (obj) =>
                                                        obj.odd_api_id ==
                                                        odd?.ApiId
                                                    )
                                                      ? `selected cursor-pointer`
                                                      : "cursor-pointer"
                                                  }
                                                `}
                                                    >
                                                      <div
                                                        id={odd?.ApiId}
                                                        onClick={() => (
                                                          handleOddClick(
                                                            odd,
                                                            liveData,
                                                            headers,
                                                            event,
                                                            item
                                                          ),
                                                          setcouponchange(1)
                                                        )}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span className="text-[#999] text-sm">
                                                          {`${
                                                            odd.additionalName ===
                                                              "bos" ||
                                                            odd.additionalName ===
                                                              null
                                                              ? odd?.name[0]
                                                              : odd.additionalName
                                                          }`}
                                                        </span>
                                                        <span className="odd">
                                                          {odd && odd.price
                                                            ? odd?.price.toFixed(
                                                                2
                                                              )
                                                            : "-"}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )
                                                )
                                              : null
                                          )}
                                        </div>

                                        <div
                                          className={`in-odds ${
                                            event.Items?.length === 0
                                              ? "disabled"
                                              : ""
                                          }`}
                                        >
                                          {event.Items?.find(
                                            (item) => item.Name === "Toplam gol"
                                          )
                                            ?.Items?.slice(0, 2)
                                            .map((odd, oddkey) => {
                                              const item = event.Items?.find(
                                                (item) => item.Name === "Toplam gol"
                                              );
                                              return (
                                                <React.Fragment key={oddkey}>
                                                  {oddkey === 0 && (
                                                    <div className="handicap">
                                                      {odd?.SPOV}
                                                    </div>
                                                  )}
                                                  <div
                                                    className={` ${
                                                      odd?.RecentStatus
                                                    }
                                                    ${
                                                      selectedOdds.find(
                                                        (obj) =>
                                                          obj.odd_api_id ==
                                                          odd?.ApiId
                                                      )
                                                        ? `selected cursor-pointer`
                                                        : "cursor-pointer"
                                                    }
                                                  `}
                                                  >
                                                    <div
                                                      id={odd?.ApiId}
                                                      onClick={() => (
                                                        handleOddClick(
                                                          odd,
                                                          liveData,
                                                          headers,
                                                          event,
                                                          item
                                                        ),
                                                        setcouponchange(1)
                                                      )}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <span className="text-[#999] text-sm">
                                                        {`${
                                                          odd?.additionalName ===
                                                            "bos" ||
                                                          odd.additionalName ===
                                                            null
                                                            ? odd?.name.slice(-3)
                                                            : odd?.additionalName.slice(-3)
                                                        }`}
                                                      </span>
                                                      <span className="odd">
                                                        {odd && odd.price
                                                          ? odd?.price.toFixed(
                                                              2
                                                            )
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            })}
                                        </div>

                                        <div
                                          className={`in-odds ${
                                            event.Items?.length === 0
                                              ? "disabled"
                                              : ""
                                          }`}
                                        >
                                          {event.Items?.map((item) =>
                                            item.Name === "Çifte Şans"
                                              ? item.Items?.map(
                                                  (odd, oddkey) => (
                                                    <div
                                                      key={oddkey}
                                                      className={` ${
                                                        odd?.RecentStatus
                                                      }
                                                  ${
                                                    selectedOdds.find(
                                                      (obj) =>
                                                        obj.odd_api_id ==
                                                        odd?.ApiId
                                                    )
                                                      ? `selected cursor-pointer`
                                                      : "cursor-pointer"
                                                  }
                                                `}
                                                    >
                                                      <div
                                                        id={odd?.ApiId}
                                                        onClick={() => (
                                                          handleOddClick(
                                                            odd,
                                                            liveData,
                                                            headers,
                                                            event,
                                                            item
                                                          ),
                                                          setcouponchange(1)
                                                        )}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span className="text-[#999] text-sm">
                                                          {`${
                                                            odd.additionalName ==
                                                              "bos" ||
                                                            odd.additionalName ==
                                                              null
                                                              ? odd?.name[0]
                                                              : odd.additionalName
                                                          }`}
                                                        </span>
                                                        <span className="odd">
                                                          {odd && odd.price
                                                            ? odd?.price.toFixed(
                                                                2
                                                              )
                                                            : "-"}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )
                                                )
                                              : null
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div
                className={`flex w-[100%] bg-[#111] rounded-[10px] border-t-[#000] border-t-4 
              mt-[15px] justify-center items-center text-white h-[50px] text-[14px]`}
              >
                {Language["en"].live.noshow}
              </div>
            )}
          </div>
          <div className="ml-1 mt-[50px]">
            <Coupon
              selectedOdds={selectedOdds}
              setSelectedOdds={setSelectedOdds}
              couponchange={couponchange}
              setcouponchange={setcouponchange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    backgroundColor: state.backgroundColor,
  };
};
export default connect(mapStateToProps, { setBackgroundColor })(Live);
