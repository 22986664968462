import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";

function VideoGamesLeft({
 handleBrandClick,
 handleAllClick,
 handleBrandName,
 setIsBrandSelected,
}) {
 const [brandData, setBrandData] = useState([]);
 const [gameBalance, setGameBalance] = useState();
 const [brandColor, setBrandColor] = useState("All Providers");

 const token = Cookies.get("token");
 const apiUrl = "https://rona.thoth.lt/api/video_game_brand";
 const apiUrl2 = "https://rona.thoth.lt/api/balance-game";

 useEffect(() => {
  axios
   .get(apiUrl, {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   })
   .then((response) => {
    setBrandData(response.data);
   })
   .catch((error) => {
    console.error(error);
   });
  axios
   .get(apiUrl2, {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   })
   .then((response) => {
    setGameBalance(response.data.balance);
   })
   .catch((error) => {
    console.error(error);
   });
 }, [
  handleBrandClick,
  handleAllClick,
  handleBrandName,
  setIsBrandSelected,
  token,
 ]);

 return (
  <div className="w-[250px]">
   <div
    className="text-[24px] text-white font-bold py-[20px] px-[30px]"
    style={{
     paddingBottom: "0px",
     color: "rgba(255,255,255,.6)",
     fontSize: "16px",
     textAlign: "center",
    }}
   >
    Game Limit
    <div className="text-[24px] py-0 px-[30ox] font-bold text-white">
     <span className="inline-block bg-[#111] rounded-[10px] w-[100%] text-center p-[10px]">
      {gameBalance}
     </span>
    </div>
    <div className="text-[24px] text-white font-bold py-[20px] px-[30px]">
     Providers
    </div>
    <div className="flex flex-col pt-0 pr-[25px] pb-[25px] ">
     <div
      className={`bg-[#000] w-[200px] mb-[3px] h-[50px] text-[rgba(255,255,255,.6)] cursor-pointer ${
       brandColor === "All Providers" ? "bg-[#000]" : "bg-[rgba(0,0,0,.5)]"
      }`}
      onClick={() => {
       handleAllClick(1);
       handleBrandClick(null);
       handleBrandName("All Video Games");
       setIsBrandSelected(false);
       setBrandColor("All Providers");
      }}
      style={{
       display: "flex",
       alignItems: "center",
       justifyContent: "flex-start",
      }}
     >
      <div className="mx-auto">All Providers</div>
     </div>
     {brandData.map((brand) => (
      <Link
       key={brand.id}
       onClick={() => {
        handleBrandClick(brand.id);
        handleBrandName(brand.name);
        setBrandColor(brand.name);
       }}
       className={`text-white w-[200px] h-[50px] my-[4px] mx-0 bg-[rgba(0,0,0,.5)] py-[7px] 
      px-[15px] rounded-[5px] decoration-none flex items-center hover:bg-black`}
       style={{
        backgroundColor: brandColor === brand.name ? "#000" : "",
       }}
      >
       <img
        className="w-[40px] h-[20px] mr-[10px]"
        src={brand.img}
        alt={brand.name}
       />
       {brand.name}
      </Link>
     ))}
    </div>
   </div>
  </div>
 );
}

export default VideoGamesLeft;
