import React, { useState } from "react";
import IsLoading from "../IsLoading";
import { GiWorld } from "react-icons/gi";
import Language from '../../language.json'

const CompetitionsClosed = ({
  leagueDatas,
  oddId = [],
  setOddId,
  searchValue,
  setSearchValue,
  isDataLoading,
  textColor,
  backgroundColor
}) => {
  const [showSelectAll, setShowSelectAll] = useState({});
  const lang = "en"
  const handleMouseEnter = (id) => {
    setShowSelectAll((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id) => {
    setShowSelectAll((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelectAllClick = (rateType) => {
    const rateTypeOdds = leagueDatas.find(
      (leagueData) => leagueData.Name === rateType
    );
    const oddIds = rateTypeOdds.Odds.map((league) => league.event_id);
    setOddId(
      oddId.includes(oddIds[0])
        ? oddId.filter((id) => !oddIds.includes(id))
        : [...oddId, ...oddIds]
    );
  };

  const handleOddClick = (id) => {
    if (oddId.includes(id)) {
      const updatedId = oddId.filter((odd) => odd !== id);
      setOddId(updatedId);
    } else {
      setOddId([...oddId, id]);
    }
  };

  const compareLeagues = (a, b) => {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  };
  const sortedLeagueDatas = [...leagueDatas].sort(compareLeagues);
  const formatDate = (dateString) => {
    const options = {
      hour12: false
    }
    const formattedDate = new Date(dateString).toLocaleString("tr-TR",options)
    return formattedDate
  }
  return (
    <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] mx-[0px] bg-[#f9f9f9]">
      <div className="p-[5px] font-medium text-[13px]">
        {Language[lang].CompetitionsClosed.text1} <br />
        <span className="text-[12px] text-[#555] py-[5px]">
        {Language[lang].CompetitionsClosed.text2}
        </span>
      </div>
      <div className="p-[10px] text-left bg-[rgba(0,0,0,0.1)] relative text-[12px] text-[#555]">
      {Language[lang].CompetitionsClosed.text3} <b>{oddId.length}</b> {Language[lang].CompetitionsClosed.text3C}
      </div>
      <div className="h-[400px] overflow-auto flex flex-col justify-start p-[5px]">
        <div className="mr-[5px] py-[5px] ">
          <input
            className="forms-select-box-shadow p-[10px] h-[37px] text-[13px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
            type="text"
            placeholder="search for competition"
            value={searchValue}
            onChange={handleSearchInputChange}
          />
        </div>
        {isDataLoading ?  
            <div className='flex w-full min-h-[100px] mt-3 justify-center items-center'> <IsLoading/> </div>
          :
          sortedLeagueDatas?.map((leagueData, index) => (
             leagueData.Odds.length > 0 &&
          <div
            key={leagueData._id}
            className="mr-[5px] text-[13px] relative py-[5px] px-[10px] mt-[10px]"
          >
            
              <>
            <div
              className={`py-[10px] flex px-[15px] text-[16px] font-semibold relative cursor-pointer hover:text-[${textColor}]`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onClick={() => handleSelectAllClick(leagueData.Name)}
            >
              {leagueData.ISO ? (
                          <img src={require(`../../assest/4x3/${leagueData?.ISO?.toLowerCase()}.svg`)} width={'25px'} alt="svg" className="mr-2" />
                        ) : (
                          <span className="flex items-center mr-3">
                            <GiWorld color="white"size={20} />
                          </span>
                        )}
              {leagueData.Name}
              <font
                className={`absolute font-normal top-[-5px] left-[15px] ${
                  showSelectAll[index] ? "text-[13px]" : "hidden"
                }`}
                style={{color:textColor}}
              >
                {oddId.includes(leagueData.Odds._id)
                  ? "Drop All"
                  : "Select All"}
              </font>
            </div>
            <div
              className={`flex flex-wrap border-l-[1px] border-l-[#DDD] border-t-[1px] border-t-[#DDD] cursor-pointer`}
            >
              {leagueData &&
                leagueData.Odds &&
                leagueData.Odds.map((league, index) => {
                  return (
                    <div
                      key={index}
                      id={league.event_id}
                      onClick={() => handleOddClick(league.event_id)}
                      className={`w-[50%] border-r-[1px] flex justify-between border-r-[#DDD] border-b-[1px] border-b-[#DDD] p-[10px] ${
                        oddId && oddId.includes(league.event_id)
                          ? `bg-[${backgroundColor}] text-[#fff]`
                          : "bg-transparent"
                      }`}
                    >
                      <div>
                        {league.comp_1_name}-{league.comp_2_name}
                      </div>
                      <div>{formatDate(league.start_date)}</div>
                    </div>
                  );
                })}
            </div>
            </>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CompetitionsClosed;
