import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  ErrorAlert,
  MyUsersBets,
  MyUsersUpdateLoginInfo,
} from "../index";
import Language from "../../language.json";
import { setTextColor, setBackgroundColor } from "../../redux/actions/actions";
import Cookies from "js-cookie";

const MyUsersUserUpdate = ({
  backgroundColor,
}) => {
  const { user_id } = useParams();
  const [myUsersUpdatePassword, setMyUsersUpdatePassword] = useState("");
  const [sportGames, setSportGames] = useState([]);
  const [videoGames, setVideoGames] = useState([]);
  const [virtualGames, setVirtualGames] = useState([]);
  const [editUsername, setEditUsername] = useState("");
  const [rememberName, setRememberName] = useState("");
  const [deduction, setDeduction] = useState("");
  const [comissionType, setComissionType] = useState([]);
  const [allBets, setAllBets] = useState([]);
  const [alert, setAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const dispatch = useDispatch();
  const selectedGames2 = useSelector((state) => state.selectedGames2);
  const [myUsersUpdateUsername, setMyUsersUpdateUsername] = useState("");
  const [myUsersUpdateRememberName, setMyUsersUpdateRememberName] =
    useState("");
  const [myUsersUpdateDeduction, setMyUsersUpdateDeduction] = useState("");
  const token = Cookies.get("token");
  const apiUrl = process.env.REACT_APP_API_URL + "/api/on_off_games";
  const apiGetUrl =
    process.env.REACT_APP_API_URL + `/api/getuserdetail/${user_id}`;

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSportGames(response.data.sport_games);
        setVideoGames(response.data.video_games);
        setVirtualGames(response.data.virtual_games);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(apiGetUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setEditUsername(response.data.username);
        setRememberName(response.data.remember_name);
        setDeduction(response.data.earning_deduction);
        setComissionType(response.data.user_comission);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  const updateUser = () => {
    const token = Cookies.get("token");
    const apiPostUrl =
      process.env.REACT_APP_API_URL + `/api/updateuser/${user_id}`;

    const postData = {
      username: myUsersUpdateUsername,
      pass: myUsersUpdatePassword,
      note: myUsersUpdateRememberName,
      earning_deduction: Number(myUsersUpdateDeduction),
      sport_games: sportGames,
      video_games: videoGames,
      virtual_games: virtualGames,
    };

    if (myUsersUpdateUsername.trim() === "") {
      delete postData.username;
    }

    if (myUsersUpdatePassword.trim() === "") {
      delete postData.pass;
    }

    if (myUsersUpdateRememberName.trim() === "") {
      delete postData.remembername;
    }

    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data === "ok") {
          setAlert("İşlem Başarılı");
        } else {
          setAlert("Error, Please check ");
        }
      })
      .catch((error) => {
        console.error("API ERROR", error);
      });
  };
  return (
    <div>
      {alert && <Alert alert={alert} setAlert={setAlert} />}
      {errorAlert && (
        <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
      )}
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language["en"].myUsersUserUpdate.title}
      </div>
      <div className="p-[15px] bg-[#dddddd] rounded-b-[15px]">
        <div className="bg-[#fff]">
          <div className="flex flex-wrap w-[100%]">
            <div className="flex-1 max-w-[600px]">
              <MyUsersUpdateLoginInfo
                user_id={user_id}
                editUsername={editUsername}
                deduction={deduction}
                rememberName={rememberName}
                setMyUsersUpdatePassword={setMyUsersUpdatePassword}
                myUsersUpdateUsername={myUsersUpdateUsername}
                setMyUsersUpdateUsername={setMyUsersUpdateUsername}
                setMyUsersUpdateRememberName={setMyUsersUpdateRememberName}
                myUsersUpdateRememberName={myUsersUpdateRememberName}
                myUsersUpdateDeduction={myUsersUpdateDeduction}
                setMyUsersUpdateDeduction={setMyUsersUpdateDeduction}
              />
            </div>
            <div className="flex-1">
              <MyUsersBets
                selectedGames2={selectedGames2}
                dispatch={dispatch}
                user_id={user_id}
                allBets={allBets}
                setAllBets={setAllBets}
                sportGames={sportGames}
                setSportGames={setSportGames}
                videoGames={videoGames}
                setVideoGames={setVideoGames}
                virtualGames={virtualGames}
                setVirtualGames={setVirtualGames}
              />
            </div>
          </div>
        </div>
        <div className="p-[10px] text-center bg-[#fff] mt-[2px] rounded-b-[20px]">
          <Link>
            <button
              onClick={updateUser}
              className="forms-button w-[30%] min-w-[240px] p-[10px] text-[18px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,0.2)] rounded-[5px] text-[#fff] cursor-pointer transition-all duration-300"
              style={{ backgroundColor: backgroundColor }}
            >
              {Language["en"].myUsersUserUpdate.buttonUpdate}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  myUsersUpdateAllSystem: state.myUsersUpdateAllSystem,
  myUsersUpdateAllSystemRule: state.myUsersUpdateAllSystemRule,
  myUsersUpdatePreMatch: state.myUsersUpdatePreMatch,
  myUsersUpdatePreMatchRule: state.myUsersUpdatePreMatchRule,
  myUsersUpdateLive: state.myUsersUpdateLive,
  myUsersUpdateLiveRule: state.myUsersUpdateLiveRule,
  myUsersUpdateVideoGame: state.myUsersUpdateVideoGame,
  myUsersUpdateVideoGameRule: state.myUsersUpdateVideoGameRule,
  backgroundColor: state.backgroundColor,
  textColor: state.textColor,
});

const mapDispatchToProps = {
  setBackgroundColor,
  setTextColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyUsersUserUpdate);
