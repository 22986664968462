import React, { useState,useEffect } from "react";
import { FaUser, FaKey, FaBalanceScale } from "react-icons/fa";
import Modal from "react-modal";
import "./desktop.css";
import axios from "axios";
import IsLoading from "./IsLoading";
import Cookies from "js-cookie";
import Language from '../language.json'


function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hashToken, setHashToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [domains, setdomains] = useState("");
  let width=window.innerWidth;
  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const domain = url.hostname;
    setdomains(domain.replace("www.",""));
    let cleanedDomain="https://m."+domain.replace("www.","");
    if(window.innerWidth<798){
      window.location.href = cleanedDomain;
    }
  }, [width]);
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleLoginClick = async () => {
    try {
      const response = await axios.post("https://rona.thoth.lt/login", {
        username: username,
        pass: password,
      });

      if (response.data.hashtoken) {
        Cookies.set("token", response.data.hashtoken,{ path: '/', domain: domains });
        window.location.href = '/';
        setHashToken(hashToken);
        setModalIsOpen(false);
        setIsLoading(false);
        Cookies.set("username",response.data.username,{ path: '/', domain: domains })
        Cookies.set("user_type",response.data.user_type,{ path: '/', domain: domains })
        const gameConfigObject = response.data.gameconfig;

        const jsonString = JSON.stringify(gameConfigObject);
        const base64EncodedString = btoa(jsonString);
        Cookies.set("gameConfig", base64EncodedString,{ path: '/', domain: domains });
      } else {
        setModalIsOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const bg = {
    overlay: {
      background: "rgb(0, 0,0, 0.7)",
    },
  };      
  return (
    <>
    {isLoading ? (
      <div className="flex w-full mt-3 justify-center items-center">
        {" "}
        <IsLoading />{" "}
      </div>
    ) :
    <div className="flex mt-0 mr-auto mb-0 ml-auto relative">
      { modalIsOpen && (
        <div className="fixed z-1 top-0 right-0 left-0 bottom-0">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Uyarı"
            className="w-[300px] absolute top-[100px] bg-[#fff] left-[40%] flex justify-center text-center items-center flex-col rounded-[10px] shadow-login-error"
            style={bg}
          >
            <div className="text-[13px] font-semibold p-[15px]">
              {Language["en"].loginPage.loginerror1}
            </div>
            <div className="w-full">
              <div
                onClick={closeModal}
                className="text-[13px] bg-[#BC2121] w-full p-[8px] mt-4 text-[#fff] text-center decoration-none font-semibold 
            rounded-bl-[10px] rounded-br-[10px] border border-t-[rgba(0,0,0,.2)] outline-none cursor-pointer"
              >
              {Language["en"].loginPage.okey}
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div
        className="w-[450px] mt-[100px] bg-[rgba(0,0,0,.6)] my-0 mx-auto py-[25px] px-[50px] border border-[rgba(255,255,255,.2)] shadow-login rounded-br-[25px]
            rounded-tl-[25px] relative"
      >
        <div className="text-[54px] font-bold text-white tracking-[-2px] absolute top-[-60px] z-[-2]">
          Rona V2
        </div>
        <div className="absolute right-0 h-[5px] bg-gradient-to-r from-red-600 via-transparent-75 to-transparent left-[20px] top-[-1px] rounded-[5px] shadow-login"></div>
        <div className="text-[#999] text-[13px] py-[10px] px-0 ">
          {Language["en"].loginPage.title}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col py-[10px] px-0 w-full">
            <span className="text-white text-[13px] pb-[10px]">{Language["en"].loginPage.username}</span>
            <span className="relative">
              <div
                className="absolute text-[#999] h-[37px] rounded-tr-none rounded-br-none top-[1px] left-0.3 rounded-[5px] 
                            bottom-[1px] w-[40px] border border-r-[#DDD] justify-center items-center"
              >
                <FaUser className="w-[40px] mt-2" />
              </div>
              <input
                type="text"
                value={username}
                onKeyDown={(e) => {
                  if (e.key === 'Enter'|| e.key === 'NumpadEnter') {
                    handleLoginClick();
                  }
                }}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full p-[5px] text-[18px] font-bold outline-none shadow-input-shadow border
                            border-[#DDD] rounded-[5px] pl-[50px]"
              />
            </span>
          </div>
          <div className="flex flex-col py-[10px] px-0 w-full">
            <span className="text-white text-[13px] pb-[10px]">{Language["en"].loginPage.pass}</span>
            <span className="relative">
              <div
                className="absolute text-[#999] h-[37px] rounded-tr-none rounded-br-none top-[1px] left-0.3 rounded-[5px] bottom-[1px] w-[40px]
                         border border-r-[#DDD] justify-center items-center"
              >
                <FaKey className="w-[40px] mt-2" />
              </div>
              <input
                type="password"
                value={password}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                    handleLoginClick();
                  }
                }}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-[5px] text-[18px] font-bold outline-none shadow-input-shadow border
                            border-[#DDD] rounded-[5px] pl-[50px]"
              />
            </span>
          </div>
          <div className="w-full mt-[10px]">
            <button
              onClick={handleLoginClick}
              className="w-full h-[50px] items-center justify-center  text-[18px] font-bold outline-none shadow-login-button-shadow border
                         border-[rgba(0,0,0,.2)] rounded-[5px] bg-[#BC2121] text-white cursor-pointer hover:brightness-110"
            >
              {Language["en"].loginPage.login}
            </button>
          </div>
        </div>
        <div className="pt-[15px] px-0 pb-0 text-[11px] text-[#999]">
          {Language["en"].loginPage.loginbottomtext}
        </div>
        <div className="p-[15px] ml-[40%] text-[#ffcc00]">
          <FaBalanceScale />
        </div>
        <div
          className="absolute h-[5px] bottom-[-60px] bg-gradient-to-r from-transparent shadow-log via-black to-transparent w-[330px] 
                left-[50%] ml-[-165px] filter-blur-(5px)"
        ></div>
      </div>
    </div>
      }
      </>
  );
}


export default Login;
