import React, { useState } from "react";
import {
  newUsersUserTypeValueUpdate,
  newUsersUsernameValueUpdate,
  newUsersPasswordValueUpdate,
  newUsersEmailValueUpdate,
  newUsersRememberNameValueUpdate,
  newUsersPointValueUpdate,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Language from '../../language.json'
const EntryandLimitInformation = ({
  newUsersUserType,
  newUsersUserTypeValueUpdate,
  newUsersUsername,
  newUsersUsernameValueUpdate,
  newUsersPassword,
  newUsersPasswordValueUpdate,
  newUsersEmail,
  newUsersEmailValueUpdate,
  newUsersRememberName,
  newUsersRememberNameValueUpdate,
  newUsersPoint,
  newUsersPointValueUpdate,
  limits,
  textColor,
  backgroundColor
}) => {
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [emailError, setEmailError] = useState("");
  // const isEmailValid = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };
  return (
    <div className="pl-[15px] pt-[15px]">
      <div className="p-[5px]">
        <div className={`p-[10px] pl-[35px] border-b-[1px] relative`}
        style={{borderBottomColor: backgroundColor,color:textColor}}
        >
          <span className={`absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff] bg-[${backgroundColor}]`}>
            1
          </span>
          {Language["en"].entryandLimitInfo.title}
        </div>
        <div className="flex flex-wrap">
          <div className="forms-box-shadow p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div className="p-[5px] font-medium text-[13px]">{Language["en"].entryandLimitInfo.userType}</div>
            <div className="flex p-[5px] justify-between">
              <select
                className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222]"
                name="user_type"
                id="user_type"
                value={newUsersUserType}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  newUsersUserTypeValueUpdate(selectedValue);
                }}
              >
                {
                  Number(Cookies.get("user_type")) == 1 &&
                  <option value="3">{Language["en"].entryandLimitInfo.superadmin}{"("+limits.super_created_limit+")"}</option>
                }
                {
                  Number(Cookies.get("user_type")) == 3 &&
                  <option value="7">{Language["en"].entryandLimitInfo.agency}{"("+limits.admin_created_limit+")"}</option>
                }
                {
                  Number(Cookies.get("user_type")) == 7 &&
                  <option value="5">{Language["en"].entryandLimitInfo.dealer}{"("+limits.dealer_created_limit+")"}</option>
                }
                {
                  Number(Cookies.get("user_type")) == 5 &&
                  <option value="9">{Language["en"].entryandLimitInfo.customer}{"("+limits.web_created_limit+")"}</option>
                }
                {
                  Number(Cookies.get("user_type")) == 2 &&
                  <option value="3">{Language["en"].entryandLimitInfo.super}</option>
                }
              </select>
            </div>
          </div>
          <div className="forms-box-shadow p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
            <div>
              <div className="p-[5px] font-medium text-[13px]">{Language["en"].entryandLimitInfo.username}</div>
              <div className="flex p-[5px] justify-between">
                <input
                  className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder={Language["en"].entryandLimitInfo.usernamePlaceHolder}
                  name="username"
                  id="username"
                  value={newUsersUsername}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersUsernameValueUpdate(selectedValue);
                    if (selectedValue.length < 3) {
                      setUsernameError(`${Language["en"].entryandLimitInfo.usernameErrorMessage}`);
                    } else {
                      setUsernameError("");
                    }
                  }}
                />
              </div>
              {usernameError && (
                <div className="text-red-500 text-xs ml-3">{usernameError}</div>
              )}
            </div>
            {/* <div>
              <div className="p-[5px] font-medium text-[13px]">
                {Language["en"].entryandLimitInfo.emailAddress}
              </div>
              <div className="flex p-[5px] justify-between">
                <input
                  className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder={Language["en"].entryandLimitInfo.emailPlaceHolder}
                  name="email"
                  id="email"
                  type="email"
                  value={newUsersEmail}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersEmailValueUpdate(selectedValue);
                    // if (!isEmailValid(selectedValue)) {
                    //   setEmailError(`${Language["en"].entryandLimitInfo.emailErrorMessage}`);
                    // } else {
                    //   setEmailError("");
                    // }
                  }}
                />
              </div>
              {/* {emailError && (
                <div className="text-red-500 text-xs ml-3">{emailError}</div>
              )} 
            </div> */}
            <div>
              <div className="p-[5px] font-medium text-[13px]">{Language["en"].entryandLimitInfo.password}</div>
              <div className="flex p-[5px] justify-between">
                <input
                  className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder={`${Language["en"].entryandLimitInfo.passwordPlaceHolder}`}
                  name="password"
                  id="password"
                  type="password"
                  value={newUsersPassword}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersPasswordValueUpdate(selectedValue);
                    if (selectedValue.length < 4) {
                      setPasswordError(`${Language["en"].entryandLimitInfo.passwordErrorMessage}`);
                    } else {
                      setPasswordError("");
                    }
                  }}
                />
              </div>
              {passwordError && (
                <div className="text-red-500 text-xs ml-3">{passwordError}</div>
              )}
            </div>
            <div>
              <div className="p-[5px] font-medium text-[13px]">
                {Language["en"].entryandLimitInfo.rememberName}
              </div>
              <div className="flex p-[5px] justify-between">
                <input
                  className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999]"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder={`${Language["en"].entryandLimitInfo.rememberNamePlaceHolder}`}
                  name="remember_name"
                  id="remember_name"
                  value={newUsersRememberName}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersRememberNameValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>
            {Cookies.get("user_type") == 7 || Cookies.get("user_type")== 5 ?
            <div>
              <div className="p-[5px] font-medium text-[13px]">{Language["en"].entryandLimitInfo.startPoint}</div>
              <div className="flex p-[5px] justify-between">
                <input
                  className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999] text-end"
                  onFocus={(e) => {
                    e.target.style.borderColor = `${textColor}`;
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#999";
                    e.target.style.boxShadow = "none";
                  }}
                  placeholder={`${Language["en"].entryandLimitInfo.startPointPlaceHolder}`}
                  name="start_point"
                  id="start_point"
                  type="number"
                  value={newUsersPoint}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    newUsersPointValueUpdate(selectedValue);
                  }}
                />
              </div>
            </div>:""}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newUsersUserType: state.newUsersUserType,
  newUsersUsernameValueUpdate: state.newUsersUsernameValueUpdate,
  newUsersPasswordValueUpdate: state.newUsersPasswordValueUpdate,
  newUsersEmailValueUpdate: state.newUsersEmailValueUpdate,
  newUsersRememberNameValueUpdate: state.newUsersRememberNameValueUpdate,
  newUsersPointValueUpdate: state.newUsersPointValueUpdate,
});

const mapDispatchToProps = {
  newUsersUserTypeValueUpdate,
  newUsersUsernameValueUpdate,
  newUsersPasswordValueUpdate,
  newUsersEmailValueUpdate,
  newUsersRememberNameValueUpdate,
  newUsersPointValueUpdate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryandLimitInformation);
