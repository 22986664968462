import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
function VideoGetGames({ attribute_id }) {
 const apiUrl = "https://rona.thoth.lt/api/video_game_list";
 const token = Cookies.get("token");
 const [gameList, setGameList] = useState([]);
 const typeId = 1;
 const currentUrl = window.location.href;
 const handleGameClick = (id) => {
  
  axios
  .post(
   "https://rona.thoth.lt/api/casino/authplay",
   { game_id: id, callback: currentUrl },
   {
    headers: {
     Authorization: `Bearer ${token}`,
    },
   }
  )
  .then((response) => {
    if(response.data.success === "true"){
      window.open(response.data.url, "_blank");
    }else{
      
    }
   
  })
  .catch((error) => {
   console.error(error);
  });

 };
 useEffect(() => {
  axios
   .post(
    apiUrl,
    { attribute_id: attribute_id, type_id: typeId },
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   )
   .then((response) => {
    setGameList(response.data);
   })
   .catch((error) => {
    console.error(error);
   });
 }, []);



 return (
  <>
   {gameList.length > 0 &&
    gameList.map((games) => (
     <div
      onClick={() => handleGameClick(games.id)}
      className="w-[20%] h-[170px] relative bg-[#111] hover:bg-[#217cbc] hover:shadow-[#217cbc] cursor-pointer"
     >
      <div className="text-white text-[16px] relative z-[999] flex p-[10px] items-end h-[100%] shadow-slot-free-spin transition-all">
       {games.name}
      </div>
      <div
       style={{ backgroundImage: `url(${games.img})` }}
       className="absolute left-[2px] top-[2px] right-[2px] bottom-[2px] bg-cover bg-no-repeat border border-[1px solid rgba(255,255,255,.4)]"
      ></div>
     </div>
    ))}
  </>
 );
}
export default VideoGetGames;
