import React from "react";
import { setTextColor,setBackgroundColor } from "../../redux/actions/actions";
import { connect } from "react-redux";
import Language from '../../language.json'

const MyUsersUpdateLoginInfo = ({
  myUsersUpdateUsername,
  setMyUsersUpdateUsername,
  myUsersUpdatePassword,
  setMyUsersUpdatePassword,
  myUsersUpdateRememberName,
  setMyUsersUpdateRememberName,
  myUsersUpdateDeduction,
  setMyUsersUpdateDeduction,
  editUsername,
  rememberName,
  deduction,
  textColor,
  backgroundColor
}) => {
    
  return (
    <div>
      <div className="p-[10px]  border-b-[1px] relative"
      style={{borderBottomColor:backgroundColor,color:textColor}}
      >
        {Language["en"].myUsersUpdateLoginInfo.title}
      </div>
      <div className="forms-box-shadow p-[10px] w-[100%] border-[1px] border-[#DDD] my-[5px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
        <div>
          <div className="p-[5px] font-medium text-[13px]">{Language["en"].myUsersUpdateLoginInfo.username}</div>
          <div className="flex p-[5px] justify-between">
            <input
              className="forms-select-box-shadow text-[#888888] dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9]"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              name="username"
              id="username"
              value={myUsersUpdateUsername || editUsername}
              
            />
          </div>
        </div>
        <div>
          <div className="p-[5px] font-medium text-[13px]">{Language["en"].myUsersUpdateLoginInfo.password}</div>
          <div className="flex p-[5px] justify-between">
            <input
              className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#222]"
              onFocus={(e) => {
                e.target.style.borderColor = `${textColor}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#999";
                e.target.style.boxShadow = "none";
              }}
              placeholder="*******"
              name="password"
              id="password"
              type="password"
              value={myUsersUpdatePassword}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMyUsersUpdatePassword(selectedValue)
              }}
            />
          </div>
        </div>
        <div>
          <div className="p-[5px] font-medium text-[13px]">{Language["en"].myUsersUpdateLoginInfo.rememberName}</div>
          <div className="flex p-[5px] justify-between">
            <input
              className="forms-select-box-shadow dashboard-selects text-[13px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] placeholder:text-[#999]"
                  onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              placeholder="not request"
              name="remember_name"
              id="remember_name"
              value={myUsersUpdateRememberName||rememberName}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMyUsersUpdateRememberName(selectedValue)
              }}
            />
          </div>
        </div>
        <div>
          <div className="p-[5px] font-medium text-[13px]">
            {Language["en"].myUsersUpdateLoginInfo.EarningsDeduction}
          </div>
          <div className="flex p-[5px] justify-between">
            <input
              className="forms-select-box-shadow dashboard-selects text-[13px] max-w-[80px] mr-[5px] p-[8px] px-[10px] border-[1px] border-[#999] font-semibold outline-none rounded-[3px] w-[100%] cursor-pointer bg-[#f9f9f9] text-[#222] text-end"
                          onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              name="start_point"
              id="start_point"
              type="number"
              value={myUsersUpdateDeduction || deduction}
              onChange={(e) => {
                const selectedValue = e.target.value
                setMyUsersUpdateDeduction(selectedValue)
            }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    backgroundColor: state.backgroundColor,
    textColor: state.textColor
  });
  
  const mapDispatchToProps = {
    setBackgroundColor,
    setTextColor
  };

export default connect(mapStateToProps,mapDispatchToProps)(MyUsersUpdateLoginInfo);
