import axios from "axios";
import React, { useEffect } from "react";
import Language from '../../language.json'
import Cookies from "js-cookie";
const SportType = ({
  usersBet,
  setUsersBet,
  selectSportType,
  handleSportType,
  textColor
}) => {
  const token = Cookies.get("token");
    const apiUrl = process.env.REACT_APP_API_URL +"/api/games";
  const lang = "en"
  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUsersBet(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  }, []);
  const sportGames = usersBet.filter(
    (item) => item.game_types === "sport_games"
  );
  return (
    <div>
      <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] mx-[0px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
        <div className="p-[5px] font-medium text-[13px]">{Language[lang].SportType.text1}</div>
        <div className="p-[5px] flex justify-between">
          <select
            className="forms-select-box-shadow dashboard-selects text-[13px] h-[40px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            name="user_affected"
            id="user_affected"
            value={selectSportType}
            onChange={handleSportType}
          >
            {sportGames?.map((data) => (
                <option key={data.id} value={data.id}>{Language[lang].Games[data.id]}</option> 
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SportType;
