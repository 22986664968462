import React, { useEffect, useState } from 'react'

import IsLoading from '../IsLoading';
import Language from '../../language.json'
const VideoGames = ({backgroundColor,textColor,videoGames,setVideoGames,isLoading}) => {

  const [selectedSports, setSelectedSports] = useState([]);

  useEffect(() => {
    const initiallySelectedSports = videoGames.filter((sport) => sport.active === 1);
    setSelectedSports(initiallySelectedSports);
  }, [videoGames]);

  const handleCheckboxChange = (event, sport) => {
    const isChecked = event.target.checked;

    if (isChecked) {

      setSelectedSports((prevSelectedSports) => [...prevSelectedSports, sport]);
    } else {

      setSelectedSports((prevSelectedSports) =>
        prevSelectedSports.filter((selectedSport) => selectedSport.id !== sport.id)
      );
    }

    const updatedVideoGames = videoGames.map((originalSport) =>
      originalSport.id === sport.id ? { ...originalSport, active: isChecked ? 1 : 0 } : originalSport
    );
  
    setVideoGames(updatedVideoGames);
  };
  return (
    <div className='p-[15px]'>
    <div className='p-[5px]'>
  <div className='p-[10px] pl-[35px] border-b-[1px] relative' style={{borderBottomColor:backgroundColor,color:textColor}}>
  <span className='absolute left-0 top-2 border-[2px] border-[#fff] w-[30px] rounded-[100%] h-[30px] flex justify-center items-center text-[18px] font-bold text-[#fff]' style={{backgroundColor:backgroundColor}}>3</span>
  {Language["en"].videoGames.title}
  </div> 
  <div className='flex flex-wrap p-[2px]'>
    {isLoading ? <div className='flex w-full mt-3 justify-center items-center'> <IsLoading/> </div>
    :
      videoGames.map((data) => (
        <label key={data.id} className='w-[50%] p-[1px] cursor-pointer min-w-[135px]'>
          <div className='text-[13px] flex items-center forms-label-box-shadow bg-[#f9f9f9] p-[10px] rounded-[5px]'>
            <input
              type='checkbox'
              id={data.id}
              className='cursor-pointer w-5 h-5 border-[1px] mr-[5px]'
              style={{accentColor: backgroundColor,borderColor:backgroundColor}}
              checked={selectedSports.some((selectedSport) => selectedSport.id === data.id)}
              onChange={(event) => handleCheckboxChange(event, data)}
            />
            {data.name}
          </div>
        </label>
      ))}
    </div>
  </div>
  </div>
  )
}

export default VideoGames