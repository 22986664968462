import React from "react";
import Language from '../../language.json'
const LeaguesShow = ({ selectShowLeague, handleLeaguesShow,textColor }) => {
  const lang = "en"
  return (
    <div>
      <div className="forms-box-shadow p-[10px] border-[1px] border-[#ddd] my-[5px] mx-[0px] bg-[#f9f9f9] cursor-pointer hover:bg-[#fff]">
        <div className="p-[5px] font-medium text-[13px]">{Language[lang].LeaguesShow.text1}</div>
        <div className="p-[5px] flex justify-between">
          <select
            className="forms-select-box-shadow dashboard-selects text-[13px] h-[40px] p-[10px] border-[1px] border-[#999] font-bold outline-none rounded-[3px] w-full cursor-pointer bg-[#f9f9f9]"
            onFocus={(e) => {
              e.target.style.borderColor = `${textColor}`;
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#999";
              e.target.style.boxShadow = "none";
            }}
            name="leagues_show"
            id="leagues_show"
            value={selectShowLeague}
            onChange={handleLeaguesShow}
          >
            <option value="0">{Language[lang].LeaguesShow.text2}</option>
            <option value="1">{Language[lang].LeaguesShow.text3}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default LeaguesShow;
