import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import VideoGamesLeft from "./VideoGamesLeft";
import VideoGamesTop from "./VideoGamesTop";
import VideoGamesFree from "./VideoGamesFree";
import VideoGamesAll from "./VideoGamesAll";
import Cookies from "js-cookie";
import axios from "axios";
function VideoGamesPage() {
 const [searchTerm, setSearchTerm] = useState("");
 const [searchResults, setSearchResults] = useState([]);
 const [brandId, setBrandId] = useState();
 const [selecTypeId, setSelectTypeId] = useState();
 const [brandName, setBrandName] = useState("");
 const [isBrandSelected, setIsBrandSelected] = useState(false);

 const apiUrl = "https://rona.thoth.lt/api/video_game_list";
 const token = Cookies.get("token");

 useEffect(() => {
  if (searchTerm.trim() !== "") {
   axios
    .post(
     apiUrl,
     { name: searchTerm },
     {
      headers: {
       Authorization: `Bearer ${token}`,
      },
     }
    )
    .then((response) => {
     setSearchResults(response.data);
    })
    .catch((error) => {
     console.error(error);
    });
  } else {
   setSearchResults([]);
  }
 }, [searchTerm, token]);

 function handleBrandClick(brandId) {
  setBrandId(brandId);
  setIsBrandSelected(true);
 }
 function handleAllClick(selecTypeId) {
  setSelectTypeId(selecTypeId);
 }

 function handleBrandName(brandName) {
  setBrandName(brandName);
 }

 return (
  <div className="flex bg-[rgba(0,0,0,.5)] mt-3 rounded-[20px] overflow-hidden">
   <VideoGamesLeft
    handleBrandClick={handleBrandClick}
    handleAllClick={handleAllClick}
    handleBrandName={handleBrandName}
    setIsBrandSelected={setIsBrandSelected}
   />
   <div className="flex-1 flex flex-col">
    <VideoGamesTop />
    <div className="p-[20px] bg-[#222] rounded-bl-[20px]">
     <div className="bg-[#222] relative flex justify-between w-[100%]">
      <span className="flex-1 flex">
       <FaSearch className="absolute text-[#999] top-[2px] left-[1px] bottom-[1px] w-[40px] h-[35px] border border-r-[#DDD] flex justify-center items-center transition-all" />
       <input
        placeholder="Write the game name"
        className="w-[100%] p-[5px] text-[18px] font-bold outline-none shadow-slot-search border border-[#999] rounded-[5px] pl-[50px]"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
       />
      </span>
     </div>
    </div>
    <div>
     {searchTerm.trim() === "" && !isBrandSelected && <VideoGamesFree />}
    </div>
    <VideoGamesAll
     name={searchTerm}
     searchResults={searchResults}
     brandId={brandId}
     typeId={selecTypeId}
     brandName={brandName}
    />
   </div>
  </div>
 );
}

export default VideoGamesPage;
