import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  dailyReportPieceValueUpdate,
  dailyReportUserInputValueUpdate,
  setBackgroundColor,
  setTextColor,
} from "../redux/actions/actions";
import { connect } from "react-redux";
import DailyUsersInput from "./dailyReport/DailyUsersInput";
import { ErrorAlert, ErrorUnAlert, IsLoading } from "./index";
import Language from "../language.json";
import Cookies from "js-cookie";
const ReportDetails = ({
  dailyReportPiece,
  dailyReportPieceValueUpdate,
  dailyReportBet,
  dailyReportUserInput,
  dailyReportUserInputValueUpdate,
  backgroundColor,
  textColor,
}) => {
  const lang = "en";
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [resetPost, setResetPost] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);

  const token = Cookies.get("token");
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/getdetailreport";

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    var birinciTarih = new Date(today);
    birinciTarih.setDate(today.getDate() - (today.getDay() + 5) % 7);
    birinciTarih.setHours(0, 0, 0, 0);
    return birinciTarih;
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    var ikinciTarih = new Date(today);
    ikinciTarih.setDate(today.getDate() + 1);
    ikinciTarih.setHours(23, 59, 59, 999);
    return ikinciTarih;
  });
 const days = ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct']
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

const tr = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}
  const formatDate2 = (dateString) => {
    const tarih = new Date(dateString);
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0');
    const gun = String(tarih.getDate()).padStart(2, '0');
    const saat = String(tarih.getHours()).padStart(2, '0');
    const dakika = String(tarih.getMinutes()).padStart(2, '0');
    const saniye = String(tarih.getSeconds()).padStart(2, '0');
    const milisaniye = String(tarih.getMilliseconds()).padStart(3, '0');
    
    const isoTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}.${milisaniye}Z`;
    return isoTarih;
};
  useEffect(() => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
    };
    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
      });


  }, [resetPost]);
  const showChanges = () => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
      coupon_length: parseInt(dailyReportPiece),
      sportid: dailyReportBet,
      user: parseInt(dailyReportUserInput),
    };

    if (dailyReportUserInput.trim() === "") {
      delete postData.user;
    }
    if (dailyReportPiece.trim() === "") {
      delete postData.coupon_length;
    }
    setIsLoading(true);
    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.error) {
          setErrorAlert(response.data.error);
        }
        if (response.data.errorUn) {
          setErrorUnAlert(response.data.errorUn);
        }
        setIsLoading(false);
        setDatas(response.data);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };

  const handleStartDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(0);
    date.setMinutes(0);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(23);
    date.setMinutes(59);
    setEndDate(date);
  };

  const handleReset = () => {
    dailyReportPieceValueUpdate("");
    dailyReportUserInputValueUpdate("");
    const resetStartDate = new Date();
    resetStartDate.setHours(0, 0, 0, 0);
    setStartDate(resetStartDate);
    const resetEndDate = new Date();
    resetEndDate.setHours(23, 59, 59, 999);
    setEndDate(resetEndDate);
    setResetPost(!resetPost);
  };

  const sortedDatas = [...datas];
  sortedDatas?.sort((a, b) => {
    return a?.username?.localeCompare(b?.username);
  });

  function getWorkingModeDescription(workingMode) {
    const parts = workingMode.split(" ");
    const mode = parts[0];
    const percent = parts[1];

    let description = "";

    if (mode === "0") {
      description = `${Language[lang].ReportDetailsPage.description1} ${percent}%`;
    } else if (mode === "1") {
      description = `${Language[lang].ReportDetailsPage.description2} ${percent}%`;
    } else if (mode === "2") {
      description = `${Language[lang].ReportDetailsPage.description3} ${percent}%`;
    } else {
      description = `${Language[lang].ReportDetailsPage.description4} ${percent}%`;
    }

    return description;
  }
  const couponTotalAmount = datas.reduce(
    (total, item) => total + item.total_amount,
    0
  );
  const totalWinAmount = datas.reduce(
    (total, item) => total + item.win_total,
    0
  );
  const totalLoseAmount = datas.reduce(
    (total, item) => total + item.loser_total,
    0
  );
  const totalContinuingAmount = datas.reduce(
    (total, item) => total + item.continuing_total,
    0
  );
  const totalCancelAmount = datas.reduce(
    (total, item) => total + item.cancel_total,
    0
  );
  const totalStatus = datas.reduce((total, item) => total + item.status, 0);
  const totalComission = datas.reduce(
    (total, item) => total + item.comission,
    0
  );
  const totalNetCase = totalStatus - totalComission - totalContinuingAmount;

  const user_type = Cookies.get("user_type");

  return (
    <div>
      {errorAlert && (
        <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
      )}
      {errorUnAlert && (
        <ErrorUnAlert
          errorUnAlert={errorUnAlert}
          setErrorUnAlert={setErrorUnAlert}
        />
      )}
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
        {Language[lang].ReportDetailsPage.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[20px] pb-5">
        <div className="forms-all-coupons flex flex-wrap xl:flex-nowrap justify-center xl:justify-start items-end forms-all-coupons-box-shadow pt-[5px] pr-[5px] pb-[10px] pl-[15px] min:h-20 relative border-[1px] border-t-[#FFF]">
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={() => window.location.reload()}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language[lang].ReportDetailsPage.textRefresh}
            </button>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <DailyUsersInput textColor={textColor} />
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] ml-[50px] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
              {Language[lang].ReportDetailsPage.textStartDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
              {Language[lang].ReportDetailsPage.textEndDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
              {Language[lang].ReportDetailsPage.textPiece}
            </span>
            <div className="">
              <select
                name="allCouponsPiece"
                id="allCouponsPiece"
                value={dailyReportPiece}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  dailyReportPieceValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="0">
                  {Language[lang].ReportDetailsPage.textOption1}
                </option>
                <option value="1">
                  {Language[lang].ReportDetailsPage.textOption2}
                </option>
                <option value="2">
                  {Language[lang].ReportDetailsPage.textOption3}
                </option>
                <option value="3">
                  {Language[lang].ReportDetailsPage.textOption4}
                </option>
                <option value="4">
                  {Language[lang].ReportDetailsPage.textOption5}
                </option>
                <option value="5">
                  {Language[lang].ReportDetailsPage.textOption6}
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              id="showChanges"
              onClick={showChanges}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language[lang].ReportDetailsPage.textShow}
            </button>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={handleReset}
              className="forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[#333] text-[#fff] cursour-pointer transition-all duration-300"
            >
              {Language[lang].ReportDetailsPage.textReset}
            </button>
          </div>
        </div>
        <table className="w-[100%] mt-[10px]">
          <thead>
            <tr className="bg-[#ccc] w-[100%]">
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px]">
                {Language[lang].ReportDetailsPage.textTd1}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd2}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd3}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd4}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd5}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd6}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd7}
              </td>
              {Number(user_type) !== 5 && (
                <>
                  <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                    {Language[lang].ReportDetailsPage.textTd8}
                  </td>
                  <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                    {Language[lang].ReportDetailsPage.textTd9}
                  </td>
                </>
              )}
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
                {Language[lang].ReportDetailsPage.textTd10}
              </td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div className="flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center">
                {" "}
                <IsLoading />{" "}
              </div>
            ) : (
              sortedDatas.map((data) => (
                <tr>
                  <td className="transition-all duration-100 p-[10px] border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    {data.username}
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div className="flex justify-between px-1">
                      <div>({data.coupon_count})</div>
                      <div>{data.total_amount}</div>
                    </div>
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div className="flex justify-between px-1 text-green-600">
                      <div>({data.win_count})</div>
                      <div>{data.win_total}</div>
                    </div>
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div className="flex justify-between px-1 text-red-600">
                      <div>({data.loser_count})</div>
                      <div>{data.loser_total}</div>
                    </div>
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div className="flex justify-between px-1">
                      <div>({data.continuing_count})</div>
                      <div>{data.continuing_total}</div>
                    </div>
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-medium">
                    <div className="flex justify-between px-1 text-gray-500">
                      <div>({data.cancel_count})</div>
                      <div>{data.cancel_total}</div>
                    </div>
                  </td>
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div
                      className={`text-end px-1 ${
                        data.status >= 0 ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      <div>{data.status}</div>
                    </div>
                  </td>
                  {Number(user_type) !== 5 && (
                    <>
                      <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                        <div className="text-end px-1">
                          <div>
                            {getWorkingModeDescription(data.working_mode)}
                          </div>
                        </div>
                      </td>
                      <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                        <div className="text-end px-1">
                          <div>{data.comission}</div>
                        </div>
                      </td>
                    </>
                  )}
                  <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                    <div
                      className={`text-end px-1 ${
                        data.status - data.comission >= 0
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      <div>{data.status - data.comission - data.continuing_total}</div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <thead>
            <tr className="bg-[#ccc] w-[100%]">
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px]"></td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1">
                  <div>{couponTotalAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1 text-green-600">
                  <div>{totalWinAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1 text-red-600">
                  <div>{totalLoseAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1">
                  <div>{totalContinuingAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1 text-gray-500">
                  <div>{totalCancelAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div>
                  <div
                    className={`${
                      totalStatus >= 0 ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {totalStatus}
                  </div>
                </div>
              </td>
              {
                Number(user_type) !== 5 &&
                <>
                <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1">
                  <div></div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1">
                  <div>{totalComission}</div>
                </div>
              </td>
              </>
              }
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div
                  className={`${
                    totalNetCase >= 0 ? "text-green-600" : "text-red-600"
                  }`}
                >
                  <div>{totalNetCase}</div>
                </div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dailyReportPiece: state.dailyReportPiece,
  dailyReportUserInput: state.dailyReportUserInput,
  textColor: state.textColor,
  backgroundColor: state.backgroundColor,
});

const mapDispatchToProps = {
  dailyReportPieceValueUpdate,
  dailyReportUserInputValueUpdate,
  setBackgroundColor,
  setTextColor,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
