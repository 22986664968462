import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  dailyReportPieceValueUpdate,
  dailyReportBetValueUpdate,
  dailyReportUserInputValueUpdate,
  setBackgroundColor,
  setTextColor
} from "../redux/actions/actions";
import { connect } from "react-redux";
import DailyUsersInput from "./dailyReport/DailyUsersInput";
import DailyUsersBet from "./dailyReport/DailyUsersBet";
import IsLoading from "./IsLoading";
import Cookies from "js-cookie";
import Language from '../language.json'
import ErrorAlert from "./ErrorAlert";
import ErrorUnAlert from "./ErrorUnAlert";
const DailyReport = ({
  dailyReportPiece,
  dailyReportPieceValueUpdate,
  dailyReportBet,
  dailyReportBetValueUpdate,
  dailyReportUserInput,
  dailyReportUserInputValueUpdate,
  backgroundColor,
  textColor
}) => {

  const [datas, setDatas] = useState([]);
  const [totalCouponCount, setTotalCouponCount] = useState(0);
  const [couponTotalAmount, setCouponTotalAmount] = useState(0);
  const [totalWinCount, setTotalWinCount] = useState(0);
  const [totalWinAmount, setTotalWinAmount] = useState(0);
  const [totalLoseCount, setTotalLoseCount] = useState(0);
  const [totalLoseAmount, setTotalLoseAmount] = useState(0);
  const [totalContinuingCount, setTotalContinuingCount] = useState(0);
  const [totalContinuingAmount, setTotalContinuingAmount] = useState(0);
  const [totalCancelCount, setTotalCancelCount] = useState(0);
  const [totalCancelAmount, setTotalCancelAmount] = useState(0);
  const [totalStatus, setTotalStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [resetPost,setResetPost] = useState(false)
  const [errorAlert, setErrorAlert] = useState(null);
  const [errorUnAlert, setErrorUnAlert] = useState(null);
  const token = Cookies.get("token")
  const apiPostUrl = process.env.REACT_APP_API_URL + "/api/getreport";
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  });
 
  useEffect(() => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
    }
    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDatas(response.data)
        setIsLoading(false)
      }
      )
      
  },[resetPost]);
  const showChanges = () => {
    const postData = {
      date1: formatDate2(startDate),
      date2: formatDate2(endDate),
      coupon_length: parseInt(dailyReportPiece),
      sportid: dailyReportBet,
      user: parseInt(dailyReportUserInput),
    };

    if (dailyReportUserInput.trim() === "") {
      delete postData.user;
    }
    if (dailyReportPiece.trim() === "") {
      delete postData.coupon_length;
    }
    if (dailyReportBet.trim() === "") {
      delete postData.sportid;
    }
    setIsLoading(true);
    axios
      .post(apiPostUrl, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(response.data.error){
          setErrorAlert(response.data.error)
        }
        if(response.data.errorUn){
          setErrorUnAlert(response.data.errorUn)
        }
        setIsLoading(false);
        const couponCountTotal = response.data.reduce(
          (total, item) => total + parseInt(item.coupon_count, 10),
          0
        );
        
        const couponTotalAmount = response.data.reduce(
          (total, item) => total + parseFloat(item.total_amount),
          0
        );
        
        const totalWinCount = response.data.reduce(
          (total, item) => total + parseInt(item.win_count, 10),
          0
        );
        
        const totalWinAmount = response.data.reduce(
          (total, item) => total + parseFloat(item.win_total),
          0
        );
        
        const totalLoseCount = response.data.reduce(
          (total, item) => total + parseInt(item.loser_count, 10),
          0
        );
        
        const totalLoseAmount = response.data.reduce(
          (total, item) => total + parseFloat(item.loser_total),
          0
        );
        const totalContinuingCount = response.data.reduce(
          (total, item) => total + parseInt(item.continuing_count, 10),
          0
        );
        
        const totalContinuingAmount = response.data.reduce(
          (total, item) => total + parseFloat(item.continuing_total),
          0
        );

        const totalCancelCount = response.data.reduce(
          (total, item) => total + parseInt(item.cancel_count, 10),
          0
        );
        
        const totalCancelAmount = response.data.reduce(
          (total, item) => total + parseFloat(item.cancel_total),
          0
        );

        const totalStatus = response.data.reduce(
          (total, item) => total + parseFloat(item.statuss),
          0
        );

        setDatas(response.data);
        setTotalCouponCount(couponCountTotal);
        setCouponTotalAmount(couponTotalAmount);
        setTotalWinCount(totalWinCount);
        setTotalWinAmount(totalWinAmount);
        setTotalLoseCount(totalLoseCount);
        setTotalLoseAmount(totalLoseAmount);
        setTotalContinuingCount(totalContinuingCount);
        setTotalContinuingAmount(totalContinuingAmount);
        setTotalCancelCount(totalCancelCount);
        setTotalCancelAmount(totalCancelAmount);
        setTotalStatus(totalStatus);
      })
      .catch((error) => {
        console.error("API ERROR:", error);
      });
  };

  const handleStartDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(0);
    date.setMinutes(0);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    date.setDate(date.getDate());
    date.setHours(23);
    date.setMinutes(59);
    setEndDate(date);
  };

  const handleReset = () => {
    dailyReportPieceValueUpdate("");
    dailyReportBetValueUpdate("");
    dailyReportUserInputValueUpdate("");
    const resetStartDate = new Date();
    resetStartDate.setHours(0, 0, 0, 0);
    setStartDate(resetStartDate);
    const resetEndDate = new Date();
    resetEndDate.setHours(23, 59, 59, 999);
    setEndDate(resetEndDate);
    setResetPost(!resetPost)
  };
  const formatDate = (dateString) => {
    const options = {
      hour12: false
    }
    const formattedDate = new Date(dateString).toLocaleDateString("tr-TR",options)
    return formattedDate
  }
  const days = ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'];
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

const tr = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}
  const formatDate2 = (dateString) => {
    const tarih = new Date(dateString);
    const yil = tarih.getFullYear();
    const ay = String(tarih.getMonth() + 1).padStart(2, '0');
    const gun = String(tarih.getDate()).padStart(2, '0');
    const saat = String(tarih.getHours()).padStart(2, '0');
    const dakika = String(tarih.getMinutes()).padStart(2, '0');
    const saniye = String(tarih.getSeconds()).padStart(2, '0');
    const milisaniye = String(tarih.getMilliseconds()).padStart(3, '0');
    
    const isoTarih = `${yil}-${ay}-${gun}T${saat}:${dakika}:${saniye}.${milisaniye}Z`;
    return isoTarih;
};
  return (
    <div>
      {
        errorAlert && (
          <ErrorAlert
          errorAlert={errorAlert}
          setErrorAlert={setErrorAlert}
          />
        )
      }
      {
      errorUnAlert && (
        <ErrorUnAlert
        errorUnAlert={errorUnAlert}
        setErrorUnAlert={setErrorUnAlert}
        />
      )
      }
      <div className="managementPanel bg-[#222] rounded-t-[15px] px-[50px] py-[20px] text-2xl text-white relative font-bold">
      {Language["en"].dailyReport.title}
      </div>
      <div className="bg-[#dddddd] rounded-b-[20px] pb-5">
        <div className="forms-all-coupons flex flex-wrap xl:flex-nowrap justify-center xl:justify-start items-end forms-all-coupons-box-shadow pt-[5px] pr-[5px] pb-[10px] pl-[15px] min:h-20 relative border-[1px] border-t-[#FFF]">
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={() => window.location.reload()}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language["en"].dailyReport.refreshButton}
            </button>
          </div>
          { Number(Cookies.get("user_type")) !== 9 &&
            <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <DailyUsersInput textColor={textColor}/>
          </div>
          }
          <div className="w-[100px] flex flex-col justify-center h-[100%] ml-[50px] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
              {Language["en"].dailyReport.startingDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].dailyReport.endDate}
            </span>
            <div className="h-[37px]">
              <DatePicker locale={tr}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <span className="text-[13px] font-semibold py-[5px] px-[3px]">
            {Language["en"].dailyReport.piece}
            </span>
            <div className="">
              <select
                name="allCouponsPiece"
                id="allCouponsPiece"
                value={dailyReportPiece}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  dailyReportPieceValueUpdate(selectedValue);
                }}
                className="forms-select-box-shadow p-[5px] h-[37px] dashboard-selects bg-[#f9f9f9] w-[100px] rounded-[3px] shadow-md text-[14px] font-semibold outline-none cursor-pointer border-[1px] border-[#999] focus:shadow-md"
                onFocus={(e) => {
                  e.target.style.borderColor = `${textColor}`;
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#999";
                  e.target.style.boxShadow = "none";
                }}
              >
                <option value="0">{Language["en"].dailyReport.pieceAll}</option>
                <option value="1">{Language["en"].dailyReport.piece1}</option>
                <option value="2">{Language["en"].dailyReport.piece2}</option>
                <option value="3">{Language["en"].dailyReport.piece3}</option>
                <option value="4">{Language["en"].dailyReport.piece4}</option>
                <option value="5">{Language["en"].dailyReport.piece5}</option>
              </select>
            </div>
          </div>
          <div className="w-[100px] flex flex-col justify-center h-[100%] mr-[15px]">
            <DailyUsersBet textColor={textColor}/>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
            id="showChanges"
              onClick={showChanges}
              className={`forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[${backgroundColor}] text-[#fff] cursour-pointer transition-all duration-300`}
            >
              {Language["en"].dailyReport.showButton}
            </button>
          </div>
          <div className="flex flex-col justify-end h-[100%] mr-[15px]">
            <button
              onClick={handleReset}
              className="forms-button flex items-center justify-center w-[100%] h-[36px] p-[10px] text-[12px] font-semibold outline-none border-[1px] border-[rgba(0,0,0,.2)] rounded-[3px] bg-[#333] text-[#fff] cursour-pointer transition-all duration-300"
            >
              {Language["en"].dailyReport.resetButton}
            </button>
          </div>
        </div>
        <table className="w-[100%] mt-[10px]">
          <thead>
            <tr className="bg-[#ccc] w-[100%]">
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px]">
              {Language["en"].dailyReport.date}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.deposited}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.win}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.lose}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.continuing}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.cancel}
              </td>
              <td className="py-[15px] px-[10px] bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] text-end">
              {Language["en"].dailyReport.status}
              </td>
            </tr>
          </thead>
          <tbody>
            {isLoading ?  
            <div className='flex w-full min-h-[100px] ml-[100%] mt-3 justify-center items-center'> <IsLoading/> </div>
          : datas.map((data,index) => (
              <tr key={index}>
                <td className="transition-all duration-100 p-[10px] border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  {formatDate(data.date)}
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  <div className="flex justify-between px-1">
                    <div>({data.coupon_count})</div>
                    <div>{data.total_amount}</div>
                  </div>
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  <div className="flex justify-between px-1 text-green-600">
                    <div>({data.win_count})</div>
                    <div>{data.win_total}</div>
                  </div>
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  <div className="flex justify-between px-1 text-red-600">
                    <div>({data.loser_count})</div>
                    <div>{data.loser_total}</div>
                  </div>
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  <div className="flex justify-between px-1">
                    <div>({data.continuing_count})</div>
                    <div>{data.continuing_total}</div>
                  </div>
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-medium">
                  <div className="flex justify-between px-1 text-gray-500">
                    <div>({data.cancel_count})</div>
                    <div>{data.cancel_total}</div>
                  </div>
                </td>
                <td className="transition-all duration-100 border-b-[1px] border-b-[rgba(0,0,0,0.1)] border-t-[1px] border-t-[rgba(255,255,255,0.5)] text-xs bg-[#f9f9f9] font-bold">
                  <div className="text-end px-1 text-green-600">
                    <div>{data.statuss}</div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <thead>
            <tr className="bg-[#ccc] w-[100%]">
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px]"></td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="flex justify-between px-1">
                  <div>({totalCouponCount})</div>
                  <div>{couponTotalAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="flex justify-between px-1 text-green-600">
                  <div>({totalWinCount})</div>
                  <div>{totalWinAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="flex justify-between px-1 text-red-600">
                  <div>({totalLoseCount})</div>
                  <div>{totalLoseAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="flex justify-between px-1">
                  <div>({totalContinuingCount})</div>
                  <div>{totalContinuingAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="flex justify-between px-1 text-gray-500">
                  <div>({totalCancelCount})</div>
                  <div>{totalCancelAmount}</div>
                </div>
              </td>
              <td className="bg-[#ccc] font-semibold border-r-[1px] border-r-[rgba(255,255,255,0.3)] border-l-[1px] border-l-[rgba(0,0,0,0.1)] border-b-[2px] border-b-[rgba(0,0,0,0.3)] border-t-[1px] border-t-[#fff] text-[13px] h-[47px] text-end">
                <div className="text-end px-1 text-green-600">
                  <div>{totalStatus}</div>
                </div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dailyReportPiece: state.dailyReportPiece,
  dailyReportBet: state.dailyReportBet,
  dailyReportUserInput: state.dailyReportUserInput,
  backgroundColor: state.backgroundColor,
  textColor: state.textColor
});

const mapDispatchToProps = {
  dailyReportPieceValueUpdate,
  dailyReportBetValueUpdate,
  dailyReportUserInputValueUpdate,
  setBackgroundColor,
  setTextColor
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyReport);
